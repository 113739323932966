import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enCommon from "../locales/en/common.json";
import enCustomerFeedback from "../locales/en/customerFeedback.json";
import enAppointments from "../locales/en/appointments.json";
import enDashboard from "../locales/en/dashboard.json";
import enAppointmentsAndOrders from "../locales/en/appointmentsAndOrder.json";
import enLocation from "../locales/en/location.json";
import enModals from "../locales/en/modals.json";
import enNotes from "../locales/en/notes.json";
import enOrders from "../locales/en/orders.json";
import enPricings from "../locales/en/pricings.json";
import enSession from "../locales/en/session.json";
import enSettings from "../locales/en/settings.json";
import enUsers from "../locales/en/users.json";
import enShopInformation from "../locales/en/shopInformation.json";
import enCarousel from "../locales/en/carousel.json";
import enPromotion from "../locales/en/promotions.json";
import frCaCommon from "../locales/fr-ca/common.json";
import frCaCustomerFeedback from "../locales/fr-ca/customerFeedback.json";
import frCaAppointments from "../locales/fr-ca/appointments.json";
import frCaDashboard from "../locales/fr-ca/dashboard.json";
import frCaAppointmentsAndOrders from "../locales/fr-ca/appointmentsAndOrder.json";
import frCaLocation from "../locales/fr-ca/location.json";
import frCaModals from "../locales/fr-ca/modals.json";
import frCaNotes from "../locales/fr-ca/notes.json";
import frCaOrders from "../locales/fr-ca/orders.json";
import frCaPricings from "../locales/fr-ca/pricings.json";
import frCaSession from "../locales/fr-ca/session.json";
import frCaSettings from "../locales/fr-ca/settings.json";
import frCaUsers from "../locales/fr-ca/users.json";
import frCaShopInformation from "../locales/fr-ca/shopInformation.json";
import frCaCarousel from "../locales/fr-ca/carousel.json";
import frCaPromotion from "../locales/fr-ca/promotions.json";
import esCommon from "../locales/es-us/common.json";
import esCustomerFeedback from "../locales/es-us/customerFeedback.json";
import esAppointments from "../locales/es-us/appointments.json";
import esDashboard from "../locales/es-us/dashboard.json";
import esAppointmentsAndOrders from "../locales/es-us/appointmentsAndOrder.json";
import esLocation from "../locales/es-us/location.json";
import esModals from "../locales/es-us/modals.json";
import esNotes from "../locales/es-us/notes.json";
import esOrders from "../locales/es-us/orders.json";
import esPricings from "../locales/es-us/pricings.json";
import esSession from "../locales/es-us/session.json";
import esSettings from "../locales/es-us/settings.json";
import esUsers from "../locales/es-us/users.json";
import esShopInformation from "../locales/es-us/shopInformation.json";
import esCarousel from "../locales/es-us/carousel.json";
import esPromotion from "../locales/es-us/promotions.json";

import { EN_LOCALE } from "../constants/actionTypes";

i18n.use(initReactI18next).init({
  lng: EN_LOCALE,
  ns: {
    namespaces: [
      "common",
      "customerFeedback",
      "appointments",
      "dashboard",
      "appointmentsAndOrders",
      "location",
      "modals",
      "notes",
      "orders",
      "pricings",
      "session",
      "settings",
      "shopInformation",
      "users",
      "modals",
      "carousel",
      "promotion",
    ],
    defaultNS: "common",
  },
  resources: {
    en: {
      common: enCommon,
      customerFeedback: enCustomerFeedback,
      appointments: enAppointments,
      dashboard: enDashboard,
      appointmentsAndOrders: enAppointmentsAndOrders,
      location: enLocation,
      modals: enModals,
      notes: enNotes,
      orders: enOrders,
      pricings: enPricings,
      session: enSession,
      settings: enSettings,
      shopInformation: enShopInformation,
      users: enUsers,
      carousel: enCarousel,
      promotion: enPromotion,
    },
    "fr-CA": {
      common: frCaCommon,
      customerFeedback: frCaCustomerFeedback,
      appointments: frCaAppointments,
      dashboard: frCaDashboard,
      appointmentsAndOrders: frCaAppointmentsAndOrders,
      location: frCaLocation,
      modals: frCaModals,
      notes: frCaNotes,
      orders: frCaOrders,
      pricings: frCaPricings,
      session: frCaSession,
      settings: frCaSettings,
      shopInformation: frCaShopInformation,
      users: frCaUsers,
      carousel: frCaCarousel,
      promotion: frCaPromotion,
    },
    "es-US": {
      common: esCommon,
      customerFeedback: esCustomerFeedback,
      appointments: esAppointments,
      dashboard: esDashboard,
      appointmentsAndOrders: esAppointmentsAndOrders,
      location: esLocation,
      modals: esModals,
      notes: esNotes,
      orders: esOrders,
      pricings: esPricings,
      session: esSession,
      settings: esSettings,
      shopInformation: esShopInformation,
      users: esUsers,
      carousel: esCarousel,
      promotion: esPromotion,
    },
  },
  fallbackLng: EN_LOCALE,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
