import React, { Component } from "react";
import { Form, Button } from "semantic-ui-react";

export default class ResetPasswordForm extends Component {
  state = {
    formData: {
      password: "",
      passwordConfirmation: "",
    },
    errors: {},
  };

  onFieldChange = (field, e) => {
    let updatedFormData = { ...this.state.formData };
    updatedFormData[field] = e.target.value;
    this.setState({ formData: updatedFormData });
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") this.validateAndSubmit();
  };

  formDataErrors = () => {
    const { password, passwordConfirmation } = this.state.formData;
    const errors = {};

    if (password !== passwordConfirmation) {
      errors.password = "Mismatch";
      errors.passwordConfirmation = "Mismatch";
      return errors;
    }

    if (password.length < 8)
      errors.password = "Must be at least 8 characters long";

    if (passwordConfirmation.length < 8)
      errors.passwordConfirmation = "Must be at least 8 characters long";

    return errors;
  };

  validateAndSubmit = () => {
    const errors = this.formDataErrors();

    if (Object.keys(errors).length === 0) {
      const { formData } = this.state;

      this.props.onSubmit(formData);
    } else {
      this.setState({ errors: errors });
    }
  };

  render() {
    const { password, passwordConfirmation } = this.state.formData;
    const { errors } = this.state;
    const { isSubmitting, t } = this.props;

    return (
      <Form error className="no-session-form">
        <Form.Input
          value={password}
          onChange={(e) => this.onFieldChange("password", e)}
          fluid
          label="PASSWORD"
          type="password"
          size="huge"
          error={errors.password}
        />
        <Form.Input
          value={passwordConfirmation}
          onChange={(e) => this.onFieldChange("passwordConfirmation", e)}
          fluid
          label="PASSWORD CONFIRMATION"
          type="password"
          size="huge"
          error={errors.passwordConfirmation}
        />

        <div className="flex-container" style={{ marginTop: "30px" }}>
          <div style={{ width: "100%", textAlign: "right" }}>
            <Button
              className={"button-primary no-session-button"}
              primary
              disabled={isSubmitting}
              onClick={this.validateAndSubmit}
            >
              {t("updatePassword")}
            </Button>
          </div>
        </div>
      </Form>
    );
  }
} // ResetPasswordForm
