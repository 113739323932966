import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { updateAppointmentStatus } from "../actions/sagas/appointmentSaga";
import store from "../actions/configureStore";
import i18n from "../utils/i18n";

function onAccept(appointment, currentShopId, dispatch) {
  const payload = {
    id: appointment.id,
    isAppointmentConfirmed: 1,
    shop_id: currentShopId,
  };
  store.dispatch(updateAppointmentStatus({ payload: payload }));
}

export function acceptButton(appointment, currentShopId, dispatch) {
  return (
    <Button
      style={{ margin: "5px", width: "90%" }}
      size="small"
      compact
      positive
      onClick={() => onAccept(appointment, currentShopId, dispatch)}
    >
      <Icon name="check" />
      {i18n.t("dashboard:accept")}
    </Button>
  );
}

function onCheckIn(appointment, currentShopId, dispatch) {
  const payload = {
    id: appointment.id,
    isAppointmentConfirmed: 2,
    shop_id: currentShopId,
  };
  store.dispatch(updateAppointmentStatus({ payload: payload }));
}

export function checkInButton(appointment, currentShopId, dispatch) {
  return (
    <Button
      style={{ margin: "5px", width: "90%" }}
      size="small"
      compact
      positive
      onClick={() => onCheckIn(appointment, currentShopId, dispatch)}
    >
      <Icon name="check" />
      {i18n.t("dashboard:checkedIn")}
    </Button>
  );
}

function onInBay(appointment, currentShopId, dispatch) {
  const payload = {
    id: appointment.id,
    isAppointmentConfirmed: 3,
    shop_id: currentShopId,
  };
  store.dispatch(updateAppointmentStatus({ payload: payload }));
}

export function inBayButton(appointment, currentShopId, dispatch) {
  return (
    <Button
      style={{ margin: "5px", width: "90%" }}
      size="small"
      compact
      positive
      onClick={() => onInBay(appointment, currentShopId, dispatch)}
    >
      <Icon name="check" />
      {i18n.t("dashboard:inBay")}
    </Button>
  );
}

function onWaitingParts(appointment, currentShopId, dispatch) {
  const payload = {
    id: appointment.id,
    isAppointmentConfirmed: 4,
    shop_id: currentShopId,
  };
  store.dispatch(updateAppointmentStatus({ payload: payload }));
}

export function waitingPartsButton(appointment, currentShopId, dispatch) {
  return (
    <Button
      style={{ margin: "5px", width: "90%" }}
      size="small"
      compact
      positive
      onClick={() => onWaitingParts(appointment, currentShopId, dispatch)}
    >
      <Icon name="check" />
      {i18n.t("dashboard:waitingForParts")}
    </Button>
  );
}

function onWaitingApproval(appointment, currentShopId, dispatch) {
  const payload = {
    id: appointment.id,
    isAppointmentConfirmed: 5,
    shop_id: currentShopId,
  };
  store.dispatch(updateAppointmentStatus({ payload: payload }));
}

export function waitingApprovalButton(appointment, currentShopId, dispatch) {
  return (
    <Button
      style={{ margin: "5px", width: "90%" }}
      size="small"
      compact
      positive
      onClick={() => onWaitingApproval(appointment, currentShopId, dispatch)}
    >
      <Icon name="check" />
      {i18n.t("waitingForApproval")}
    </Button>
  );
}

function onCompleted(appointment, currentShopId, dispatch) {
  const payload = {
    id: appointment.id,
    isAppointmentConfirmed: 6,
    shop_id: currentShopId,
  };
  store.dispatch(updateAppointmentStatus({ payload: payload }));
}

export function completeButton(appointment, currentShopId, dispatch) {
  return (
    <Button
      style={{ margin: "5px", width: "90%" }}
      size="small"
      compact
      positive
      onClick={() => onCompleted(appointment, currentShopId, dispatch)}
    >
      <Icon name="check" />
      {i18n.t("completed")}
    </Button>
  );
}
