import {
  formatInternalErrors,
  toString,
  consoleLog,
  catchErrors,
} from "../../helpers/apiErrorHelpers";
import {
  SHOPS_PATH,
  SHOPS_BUSINESS_DAYS,
  SET_SHOP_HOURS_LOADING,
  UNSET_SHOP_HOURS_LOADING,
  SHOP_LOAD_SUCCEEDED,
  BUSINESS_HOURS_LOAD_SUCCEEDED,
  SHOP_DETAILS_UPDATE_SUCCEEDED,
} from "../../constants/actionTypes";

import Notifications from "../../components/Notifications";
import store from "../configureStore";
import { putJSON, getJSON, putJSONNew } from "../../utils/fetch";
import i18n from "../../utils/i18n";

export function updateShop(action) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_SHOP_HOURS_LOADING });
      let user = getState().users || {};
      const { authentication_token } = user;

      const payload = action.payload || {};
      const {
        id,
        laborRate,
        retailLaborRate,
        mainEmail,
        mainPhoneNumber,
        addressLineOne,
        city,
        shopState,
        zip,
      } = payload;

      const request = {
        url: `${SHOPS_PATH}/${id}`,
        headers: { Authorization: authentication_token },
        body: {
          address_line1: addressLineOne,
          city: city,
          state: shopState,
          zip: zip,
          main_phone_number: mainPhoneNumber,
          main_email: mainEmail,
          labor_rate: laborRate,
          retail_labor_rate: retailLaborRate,
        },
      };

      const response = await putJSON(
        request.url,
        request.body,
        request.headers
      );
      catchErrors(response.result);

      await dispatch({
        type: SHOP_LOAD_SUCCEEDED,
        payload: { shop: response.result.shop },
      });
      await dispatch({ type: UNSET_SHOP_HOURS_LOADING });
      store.dispatch(
        Notifications.success({
          title: i18n.t("common:successTitle"),
          message: i18n.t("common:shopUpdatedMsg"),
          position: "tr",
          autoDismiss: 5,
        })
      );
    } catch (errors) {
      dispatch({ type: UNSET_SHOP_HOURS_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:shopUpdateFailedMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
    }
  };
}

export function loadBusinessHours(action) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_SHOP_HOURS_LOADING });
      let user = getState().users || {};
      const { authentication_token } = user;

      const shopId = action.shopId || {};

      const request = {
        url: `${SHOPS_BUSINESS_DAYS}?id=${shopId}`,
        headers: { Authorization: authentication_token },
        body: {},
      };

      const response = await getJSON(
        request.url,
        request.body,
        request.headers
      );
      catchErrors(response.result);

      await dispatch({
        type: BUSINESS_HOURS_LOAD_SUCCEEDED,
        payload: { businessHours: response.result.shop_business_days },
      });

      await dispatch({ type: UNSET_SHOP_HOURS_LOADING });
    } catch (errors) {
      dispatch({ type: UNSET_SHOP_HOURS_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:errorWhileLoadingShopMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
    }
  };
}

export function updateShopDetails(action) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_SHOP_HOURS_LOADING });
      let user = getState().users || {};
      const { authentication_token } = user;

      const id = action.id || {};
      const formDataBody = action.formDataBody || {};

      const request = {
        url: `${SHOPS_BUSINESS_DAYS}/${id}`,
        headers: { Authorization: authentication_token },
        body: formDataBody,
      };

      const response = await putJSONNew(
        request.url,
        request.body,
        request.headers
      );
      catchErrors(response.result);

      await dispatch({
        type: SHOP_DETAILS_UPDATE_SUCCEEDED,
        payload: { businessHours: response.result },
      });

      await dispatch({
        type: SHOP_LOAD_SUCCEEDED,
        payload: { shop: response.result.shop },
      });
      await dispatch(loadBusinessHours({ shopId: id }));

      await dispatch({ type: UNSET_SHOP_HOURS_LOADING });

      store.dispatch(
        Notifications.success({
          title: i18n.t("common:successTitle"),
          message: i18n.t("common:shopDetailsUpdatedMsg"),
          position: "tr",
          autoDismiss: 5,
        })
      );
    } catch (errors) {
      dispatch({ type: UNSET_SHOP_HOURS_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:shopDetailsUpdateFailedMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
    }
  };
}
