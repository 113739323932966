import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import store from "../actions/configureStore";
import { routerPaths, navigationPaths } from "../constants/paths";
import FontAwesome from "react-fontawesome";
import * as uiActions from "../actions/ui";
import * as arrayHelpers from "../helpers/arrayHelpers";
import { userSignedIn } from "../helpers/sessionHelpers";
import { getSubdomain } from "./shared/StylesheetInjector";
import ReactSVG from "react-svg";
import { isNTWTBC } from "../helpers/affiliationHelpers";
import { getStyles } from "./shared/StylesheetInjector";
import { selectShopOptions } from "../helpers/shopHelpers";
import { isNationalOrRegionalOrOwner } from "../helpers/userHelpers";
import { loadUser } from "../actions/sagas/userSaga";
import { withTranslation } from "react-i18next";
import TranslationDropdown from "./sessions/Translation";
import { SplitTreatments } from "@splitsoftware/splitio-react";
import { factoryAttributes, setupSplitFlags } from "./config/SplitClient";

const styles = getStyles();

class Sidebar extends React.Component {
  headerLogoSize(subdomain) {
    return (
      {
        caradvise: 70,
        ntw: 65,
      }[subdomain] || 208
    );
  }

  logoSize(subdomain) {
    return (
      {
        caradvise: 200,
        ntw: 150,
      }[subdomain] || 208
    );
  }

  constructor() {
    super();
    this.state = {
      navItems: [
        { path: routerPaths.dashboard, title: "users:home", icon: "home" },
        {
          path: routerPaths.location,
          title: "users:shopDetails",
          icon: "map-marker",
        },
        {
          path: routerPaths.pricing,
          title: "users:pricing",
          icon: "usd",
          icon2: "usd",
        },
        {
          path: routerPaths.promotions,
          title: "users:promotions",
          icon: "certificate",
        },
        {
          path: routerPaths.shopOrders,
          title: "users:shopOrders",
          icon: "calendar",
        },
        {
          path: routerPaths.customerFeedback,
          title: "users:customerFeedback",
          icon: "comments",
        },
      ],
      mobileView: false,
    };
    this.updateViewState = this.updateViewState.bind(this);
  }

  componentDidMount = () => {
    if (userSignedIn()) {
      store.dispatch(loadUser());
    }
    window.addEventListener("resize", this.updateViewState);
    setupSplitFlags.bind(this)({ sub_domain: getSubdomain() });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateViewState);
  }

  componentWillMount() {
    this.updateViewState();
  }

  changeCurrentShop = (e, data) => {
    const changedCurrentShopId = data.value;

    store.dispatch(uiActions.updateCurrentShopId({ id: changedCurrentShopId }));
  };

  toggleHandle = () => {
    let sidebar = document.querySelector(".sidebar");
    let sidebarBtn = document.querySelector(".bx-menu");
    sidebarBtn.addEventListener(
      "click",
      () => {
        sidebar.classList.toggle("close");
      },
      { once: true }
    );
  };

  updateViewState() {
    if (!this.state.mobileView && document.documentElement.clientWidth < 1080) {
      this.setState({
        mobileView: true,
        showSidebar: true,
      });
    } else if (document.documentElement.clientWidth > 1080) {
      this.setState({
        mobileView: true,
        showSidebar: true,
      });
    }
  }

  onShopChange = (e, { value }) => {
    this.setState({ value }, () => {
      this.setState({
        currentShopId: value,
      });
    });
    store.dispatch(uiActions.updateCurrentShopId({ id: value }));
  };

  render() {
    const { children, shops, currentShop, t } = this.props;
    const { currentShopId } = this.state;
    const sub = getSubdomain();
    const currentUser = store.getState().users || this.props.currentUser;
    const brandInitials =
      currentUser.brand_name && `${currentUser.brand_name.slice(0, 1)}`;
    const currentUserInitials =
      currentUser.first_name && currentUser.last_name
        ? `${
            currentUser.first_name.charAt(0) + currentUser.last_name.charAt(0)
          }`
        : "";

    let svgStyle = this.headerLogoSize(sub)
      ? { width: this.headerLogoSize(sub) }
      : {};
    let svgLogoStyle = this.logoSize(sub) ? { width: this.logoSize(sub) } : {};
    let headerLogo = `${sub}_white_logo_alt.svg`;
    let logo = `${sub}_white_logo.svg`;
    return (
      <div>
        <div className="sidebar close">
          <div className="logo-details">
            <Link
              className="logo-details"
              style={
                isNTWTBC()
                  ? { backgroundColor: styles.whiteTextColor }
                  : { backgroundColor: "none" }
              }
              to={navigationPaths.dashboard()}
            >
              <ReactSVG
                className="check-logo"
                src={`images/${headerLogo}`}
                svgStyle={svgStyle}
              />
              <ReactSVG
                className="logo_name"
                src={`images/${logo}`}
                svgStyle={svgLogoStyle}
              />
            </Link>
          </div>
          <div className="imgSelect">
            <div className="shop">{brandInitials} </div>
            <span>{currentUser.brand_name && currentUser.brand_name}</span>
          </div>
          <ul className="nav-links">
            {this.state.navItems.map((item) => (
              <li key={item.title}>
                <NavLink
                  activeClassName={"active-nav-link"}
                  exact
                  to={item.path}
                >
                  <FontAwesome className="fontawesome-icons" name={item.icon} />
                  <span className="link_name"> {t(item.title)} </span>
                </NavLink>
                <ul className="sub-menu blank">
                  <li>
                    <a className="link_name"> {t(item.title)} </a>
                  </li>
                </ul>
              </li>
            ))}
            <li>
              <div className="profile-details">
                <div className="profile-content">
                  <Dropdown
                    className="profile-initials"
                    text={currentUserInitials}
                    icon={null}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Header>
                        <h5>
                          {currentUser.first_name + " " + currentUser.last_name}
                        </h5>
                      </Dropdown.Header>
                      <Dropdown.Item>
                        <Link to={routerPaths.settings}>
                          {t("settingsText")}
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link to={navigationPaths.signOut()}>
                          {t("signOut")}
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="name-job">
                  <div className="profile_name">
                    {currentUser.first_name && currentUser.last_name ? (
                      <span className="my-account-name">
                        {currentUser.first_name + " " + currentUser.last_name}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="logout"></div>
              </div>
            </li>
          </ul>
        </div>
        <section className="home-section">
          <div className="home-content">
            <i className="bx bx-menu" onClick={() => this.toggleHandle()}></i>

            <span>
              <span className="page-header">
                {currentUser &&
                currentUser.shop_user_shops &&
                currentUser.shop_user_shops.length > 0 &&
                isNationalOrRegionalOrOwner(currentUser) ? (
                  <Dropdown
                    value={currentShopId || "null"}
                    options={selectShopOptions(shops, {
                      includeBlank: isNationalOrRegionalOrOwner(currentUser),
                      t: t,
                    })}
                    onChange={this.onShopChange}
                    selection
                  />
                ) : (
                  <Dropdown
                    value={currentShopId || (currentShop && currentShop.id)}
                    options={selectShopOptions(shops, {
                      includeBlank: isNationalOrRegionalOrOwner(currentUser),
                      t: t,
                    })}
                    onChange={this.onShopChange}
                    search={false}
                  />
                )}
              </span>
            </span>
          </div>
          {/* {children} */}
          <SplitTreatments
            names={["Show_Footer_Component"]}
            attributes={factoryAttributes}
          >
            {({ isReady, treatments }) => {
              return isReady ? (
                <>
                  {children}
                  <TranslationDropdown
                    treatments={treatments}
                    footerComponentConfig={this.state.footerComponentConfig}
                  />
                </>
              ) : (
                <TranslationDropdown />
              );
            }}
          </SplitTreatments>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  navOpen: state.ui.navOpen,
  shops: state.shops.userShops || [],
  currentUser: state.users.currentUser,
  currentShop:
    arrayHelpers.findObjectById(
      state.shops.userShops,
      state.shops.currentShopId
    ) || {},
  currentShopId: state.shops.currentShopId,
});

export default connect(mapStateToProps, { loadUser })(
  withTranslation("users")(Sidebar)
);
