import React from "react";
import * as arrayHelpers from "../../helpers/arrayHelpers";
import { connect } from "react-redux";
import {
  DATE_TIME_FORMAT,
  DAYS,
  DAY_ORDER_IN_WEEK,
} from "../../constants/dateTime";
import moment from "moment";

class ShopHours extends React.Component {
  showOpeningDays = () => {
    const { operationalBusinessDays, t } = this.props;
    let operationalDays =
      operationalBusinessDays &&
      operationalBusinessDays.sort((a, b) => {
        return a.order_in_week > b.order_in_week ? 1 : -1;
      });
    return (
      <ul style={{ listStyle: "none", marginLeft: "30px", padding: "0" }}>
        {operationalDays &&
          operationalDays.map((item) => (
            <li key={item.id}>
              <span>
                {item.order_in_week === DAY_ORDER_IN_WEEK.SUNDAY
                  ? t(DAYS.SUNDAY)
                  : item.order_in_week === DAY_ORDER_IN_WEEK.MONDAY
                  ? t(DAYS.MONDAY)
                  : item.order_in_week === DAY_ORDER_IN_WEEK.TUESDAY
                  ? t(DAYS.TUESDAY)
                  : item.order_in_week === DAY_ORDER_IN_WEEK.WEDNESDAY
                  ? t(DAYS.WEDNESDAY)
                  : item.order_in_week === DAY_ORDER_IN_WEEK.THURSDAY
                  ? t(DAYS.THURSDAY)
                  : item.order_in_week === DAY_ORDER_IN_WEEK.FRIDAY
                  ? t(DAYS.FRIDAY)
                  : item.order_in_week === DAY_ORDER_IN_WEEK.SATURDAY
                  ? t(DAYS.SATURDAY)
                  : ""}{" "}
                -
              </span>
              {item.open_time !== null &&
              item.close_time !== null &&
              item.open_time !== item.close_time ? (
                <span style={{ display: "inline-block", marginLeft: "10px" }}>
                  {moment(item.open_time)
                    .parseZone()
                    .format(DATE_TIME_FORMAT.LT)}{" "}
                  -{" "}
                  {moment(item.close_time)
                    .parseZone()
                    .format(DATE_TIME_FORMAT.LT)}
                </span>
              ) : (
                " " + t("closed")
              )}
            </li>
          ))}
      </ul>
    );
  };

  render() {
    const { operationalBusinessDays, t } = this.props;
    return (
      <React.Fragment>
        <div>
          <span className="headings">{t("openingHours")}</span>
          {operationalBusinessDays ? (
            <>{this.showOpeningDays(t)}</>
          ) : (
            <ul style={{ listStyle: "none", marginLeft: "30px", padding: "0" }}>
              {DAYS &&
                Object.values(DAYS).map((item) => (
                  <li key={item}>
                    <span>{t(item)}</span>
                  </li>
                ))}
            </ul>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
  currentShop:
    arrayHelpers.findObjectById(
      state.shops.userShops,
      state.shops.currentShopId
    ) || {},
});

export default connect(mapStateToProps)(ShopHours);
