import React from "react";
import { connect } from "react-redux";
import CustomLoader from "../elements/CustomLoader";
import { Card, Grid, Icon, Input } from "semantic-ui-react";
import ShopOrdersTable from "./ShopOrdersTable";
import Clock from "./Clock";
import Summary from "./Summary";
import { getStyles } from "../shared/StylesheetInjector";
import { CASE_INSENSITIVE } from "../../constants/application";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "../../constants/dateTime";
import moment from "moment";
import { isNationalOrRegionalOrOwner } from "../../helpers/userHelpers";
import _ from "lodash";
import { loadAppointments } from "../../actions/sagas/appointmentSaga";
import store from "../../actions/configureStore";
import { withTranslation } from "react-i18next";

const styles = getStyles();

class AppointmentsOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
    };
  }
  componentDidMount() {
    store.dispatch(loadAppointments({ payload: {} }));
  }

  handleSearch(data, searchString) {
    let searchValue = searchString.trim().toLowerCase();
    return data.filter(
      (e) =>
        (e.customer_name &&
          e.customer_name
            .toLowerCase()
            .match(new RegExp(searchValue, CASE_INSENSITIVE))) ||
        (e.vin &&
          e.vin
            .toLowerCase()
            .match(new RegExp(searchValue, CASE_INSENSITIVE))) ||
        (e.model &&
          e.model
            .toLowerCase()
            .match(new RegExp(searchValue, CASE_INSENSITIVE))) ||
        (e.make &&
          e.make
            .toLowerCase()
            .match(new RegExp(searchValue, CASE_INSENSITIVE))) ||
        (e.year &&
          e.year
            .toLowerCase()
            .match(new RegExp(searchValue, CASE_INSENSITIVE))) ||
        (e.appt_cancel_reason &&
          e.appt_cancel_reason
            .toLowerCase()
            .match(new RegExp(searchValue, CASE_INSENSITIVE))) ||
        (e.appt_cancel_by &&
          e.appt_cancel_by
            .toLowerCase()
            .match(new RegExp(searchValue, CASE_INSENSITIVE))) ||
        (e.appointment_datetime_pretty &&
          moment(e.appointment_datetime_pretty)
            .parseZone()
            .format(DATE_FORMAT.MM_DD_YYYY)
            .toLowerCase()
            .match(new RegExp(searchValue, CASE_INSENSITIVE))) ||
        (e.appointment_datetime_pretty &&
          moment(e.appointment_datetime_pretty)
            .parseZone()
            .format(DATE_TIME_FORMAT.HHMMAA)
            .toLowerCase()
            .match(new RegExp(searchValue, CASE_INSENSITIVE)))
    );
  }
  render() {
    const {
      currentShop,
      currentUser,
      currentShopId,
      isLoading,
      appointments,
      dispatch,
      t,
    } = this.props;
    const { search } = this.state;
    const { userAppointments } = appointments;
    const filteredAppointments =
      currentShopId !== null && currentShopId !== "null"
        ? userAppointments &&
          _.uniqBy(userAppointments, "id").filter(
            (app) => app.shop_id === currentShopId
          )
        : _.uniqBy(userAppointments, "id");
    const isNationalOrRegionalOrOwnerFlag =
      currentUser &&
      currentUser.shop_user_shops &&
      currentUser.shop_user_shops.length > 0 &&
      isNationalOrRegionalOrOwner(currentUser);
    return (
      <div className="full-shop-order-container">
        {isLoading && <CustomLoader />}
        {!isLoading && (
          <React.Fragment>
            <Card fluid>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <div>
                      <Clock
                        currentShop={currentShop}
                        isNationalOrRegionalOrOwnerFlag={
                          isNationalOrRegionalOrOwnerFlag
                        }
                        shops={currentUser.shops}
                        t={t}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column width={7}>
                    <Summary
                      appointments={this.handleSearch(
                        filteredAppointments,
                        search
                      )}
                      t={t}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <div className="top-right">
                      <Icon style={{ color: styles.red }} name="circle" />{" "}
                      <span
                        style={{
                          color: styles.red,
                          fontWeight: "bold",
                          fontSize: "10px",
                        }}
                      >
                        {" "}
                        {t("orders")}{" "}
                      </span>
                      <Icon
                        style={{
                          color: styles.overrideLinkColor,
                          marginLeft: "20px",
                        }}
                        name="circle"
                      />{" "}
                      <span
                        style={{
                          color: styles.overrideLinkColor,
                          fontWeight: "bold",
                          fontSize: "10px",
                        }}
                      >
                        {" "}
                        {t("appointments")}
                      </span>
                    </div>
                    <div className="div-search">
                      <Input
                        icon="search"
                        placeholder={t("searchDetailsPlaceholder")}
                        onChange={(e) =>
                          this.setState({ search: e.target.value })
                        }
                      />
                    </div>
                    <ShopOrdersTable
                      currentShopId={currentShopId}
                      isLoading={isLoading}
                      currentUser={currentUser}
                      appointments={this.handleSearch(
                        filteredAppointments,
                        search
                      )}
                      dispatch={dispatch}
                      t={t}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentShopId } = state.shops;
  const currentShop = state.shops.userShops.find(
    (s) => s.id === state.shops.currentShopId
  );
  const userAppointments = state.appointments;

  return {
    currentUser: state.users.currentUser,
    isLoading:
      state.application.isAppointmentsLoading || state.application.isLoading
        ? true
        : false,
    currentShop: currentShop,
    currentShopId: currentShopId,
    appointments: userAppointments,
  };
};

export default connect(mapStateToProps)(
  withTranslation("appointmentsAndOrders")(AppointmentsOrders)
);
