import React, { Component } from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import store from "../../actions/configureStore";
import { createNote } from "../../actions/sagas/noteSaga";
import { withTranslation } from "react-i18next";

class AddNoteModal extends Component {
  state = {
    formData: {
      title: "",
      comment: "",
    },
    errors: {},
  };

  onFieldChange = (field, e) => {
    let updatedFormData = { ...this.state.formData };
    updatedFormData[field] = e.target.value;
    this.setState({ formData: updatedFormData });
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      this.validateAndSubmit();
    }
  };

  formDataErrors = () => {
    const { title, comment } = this.state.formData;
    const errors = {};

    if (title.length === 0) errors.title = "Title is required";

    if (comment.length === 0) errors.comment = "Comment is required";

    return errors;
  };

  onCreateNote = async () => {
    const errors = this.formDataErrors();
    const { shop_order_id, currentShopId, currentUser } = this.props;

    if (Object.keys(errors).length === 0) {
      const { formData } = this.state;
      await store.dispatch(
        createNote({
          shop_order_id: shop_order_id,
          shop_id: currentShopId,
          title: formData.title,
          comment: formData.comment,
          created_by: currentUser.email,
        })
      );
    } else {
      this.setState({ errors: errors });
    }
  };

  onHandleClose = () => {
    const { close } = this.props;
    close();
    this.setState({ errors: {} });
  };

  render() {
    const { errors } = this.state;
    const { title, comment } = this.state.formData;
    const { open, t } = this.props;
    return (
      <Modal
        open={open}
        onClose={this.onHandleClose}
        closeIcon
        size="small"
        closeOnDimmerClick={false}
      >
        <Modal.Header>{t("addNote")}</Modal.Header>
        <Modal.Content>
          <Form error className="no-session-form">
            <Form.Group widths="equal">
              <Form.Input
                value={title}
                onChange={(e) => this.onFieldChange("title", e)}
                fluid
                label={t("title")}
                size="large"
                error={errors.title}
              />
            </Form.Group>
            <Form.TextArea
              value={comment}
              onChange={(e) => this.onFieldChange("comment", e)}
              label={t("note")}
              size="large"
              error={errors.comment}
              style={{ minHeight: 100 }}
              onClick={(e) => e.stopPropagation()}
              onKeyDown={(e) => (e.keyCode === 32 ? e.stopPropagation() : null)}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            circular
            content={t("addNote")}
            onClick={this.onCreateNote}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.application.isLoading,
  };
};

export default connect(mapStateToProps)(withTranslation("notes")(AddNoteModal));
