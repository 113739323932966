import React, { Component } from "react";
import {
  Button,
  Modal,
  Form,
  Divider,
  Grid,
  Message,
  Dropdown,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { handleKeypress } from "../../helpers/numberHelpers";
import unitedStates from "../../utils/unitedStates.json";
import { EMAIL_REGEX, STRING_REGEX } from "../../constants/application";
import store from "../../actions/configureStore";
import { updateShopDetails } from "../../actions/sagas/shopSaga";

class EditShopDetailsModal extends Component {
  constructor(props) {
    super(props);
    const { shopBusinessHours } = props;

    this.state = {
      formData: {
        name: shopBusinessHours.name,
        email: shopBusinessHours.main_email,
        phone: shopBusinessHours.main_phone_number,
        storeNumber: shopBusinessHours.store_number,
        numberOfBays: shopBusinessHours.number_of_bays,
        ownerName: shopBusinessHours.owner_name,
        addressline1: shopBusinessHours.address_line1,
        addressline2: shopBusinessHours.address_line2,
        city: shopBusinessHours.city,
        zip: shopBusinessHours.zip,
      },
      errors: {},
      value: null,
      selectedState: shopBusinessHours.state,
      isStateDropdownSelected: true,
    };
  }

  fileInputRef = React.createRef();

  onFieldChange = (field, e) => {
    let updatedFormData = { ...this.state.formData };
    updatedFormData[field] = e.target.value;
    this.setState({ formData: updatedFormData });
  };

  formDataErrors = () => {
    const { t } = this.props;
    const { formData } = this.state;
    const {
      name,
      email,
      phone,
      storeNumber,
      numberOfBays,
      ownerName,
      addressline1,
      city,
      zip,
    } = formData;
    const errors = {};

    if (STRING_REGEX.test(name)) errors.name = t("shopNameCantBeBlankError");

    if (!email.match(EMAIL_REGEX)) errors.email = t("emailFormatInvalidError");

    if (phone.replace(/[^\d]/g, "").length !== 10)
      errors.phone = t("phoneNumberMustBr10DigitsError");

    if (storeNumber.length === 0)
      errors.storeNumber = t("storeNumberIsRequiredError");

    if (numberOfBays.length === 0)
      errors.numberOfBays = t("numOfBaysIsRequiredError");

    if (STRING_REGEX.test(ownerName))
      errors.ownerName = t("ownerNameIsCantBeBlankError");

    if (STRING_REGEX.test(addressline1))
      errors.addressline1 = t("addresscantBeBlankError");

    if (STRING_REGEX.test(city)) errors.city = t("cityCantBeBlankError");

    if (zip.replace(/[^\d]/g, "").length !== 5)
      errors.zip = t("zipMustBe5DigitsError");
    return errors;
  };

  onUpdateShopDetails = () => {
    const errors = this.formDataErrors();
    const { currentShopId } = this.props;
    const { formData, selectedState, isStateDropdownSelected } = this.state;
    const {
      name,
      email,
      phone,
      storeNumber,
      numberOfBays,
      ownerName,
      addressline1,
      city,
      zip,
    } = formData;

    const formDataBody = new FormData();
    formDataBody.append("name", name);
    formDataBody.append("main_email", email);
    formDataBody.append("main_phone_number", phone);
    formDataBody.append("store_number", storeNumber);
    formDataBody.append("number_of_bays", numberOfBays);
    formDataBody.append("owner_name", ownerName);
    formDataBody.append("address_line1", addressline1);
    formDataBody.append("city", city);
    formDataBody.append("state", selectedState);
    formDataBody.append("zip", zip);

    if (isStateDropdownSelected && selectedState !== null) {
      if (Object.keys(errors).length === 0) {
        store.dispatch(updateShopDetails({ id: currentShopId, formDataBody }));
      } else {
        this.setState({ errors: errors });
      }
    } else {
      this.setState({ isStateDropdownSelected: false });
    }
  };

  onStateChange = (e, { value }) => {
    this.setState({ value }, () => {
      this.setState({
        selectedState: value,
        isStateDropdownSelected: true,
      });
    });
  };

  handleOnChange = (e, data) => {
    this.setAmenities(data.value);
  };

  fileChange = (e) => {
    const image = e.target.files[0];
    this.setState({
      shopImage: image,
      file: image,
    });
  };

  render() {
    const { open, close, t } = this.props;
    const {
      formData,
      errors,
      isStateDropdownSelected,
      selectedState,
    } = this.state;
    const {
      name,
      email,
      phone,
      storeNumber,
      numberOfBays,
      ownerName,
      addressline1,
      city,
      zip,
    } = formData;
    return (
      <>
        <Modal
          open={open}
          onClose={() => close()}
          closeIcon
          size="tiny"
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            <Grid centered columns={1}>
              <Grid.Column width={10}>
                <div style={{ textAlign: "center" }}>
                  <h3>{t("editShopDetails")}</h3>
                </div>
                <Divider hidden />
                <Form error className="no-session-form">
                  <Form.Field>
                    <label>{t("shopDetails")}</label>
                  </Form.Field>
                  <Form.Field required>
                    <label>{t("shopName")}</label>
                    <Form.Input
                      value={name}
                      onChange={(e) => this.onFieldChange("name", e)}
                      fluid
                      size="small"
                      error={errors.name}
                    />
                  </Form.Field>
                  <Form.Field required>
                    <label>{t("emailAddress")}</label>
                    <Form.Input
                      value={email}
                      onChange={(e) => this.onFieldChange("email", e)}
                      fluid
                      size="small"
                      error={errors.email}
                    />
                  </Form.Field>
                  <Form.Field required>
                    <label>{t("phone")}</label>
                    <Form.Input
                      value={phone}
                      onChange={(e) => this.onFieldChange("phone", e)}
                      fluid
                      size="small"
                      error={errors.phone}
                      type="number"
                      min="0"
                    />
                  </Form.Field>
                  <Form.Group widths="equal">
                    <Form.Field required>
                      <label>{t("storeNumberText")}</label>
                      <Form.Input
                        value={storeNumber}
                        onChange={(e) => this.onFieldChange("storeNumber", e)}
                        fluid
                        size="small"
                        error={errors.storeNumber}
                        onKeyDown={handleKeypress}
                        type="number"
                        min="0"
                      />
                    </Form.Field>
                    <Form.Field required>
                      <label>{t("numberOfBaysText")}</label>
                      <Form.Input
                        value={numberOfBays}
                        onChange={(e) => this.onFieldChange("numberOfBays", e)}
                        fluid
                        size="small"
                        error={errors.numberOfBays}
                        onKeyDown={handleKeypress}
                        type="number"
                        min="0"
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Field required>
                    <label>{t("shopManager")}</label>
                    <Form.Input
                      value={ownerName}
                      onChange={(e) => this.onFieldChange("ownerName", e)}
                      fluid
                      size="small"
                      error={errors.ownerName}
                    />
                  </Form.Field>
                  <Form.Field required>
                    <label>{t("addressline1")}</label>
                    <Form.Input
                      value={addressline1}
                      onChange={(e) => this.onFieldChange("addressline1", e)}
                      fluid
                      size="small"
                      error={errors.addressline1}
                    />
                  </Form.Field>
                  <Form.Field required>
                    <label>{t("city")}</label>
                    <Form.Input
                      value={city}
                      onChange={(e) => this.onFieldChange("city", e)}
                      fluid
                      size="small"
                      error={errors.city}
                    />
                  </Form.Field>
                  <Form.Group widths="equal">
                    <Form.Field required>
                      <label>{t("state")}</label>
                      <Dropdown
                        value={selectedState}
                        floating
                        selection
                        options={unitedStates.map((unitedState) => ({
                          key: unitedState.abbreviation,
                          text: unitedState.name,
                          value: unitedState.abbreviation,
                        }))}
                        search
                        onChange={this.onStateChange}
                      />
                      {!isStateDropdownSelected && (
                        <Message error content={t("pleaseSelectStateMsg")} />
                      )}
                    </Form.Field>
                    <Form.Field required>
                      <label>{t("zip")}</label>
                      <Form.Input
                        value={zip}
                        onChange={(e) => this.onFieldChange("zip", e)}
                        fluid
                        size="small"
                        error={errors.zip}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
                <div style={{ textAlign: "center" }}>
                  <Button
                    fluid
                    circular
                    content={t("saveDetails")}
                    onClick={this.onUpdateShopDetails}
                  />
                </div>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentShopId } = state.shops;

  return {
    isLoading: state.application.isLoading,
    currentShopId: currentShopId,
  };
};

export default connect(mapStateToProps)(EditShopDetailsModal);
