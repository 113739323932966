import {
  formatInternalErrors,
  toString,
  consoleLog,
  catchErrors,
} from "../../helpers/apiErrorHelpers";
import {
  PROMOTIONS_PATH,
  SET_SHOP_PROMOTIONS_LOADING,
  SHOP_PROMOTION_CREATE_SUCCEEDED,
  SHOP_PROMOTION_LOAD_SUCCEEDED,
  UNSET_SHOP_PROMOTIONS_LOADING,
} from "../../constants/actionTypes";
import store from "../configureStore";
import Notifications from "../../components/Notifications";
import { getJSON, postJSONNew } from "../../utils/fetch";
import i18n from "../../utils/i18n";

export function createPromotion(action) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_SHOP_PROMOTIONS_LOADING });

      const formDataBody = action.formDataBody || {};
      let user = getState().users || {};
      const { authentication_token } = user;

      const request = {
        url: PROMOTIONS_PATH,
        headers: { Authorization: authentication_token },
        body: formDataBody,
      };
      const response = await postJSONNew(
        request.url,
        request.body,
        request.headers
      );
      catchErrors(response.result);

      await dispatch({
        type: SHOP_PROMOTION_CREATE_SUCCEEDED,
        payload: { promotion: response.result },
      });

      await dispatch({ type: UNSET_SHOP_PROMOTIONS_LOADING });
      store.dispatch(
        Notifications.success({
          title: i18n.t("common:successTitle"),
          message: i18n.t("common:promotionCreatedSuccessfullyMsg"),
          position: "tr",
          autoDismiss: 5,
        })
      );
    } catch (errors) {
      dispatch({ type: UNSET_SHOP_PROMOTIONS_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:errorCreatingPromotionMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
    }
  };
} // createPromotion

export function loadShopsPromotions() {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_SHOP_PROMOTIONS_LOADING });

      let user = getState().users || {};
      const { authentication_token } = user;

      const request = {
        url: `${PROMOTIONS_PATH}`,
        headers: { Authorization: authentication_token },
        body: {},
      };

      const response = await getJSON(
        request.url,
        request.body,
        request.headers
      );

      catchErrors(response.result);

      await dispatch({
        type: SHOP_PROMOTION_LOAD_SUCCEEDED,
        payload: { promotions: response.result },
      });

      await dispatch({ type: UNSET_SHOP_PROMOTIONS_LOADING });
    } catch (errors) {
      dispatch({ type: UNSET_SHOP_PROMOTIONS_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      consoleLog(formattedErrors);
    }
  };
}
