import React from "react";
import { Header, Modal } from "semantic-ui-react";

export default function PrivacyPolicy({ t }) {
  const [open, setOpen] = React.useState(false);

  return (
    <Modal
      closeIcon
      open={open}
      trigger={
        <a href="#" onClick={(e) => e.preventDefault()}>
          {t("privacyPolicy")}
        </a>
      }
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Header content="Privacy Policy" />
      <Modal.Content scrolling style={{ height: "300px" }}>
        <div>
          This Privacy Policy applies to all CarAdvise, LLC (“we,” “our,” or
          “CarAdvise”) website(s), all online and offline services and all of
          our mobile sites and mobile applications (together with any other
          services or products offered by CarAdvise, the “Services”). CarAdvise
          respects your privacy and is committed to protecting your personally
          identifiable information. This Privacy Policy (this “Policy”)
          describes how CarAdvise treats and uses personal information through
          the Services. This Privacy Policy is hereby incorporated into the
          CarAdvise Terms of Use, available at: www.caradvise.com/terms (the
          “Terms”). Any use of the Services is entirely voluntary. No individual
          is ever required to register for a CarAdvise account or to use the
          Services. We collect information from and about you. To use the
          Services, you must register for CarAdvise, which requires you to
          provide an e-mail address. This is the minimum amount of personally
          identifiable information required to use the Services. You may
          voluntarily choose to provide additional information to us to enable
          CarAdvise to provide particular Services. Should you do so, the
          information may be shared with third parties from time to time, as set
          forth in this Policy. Information we may collect about may include,
          without limitation, the following (together with any other information
          collected from you by CarAdvise, the “Information”): * Contact
          information, such as your name, street address, city, state, zip code,
          phone number, fax number, and email address; * Vehicle information,
          such as the VIN, mileage, make, model, location (via GPS technology)
          and driving behavior of your vehicle; * Repair and maintenance
          services information, such as information regarding how your vehicle
          is repaired or maintained, including work recommended for your vehicle
          (whether completed or not); * Payment information, if you make a
          payment through our website; * Information submitted by you to book an
          appointment or make a customer service request from one of the vehicle
          repair/maintenance shops (each, a “Maintenance Partner”) listed on our
          website; * Demographic information, such as your gender, age, place of
          residence or professional information; and * Other information, such
          as information about the browser you’re using and your IP address, the
          website you were to us from or the website you visit after you leave
          our website. How we collect information. We may collect Information
          from you in the following ways, without limitation: * Directly from
          you: For example, if you approve repair/maintenance services with a
          Maintenance Partner through CarAdvise, or if you contact us online or
          offline. * Passively from you: CarAdvise, or third parties on our
          site, may use tracking tools such as browser cookies, GPS technology
          and web beacons to collect Information from you. * Indirectly from
          third parties: For example, our third party-business partners may give
          us information about you. We may combine this Information with
          Information you give us, such as, for example, if a Maintenance
          Partner notifies us of the date and time of any appointments you book
          though CarAdvise. How we use your Information. CarAdvise uses the
          Information in a variety of ways. We may use the Information to
          respond to your requests or questions, to notify you if you reserve
          repair services with a Maintenance Partner through our Services or to
          contact you about your account. We may use your Information to improve
          our Services, including our websites, mobile apps, and products. We
          might use your Information to customize your experience with us. We
          may use Information for marketing or promotional purposes. For
          example, CarAdvise might send you information via, as applicable,
          telephone, email, text message and/or alert via the CarAdvise App
          about new products/services and special promotions. We might tell you
          about new features or updates to the Services. We use Information to
          communicate with you about your account or our relationship. We may
          contact you about your order or feedback. We might also contact you
          about this Policy or our Terms. We may share Information with third
          parties. We may share Information with third parties, such as
          Maintenance Partners, CarAdvise affiliates or other third parties who
          perform services on our behalf or with whom we work with to provide
          the Services to you. We will also share Information in order to comply
          with the law, regulatory provisions, or as we otherwise see fit to
          protect ourselves, our users and enforce our Terms of Use. We may also
          disclose your Information to third parties when we have a reason to
          believe that a disclosure is necessary to address potential or actual
          injury or interference with our rights, property, operations, other
          users or others who may be harmed or may suffer loss or damage, or if
          we believe that such disclosure is necessary to protect our rights,
          combat fraud and/or comply with a judicial proceeding, court order,
          subpoena, or legal process served on CarAdvise. We may share
          Information with any successor to all, or part of, our business(es),
          or for for other reasons that we may describe to you. You have certain
          choices about our marketing and tracking tools. You can opt out of
          receiving certain of our commercial email messages. From time to time,
          CarAdvise may send messages via, as applicable, telephone, email, text
          message and/or alert via the CarAdvise App that it believes to be
          relevant and beneficial to CarAdvise users, such as promotional or
          marketing messages or advertisements. To stop receiving our commercial
          email messages, simply send us an email at support@CarAdvise.com with
          the subject “Unsubscribe;” write to us at the below address (include
          your email address); or use the unsubscribe function in any
          promotional message you get from us. Even if you opt out of getting
          our commercial messages, we will still send you transactional or
          relationship e-mail messages. These include responses to your
          questions, order confirmations, and other important notices about your
          account and/or Services you have booked through CarAdvise. You can
          control cookies and tracking tools. Your browser may give you the
          ability to control cookies. How you do so depends on the type of
          cookie. Certain browsers can be set to reject browser cookies. If you
          block cookies on your browser, certain of the Services, including
          certain features on our websites or mobile applications, may not work.
          “Do Not Track.” Some browsers have a “Do Not Track” feature that lets
          you tell websites that you do not want to have your online activities
          tracked. CarAdvise is not currently set up to respond to Do Not Track
          signals. Your California privacy rights. If you reside in California,
          you have the right to ask us one time each year whether we have shared
          personal Information with third parties for their direct marketing
          purposes. To make a request, please send us an email, or write to us
          at the address listed below, indicating in your letter that you are a
          California resident making a “Shine the Light” inquiry. CarAdvise, the
          Services and children. Our site is meant for adults. We do not
          knowingly collect or retain personally identifiable information from
          children under 13 without permission from a parent or guardian. If
          CarAdvise ever becomes aware that a child under 13 has provided us
          with personal information, we will promptly delete that information.
          If you are a parent or legal guardian and believe your child under 13
          has given us information, you can email us. You can also write to us
          at the address listed at the end of this policy. Please mark your
          inquiries “COPPA Information Request.” We use industry standard
          security measures. CarAdvise follows generally accepted industry
          standards and maintains reasonable safeguards to attempt to ensure the
          security, integrity and privacy of our sites, the Services and the
          Information. While CarAdvise exercises reasonable care and due
          diligence in providing a secure conduit of information between your
          computer or other device and its servers, the Internet is not 100%
          secure. We cannot and do not promise that your use of our Services
          will be completely safe. Therefore, while CarAdvise will attempt to
          protect the transmission of any Information using methods that comply
          with the industry standards, CarAdvise does not accept liability for
          any unintentional disclosure of same. We encourage you to always use
          caution when using the Internet. We keep Information as long as it may
          be necessary or relevant to do so for the practices described in this
          Policy. We also keep Information as otherwise required by law. We
          store Information in the United States. Information we maintain will
          be stored within the United States. If you live outside of the United
          States, you understand and agree that we may transfer your Information
          to the United States. We may link to other sites or have third party
          services on our site we don’t control. The Services may incorporate
          links to third party websites or other applications. We do not control
          any third party websites or other applications. This Policy does not
          apply to the privacy practices of any third party websites or other
          applications. You should always read the privacy policy and terms of
          use of any third party website or application carefully. CarAdvise is
          not liable for any such third party terms and conditions and their use
          of your Information, and if you use a third party website or service,
          you do so at your own risk.
          <h5>Facebook Data Deletion Instructions</h5>
          CarAdvise does not save your Facebook personal data on its server.
          However, according to Facebook policy, we have to provide User Data
          Deletion Callback URL or Data Deletion Instructions URL. If you want
          to delete your data from the CarAdvise App, you can remove your
          information by following these steps: 1. Go to your Facebook Account’s
          Setting & Privacy. Click “Settings” 2. Look for “Apps and Websites”
          and you will see all of the apps and websites you linked with your
          Facebook. 3. Search and Click “CarAdvise” in the search bar. 4. Scroll
          and click “Remove”. 5. You have successfully removed your app
          activities and data from the CarAdvise App. If you wish to delete your
          PII, please reach out to our support team at 844-9-ADVISE. Information
          applicable to Maintenance Partners In addition to the foregoing, we
          may collect and use Information differently or in addition to what is
          set forth above for our Maintenance Partners. We may collect
          Information about your professional credentials, including mechanical
          credentials, years in the trade, certifications received. Feel free to
          contact us if you have more questions. If you have any questions about
          this Policy or other privacy concerns, please email us at
          support@CarAdvise.com. You can also write to us or call at: CarAdvise,
          LLC Customer Privacy 216 S. Jefferson St., Suite 601, Chicago, IL
          60661 Phone: 844-9-ADVISE We may update this Policy. From time to time
          we may change this Policy. We reserve the right, at our and without
          prior notice, to change, modify, amend, add, subtract, or otherwise
          remove portions of this Policy at any time and for any or no reason.
          We may notify you of any material changes to our Policy, or as
          otherwise required by law. We will also post an updated Policy on our
          website. Please check our site periodically for updates. By using the
          Services, you consent, acknowledge and agree to our Policy, as it may
          be amended from time to time.
          <p>Updated April 22, 2022</p>
        </div>
      </Modal.Content>
      {/* <Modal.Actions>
        <Button color='red' onClick={() => setOpen(false)}>
          <Icon name='remove' /> No
        </Button>
        <Button color='green' onClick={() => setOpen(false)}>
          <Icon name='checkmark' /> Yes
        </Button>
      </Modal.Actions> */}
    </Modal>
  );
}
