export function append(array, element) {
  return [...array, element]
}

export function chunk(array, size) {
  const chunked_arr = [];
  for (let i = 0; i < array.length; i++) {
    const last = chunked_arr[chunked_arr.length - 1];
    if (!last || last.length === size) {
      chunked_arr.push([array[i]]);
    } else {
      last.push(array[i]);
    }
  }
  return chunked_arr;
}

export function uniq(arrArg) {
  return arrArg.filter((elem, pos, arr) => {
    return arr.indexOf(elem) === pos;
  });
}

export function findObjectById(objects, id) {
  let foundObject = null

  if (typeof id === 'string') {
    id = parseInt(id)
  }

  objects.forEach((object) => {
    if (object.id === id) {
      foundObject = object
    }
  })

  return foundObject
}

export function prepend(array, element) {
  return [element, ...array]
}

export function removeObjectById(objects, id) {
  return objects.filter((object) => object.id !== id)
}

// NOTE: This explicitly takes an ID rather than pulling from the new object, to allow to the replacing of previously null IDs (aka new objects).
export function replaceObjectById(objects, newObject, id) {
  return objects.map(function(object) { return object.id === id ? newObject : object })
}

export function toggleElement(array, element) {
  if (array.includes(element)) {
    return array.filter(existingElement => existingElement !== element)
  }
  else {
    return [...array, element]
  }
}
