import {
    ORDERS_LOAD_SUCCEEDED, ORDER_UPDATE_SUCCEEDED
  } from "../../constants/actionTypes"

  export const initialState = {
    userOrders: []
  }

  export default function(state=initialState, action = {}) {
    const { payload, type } = action
    switch(type) {
      case ORDERS_LOAD_SUCCEEDED:
        return {
          ...state,
          userOrders: payload.orders
        }
        case ORDER_UPDATE_SUCCEEDED:
          const newOrder = state.userOrders.map(order => {
            return order.id === payload.order.id ? payload.order : order;
          })
        return {
          ...state,
          userOrders: newOrder
        }   
      default:
        return state
    }
  }
  