import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { navigationPaths } from "../../constants/paths";
import CustomLoader from "../../components/elements/CustomLoader";
import NoSessionLayout from "../../layouts/NoSessionLayout";
import ResetPasswordForm from "../../components/sessions/ResetPasswordForm";
import { Icon } from "semantic-ui-react";
import { resetPassword } from "../../actions/sagas/userSaga";
import store from "../../actions/configureStore";
import { withTranslation } from "react-i18next";
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    const queryString = props.location.search;
    const queryParams = new URLSearchParams(queryString);

    this.state = {
      resetToken: queryParams.get("reset_password_token"),
    };
  }

  onSubmit = (data) => {
    store.dispatch(
      resetPassword({
        ...data,
        resetToken: this.state.resetToken,
        callback: () => {
          this.props.history.push({ pathname: navigationPaths.dashboard() });
        },
      })
    );
  };

  render() {
    const { isLoading, t } = this.props;

    return (
      <NoSessionLayout>
        {isLoading && <CustomLoader />}
        {!isLoading && (
          <div className="no-session-content">
            <div className="no-session-content-header">
              <Link className="cursor-pointer" to={navigationPaths.signIn()}>
                <Icon
                  color="black"
                  name="arrow left"
                  size="large"
                  className="cursor-pointer"
                />
              </Link>
              <h1>{t("resetPassword")}</h1>
            </div>
            <ResetPasswordForm
              onSubmit={this.onSubmit}
              isSubmitting={isLoading}
              t={t}
            />
          </div>
        )}
      </NoSessionLayout>
    );
  }
} // ResetPassword

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
});

export default connect(mapStateToProps, { resetPassword })(
  withTranslation("session")(ResetPassword)
);
