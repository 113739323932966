import {
  SHOP_LOAD_SUCCEEDED,
  SHOPS_LOAD_SUCCEEDED,
  UPDATE_CURRENT_SHOP_ID,
  BUSINESS_HOURS_LOAD_SUCCEEDED,
  SHOP_DETAILS_UPDATE_SUCCEEDED,
} from "../../constants/actionTypes";

export const initialState = {
  userShops: [],
  currentShopId: null,
  shopHours: [],
};

export default function (state = initialState, action = {}) {
  const { type, payload } = action;
  let newDetails;
  switch (type) {
    case SHOP_LOAD_SUCCEEDED:
      const payloadShop = payload.shop || {};
      const updatedShops = state.userShops.map((shop) => {
        return payloadShop.id === shop.id ? payloadShop : shop;
      });
      return {
        ...state,
        userShops: updatedShops,
      };
    case BUSINESS_HOURS_LOAD_SUCCEEDED:
      return {
        ...state,
        shopHours: payload.businessHours,
      };
    case SHOP_DETAILS_UPDATE_SUCCEEDED:
      newDetails = state.userShops.map((shop) => {
        return shop.id === payload.businessHours.shop.id
          ? payload.businessHours
          : shop;
      });
      return {
        ...state,
        shopHours: newDetails,
      };
    case SHOPS_LOAD_SUCCEEDED:
      let newState = {
        ...state,
        userShops: payload.shops,
      };
      if (state.currentShopId == null && state.currentShopId === "null") {
        newState.currentShopId = null;
      }

      return newState;
    case UPDATE_CURRENT_SHOP_ID:
      return {
        ...state,
        currentShopId: payload,
      };

    default:
      return state;
  }
}
