import React from "react";
import { connect } from "react-redux";
import CustomLoader from "../elements/CustomLoader";
import { Card, Header } from "semantic-ui-react";
import FlagSettings from "./FlagSettings";
import NoShopSelected from "../shared/NoShopSelected";
import { withTranslation } from "react-i18next";

class Settings extends React.Component {
  render() {
    const { currentShop, currentUser, isLoading, dispatch, t } = this.props;
    return (
      <div className="full-settings-container">
        {isLoading && <CustomLoader />}
        {!isLoading && (
          <React.Fragment>
            {currentShop ? (
              <React.Fragment>
                <Card>
                  <Card.Content>
                    <Header color="black">{t("settings")}</Header>
                    <div>
                      <FlagSettings
                        currentUser={currentUser}
                        dispatch={dispatch}
                        t={t}
                      />
                    </div>
                  </Card.Content>
                </Card>
              </React.Fragment>
            ) : (
              <NoShopSelected
                message={t("selectShopForSpecificShopSettings")}
              />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const currentShop = state.shops.userShops.find(
    (s) => s.id === state.shops.currentShopId
  );

  return {
    currentUser: state.users.currentUser,
    isLoading: state.application.isLoading,
    currentShop: currentShop,
    currentShopId: state.shops.currentShopId,
  };
};
export default connect(mapStateToProps)(withTranslation("settings")(Settings));
