import React from "react";
import { connect } from "react-redux";
import { Card, Grid, Table } from "semantic-ui-react";
import * as arrayHelpers from "../../helpers/arrayHelpers";
import { sortByAttr } from "../../helpers/numberHelpers";
import PricingRowSummary from "./PricingRowSummary";
import _ from "lodash";

class PricingSummary extends React.Component {
  state = {
    openEditModal: false,
    pricing: null,
  };

  editPricingModalClose = () => {
    this.setState({ openEditModal: false });
  };

  render() {
    const {
      pricings,
      currentUser,
      isNationalOrRegionalOrOwnerFlag,
      t,
    } = this.props;
    return (
      <Card fluid>
        <Card.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={3}>
                <div className="d-flex">
                  <h3 className="table-header"> {t("totalPricings")}</h3>
                  <div className="badge">{pricings.length}</div>
                </div>
              </Grid.Column>
              <Grid.Column width={13}>
                <div className="dash-table">
                  <Table unstackable striped compact selectable>
                    <Table.Header>
                      <Table.Row>
                        {isNationalOrRegionalOrOwnerFlag && (
                          <Table.HeaderCell>{t("shopName")}</Table.HeaderCell>
                        )}
                        <Table.HeaderCell>{t("services")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("position")}</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left">
                          {t("carAdvisePrice")}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left">
                          {t("retailPrice")}
                        </Table.HeaderCell>
                        {/* <Table.HeaderCell textAlign="left">Savings</Table.HeaderCell> */}
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {pricings.length > 0 ? (
                        sortByAttr(_.uniqBy(pricings, "id"), "name").map(
                          (pricing) => {
                            return (
                              pricing.id && (
                                <PricingRowSummary
                                  key={`pricing-row-${pricing.id}-${pricing.name}-${pricing.position}`}
                                  pricing={pricing}
                                  currentUser={currentUser}
                                  isNationalOrRegionalOrOwnerFlag={
                                    isNationalOrRegionalOrOwnerFlag
                                  }
                                />
                              )
                            );
                          }
                        )
                      ) : (
                        <div className="no-content">
                          {t("noPricingDataFound")}
                        </div>
                      )}
                    </Table.Body>
                  </Table>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
  currentShop:
    arrayHelpers.findObjectById(
      state.shops.userShops,
      state.shops.currentShopId
    ) || {},
});

export default connect(mapStateToProps)(PricingSummary);
