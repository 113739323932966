import { combineReducers } from "redux";
import application from "./application";
import notifications from "./notifications";
import pricings from "./pricings";
import serviceDefinitions from "./serviceDefinitions";
import shops from "./shops";
import users from "./users";
import ui from "./ui";
import appointments from "./appointments";
import notes from "./notes";
import orders from "./orders";
import customerRatings from "./customerFeedback";
import commonServices from "./commonServices";
import promotions from "./promotions";

const combinedReducers = combineReducers({
  /* NOTE: Add other reducers here */
  orders,
  notes,
  appointments,
  application,
  notifications,
  pricings,
  serviceDefinitions,
  shops,
  users,
  ui,
  customerRatings,
  commonServices,
  promotions,
});

export default combinedReducers;
