import { Component } from "react";
import { connect } from "react-redux";
import { navigationPaths } from "../../constants/paths";
import { signOut } from "../../actions/sagas/sessionSaga";
import store from "../../actions/configureStore";

// NOTE: This allows for a user to sign out, without each view/component that renders the nav link having to worry about the presence of this.props.location
class SessionDestroy extends Component {
  componentDidMount() {
    store.dispatch(
      signOut({
        payload: {},
        callback: () =>
          this.props.history.push({ pathname: navigationPaths.signIn() }),
      })
    );
  }

  render() {
    return null;
  }
}

export default connect(() => ({}))(SessionDestroy);
