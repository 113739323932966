import React, { Component } from "react";
import { Image } from "semantic-ui-react";
import ReactSVG from "react-svg";
import { getSubdomain } from "./StylesheetInjector";
import { NTWTBC } from "../../constants/application";
import {
  CARADVISE_NO_SESSION_BULLETS,
  NTW_FLEET_DEALER_NO_SESSION_BULLETS,
} from "../../constants/welcomeScreenBullets";
import { withTranslation } from "react-i18next";

// const carouselContainer = document.querySelector(".carousel-container");

// class CarouselLeftArrow extends Component {
//   render() {
//     return (
//       <a
//         href="#"
//         className="carousel__arrow carousel__arrow--left"
//         onClick={this.props.onClick}
//       >
//         <span className="fa fa-2x fa-angle-left" />
//       </a>
//     );
//   }
// }

// class CarouselRightArrow extends Component {
//   render() {
//     return (
//       <a
//         href="#"
//         className="carousel__arrow carousel__arrow--right"
//         onClick={this.props.onClick}
//       >
//         <span className="fa fa-2x fa-angle-right" />
//       </a>
//     );
//   }
// }

class CarouselIndicator extends Component {
  render() {
    return (
      <li>
        <a
          href="#"
          className={
            this.props.index === this.props.activeIndex
              ? "carousel__indicator carousel__indicator--active"
              : "carousel__indicator"
          }
          onClick={(e) => {
            e.preventDefault();
            this.props.onClick();
          }}
        />
      </li>
    );
  }
}

class CarouselSlide extends Component {
  render() {
    const { t, slide } = this.props;
    return (
      <li
        className={
          this.props.index === this.props.activeIndex
            ? "carousel__slide carousel__slide--active"
            : "carousel__slide"
        }
      >
        <Image fluid src={slide.image} />
        <p align="left" className="carousel-slide-title">
          {t(slide.title)}
        </p>
        <p align="left" className="carousel-slide-text">
          {t(slide.text)}
        </p>
      </li>
    );
  }
}

// Carousel wrapper component
class Carousel extends Component {
  constructor(props) {
    super(props);
    const sub = getSubdomain();
    this.goToSlide = this.goToSlide.bind(this);
    this.goToPrevSlide = this.goToPrevSlide.bind(this);
    this.goToNextSlide = this.goToNextSlide.bind(this);

    this.state = {
      activeIndex: 0,
      paused: false,
      slides:
        sub === NTWTBC
          ? NTW_FLEET_DEALER_NO_SESSION_BULLETS
          : CARADVISE_NO_SESSION_BULLETS,
    };
  }

  componentDidMount() {
    const interval = setInterval(() => {
      if (!this.state.paused) {
        this.updateIndex(this.state.activeIndex + 1);
      }
    }, 3000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }

  updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = this.state.slides.length - 1;
    } else if (newIndex >= this.state.slides.length) {
      newIndex = 0;
    }

    this.setState({ activeIndex: newIndex });
  };

  goToSlide(index) {
    this.setState({
      activeIndex: index,
    });
  }

  goToPrevSlide(e) {
    e.preventDefault();

    let index = this.state.activeIndex;
    let { slides } = this.props;
    let slidesLength = slides.length;

    if (index < 1) {
      index = slidesLength;
    }

    --index;

    this.setState({
      activeIndex: index,
    });
  }

  goToNextSlide(e) {
    e.preventDefault();

    let index = this.state.activeIndex;
    let { slides } = this.state;
    let slidesLength = slides.length - 1;

    if (index === slidesLength) {
      index = -1;
    }

    ++index;

    this.setState({
      activeIndex: index,
    });
  }

  render() {
    const { headerLogo, svgStyle, t } = this.props;
    return (
      <div
        className="carousel"
        onMouseEnter={() => this.setState({ paused: true })}
        onMouseLeave={() => this.setState({ paused: false })}
      >
        {/* <CarouselLeftArrow onClick={e => this.goToPrevSlide(e)} /> */}
        <div>
          <ReactSVG
            src={`images/${headerLogo}`}
            className="logo-image"
            svgStyle={svgStyle}
          />
        </div>
        <ul className="carousel__slides">
          {this.state.slides.map((slide, index) => (
            <CarouselSlide
              key={index}
              index={index}
              activeIndex={this.state.activeIndex}
              slide={slide}
              t={t}
            />
          ))}
        </ul>

        {/* <CarouselRightArrow onClick={e => this.goToNextSlide(e)} /> */}

        <ul className="carousel__indicators">
          {this.state.slides.map((slide, index) => (
            <CarouselIndicator
              key={index}
              index={index}
              activeIndex={this.state.activeIndex}
              isActive={this.state.activeIndex === index}
              onClick={(e) => this.goToSlide(index)}
            />
          ))}
        </ul>
      </div>
    );
  }
}

export default withTranslation("carousel")(Carousel);
