import {
  formatInternalErrors,
  toString,
  consoleLog,
  catchErrors,
} from "../../helpers/apiErrorHelpers";
import {
  SET_SHOP_PRICINGS_LOADING,
  UNSET_SHOP_PRICINGS_LOADING,
  USER_PRICINGS_LOAD_SUCCEEDED,
  USER_PRICING_CREATE_SUCCEEDED,
  USER_PRICING_UPDATE_SUCCEEDED,
  USER_PRICING_DESTROY_SUCCEEDED,
  PRICINGS_PATH,
  SHOPS_PATH,
  SHOP_LOAD_SUCCEEDED,
} from "../../constants/actionTypes";
import Notifications from "../../components/Notifications";
import store from "../configureStore";
import { deleteJSON, getJSON, postJSON, putJSON } from "../../utils/fetch";
import { loadCommonServices } from "./commonServicesSaga";
import i18n from "../../utils/i18n";

export function loadPricings(action) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_SHOP_PRICINGS_LOADING });
      let user = getState().users || {};
      const { authentication_token } = user;

      const request = {
        url: PRICINGS_PATH,
        headers: { Authorization: authentication_token },
        body: {},
      };

      const response = await getJSON(
        request.url,
        request.body,
        request.headers
      );
      catchErrors(response.result);

      await dispatch({
        type: USER_PRICINGS_LOAD_SUCCEEDED,
        payload: { pricings: response.result },
      });

      await dispatch(loadCommonServices());
      await dispatch({ type: UNSET_SHOP_PRICINGS_LOADING });
    } catch (errors) {
      dispatch({ type: UNSET_SHOP_PRICINGS_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:errorLoadingPricingsMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
    }
  };
}

export function createPricing(action) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_SHOP_PRICINGS_LOADING });
      let user = getState().users || {};
      const { authentication_token } = user;

      const payload = action.paload || {};
      const { pricings, shop_id } = payload;

      const request = {
        url: PRICINGS_PATH,
        headers: { Authorization: authentication_token },
        body: {
          pricings: pricings,
          shop_id: shop_id,
        },
      };

      const response = await postJSON(
        request.url,
        request.body,
        request.headers
      );
      catchErrors(response.result);

      await dispatch({
        type: USER_PRICING_CREATE_SUCCEEDED,
        payload: { pricing: response.result },
      });
      await dispatch(loadPricings());

      await dispatch({ type: UNSET_SHOP_PRICINGS_LOADING });
      store.dispatch(
        Notifications.success({
          title: i18n.t("common:successTitle"),
          message: i18n.t("common:pricingsCreatedMsg"),
          position: "tr",
          autoDismiss: 5,
        })
      );
    } catch (errors) {
      dispatch({ type: UNSET_SHOP_PRICINGS_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:errorCreatingPricingMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
      await dispatch(action.errorCallback);
    }
  };
}

export function updatePricing(action) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_SHOP_PRICINGS_LOADING });
      let user = getState().users || {};
      const { authentication_token } = user;

      const payload = action.payload || {};
      const {
        pricingsForUpdate,
        conventionalQuarts,
        semiSyntheticQuarts,
        euroSyntheticQuarts,
        fullSyntheticQuarts,
        shop_id,
        pricings,
        isQuartsChanged,
        isRateChanged,
        laborRate,
        retailLaborRate,
      } = payload;

      const newExtraQuarts = {
        conventional_extra_quarts_price: conventionalQuarts,
        semi_synthetic_extra_quarts_price: semiSyntheticQuarts,
        euro_synthetic_extra_quarts_price: euroSyntheticQuarts,
        full_synthetic_extra_quarts_price: fullSyntheticQuarts,
      };

      if (pricings.length > 0) {
        const createRequest = {
          url: PRICINGS_PATH,
          headers: { Authorization: authentication_token },
          body: {
            pricings: pricings,
            shop_id: shop_id,
          },
        };
        const createResponse = await postJSON(
          createRequest.url,
          createRequest.body,
          createRequest.headers
        );
        catchErrors(createResponse.result);

        await dispatch({
          type: USER_PRICING_CREATE_SUCCEEDED,
          payload: { pricing: createResponse.result },
        });
      }

      if (!isQuartsChanged || pricingsForUpdate.length > 0) {
        const request = {
          url: `${PRICINGS_PATH}`,
          headers: { Authorization: authentication_token },
          body: {
            pricings: pricingsForUpdate,
            additional_quarts: newExtraQuarts,
            shop_id: shop_id,
          },
        };
        const response = await putJSON(
          request.url,
          request.body,
          request.headers
        );
        catchErrors(response.result);

        await dispatch({
          type: USER_PRICING_UPDATE_SUCCEEDED,
          payload: { pricing: response.result },
        });
      }

      if (!isRateChanged) {
        const shopRequest = {
          url: `${SHOPS_PATH}/${shop_id}`,
          headers: { Authorization: authentication_token },
          body: {
            labor_rate: laborRate,
            retail_labor_rate: retailLaborRate,
          },
        };

        const shopResponse = await putJSON(
          shopRequest.url,
          shopRequest.body,
          shopRequest.headers
        );
        catchErrors(shopResponse.result);

        await dispatch({
          type: SHOP_LOAD_SUCCEEDED,
          payload: { shop: shopResponse.result.shop },
        });
      }

      await dispatch(loadPricings());
      await dispatch({ type: UNSET_SHOP_PRICINGS_LOADING });

      store.dispatch(
        Notifications.success({
          title: i18n.t("common:successTitle"),
          message: i18n.t("common:pricingUpdatedMsg"),
          position: "tr",
          autoDismiss: 5,
        })
      );
    } catch (errors) {
      dispatch({ type: UNSET_SHOP_PRICINGS_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:errorUpdatingPricingMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
      await dispatch(action.errorCallback);
    }
  };
}

export function destroyPricing(action) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_SHOP_PRICINGS_LOADING });
      let user = getState().users || {};
      const { authentication_token } = user;

      const payload = action || {};
      const { id, shopId } = payload;

      const request = {
        url: `${PRICINGS_PATH}/${id}`,
        headers: { Authorization: authentication_token },
        body: { id: id, shop_id: shopId },
      };

      const response = await deleteJSON(
        request.url,
        request.body,
        request.headers
      );
      catchErrors(response.result);

      await dispatch({
        type: USER_PRICING_DESTROY_SUCCEEDED,
        payload: { pricingId: response.result.id },
      });
      await dispatch({ type: UNSET_SHOP_PRICINGS_LOADING });
      store.dispatch(
        Notifications.success({
          title: i18n.t("common:successTitle"),
          message: i18n.t("common:pricingDeletedMsg"),
          position: "tr",
          autoDismiss: 5,
        })
      );
    } catch (errors) {
      dispatch({ type: UNSET_SHOP_PRICINGS_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:errorDeletingPricingMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
    }
  };
}
