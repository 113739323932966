import React from "react";
import { connect } from "react-redux";
import { Button, Form, Modal } from "semantic-ui-react";
import { updateCustomerFeedback } from "../../actions/sagas/customerFeedBackSaga";
import store from "../../actions/configureStore";

class ShopResponseModal extends React.Component {
  state = {
    formData: {
      comment: "",
    },
    errors: {},
  };

  onChange = (field, e) => {
    let updatedFormData = { ...this.state.formData };
    updatedFormData[field] = e.target.value;
    this.setState({ formData: updatedFormData, errors: {} });
  };

  formDataErrors = () => {
    const { formData } = this.state;
    const { comment } = formData;
    const errors = {};

    if (comment.length === 0) errors.comment = "Comment is required";
    return errors;
  };

  onHandleClose = () => {
    const { close } = this.props;
    close();
    this.setState({
      formData: {
        comment: "",
      },
      errors: {},
    });
  };

  onResponse = (rating) => {
    const errors = this.formDataErrors();
    const { currentUser, currentShopId } = this.props;
    const { email } = currentUser;
    const { formData } = this.state;
    const { comment } = formData;

    if (Object.keys(errors).length === 0) {
      const payload = {
        id: rating.id,
        shop_explanation: comment,
        shop_user_email: email,
        shop_id: currentShopId,
      };
      store.dispatch(updateCustomerFeedback(payload));
    } else {
      this.setState({ errors: errors });
    }
  };

  render() {
    const { open, close, rating, t } = this.props;
    const { errors, formData } = this.state;
    const { comment } = formData;

    return (
      <Modal
        size="small"
        open={open}
        closeIcon
        onClose={() => close()}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{t("addResponse")}</Modal.Header>
        <Modal.Content>
          <Form error>
            <Form.Field>{t("commentText")}</Form.Field>
            <Form.Field>
              <Form.TextArea
                placeholder={t("enterYourResponse")}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) =>
                  e.keyCode === 32 ? e.stopPropagation() : null
                }
                onChange={(e) => this.onChange("comment", e)}
                value={comment}
                size="large"
                rows={3}
                error={errors.comment}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button positive circular onClick={() => this.onResponse(rating)}>
            {t("addText")}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentShopId } = state.shops;

  return {
    isLoading: state.application.isLoading,
    currentShopId: currentShopId,
  };
};

export default connect(mapStateToProps)(ShopResponseModal);
