import React from "react";
import { connect } from "react-redux";
import CustomLoader from "../elements/CustomLoader";
import { Card, Header, Input, Grid, Progress, Rating } from "semantic-ui-react";
import CustomerFeedbackTable from "./CustomerFeedbackTable";
import { fetchCustomerFeedback } from "../../actions/sagas/customerFeedBackSaga";
import store from "../../actions/configureStore";
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  filters,
  THIS_WEEK,
} from "../../constants/dateTime";
import moment from "moment";
import { CASE_INSENSITIVE } from "../../constants/application";
import { isEmpty } from "lodash";
import NoShopSelected from "../shared/NoShopSelected";
import { withTranslation } from "react-i18next";

class CustomerFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentShopId: props.currentShopId,
      search: "",
      userCustomerRatings: props.userCustomerRatings,
      quickFilterDate: null,
      selectedFilter: null,
      rating: 0,
    };
  }

  componentDidMount() {
    const { currentShopId, userCustomerRatings } = this.props;
    if (
      currentShopId &&
      currentShopId !== "null" &&
      isEmpty(userCustomerRatings)
    ) {
      store.dispatch(fetchCustomerFeedback({ shopId: currentShopId }));
    }
  }

  componentDidUpdate(prevProps) {
    const { currentShopId } = this.props;
    if (currentShopId !== "null" && currentShopId !== prevProps.currentShopId) {
      store.dispatch(fetchCustomerFeedback({ shopId: currentShopId }));
    }
  }

  filterRatings = () => {
    const { userCustomerRatings } = this.props;
    const { quickFilterDate, selectedFilter } = this.state;
    if (["", undefined, null].includes(quickFilterDate))
      return userCustomerRatings;

    if (selectedFilter === THIS_WEEK)
      return userCustomerRatings.filter(
        (p) =>
          p.created_at >= moment(quickFilterDate).format(DATE_FORMAT.YYYY_MM_DD)
      );

    return (
      userCustomerRatings &&
      userCustomerRatings.filter((p) =>
        p.created_at.match(new RegExp(quickFilterDate, "i"))
      )
    );
  };

  filteredCustomerRatings = () => {
    const searchValue = this.state.search.trim();

    if (["", undefined, null].includes(searchValue)) {
      return this.filterRatings();
    } else {
      return this.filterRatings().filter(
        (e) =>
          (e.customer_name &&
            e.customer_name
              .toLowerCase()
              .match(new RegExp(searchValue, CASE_INSENSITIVE))) ||
          (e.customer_explanation &&
            e.customer_explanation
              .toLowerCase()
              .match(new RegExp(searchValue, CASE_INSENSITIVE))) ||
          (e.created_at &&
            moment(e.created_at)
              .parseZone()
              .format(DATE_TIME_FORMAT.YYDM)
              .toLowerCase()
              .match(new RegExp(searchValue, CASE_INSENSITIVE)))
      );
    }
  };

  calAverageRating = () => {
    const { rating } = this.state;
    let fiveStarRatingCount = 0;
    let fourStarRatingCount = 0;
    let threeStarRatingCount = 0;
    let twoStarRatingCount = 0;
    let oneStarRatingCount = 0;
    let totalStars = 0;
    let totalReviews = 0;
    const CustomerRatings = this.filteredCustomerRatings();

    CustomerRatings &&
      CustomerRatings.forEach((rating) => {
        if (rating.score === 5) {
          fiveStarRatingCount++;
        } else if (rating.score === 4) {
          fourStarRatingCount++;
        } else if (rating.score === 3) {
          threeStarRatingCount++;
        } else if (rating.score === 2) {
          twoStarRatingCount++;
        } else if (rating.score === 1) {
          oneStarRatingCount++;
        }
      });

    const ratings = [
      {
        weight: 5,
        count: fiveStarRatingCount,
      },
      {
        weight: 4,
        count: fourStarRatingCount,
      },
      {
        weight: 3,
        count: threeStarRatingCount,
      },
      {
        weight: 2,
        count: twoStarRatingCount,
      },
      {
        weight: 1,
        count: oneStarRatingCount,
      },
    ];

    ratings.forEach((rating) => {
      if (rating.count !== 0) {
        const weightMultipliedByNumber = rating.weight * rating.count;
        totalStars += weightMultipliedByNumber;
        totalReviews += rating.count;
      }
    });
    const averageRating = totalStars / totalReviews;
    if (!isNaN(averageRating)) {
      return averageRating.toFixed(1);
    }
    return rating;
  };

  calcRatingPercentage = (rating) => {
    const { userCustomerRatings } = this.props;
    const customerRatingsCount =
      userCustomerRatings && this.filteredCustomerRatings().length;
    const ratingPercentage = (rating / customerRatingsCount) * 100;
    if (!isNaN(ratingPercentage)) return ratingPercentage.toFixed(2);
    return 0;
  };

  showRating = (rating) => {
    return (
      <Rating
        size="tiny"
        maxRating={5}
        rating={Math.round(rating)}
        icon="star"
        disabled
      />
    );
  };

  filterButtons = (filter) => {
    const { selectedFilter } = this.state;
    const { t } = this.props;
    return (
      <div
        className={
          selectedFilter === filter.text ? "div-filters-active" : "div-filters"
        }
        onClick={(e) =>
          this.setState({
            quickFilterDate: filter.value,
            selectedFilter: filter.text,
          })
        }
      >
        {t(filter.text)}
      </div>
    );
  };

  render() {
    const {
      currentShop,
      currentShopId,
      currentUser,
      isLoading,
      dispatch,
      t,
    } = this.props;
    const customerRatings = this.filteredCustomerRatings();
    let fiveStarRatingCount = 0;
    let fourStarRatingCount = 0;
    let threeStarRatingCount = 0;
    let twoStarRatingCount = 0;
    let oneStarRatingCount = 0;

    customerRatings &&
      customerRatings.forEach((rating) => {
        if (rating.score === 5) {
          fiveStarRatingCount++;
        } else if (rating.score === 4) {
          fourStarRatingCount++;
        } else if (rating.score === 3) {
          threeStarRatingCount++;
        } else if (rating.score === 2) {
          twoStarRatingCount++;
        } else if (rating.score === 1) {
          oneStarRatingCount++;
        }
      });

    return (
      <div className="full-feedback-container">
        {isLoading && <CustomLoader />}
        {!isLoading && (
          <React.Fragment>
            {currentShop ? (
              <React.Fragment>
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    margin: "14px 20px 30px 0",
                  }}
                >
                  <Input
                    icon="search"
                    placeholder="Search"
                    style={{ marginRight: "20px" }}
                    onChange={(e) => this.setState({ search: e.target.value })}
                  />
                </div>
                <Card>
                  <Card.Content>
                    <Header color="black">{t("customerFeedback")}</Header>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={12} className="d-flex">
                          <div className="rating-summary">
                            <p>{t("avgRating")}</p>
                          </div>
                          {filters.map((filter) => {
                            return (
                              <React.Fragment key={filter.key}>
                                {this.filterButtons(filter)}
                              </React.Fragment>
                            );
                          })}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={2}>
                          <div className="rating-summary">
                            <h1 className="item-count">
                              {this.calAverageRating()}
                            </h1>
                            <div style={{ textAlign: "center" }}>
                              <div>
                                {this.showRating(this.calAverageRating())}
                              </div>
                              {customerRatings && (
                                <span>
                                  {customerRatings.length} {t("ratings")}
                                </span>
                              )}
                            </div>
                          </div>
                        </Grid.Column>
                        <Grid.Column width={11}>
                          <div>
                            <div style={{ width: "80%", float: "left" }}>
                              <Progress
                                active
                                percent={this.calcRatingPercentage(
                                  fiveStarRatingCount
                                )}
                                size="small"
                              />
                            </div>
                            <div
                              style={{
                                float: "right",
                                margin: "-4px 40px 0 0",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                {this.calcRatingPercentage(fiveStarRatingCount)}
                                %{" "}
                              </span>
                              <span style={{ marginLeft: "auto" }}>
                                {fiveStarRatingCount} {t("reviews")}
                              </span>
                            </div>
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ width: "80%", float: "left" }}>
                              <Progress
                                active
                                percent={this.calcRatingPercentage(
                                  fourStarRatingCount
                                )}
                                size="small"
                              />
                            </div>
                            <div
                              style={{
                                float: "right",
                                margin: "-4px 40px 0 0",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                {this.calcRatingPercentage(fourStarRatingCount)}
                                %{" "}
                              </span>
                              <span>
                                {fourStarRatingCount} {t("reviews")}
                              </span>
                            </div>
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ width: "80%", float: "left" }}>
                              <Progress
                                active
                                percent={this.calcRatingPercentage(
                                  threeStarRatingCount
                                )}
                                size="small"
                              />
                            </div>
                            <div
                              style={{
                                float: "right",
                                margin: "-4px 40px 0 0",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                {this.calcRatingPercentage(
                                  threeStarRatingCount
                                )}
                                %{" "}
                              </span>
                              <span>
                                {threeStarRatingCount} {t("reviews")}
                              </span>
                            </div>
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ width: "80%", float: "left" }}>
                              <Progress
                                active
                                percent={this.calcRatingPercentage(
                                  twoStarRatingCount
                                )}
                                size="small"
                              />
                            </div>
                            <div
                              style={{
                                float: "right",
                                margin: "-4px 40px 0 0",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                {this.calcRatingPercentage(twoStarRatingCount)}%{" "}
                              </span>
                              <span>
                                {twoStarRatingCount} {t("reviews")}
                              </span>
                            </div>
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ width: "80%", float: "left" }}>
                              <Progress
                                active
                                percent={this.calcRatingPercentage(
                                  oneStarRatingCount
                                )}
                                size="small"
                              />
                            </div>
                            <div
                              style={{
                                float: "right",
                                margin: "-4px 40px 0 0",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                {this.calcRatingPercentage(oneStarRatingCount)}%{" "}
                              </span>
                              <span>
                                {oneStarRatingCount} {t("reviews")}
                              </span>
                            </div>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <CustomerFeedbackTable
                      currentShopId={currentShopId}
                      currentShop={currentShop}
                      dispatch={dispatch}
                      currentUser={currentUser}
                      userCustomerRatings={customerRatings}
                      t={t}
                    />
                  </Card.Content>
                </Card>
              </React.Fragment>
            ) : (
              <NoShopSelected message={t("noSelectShopForFeedback")} />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const currentShop = state.shops.userShops.find(
    (s) => s.id === state.shops.currentShopId
  );
  return {
    currentUser: state.users.currentUser,
    isLoading:
      state.application.isShopFeedbackLoading || state.application.isLoading
        ? true
        : false,
    currentShop: currentShop,
    currentShopId: state.shops.currentShopId,
    userCustomerRatings: state.customerRatings.userCustomerRatings || [],
  };
};

export default connect(mapStateToProps)(
  withTranslation("customerFeedback")(CustomerFeedback)
);
