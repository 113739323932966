import "whatwg-fetch";
import { applicationIdentifier } from "../constants/application";
import store from "../actions/configureStore";
import { removeUser } from "../actions/sagas/userSaga";
const api = "/api/shop_portal";
const BRACKETS = "brackets";
const CORS = "cors";

export async function getJSON(url, params = {}, headers = {}) {
  try {
    params = Object.keys(params)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
      })
      .join("&");

    if (params) url += `?${params}`;

    let response = await fetch(api + url, {
      method: "GET",
      headers: Object.assign({ Accept: "application/json" }, headers),
    });

    if (response.ok) {
      let result = await response.json();
      return { result };
    } else if (response.status >= 400 && response.status < 500) {
      let result = await response.json();
      if (response.status === 401) await store.dispatch(removeUser());
      else return { error: result.errors || result.error };
    } else {
      throw new Error(
        `Error with status ${response.status}, url: ${response.url}`
      );
    }
  } catch (error) {
    console.log(error);
    return { error: error.message };
  }
}

export async function postJSON(url, data = {}, headers = {}) {
  try {
    let response = await fetch(api + url, {
      method: "POST",
      headers: Object.assign(
        {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        headers
      ),
      body: JSON.stringify(data),
    });

    if (response.ok) {
      let result = await response.json();
      return { result };
    } else if (response.status >= 400 && response.status < 500) {
      let result = await response.json();
      if (response.status === 401) await store.dispatch(removeUser());
      else return { error: result.errors || result.error || result.message };
    } else {
      throw new Error(`Error with status ${response.status}`);
    }
  } catch (error) {
    console.log(error);
    return { error: error.message };
  }
}
export async function postJSONNew(url, data = {}, headers = {}) {
  try {
    let response = await fetch(api + url, {
      method: "POST",
      headers: Object.assign(
        {
          Accept: "application/json",
        },
        headers
      ),
      body: data,
      arrayFormat: BRACKETS,
      mode: CORS,
    });

    if (response.ok) {
      let result = await response.json();
      return { result };
    } else if (response.status >= 400 && response.status < 500) {
      let result = await response.json();
      if (response.status === 401) await store.dispatch(removeUser());
      else return { error: result.errors || result.error || result.message };
    } else {
      throw new Error(`Error with status ${response.status}`);
    }
  } catch (error) {
    console.log(error);
    return { error: error.message };
  }
}

export async function putJSON(url, data = {}, headers = {}) {
  try {
    let response = await fetch(api + url, {
      method: "PUT",
      headers: Object.assign(
        {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        headers
      ),
      body: JSON.stringify(data),
    });

    if (response.ok) {
      let result = await response.json().catch(() => null);
      return { result };
    } else if (response.status >= 400 && response.status < 500) {
      let result = await response.json();
      if (response.status === 401) await store.dispatch(removeUser());
      else return { error: result.errors || result.error };
    } else {
      throw new Error(`Error with status ${response.status}`);
    }
  } catch (error) {
    console.log(error);
    return { error: error.message };
  }
}

export async function putJSONNew(url, data = {}, headers = {}) {
  try {
    let response = await fetch(api + url, {
      method: "PUT",
      headers: Object.assign(
        {
          Accept: "application/json",
          "X-AppName": applicationIdentifier,
        },
        headers
      ),
      body: data,
      arrayFormat: BRACKETS,
      mode: CORS,
    });

    if (response.ok) {
      let result = await response.json().catch(() => null);
      return { result };
    } else if (response.status >= 400 && response.status < 500) {
      let result = await response.json();
      if (response.status === 401) await store.dispatch(removeUser());
      else return { error: result.errors || result.error };
    } else {
      throw new Error(`Error with status ${response.status}`);
    }
  } catch (error) {
    console.log(error);
    return { error: error.message };
  }
}

export async function deleteJSON(url, data = {}, headers = {}) {
  try {
    let response = await fetch(api + url, {
      method: "DELETE",
      headers: Object.assign(
        {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        headers
      ),
      body: JSON.stringify(data),
    });

    if (response.ok) {
      return { result: true };
    } else if (response.status >= 400 && response.status < 500) {
      let result = await response.json();
      if (response.status === 401) await store.dispatch(removeUser());
      else return { error: result.errors || result.error };
    } else {
      throw new Error(`Error with status ${response.status}`);
    }
  } catch (error) {
    console.log(error);
    return { error: error.message };
  }
}

export async function patchJSON(url, data = {}, headers = {}) {
  try {
    let response = await fetch(url, {
      method: "PATCH",
      headers: Object.assign(
        {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        headers
      ),
      body: JSON.stringify(data),
    });

    if (response.ok) {
      let result = await response.json();
      return { result };
    } else if (response.status >= 400 && response.status < 500) {
      let result = await response.json();
      if (response.status === 401) await store.dispatch(removeUser());
      else return { error: result.errors || result.error };
    } else {
      throw new Error(`Error with status ${response.status}`);
    }
  } catch (error) {
    return { error: error.message };
  }
}
