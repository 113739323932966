import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Popup } from "semantic-ui-react";
import { getStyles } from "../shared/StylesheetInjector";
import { INITIATED_BY_USER } from "../../constants/actionTypes";
import { WALK_IN } from "../../constants/actionTypes";
import { CANCELLED } from "../../constants/actionTypes";
import { ACTIVE } from "../../constants/actionTypes";
import { WALK_IN_ACTIVE } from "../../constants/actionTypes";
import { CHECKED_IN } from "../../constants/actionTypes";
import { IN_BAY } from "../../constants/actionTypes";
import { AWAITING_APPROVAL } from "../../constants/actionTypes";
import { APPROVAL } from "../../constants/actionTypes";
import { COMPLETED } from "../../constants/actionTypes";
import { PAID } from "../../constants/actionTypes";
import { IN_MANUAL_REVIEW } from "../../constants/actionTypes";
import { PAID_AT_SHOP } from "../../constants/actionTypes";
import { WALK_IN_NEEDS_RESCHEDULE } from "../../constants/actionTypes";
import { USER_INITIATED_NEEDS_RESCHEDULE } from "../../constants/actionTypes";
import { WALK_IN_ACTIVE_NEEDS_RESCHEDULE } from "../../constants/actionTypes";
import { ACTIVE_NEEDS_RESCHEDULE } from "../../constants/actionTypes";

const styles = getStyles();

export default class ProgressStatusBar extends React.Component {
  render() {
    const { status, t } = this.props;
    const steps = [
      status === INITIATED_BY_USER
        ? {
            stat: INITIATED_BY_USER,
            tip: t("booked"),
          }
        : status === WALK_IN
        ? {
            stat: WALK_IN,
            tip: t("booked"),
          }
        : status === CANCELLED
        ? {
            stat: CANCELLED,
            tip: t("cancelled"),
          }
        : status === WALK_IN_NEEDS_RESCHEDULE
        ? {
            stat: WALK_IN_NEEDS_RESCHEDULE,
            tip: t("rescheduled"),
          }
        : status === USER_INITIATED_NEEDS_RESCHEDULE
        ? {
            stat: USER_INITIATED_NEEDS_RESCHEDULE,
            tip: t("rescheduled"),
          }
        : {
            stat: INITIATED_BY_USER,
            tip: t("booked"),
          },
      status === ACTIVE
        ? {
            stat: ACTIVE,
            tip: t("accepted"),
          }
        : status === WALK_IN_ACTIVE
        ? {
            stat: WALK_IN_ACTIVE,
            tip: t("accepted"),
          }
        : status === WALK_IN_ACTIVE_NEEDS_RESCHEDULE
        ? {
            stat: WALK_IN_ACTIVE_NEEDS_RESCHEDULE,
            tip: t("rescheduled"),
          }
        : status === ACTIVE_NEEDS_RESCHEDULE
        ? {
            stat: ACTIVE_NEEDS_RESCHEDULE,
            tip: t("rescheduled"),
          }
        : {
            stat: ACTIVE,
            tip: t("accepted"),
          },
      {
        stat: CHECKED_IN,
        tip: t("arrived"),
      },
      {
        stat: IN_BAY,
        tip: t("inBay"),
      },
      {
        stat: AWAITING_APPROVAL,
        tip: t("approvalReq"),
      },
      {
        stat: APPROVAL,
        tip: t("approval"),
      },
      status === COMPLETED
        ? {
            stat: COMPLETED,
            tip: t("completed"),
          }
        : status === PAID
        ? {
            stat: PAID,
            tip: t("paid"),
          }
        : status === PAID_AT_SHOP
        ? {
            stat: PAID_AT_SHOP,
            tip: t("paidAtShop"),
          }
        : status === IN_MANUAL_REVIEW
        ? {
            stat: IN_MANUAL_REVIEW,
            tip: t("inManualReview"),
          }
        : {
            stat: COMPLETED,
            tip: t("completed"),
          },
    ];
    return (
      <div className="div-progress">
        <ProgressBar
          width="100%"
          percent={
            100 *
              ((steps.findIndex(({ stat }) => stat === status) + 1) /
                (steps.length - 1)) -
            1
          }
          filledBackground={styles.progressBarActive}
        >
          {steps.map((step, index, arr) => {
            return (
              <Step
                key={index}
                transition="scale"
                children={({ accomplished }) => (
                  <Popup
                    size="mini"
                    trigger={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          width: 20,
                          height: 20,
                          color: styles.whiteTextColor,
                          backgroundColor: accomplished
                            ? step.stat === CANCELLED
                              ? styles.red
                              : step.stat === USER_INITIATED_NEEDS_RESCHEDULE ||
                                step.stat === ACTIVE_NEEDS_RESCHEDULE ||
                                step.stat === WALK_IN_ACTIVE_NEEDS_RESCHEDULE ||
                                step.stat === WALK_IN_NEEDS_RESCHEDULE
                              ? styles.rescheduleColor
                              : styles.progressBarActive
                            : styles.progressBarInactive,
                        }}
                      >
                        {/* /  {step.status} */}
                      </div>
                    }
                    content={step.tip}
                    position="top center"
                  />
                )}
              />
            );
          })}
        </ProgressBar>
      </div>
    );
  }
}
