import { faFileMedical, faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Button, Grid, Header, Modal } from "semantic-ui-react";
import AddNoteModal from "../notes/AddNoteModal";
import { getStyles } from "../shared/StylesheetInjector";
import { DATE_FORMAT } from "../../constants/dateTime";
import store from "../../actions/configureStore";
import { destroyNote } from "../../actions/sagas/noteSaga";
import { withTranslation } from "react-i18next";

const styles = getStyles();

class ShowNotesModal extends React.Component {
  state = {
    selectedNote: this.props.notes[0].title,
    openNotesModal: false,
    noteContaint: this.props.notes[0],
  };

  close = () => this.setState({ openNotesModal: false });

  onRemove = (id) => {
    const { currentShopId } = this.props;
    store.dispatch(destroyNote({ noteId: id, shopId: currentShopId }));
  };

  selectedNoteTitle = (value) => {
    this.setState({ selectedNote: value.title, noteContaint: value });
  };

  notesTitle = (note) => {
    const { selectedNote } = this.state;
    const noteDate = moment(note.created_at).format(DATE_FORMAT.MM_DD_YYYY);
    return (
      <div
        style={{
          backgroundColor:
            selectedNote === note.title ? styles.overrideLinkColor : "",
          color: selectedNote === note.title ? styles.whiteTextColor : "",
        }}
        className="show-note"
        onClick={() => this.selectedNoteTitle(note)}
      >
        <FontAwesomeIcon
          size="1x"
          icon={faFile}
          style={{
            color:
              selectedNote === note.title
                ? styles.whiteTextColor
                : styles.overrideLinkColor,
          }}
          className="add-note-icon"
        />
        <div
          style={{
            color:
              selectedNote === note.title ? styles.whiteTextColor : "black",
            marginLeft: "10px",
          }}
        >
          <span className="notes-summary">
            CREATED {noteDate} BY {note.created_by}
          </span>
          <h3 className="notes-title">{note.title}</h3>
        </div>
      </div>
    );
  };

  render() {
    const {
      open,
      close,
      notes,
      currentUser,
      shop_order_id,
      currentShopId,
      t,
    } = this.props;
    const { openNotesModal, noteContaint } = this.state;
    const noteDate = moment(noteContaint.created_at).format(
      DATE_FORMAT.MM_DD_YYYY
    );
    return (
      <Modal
        size="small"
        closeIcon
        open={open}
        onClose={() => close()}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column width={7}>
                <Header as="h2" style={{ marginLeft: "5px" }}>
                  {t("notes")}
                </Header>
                <div className="show-notes-container">
                  {notes.map((note) => {
                    return (
                      <React.Fragment key={note.id}>
                        {this.notesTitle(note)}
                      </React.Fragment>
                    );
                  })}
                </div>
                <div
                  className="add-note-button"
                  onClick={() =>
                    this.setState({
                      openNotesModal: !openNotesModal,
                    })
                  }
                >
                  <FontAwesomeIcon
                    size="1x"
                    icon={faFileMedical}
                    className="add-note-icon"
                  />
                  <h3 className="add-note-text">{t("createNewNote")}</h3>
                </div>
                {openNotesModal && (
                  <AddNoteModal
                    open={openNotesModal}
                    close={this.close}
                    shop_order_id={shop_order_id}
                    currentUser={currentUser}
                    currentShopId={currentShopId}
                  />
                )}
              </Grid.Column>
              <Grid.Column width={8}>
                <h3 style={{ margin: "0", color: "black" }}>
                  {noteContaint.title}
                </h3>
                <span className="notes-summary">
                  {t("created")} {noteDate} {t("by")} {noteContaint.created_by}
                </span>
                <div className="note-comment">
                  <span>{noteContaint.comment}</span>
                </div>
                <div>
                  <Button
                    compact
                    secondary
                    fluid
                    circular
                    onClick={() => this.onRemove(noteContaint.id)}
                    style={{ borderRadius: "50px", width: "30%" }}
                  >
                    {t("delete")}
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const currentShop = state.shops.userShops.find(
    (s) => s.id === state.shops.currentShopId
  );

  return {
    isLoading: state.application.isLoading,
    currentUser: state.users.currentUser,
    currentShop: currentShop,
  };
};

export default connect(mapStateToProps)(
  withTranslation("modals")(ShowNotesModal)
);
