import React, { Component } from "react";
import { Form, Button, Input } from "semantic-ui-react";
import { BACKSPACE, TAB } from "../../constants/application";
import { getSubdomain } from "../shared/StylesheetInjector";
import { setupSplitFlags } from "../config/SplitClient";
let sub = getSubdomain();

// TODO: extract this to caradvise_shared_components?
//   Maybe once we need to pull that package in to this project
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const STRING_REGEX = /^\s*$/;

export default class SignUpForm extends Component {
  state = {
    formData: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      shopName: "",
      numShops: "",
      accessCode: "",
    },
    errors: {},
  };

  onFieldChange = (field, e) => {
    let updatedFormData = { ...this.state.formData };
    updatedFormData[field] = e.target.value;
    this.setState({ formData: updatedFormData });
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      this.validateAndSubmit();
    }
  };

  handleKeypress = (e) => {
    const characterCode = e.key;
    if (characterCode === BACKSPACE || characterCode === TAB) return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  };

  formDataErrors = () => {
    const {
      firstName,
      lastName,
      email,
      phone,
      password,
      shopName,
    } = this.state.formData;
    const { t } = this.props;
    const errors = {};
    if (STRING_REGEX.test(firstName))
      errors.firstName = t("firstNameCantBlankMessage");

    if (STRING_REGEX.test(lastName))
      errors.lastName = t("lastNameCantBlankMessage");

    if (!email.match(EMAIL_REGEX))
      errors.email = t("emailFormatInvalidMessage");

    if (phone.replace(/[^\d]/g, "").length !== 10)
      errors.phone = t("phoneNumMustBe10DigitsMessage");

    if (STRING_REGEX.test(password) && password.trim().length === 0)
      errors.password = t("passwordCantBeBlankMessage");

    if (password.length < 8)
      errors.password = t("passwordMustbeAtLeast8CharsMessage");

    if (STRING_REGEX.test(shopName))
      errors.shopName = t("shopNameCantBeBlankMessage");

    return errors;
  };

  validateAndSubmit = () => {
    const errors = this.formDataErrors();

    if (Object.keys(errors).length === 0) {
      const { formData } = this.state;

      this.props.onSubmit({
        ...formData,
        phone: formData.phone.replace(/[^\d]/g, ""),
      });
    } else {
      this.setState({ errors: errors });
    }
  };

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: sub });
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      phone,
      password,
      shopName,
      numShops,
      accessCode,
    } = this.state.formData;
    const { errors } = this.state;
    const { isSubmitting, t } = this.props;

    return (
      <Form error className="no-session-form">
        <Form.Field required>
          <label>{t("firstName")}</label>
          <Form.Input
            value={firstName}
            onChange={(e) => this.onFieldChange("firstName", e)}
            fluid
            size="large"
            error={errors.firstName}
          />
        </Form.Field>
        <Form.Field required>
          <label>{t("lastName")}</label>
          <Form.Input
            value={lastName}
            onChange={(e) => this.onFieldChange("lastName", e)}
            fluid
            size="large"
            error={errors.lastName}
          />
        </Form.Field>
        <Form.Field required>
          <label>{t("phoneNumber")}</label>
          <Form.Input
            value={phone}
            onChange={(e) => this.onFieldChange("phone", e)}
            fluid
            error={errors.phone}
            size="large"
          />
        </Form.Field>
        <Form.Field required>
          <label>{t("email")}</label>
          <Form.Input
            value={email}
            onChange={(e) => this.onFieldChange("email", e)}
            fluid
            size="large"
            error={errors.email}
          />
        </Form.Field>
        <Form.Field required>
          <label>{t("password")}</label>
          <Form.Input
            value={password}
            onChange={(e) => this.onFieldChange("password", e)}
            fluid
            type="password"
            size="large"
            error={errors.password}
          />
        </Form.Field>
        <Form.Field required>
          <label>{t("brandName")}</label>
          <Form.Input
            value={shopName}
            onChange={(e) => this.onFieldChange("shopName", e)}
            fluid
            size="large"
            error={errors.shopName}
          />
        </Form.Field>
        <Form.Field>
          <label>{t("numOfShops")}</label>
          <Form.Input
            value={numShops}
            onChange={(e) => this.onFieldChange("numShops", e)}
            fluid
            type="number"
            size="large"
            min="0"
            onKeyDown={this.handleKeypress}
          />
        </Form.Field>

        <div className="flex-container">
          <div style={{ width: "95%", textAlign: "left" }}>
            <Form.Field>
              <label className="label">{t("accessCode")}</label>
              <Input
                value={accessCode}
                fluid
                onChange={(e) => this.onFieldChange("accessCode", e)}
                size="large"
              />
            </Form.Field>
          </div>
          <div style={{ width: "100%", textAlign: "right", marginTop: "25px" }}>
            <Button
              className={"button-primary no-session-button"}
              primary
              disabled={isSubmitting}
              onClick={this.validateAndSubmit}
            >
              {t("signUp")}
            </Button>
          </div>
        </div>
      </Form>
    );
  }
} // SignUpForm
