import {
  formatInternalErrors,
  consoleLog,
  catchErrors,
} from "../../helpers/apiErrorHelpers";
import {
  // ACTIVE,
  // ACTIVE_NEEDS_RESCHEDULE,
  APPOINTMENTS_LOAD_SUCCEEDED,
  APPOINTMENTS_PATH,
  // INITIATED_BY_USER,
  PRICINGS_PATH,
  // STATUS,
  // USER_INITIATED_NEEDS_RESCHEDULE,
  USER_PRICINGS_LOAD_SUCCEEDED,
  // WALK_IN,
  // WALK_IN_ACTIVE,
  // WALK_IN_ACTIVE_NEEDS_RESCHEDULE,
  // WALK_IN_NEEDS_RESCHEDULE,
  // CHECKED_IN,
  // IN_BAY,
  // WAITING_FOR_PARTS,
  // AWAITING_APPROVAL,
  // COMPLETED,
  // IN_MANUAL_REVIEW,
  // PAID,
  // APPROVAL,
  UNSET_DASHBOARD_LOADING,
  SET_DASHBOARD_LOADING,
} from "../../constants/actionTypes";
import Notifications from "../../components/Notifications";
import store from "../configureStore";
import { getJSON } from "../../utils/fetch";
import i18n from "../../utils/i18n";

// const SCHEDULED_STATUSES_FROM_RETAIL = [
//   WALK_IN,
//   USER_INITIATED_NEEDS_RESCHEDULE,
//   WALK_IN_ACTIVE_NEEDS_RESCHEDULE,
//   ACTIVE_NEEDS_RESCHEDULE,
//   WALK_IN_NEEDS_RESCHEDULE,
//   INITIATED_BY_USER,
//   ACTIVE,
//   WALK_IN_ACTIVE,
//   CHECKED_IN,
//   IN_BAY,
//   WAITING_FOR_PARTS,
//   AWAITING_APPROVAL,
//   COMPLETED,
//   IN_MANUAL_REVIEW,
//   PAID,
//   APPROVAL,
// ].map((string) => STATUS[string]);

export function loadDashboard() {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_DASHBOARD_LOADING });

      let user = getState().users || {};
      const { authentication_token } = user;

      const pricingRequest = {
        url: PRICINGS_PATH,
        headers: { Authorization: authentication_token },
        body: {},
      };

      const pricingResponse = await getJSON(
        pricingRequest.url,
        pricingRequest.body,
        pricingRequest.headers
      );
      catchErrors(pricingResponse.result);

      dispatch({
        type: USER_PRICINGS_LOAD_SUCCEEDED,
        payload: { pricings: pricingResponse.result },
      });

      const appointmentRequest = {
        url: `/${APPOINTMENTS_PATH}`,
        headers: { Authorization: authentication_token },
        body: {},
      };

      const appointmentsResponse = await getJSON(
        appointmentRequest.url,
        appointmentRequest.body,
        appointmentRequest.headers
      );
      catchErrors(appointmentsResponse.result);

      dispatch({
        type: APPOINTMENTS_LOAD_SUCCEEDED,
        payload: { appointments: appointmentsResponse.result },
      });

      dispatch({ type: UNSET_DASHBOARD_LOADING });
    } catch (errors) {
      dispatch({ type: UNSET_DASHBOARD_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t(
            "common:errorWhileLoadingDashboardMsg"
          )} ${toString(formattedErrors)}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
    }
  };
}
