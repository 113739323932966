// reducersNG
export const CURRENT_USER_LOAD_SUCCEEDED = "CURRENT_USER_LOAD_SUCCEEDED";
export const USER_PRICING_CREATE_SUCCEEDED = "USER_PRICING_CREATE_SUCCEEDED";
export const USER_PRICING_UPDATE_SUCCEEDED = "USER_PRICING_UPDATE_SUCCEEDED";
export const USER_PRICING_DESTROY_SUCCEEDED = "USER_PRICING_DESTROY_SUCCEEDED";
export const SET_LOADING = "SET_LOADING";
export const SERVICE_DEFINITIONS_LOAD_SUCCEEDED =
  "SERVICE_DEFINITIONS_LOAD_SUCCEEDED";
export const SERVICE_DEFINITIONS_SEARCH_LOAD_SUCCEEDED =
  "SERVICE_DEFINITIONS_SEARCH_LOAD_SUCCEEDED";
export const SHOP_LOAD_SUCCEEDED = "SHOP_LOAD_SUCCEEDED";
export const SHOPS_LOAD_SUCCEEDED = "SHOPS_LOAD_SUCCEEDED";
export const SIGN_OUT_SUCCEEDED = "SIGN_OUT_SUCCEEDED";
export const UNSET_LOADING = "UNSET_LOADING";
export const USER_PRICINGS_LOAD_SUCCEEDED = "USER_PRICINGS_LOAD_SUCCEEDED";
export const APPOINTMENTS_LOAD_SUCCEEDED = "APPOINTMENTS_LOAD_SUCCEEDED";
export const APPOINTMENT_UPDATE_SUCCEEDED = "APPOINTMENT_UPDATE_SUCCEEDED";
export const USER_NOTE_CREATE_SUCCEEDED = "USER_NOTE_CREATE_SUCCEEDED";
export const USER_NOTE_UPDATE_SUCCEEDED = "USER_NOTE_UPDATE_SUCCEEDED";
export const USER_NOTE_DESTROY_SUCCEEDED = "USER_NOTE_DESTROY_SUCCEEDED";
export const ORDERS_LOAD_SUCCEEDED = "ORDERS_LOAD_SUCCEEDED";
export const ORDER_UPDATE_SUCCEEDED = "ORDER_UPDATE_SUCCEEDED";
export const BUSINESS_HOURS_LOAD_SUCCEEDED = "BUSINESS_HOURS_LOAD_SUCCEEDED";
export const SHOP_DETAILS_UPDATE_SUCCEEDED = "SHOP_DETAILS_UPDATE_SUCCEEDED";
export const CUSTOMER_FEEDBACK_LOAD_SUCCEEDED =
  "CUSTOMER_FEEDBACK_LOAD_SUCCEEDED";
export const CUSTOMER_FEEDBACK_UPDATE_SUCCEEDED =
  "CUSTOMER_FEEDBACK_UPDATE_SUCCEEDED";
export const CURRENT_USER_SETTINGS_UPDATE_SUCCEEDED =
  "CURRENT_USER_SETTINGS_UPDATE_SUCCEEDED";
export const COMMON_SERVICES_LOAD_SUCCEEDED = "COMMON_SERVICES_LOAD_SUCCEEDED";
export const SET_APPOINTMENT_LOADING = "SET_APPOINTMENT_LOADING";
export const UNSET_APPOINTMENT_LOADING = "UNSET_APPOINTMENT_LOADING";
export const SET_DASHBOARD_LOADING = "SET_DASHBOARD_LOADING";
export const UNSET_DASHBOARD_LOADING = "UNSET_DASHBOARD_LOADING";
export const SET_SHOP_HOURS_LOADING = "SET_SHOP_HOURS_LOADING";
export const UNSET_SHOP_HOURS_LOADING = "UNSET_SHOP_HOURS_LOADING";
export const SET_SHOP_PRICINGS_LOADING = "SET_SHOP_PRICINGS_LOADING";
export const UNSET_SHOP_PRICINGS_LOADING = "UNSET_SHOP_PRICINGS_LOADING";
export const SET_SHOP_FEEDBACK_LOADING = "SET_SHOP_FEEDBACK_LOADING";
export const UNSET_SHOP_FEEDBACK_LOADING = "UNSET_SHOP_FEEDBACK_LOADING";
export const SET_SHOP_PROMOTIONS_LOADING = "SET_SHOP_PROMOTIONS_LOADING";
export const UNSET_SHOP_PROMOTIONS_LOADING = "UNSET_SHOP_PROMOTIONS_LOADING";
export const SHOP_PROMOTION_CREATE_SUCCEEDED =
  "SHOP_PROMOTION_CREATE_SUCCEEDED";
export const SHOP_PROMOTION_LOAD_SUCCEEDED = "SHOP_PROMOTION_LOAD_SUCCEEDED";
export const IDENTIFY_AND_SET_USER = "IDENTIFY_AND_SET_USER";
export const REMOVE_USER = "REMOVE_USER";
export const SET_USER_LANGUAGE = "SET_USER_LANGUAGE";

// UI
export const SHOW_NAV = "SHOW_NAV";
export const HIDE_NAV = "HIDE_NAV";
export const UPDATE_CURRENT_SHOP_ID = "UPDATE_CURRENT_SHOP_ID";

//URL
export const USERS_PATH = "/shop_users";
export const APPOINTMENTS_PATH = "appointments";
export const ORDERS_PATH = "orders";
export const PRICINGS_PATH = "/pricings";
export const SHOPS_PATH = "/shops";
export const SHOPS_BUSINESS_DAYS = "/operational_business_days";
export const CUSTOMER_RATING_PATH = "/customers_rating";
export const COMMON_SERVICES_PATH = "/shop_portal_default_services";
export const PROMOTIONS_PATH = "/shop_promotions";
export const SESSIONS_PATH = "/sessions";
export const SERVICE_DEFINITIONS_PATH = "/service_definitions";
export const NOTES_PATH = "/notes";
//status
export const STATUS = {
  active: 0,
  completed: 1,
  canceled: 2,
  paid: 3,
  paid_at_shop: 4,
  initiated_by_user: 5,
  awaiting_approval: 6,
  approval: 7,
  in_manual_review: 8,
  walk_in: 9,
  seeking_quote: 10,
  price_review: 11,
  active_needs_reschedule: 12,
  walk_in_needs_reschedule: 13,
  indefinite_open: 14,
  checked_in: 15,
  user_initiated_needs_reschedule: 16,
  walk_in_active_needs_reschedule: 17,
  walk_in_active: 18,
  in_bay: 19,
  waiting_for_parts: 20,
};

export const INITIATED_BY_USER = "initiated_by_user";
export const ACTIVE = "active";
export const CANCELLED = "canceled";
export const COMPLETED = "completed";
export const PAID = "paid";
export const PAID_AT_SHOP = "paid_at_shop";
export const IN_MANUAL_REVIEW = "in_manual_review";
export const APPROVAL = "approval";
export const AWAITING_APPROVAL = "awaiting_approval";
export const WALK_IN = "walk_in";
export const WALK_IN_ACTIVE = "walk_in_active";
export const SEEKING_QUOTE = "seeking_quote";
export const PRICE_REVIEW = "price_review";
export const ACTIVE_NEEDS_RESCHEDULE = "active_needs_reschedule";
export const WALK_IN_NEEDS_RESCHEDULE = "walk_in_needs_reschedule";
export const INDEFINITE_OPEN = "indefinite_open";
export const USER_INITIATED_NEEDS_RESCHEDULE =
  "user_initiated_needs_reschedule";
export const WALK_IN_ACTIVE_NEEDS_RESCHEDULE =
  "walk_in_active_needs_reschedule";
export const CHECKED_IN = "checked_in";
export const IN_BAY = "in_bay";
export const WAITING_FOR_PARTS = "waiting_for_parts";

//Variables
export const ASCENDING = "ascending";
export const DESCENDING = "descending";

//Appointment Status
export const UPCOMING = "UPCOMING";
export const DUE = "DUE";
export const PAST_DUE = "PAST DUE";

// translation
export const EN_LOCALE = "en";
export const FR_LOCALE = "fr";
export const ES_LOCALE = "es";
export const CANDA_COUNTRY_CODE = "ca";
export const US_COUNTRY_CODE = "us";
export const PR_COUNTRY_CODE = "pr";
export const EN_CANDA_LOCALE = "en-CA";
export const FR_CANDA_LOCALE = "fr-CA";
export const ES_USA_LOCALE = "es-US";

export const MAX_FILE_LENGTH = 5;

export const SUPPORTED_DOCUMENT_TYPES = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
];
