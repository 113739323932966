import moment from "moment";
import React from "react";
import Chart from "react-apexcharts";
import { INITIATED_BY_USER, WALK_IN } from "../../constants/actionTypes";
import {
  DATE_FORMAT,
  THIS_MONTH,
  THIS_WEEK,
  TODAY,
  WEEK_DAYS,
} from "../../constants/dateTime";
import { Button, Card } from "semantic-ui-react";
import ProgressBar from "../ProgressBar";
import { getStyles } from "../shared/StylesheetInjector";

const styles = getStyles();

class Summary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedButton: TODAY,
      thisWeekOptions: {
        colors: [styles.apexChartPrimaryColor, styles.apexChartSecondaryColor],
        chart: {
          type: "bar",
          height: "300px",
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
            labels: {
              show: false,
            },
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 6,
            columnWidth: "50%",
          },
        },
        xaxis: {
          type: "category",
          categories: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
          labels: {
            show: false,
          },
        },
        legend: {
          position: "right",
          offsetY: 70,
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 30,
          },
        },
        fill: {
          opacity: 1,
        },
      },
      thisMonthOptions: {
        colors: [styles.apexChartPrimaryColor, styles.apexChartSecondaryColor],
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
            labels: {
              show: false,
            },
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 6,
            columnWidth: "50%",
          },
        },
        xaxis: {
          type: "category",
          categories: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
          labels: {
            show: false,
          },
        },
        legend: {
          position: "right",
          offsetY: 70,
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: styles.whiteTextColor,
            radius: 30,
          },
        },
        fill: {
          opacity: 1,
        },
      },
    };
  }

  todaysAppointments = () => {
    const { appointments, currentShopId, t } = this.props;
    let requestedAppointments = 0;
    let confirmedAppointments = 0;
    let requestedApptsPercent = 0;
    let confirmedApptsPercent = 0;

    appointments &&
      appointments.length > 0 &&
      appointments
        .filter((app) =>
          currentShopId !== null && currentShopId !== "null"
            ? app.shop_id === currentShopId
            : app
        )
        .forEach((appts) => {
          if (
            moment(appts.created_at)
              .parseZone()
              .format(DATE_FORMAT.YYYY_MM_DD) ===
            moment().format(DATE_FORMAT.YYYY_MM_DD)
          ) {
            if (
              appts.status === INITIATED_BY_USER ||
              appts.status === WALK_IN
            ) {
              requestedAppointments++;
            } else {
              confirmedAppointments++;
            }
          }
        });

    const totalAppointments = requestedAppointments + confirmedAppointments;
    if (totalAppointments !== 0) {
      requestedApptsPercent = (requestedAppointments / totalAppointments) * 100;
      confirmedApptsPercent = (confirmedAppointments / totalAppointments) * 100;
    }

    return (
      <div style={{ display: "flex", marginBottom: 61 }}>
        <span className="summary-index-start">0</span>
        <span className="summary-index-end">10</span>

        <span className="appointments-count" style={{ marginLeft: "77px" }}>
          {requestedAppointments}
        </span>
        <span className="appointments-count" style={{ marginLeft: "121px" }}>
          {confirmedAppointments}
        </span>

        <div style={{ height: "141px", width: "170px", marginLeft: "45px" }}>
          <ProgressBar
            percent={totalAppointments !== 0 ? requestedApptsPercent : 0}
            size={"medium"}
            showInfo={false}
            color={styles.apexChartSecondaryColor}
          />
          <ProgressBar
            percent={totalAppointments !== 0 ? confirmedApptsPercent : 0}
            size={"large"}
            showInfo={false}
            color={styles.apexChartPrimaryColor}
          />
        </div>

        <span
          style={{
            color: "black",
            fontWeight: "bold",
            fontSize: 40,
            textAlign: "center",
            margin: "30px 0 0 0",
          }}
        >
          {totalAppointments}
        </span>
        <span
          style={{
            position: "absolute",
            marginLeft: "205px",
            color: "black",
            marginTop: "70px",
            fontWeight: "bold",
          }}
        >
          {t("today")}
        </span>

        <ul
          style={{
            position: "absolute",
            listStyle: "none",
            marginLeft: "190px",
            marginTop: "95px",
            fontSize: "13px",
          }}
        >
          <li
            style={{
              color: styles.apexChartPrimaryColor,
              display: "inline-flex",
              top: 18,
            }}
          >
            <div className="div-confirmed"></div>
            <span style={{ color: "black" }}>{t("confirmed")}</span>
          </li>
          <li
            style={{
              color: styles.apexChartSecondaryColor,
              display: "inline-flex",
            }}
          >
            <div className="div-requested"></div>
            <span style={{ color: "black" }}>{t("requested")}</span>
          </li>
        </ul>
      </div>
    );
  };

  weeklyAppointments = () => {
    const { appointments, t } = this.props;
    const { thisWeekOptions } = this.state;

    let requestedApptsSun = 0;
    let requestedApptsMon = 0;
    let requestedApptsTue = 0;
    let requestedApptsWed = 0;
    let requestedApptsThu = 0;
    let requestedApptsFri = 0;
    let requestedApptsSat = 0;
    let confirmedApptsSun = 0;
    let confirmedApptsMon = 0;
    let confirmedApptsTue = 0;
    let confirmedApptsWed = 0;
    let confirmedApptsThu = 0;
    let confirmedApptsFri = 0;
    let confirmedApptsSat = 0;

    appointments.forEach((appts) => {
      if (
        moment(appts.created_at).parseZone().format(DATE_FORMAT.YYYY_MM_DD) ===
        moment().clone().weekday(0).format(DATE_FORMAT.YYYY_MM_DD)
      ) {
        if (appts.status === INITIATED_BY_USER || appts.status === WALK_IN) {
          requestedApptsSun++;
        } else {
          confirmedApptsSun++;
        }
      } else if (
        moment(appts.created_at).parseZone().format(DATE_FORMAT.YYYY_MM_DD) ===
        moment().clone().weekday(1).format(DATE_FORMAT.YYYY_MM_DD)
      ) {
        if (appts.status === INITIATED_BY_USER || appts.status === WALK_IN) {
          requestedApptsMon++;
        } else {
          confirmedApptsMon++;
        }
      } else if (
        moment(appts.created_at).parseZone().format(DATE_FORMAT.YYYY_MM_DD) ===
        moment().clone().weekday(2).format(DATE_FORMAT.YYYY_MM_DD)
      ) {
        if (appts.status === INITIATED_BY_USER || appts.status === WALK_IN) {
          requestedApptsTue++;
        } else {
          confirmedApptsTue++;
        }
      } else if (
        moment(appts.created_at).parseZone().format(DATE_FORMAT.YYYY_MM_DD) ===
        moment().clone().weekday(3).format(DATE_FORMAT.YYYY_MM_DD)
      ) {
        if (appts.status === INITIATED_BY_USER || appts.status === WALK_IN) {
          requestedApptsWed++;
        } else {
          confirmedApptsWed++;
        }
      } else if (
        moment(appts.created_at).parseZone().format(DATE_FORMAT.YYYY_MM_DD) ===
        moment().clone().weekday(4).format(DATE_FORMAT.YYYY_MM_DD)
      ) {
        if (appts.status === INITIATED_BY_USER || appts.status === WALK_IN) {
          requestedApptsThu++;
        } else {
          confirmedApptsThu++;
        }
      } else if (
        moment(appts.created_at).parseZone().format(DATE_FORMAT.YYYY_MM_DD) ===
        moment().clone().weekday(5).format(DATE_FORMAT.YYYY_MM_DD)
      ) {
        if (appts.status === INITIATED_BY_USER || appts.status === WALK_IN) {
          requestedApptsFri++;
        } else {
          confirmedApptsFri++;
        }
      } else if (
        moment(appts.created_at).parseZone().format(DATE_FORMAT.YYYY_MM_DD) ===
        moment().clone().weekday(6).format(DATE_FORMAT.YYYY_MM_DD)
      ) {
        if (appts.status === INITIATED_BY_USER || appts.status === WALK_IN) {
          requestedApptsSat++;
        } else {
          confirmedApptsSat++;
        }
      }
    });

    const totalWeeklycount = appointments.filter(
      (appts) =>
        moment(appts.created_at).parseZone().format(DATE_FORMAT.YYYY_MM_DD) >=
        moment().clone().weekday(0).format(DATE_FORMAT.YYYY_MM_DD)
    ).length;

    const weeklySeries = [
      {
        name: "Confirmed",
        data: [
          confirmedApptsMon,
          confirmedApptsTue,
          confirmedApptsWed,
          confirmedApptsThu,
          confirmedApptsFri,
          confirmedApptsSat,
          confirmedApptsSun,
        ],
      },
      {
        name: "Requested",
        data: [
          requestedApptsMon,
          requestedApptsTue,
          requestedApptsWed,
          requestedApptsThu,
          requestedApptsFri,
          requestedApptsSat,
          requestedApptsSun,
        ],
      },
    ];
    return (
      <div>
        <div style={{ zIndex: "0px", position: "absolute" }}>
          <span className="appointments-count-weekly" style={{ left: 30.5 }}>
            {" "}
            {requestedApptsMon + confirmedApptsMon === 0
              ? ""
              : requestedApptsMon + confirmedApptsMon}{" "}
          </span>
          <span className="appointments-count-weekly" style={{ left: 54 }}>
            {" "}
            {requestedApptsTue + confirmedApptsTue === 0
              ? ""
              : requestedApptsTue + confirmedApptsTue}{" "}
          </span>
          <span className="appointments-count-weekly" style={{ left: 76.5 }}>
            {" "}
            {requestedApptsWed + confirmedApptsWed === 0
              ? ""
              : requestedApptsWed + confirmedApptsWed}{" "}
          </span>
          <span className="appointments-count-weekly" style={{ left: 99.5 }}>
            {" "}
            {requestedApptsThu + confirmedApptsThu === 0
              ? ""
              : requestedApptsThu + confirmedApptsThu}{" "}
          </span>
          <span className="appointments-count-weekly" style={{ left: 122 }}>
            {" "}
            {requestedApptsFri + confirmedApptsFri === 0
              ? ""
              : requestedApptsFri + confirmedApptsFri}{" "}
          </span>
          <span className="appointments-count-weekly" style={{ left: 145 }}>
            {" "}
            {requestedApptsSat + confirmedApptsSat === 0
              ? ""
              : requestedApptsSat + confirmedApptsSat}{" "}
          </span>
          <span className="appointments-count-weekly" style={{ left: 167.5 }}>
            {" "}
            {requestedApptsSun + confirmedApptsSun === 0
              ? ""
              : requestedApptsSun + confirmedApptsSun}{" "}
          </span>
        </div>
        <Chart options={thisWeekOptions} series={weeklySeries} type="bar" />
        <div style={{ zIndex: "0px", position: "absolute" }}>
          <span className="appointments-xaxis" style={{ left: 24 }}>
            {" "}
            {t("mon")}{" "}
          </span>
          <span className="appointments-xaxis" style={{ left: 49 }}>
            {" "}
            {t("tue")}{" "}
          </span>
          <span className="appointments-xaxis" style={{ left: 71 }}>
            {" "}
            {t("wed")}{" "}
          </span>
          <span className="appointments-xaxis" style={{ left: 94 }}>
            {" "}
            {t("thu")}{" "}
          </span>
          <span className="appointments-xaxis" style={{ left: 120 }}>
            {" "}
            {t("fri")}{" "}
          </span>
          <span className="appointments-xaxis" style={{ left: 142 }}>
            {" "}
            {t("sat")}{" "}
          </span>
          <span className="appointments-xaxis" style={{ left: 164 }}>
            {" "}
            {t("sun")}{" "}
          </span>
        </div>

        <div
          style={{
            position: "absolute",
            margin: "25px 0 0 5px",
            color: "black",
          }}
        >
          <span
            style={{
              position: "absolute",
              left: 190,
              color: "black",
              fontWeight: "bold",
              fontSize: 40,
              width: 100,
              textAlign: "center",
              top: -220,
            }}
          >
            {" "}
            {totalWeeklycount}{" "}
          </span>
          <span
            style={{
              position: "absolute",
              textAlign: "center",
              fontWeight: "bold",
              top: -185,
              left: 220,
            }}
          >
            {t("thisWeek")}
          </span>
        </div>
      </div>
    );
  };

  monthlyAppointments = () => {
    const { appointments, t } = this.props;
    const { thisMonthOptions } = this.state;

    let requestedApptsSun = 0;
    let requestedApptsMon = 0;
    let requestedApptsTue = 0;
    let requestedApptsWed = 0;
    let requestedApptsThu = 0;
    let requestedApptsFri = 0;
    let requestedApptsSat = 0;
    let confirmedApptsSun = 0;
    let confirmedApptsMon = 0;
    let confirmedApptsTue = 0;
    let confirmedApptsWed = 0;
    let confirmedApptsThu = 0;
    let confirmedApptsFri = 0;
    let confirmedApptsSat = 0;

    appointments
      .filter(
        (appts) =>
          moment(appts.created_at).parseZone().format(DATE_FORMAT.MMMM_YYYY) ===
          moment().parseZone().format(DATE_FORMAT.MMMM_YYYY)
      )
      .forEach((appts) => {
        if (
          moment(appts.created_at).parseZone().format("ddd") ===
          WEEK_DAYS.SUNDAY
        ) {
          if (appts.status === INITIATED_BY_USER || appts.status === WALK_IN) {
            requestedApptsSun++;
          } else {
            confirmedApptsSun++;
          }
        } else if (
          moment(appts.created_at).parseZone().format("ddd") ===
          WEEK_DAYS.MONDAY
        ) {
          if (appts.status === INITIATED_BY_USER || appts.status === WALK_IN) {
            requestedApptsMon++;
          } else {
            confirmedApptsMon++;
          }
        } else if (
          moment(appts.created_at).parseZone().format("ddd") ===
          WEEK_DAYS.TUESDAY
        ) {
          if (appts.status === INITIATED_BY_USER || appts.status === WALK_IN) {
            requestedApptsTue++;
          } else {
            confirmedApptsTue++;
          }
        } else if (
          moment(appts.created_at).parseZone().format("ddd") ===
          WEEK_DAYS.WEDNESDAY
        ) {
          if (appts.status === INITIATED_BY_USER || appts.status === WALK_IN) {
            requestedApptsWed++;
          } else {
            confirmedApptsWed++;
          }
        } else if (
          moment(appts.created_at).parseZone().format("ddd") ===
          WEEK_DAYS.THURSDAY
        ) {
          if (appts.status === INITIATED_BY_USER || appts.status === WALK_IN) {
            requestedApptsThu++;
          } else {
            confirmedApptsThu++;
          }
        } else if (
          moment(appts.created_at).parseZone().format("ddd") ===
          WEEK_DAYS.FRIDAY
        ) {
          if (appts.status === INITIATED_BY_USER || appts.status === WALK_IN) {
            requestedApptsFri++;
          } else {
            confirmedApptsFri++;
          }
        } else if (
          moment(appts.created_at).parseZone().format("ddd") ===
          WEEK_DAYS.SATURDAY
        ) {
          if (appts.status === INITIATED_BY_USER || appts.status === WALK_IN) {
            requestedApptsSat++;
          } else {
            confirmedApptsSat++;
          }
        }
      });

    const totalMonthlyConfirmedCount =
      confirmedApptsMon +
      confirmedApptsTue +
      confirmedApptsWed +
      confirmedApptsThu +
      confirmedApptsFri +
      confirmedApptsSat +
      confirmedApptsSun;
    const totalMonthlyRequestedCount =
      requestedApptsMon +
      requestedApptsTue +
      requestedApptsWed +
      requestedApptsThu +
      requestedApptsFri +
      requestedApptsSat +
      requestedApptsSun;
    const totalMonthlyCount =
      totalMonthlyConfirmedCount + totalMonthlyRequestedCount;

    const monthlySeries = [
      {
        name: "Confirmed",
        data: [
          confirmedApptsMon,
          confirmedApptsTue,
          confirmedApptsWed,
          confirmedApptsThu,
          confirmedApptsFri,
          confirmedApptsSat,
          confirmedApptsSun,
        ],
      },
      {
        name: "Requested",
        data: [
          requestedApptsMon,
          requestedApptsTue,
          requestedApptsWed,
          requestedApptsThu,
          requestedApptsFri,
          requestedApptsSat,
          requestedApptsSun,
        ],
      },
    ];

    return (
      <div>
        <div>
          <span className="appointments-count-monthly" style={{ left: 30.5 }}>
            {requestedApptsMon + confirmedApptsMon === 0
              ? ""
              : requestedApptsMon + confirmedApptsMon}
          </span>
          <span className="appointments-count-monthly" style={{ left: 54 }}>
            {requestedApptsTue + confirmedApptsTue === 0
              ? ""
              : requestedApptsTue + confirmedApptsTue}
          </span>
          <span className="appointments-count-monthly" style={{ left: 76.5 }}>
            {requestedApptsWed + confirmedApptsWed === 0
              ? ""
              : requestedApptsWed + confirmedApptsWed}
          </span>
          <span className="appointments-count-monthly" style={{ left: 99.5 }}>
            {requestedApptsThu + confirmedApptsThu === 0
              ? ""
              : requestedApptsThu + confirmedApptsThu}
          </span>
          <span className="appointments-count-monthly" style={{ left: 122 }}>
            {requestedApptsFri + confirmedApptsFri === 0
              ? ""
              : requestedApptsFri + confirmedApptsFri}
          </span>
          <span className="appointments-count-monthly" style={{ left: 145 }}>
            {requestedApptsSat + confirmedApptsSat === 0
              ? ""
              : requestedApptsSat + confirmedApptsSat}
          </span>
          <span className="appointments-count-monthly" style={{ left: 167.5 }}>
            {requestedApptsSun + confirmedApptsSun === 0
              ? ""
              : requestedApptsSun + confirmedApptsSun}
          </span>
        </div>
        <Chart options={thisMonthOptions} series={monthlySeries} type="bar" />
        <div style={{ zIndex: "0px", position: "absolute" }}>
          <span className="appointments-xaxis" style={{ left: 24 }}>
            {" "}
            {t("mon")}{" "}
          </span>
          <span className="appointments-xaxis" style={{ left: 49 }}>
            {" "}
            {t("tue")}{" "}
          </span>
          <span className="appointments-xaxis" style={{ left: 71 }}>
            {" "}
            {t("wed")}{" "}
          </span>
          <span className="appointments-xaxis" style={{ left: 94 }}>
            {" "}
            {t("thu")}{" "}
          </span>
          <span className="appointments-xaxis" style={{ left: 120 }}>
            {" "}
            {t("fri")}{" "}
          </span>
          <span className="appointments-xaxis" style={{ left: 142 }}>
            {" "}
            {t("sat")}{" "}
          </span>
          <span className="appointments-xaxis" style={{ left: 164 }}>
            {" "}
            {t("sun")}{" "}
          </span>
        </div>
        <div
          style={{ position: "absolute", margin: "30px 0 0 0", color: "black" }}
        >
          <span
            style={{
              position: "absolute",
              left: 195,
              color: "black",
              fontWeight: "bold",
              fontSize: 40,
              width: 100,
              textAlign: "center",
              top: -220,
            }}
          >
            {" "}
            {totalMonthlyCount}{" "}
          </span>
          <span
            style={{
              position: "absolute",
              textAlign: "center",
              fontWeight: "bold",
              top: -185,
              left: 220,
            }}
          >
            {t("thisMonth")}
          </span>
        </div>
      </div>
    );
  };

  render() {
    const { selectedButton } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Card style={{ width: 300, height: 220, marginTop: "20px" }}>
          <span
            style={{
              marginLeft: 5,
              marginTop: 1,
              fontWeight: "bold",
              color: "black",
            }}
          >
            {t("appointmentsAndOrders")}{" "}
          </span>
          {selectedButton === TODAY ? (
            <React.Fragment>{this.todaysAppointments()}</React.Fragment>
          ) : selectedButton === THIS_WEEK ? (
            <React.Fragment>{this.weeklyAppointments()}</React.Fragment>
          ) : selectedButton === THIS_MONTH ? (
            <React.Fragment>{this.monthlyAppointments()}</React.Fragment>
          ) : (
            ""
          )}
          <div style={{ margin: "177px 0px 0px 0px", position: "absolute" }}>
            <div style={{ height: 32, width: 294, left: 61, right: 0 }}>
              <Button.Group size="mini" style={{ width: 150, marginLeft: 9 }}>
                <Button
                  circular
                  style={{
                    marginLeft: 0,
                    marginRight: 0,
                    width: 80,
                    hight: 25,
                    marginTop: 1,
                    borderRadius: "6px !important",
                  }}
                  onClick={() => this.setState({ selectedButton: TODAY })}
                >
                  {t("today")}
                </Button>
                <Button
                  circular
                  style={{
                    marginLeft: 5,
                    marginRight: 0,
                    width: 95,
                    hight: 25,
                    marginTop: 1,
                  }}
                  onClick={() => this.setState({ selectedButton: THIS_WEEK })}
                >
                  {t("thisWeek")}
                </Button>
                <Button
                  circular
                  style={{
                    marginLeft: 5,
                    marginRight: 0,
                    width: 95,
                    hight: 25,
                    marginTop: 1,
                  }}
                  onClick={() => this.setState({ selectedButton: THIS_MONTH })}
                >
                  {t("thisMonth")}
                </Button>
              </Button.Group>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}
export default Summary;
