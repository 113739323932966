import React from "react";
import {
  Button,
  Card,
  Dropdown,
  Grid,
  Popup,
  Segment,
  Table,
} from "semantic-ui-react";
import {
  CONVENTIONAL_EXTRA_QUARTS_PRICE,
  EURO_SYNTHETIC_EXTRA_QUARTS_PRICE,
  FULL_SYNTHETIC_EXTRA_QUARTS_PRICE,
  OIL_EXTRA_QUARTS,
  RETAIL_PRICE_INFO,
  SEMI_SYNTHETIC_EXTRA_QUARTS_PRICE,
} from "../../constants/pricing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BATTERY_GROUP,
  BRAKES_GROUP,
  CUSTOM_GROUP,
  DEFAULT_SERVICE_DEFINITIONS,
  DEFAULT_SERVICE_GROUPS,
  FILTERS_GROUP,
  INSPECTION_GROUP,
  MISC_GROUP,
  OIL_CHANGE_GROUP,
  TIRES_GROUP,
} from "../../constants/serviceDefinitions";
import PricingRow from "./PricingRow";
import { handleKeypress } from "../../helpers/numberHelpers";
import { faInfoCircle, faPencil } from "@fortawesome/pro-light-svg-icons";
import { isNotShopBasicUser } from "../../helpers/userHelpers";
import RNS from "react-notification-system";
import { faDollar } from "@fortawesome/free-solid-svg-icons";
import { faPencilSlash } from "@fortawesome/pro-solid-svg-icons";
import store from "../../actions/configureStore";
import { updatePricing } from "../../actions/sagas/pricingSaga";
import _ from "lodash";
import i18n from "../../utils/i18n";

const PricingCard = ({
  pricings,
  currentUser,
  currentShop,
  currentShopId,
  isEditable,
  onEditing,
  onSubmit,
}) => {
  return (
    <>
      {pricings.length > 0 ? (
        pricings.map((pricing, index) => {
          return (
            <PricingRow
              key={pricing.displayName}
              pricing={pricing}
              currentUser={currentUser}
              currentShop={currentShop}
              currentShopId={currentShopId}
              isEditable={isEditable}
              onEditing={onEditing}
              onSubmit={onSubmit}
            />
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell>
            <div className="div-center">No records found</div>
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
};

export default class PricingsTableEnhanced extends React.Component {
  notificationSystem = React.createRef();
  constructor(props) {
    super(props);
    const groupName = "customGroup";
    const conventionalQuart = props.extraQuarts.filter(
      (q) => q.name === CONVENTIONAL_EXTRA_QUARTS_PRICE
    );
    const semiSyntheticQuart = props.extraQuarts.filter(
      (q) => q.name === SEMI_SYNTHETIC_EXTRA_QUARTS_PRICE
    );
    const euroSyntheticQuart = props.extraQuarts.filter(
      (q) => q.name === EURO_SYNTHETIC_EXTRA_QUARTS_PRICE
    );
    const fullSyntheticQuart = props.extraQuarts.filter(
      (q) => q.name === FULL_SYNTHETIC_EXTRA_QUARTS_PRICE
    );

    this.state = {
      updatedPricings: [],
      isEditable: false,
      selectedServiceGroup: DEFAULT_SERVICE_GROUPS[0].value,
      laborRate: props.currentShop.labor_rate
        ? parseFloat(props.currentShop.labor_rate).toFixed(2)
        : "",
      retailLaborRate: props.currentShop.retail_labor_rate
        ? parseFloat(props.currentShop.retail_labor_rate).toFixed(2)
        : "",
      conventionalQuartsPrice:
        conventionalQuart.length > 0 && conventionalQuart[0].retailPrice,
      semiSyntheticQuartsPrice:
        semiSyntheticQuart.length > 0 && semiSyntheticQuart[0].retailPrice,
      euroSyntheticQuartsPrice:
        euroSyntheticQuart.length > 0 && euroSyntheticQuart[0].retailPrice,
      fullSyntheticQuartsPrice:
        fullSyntheticQuart.length > 0 && fullSyntheticQuart[0].retailPrice,
      formData: {
        laborRate: props.currentShop.labor_rate
          ? parseFloat(props.currentShop.labor_rate).toFixed(2)
          : "",
        retailLaborRate: props.currentShop.retail_labor_rate
          ? parseFloat(props.currentShop.retail_labor_rate).toFixed(2)
          : "",
      },
      oilData: this.props.pricings.filter((pricing) => {
        const sdConfig = DEFAULT_SERVICE_DEFINITIONS.find((dsd) => {
          return dsd.id === pricing.serviceDefinitionId;
        });
        return sdConfig && sdConfig.group === OIL_CHANGE_GROUP;
      }),
      brakesData: this.props.pricings.filter((pricing) => {
        const sdConfig = DEFAULT_SERVICE_DEFINITIONS.find((dsd) => {
          return dsd.id === pricing.serviceDefinitionId;
        });
        return sdConfig && sdConfig.group === BRAKES_GROUP;
      }),
      tiresData: this.props.pricings.filter((pricing) => {
        const sdConfig = DEFAULT_SERVICE_DEFINITIONS.find((dsd) => {
          return dsd.id === pricing.serviceDefinitionId;
        });
        return sdConfig && sdConfig.group === TIRES_GROUP;
      }),
      miscData: this.props.pricings.filter((pricing) => {
        const sdConfig = DEFAULT_SERVICE_DEFINITIONS.find((dsd) => {
          return dsd.id === pricing.serviceDefinitionId;
        });
        return (
          (sdConfig && sdConfig.group === MISC_GROUP) ||
          (sdConfig == null && groupName === CUSTOM_GROUP)
        );
      }),
      inspectionData: this.props.pricings.filter((pricing) => {
        const sdConfig = DEFAULT_SERVICE_DEFINITIONS.find((dsd) => {
          return dsd.id === pricing.serviceDefinitionId;
        });
        return sdConfig && sdConfig.group === INSPECTION_GROUP;
      }),
      filtersData: this.props.pricings.filter((pricing) => {
        const sdConfig = DEFAULT_SERVICE_DEFINITIONS.find((dsd) => {
          return dsd.id === pricing.serviceDefinitionId;
        });
        return sdConfig && sdConfig.group === FILTERS_GROUP;
      }),
      batteryData: this.props.pricings.filter((pricing) => {
        const sdConfig = DEFAULT_SERVICE_DEFINITIONS.find((dsd) => {
          return dsd.id === pricing.serviceDefinitionId;
        });
        return sdConfig && sdConfig.group === BATTERY_GROUP;
      }),
      errors: {},
    };
  }

  changedPricings = (data) => {
    const { updatedPricings } = this.state;
    if (updatedPricings.length === 0) {
      updatedPricings.push(data);
    }

    const update = (pricing) => Object.assign(pricing, data);
    let details = updatedPricings.find((pricing) => {
      if (pricing.id) {
        return (
          pricing.id === data.id && pricing.displayName === data.displayName
        );
      } else {
        return pricing.displayName === data.displayName;
      }
    });

    details ? update(details) : updatedPricings.push(data);
  };

  ratesUnchanged = () => {
    const { laborRate, retailLaborRate } = this.state;
    const { currentShop } = this.props;
    const { labor_rate, retail_labor_rate } = currentShop;

    return (
      parseFloat(laborRate).toFixed(2) === parseFloat(labor_rate).toFixed(2) &&
      parseFloat(retailLaborRate).toFixed(2) ===
        parseFloat(retail_labor_rate).toFixed(2)
    );
  };

  quartsUnchanged = (
    conventionalQuarts,
    semiSyntheticQuarts,
    euroSyntheticQuarts,
    fullSyntheticQuarts
  ) => {
    const {
      conventionalQuartsPrice,
      euroSyntheticQuartsPrice,
      semiSyntheticQuartsPrice,
      fullSyntheticQuartsPrice,
    } = this.state;

    return (
      conventionalQuartsPrice === conventionalQuarts &&
      semiSyntheticQuarts === semiSyntheticQuartsPrice &&
      euroSyntheticQuarts === euroSyntheticQuartsPrice &&
      fullSyntheticQuarts === fullSyntheticQuartsPrice
    );
  };

  onSubmit = () => {
    const {
      laborRate,
      retailLaborRate,
      updatedPricings,
      conventionalQuartsPrice,
      euroSyntheticQuartsPrice,
      semiSyntheticQuartsPrice,
      fullSyntheticQuartsPrice,
    } = this.state;
    let conventionalQuarts = conventionalQuartsPrice;
    let semiSyntheticQuarts = semiSyntheticQuartsPrice;
    let euroSyntheticQuarts = euroSyntheticQuartsPrice;
    let fullSyntheticQuarts = fullSyntheticQuartsPrice;
    const newPricingsForCreate =
      updatedPricings &&
      updatedPricings
        .filter(
          (pricing) =>
            pricing.id === null && !OIL_EXTRA_QUARTS.includes(pricing.name)
        )
        .map((pricing) => ({
          company_id: pricing.company_id,
          shop_id: pricing.shopId,
          retail_price: pricing.retailPrice,
          pricing: pricing.shopCaradvisePrice,
          service_definition_id: pricing.serviceDefinitionId,
          position: pricing.position,
          displayName: `${pricing.name} - ${pricing.position}`,
        }));
    const pricingsForUpdate =
      updatedPricings &&
      updatedPricings
        .filter((pricing) => pricing.id !== null)
        .map((pricing) => ({
          id: pricing.id,
          company_id: pricing.company_id,
          shop_id: pricing.shopId,
          retail_price: pricing.retailPrice,
          pricing: pricing.shopCaradvisePrice,
          displayName: pricing.displayName,
        }));
    const newExtraQuarts =
      updatedPricings &&
      updatedPricings
        .filter((pricing) => OIL_EXTRA_QUARTS.includes(pricing.name))
        .map((quarts) => ({ name: quarts.name, price: quarts.retailPrice }));

    newExtraQuarts &&
      newExtraQuarts.map((quarts) => {
        if (quarts.name === CONVENTIONAL_EXTRA_QUARTS_PRICE) {
          conventionalQuarts = quarts.price;
        }

        if (quarts.name === SEMI_SYNTHETIC_EXTRA_QUARTS_PRICE) {
          semiSyntheticQuarts = quarts.price;
        }

        if (quarts.name === EURO_SYNTHETIC_EXTRA_QUARTS_PRICE) {
          euroSyntheticQuarts = quarts.price;
        }

        if (quarts.name === FULL_SYNTHETIC_EXTRA_QUARTS_PRICE) {
          fullSyntheticQuarts = quarts.price;
        }
      });
    this.updateLaborRates({
      laborRate,
      retailLaborRate,
      pricingsForUpdate,
      conventionalQuarts,
      semiSyntheticQuarts,
      euroSyntheticQuarts,
      fullSyntheticQuarts,
      newPricingsForCreate,
    });
  };

  formDataErrors = (
    pricings,
    laborRate,
    retailLaborRate,
    conventionalQuarts,
    semiSyntheticQuarts,
    euroSyntheticQuarts,
    fullSyntheticQuarts
  ) => {
    const errors = [];
    if (pricings.length > 0) {
      pricings.map((pricing) => {
        if (
          pricing.retail_price === undefined ||
          pricing.retail_price.length === 0
        )
          errors.push(
            `${i18n.t("common:pleaseUpdateYourRetailPricingMsg")} ${
              pricing.displayName
            }`
          );
        if (
          pricing.pricing === undefined ||
          pricing.pricing === "" ||
          pricing.pricing === 0 ||
          pricing.pricing.length === 0
        )
          errors.push(
            `${i18n.t("common:pleaseUpdateYourCarAdviseRetailPricingMsg")} ${
              pricing.displayName
            }`
          );
        if (parseFloat(pricing.pricing) > parseFloat(pricing.retail_price))
          errors.push(
            `${i18n.t("common:retailPriceCantBeLessThanCarAdvisePriceMsg")} ${
              pricing.displayName
            }`
          );
      });
    }
    if (
      retailLaborRate === null ||
      retailLaborRate === undefined ||
      retailLaborRate.length === 0
    ) {
      errors.push(i18n.t("common:pleaseUpdateYourRetailLaborRateMsg"));
    }
    if (
      laborRate === null ||
      laborRate === undefined ||
      laborRate.length === 0
    ) {
      errors.push(i18n.t("common:pleaseUpdateYourCarAdviseLaborrateMsg"));
    }
    if (parseFloat(laborRate) > parseFloat(retailLaborRate)) {
      errors.push(i18n.t("common:retailLaborHourlyRateCantLessThanLaborMsg"));
    }
    if (conventionalQuarts.length === 0) {
      errors.push(i18n.t("common:pleaseUpdatePricingForConventionalOilMsg"));
    }
    if (fullSyntheticQuarts.length === 0) {
      errors.push(i18n.t("common:pleaseUpdatePricingForFullSyntheticOilMsg"));
    }
    if (semiSyntheticQuarts.length === 0) {
      errors.push(i18n.t("common:pleaseUpdatePricingForSemiSyntheticOilMsg"));
    }
    if (euroSyntheticQuarts.length === 0) {
      errors.push(i18n.t("common:pleaseUpdatePricingForEuroSyntheticOilMsg"));
    }
    return errors;
  };

  addNotification = (errors) => {
    const notification = this.notificationSystem.current;
    errors.map((error) => {
      notification.addNotification({
        title: i18n.t("common:errorTitle"),
        message: error,
        level: "error",
        //autoDismiss: 0
      });
    });
  };

  updateLaborRates = (data) => {
    const {
      retailLaborRate,
      laborRate,
      pricingsForUpdate,
      conventionalQuarts,
      semiSyntheticQuarts,
      euroSyntheticQuarts,
      fullSyntheticQuarts,
      newPricingsForCreate,
    } = data;
    const { currentShopId } = this.props;
    const isQuartsChanged = this.quartsUnchanged(
      conventionalQuarts,
      semiSyntheticQuarts,
      euroSyntheticQuarts,
      fullSyntheticQuarts
    );
    const isRateChanged = this.ratesUnchanged();
    const pricings = [...pricingsForUpdate, ...newPricingsForCreate];
    const errors = this.formDataErrors(
      pricings,
      laborRate,
      retailLaborRate,
      conventionalQuarts,
      semiSyntheticQuarts,
      euroSyntheticQuarts,
      fullSyntheticQuarts
    );

    if (Object.keys(errors).length === 0) {
      if (
        !isQuartsChanged ||
        pricingsForUpdate.length > 0 ||
        newPricingsForCreate.length > 0 ||
        !isRateChanged
      ) {
        store.dispatch(
          updatePricing({
            payload: {
              pricingsForUpdate,
              conventionalQuarts,
              semiSyntheticQuarts,
              euroSyntheticQuarts,
              fullSyntheticQuarts,
              shop_id: currentShopId,
              pricings: newPricingsForCreate,
              isQuartsChanged: isQuartsChanged,
              isRateChanged: isRateChanged,
              laborRate: laborRate,
              retailLaborRate: retailLaborRate,
            },
          })
        );
      }
      this.setState({ isEditable: false });
    } else {
      this.addNotification(errors);
    }
  };

  onEditing = () => {
    this.setState({ isEditable: true });
  };

  setServiceGroup = (e, { value }) => {
    this.setState({ selectedServiceGroup: value, updatedPricings: [] });
  };

  render() {
    const {
      currentUser,
      extraQuarts,
      currentShop,
      currentShopId,
      t,
    } = this.props;
    const {
      oilData,
      brakesData,
      tiresData,
      miscData,
      inspectionData,
      batteryData,
      filtersData,
      isEditable,
      laborRate,
      retailLaborRate,
      selectedServiceGroup,
    } = this.state;
    const pricingsGroups = [
      {
        oilChangeGroup: [...oilData, ...extraQuarts],
        tiresGroup: tiresData,
        brakesGroup: brakesData,
        filtersGroup: filtersData,
        miscGroup: miscData,
        batteryGroup: batteryData,
        inspectionGroup: inspectionData,
      },
    ];
    return (
      <div>
        {isNotShopBasicUser(currentUser) && (
          <div>
            <Button
              circular
              size="large"
              content={t("saveChanges")}
              onClick={this.onSubmit}
              floated="right"
              disabled={!isEditable}
            />
            <RNS ref={this.notificationSystem} />
          </div>
        )}
        <Grid columns={2}>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Segment>
              <p
                style={{ fontSize: "20px", fontWeight: 600, color: "#2F2F2F" }}
              >
                {t("retailHourlyLaborRate")}
              </p>
              {!isEditable ? (
                <div className="input-rate" onClick={() => this.onEditing()}>
                  <span>
                    <FontAwesomeIcon className="prefix" icon={faDollar} />
                  </span>
                  <input
                    value={
                      retailLaborRate
                        ? parseFloat(retailLaborRate).toFixed(2)
                        : ""
                    }
                  />
                  <span>
                    <FontAwesomeIcon
                      className="suffix"
                      icon={faPencil}
                      onClick={() => this.onEditing()}
                    />
                  </span>
                </div>
              ) : isNotShopBasicUser(currentUser) ? (
                <div
                  className={
                    parseFloat(laborRate) > parseFloat(retailLaborRate) ||
                    retailLaborRate === null ||
                    retailLaborRate === undefined ||
                    retailLaborRate.length === 0
                      ? "input-rate-error"
                      : "input-rate"
                  }
                >
                  <span>
                    <FontAwesomeIcon className="prefix" icon={faDollar} />
                  </span>
                  <input
                    value={retailLaborRate || ""}
                    onChange={(e) =>
                      this.setState({ retailLaborRate: e.target.value })
                    }
                    onKeyDown={handleKeypress}
                    error={!retailLaborRate ? true : false}
                    min="1"
                    id="input"
                    type="number"
                  />
                  <span>
                    <FontAwesomeIcon
                      className="suffix"
                      icon={faPencil}
                      onClick={() => this.onEditing()}
                    />
                  </span>
                </div>
              ) : (
                <div className="input-rate">
                  <span>
                    <FontAwesomeIcon className="prefix" icon={faDollar} />
                  </span>
                  <input value={retailLaborRate || ""} />
                  <span>
                    <FontAwesomeIcon className="suffix" icon={faPencilSlash} />
                  </span>
                </div>
              )}
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Segment>
              <p
                style={{ fontSize: "20px", fontWeight: 600, color: "#2F2F2F" }}
              >
                {t("carAdviseHourlyLaborRate")}
              </p>
              {!isEditable ? (
                <div className="input-rate" onClick={() => this.onEditing()}>
                  <span>
                    <FontAwesomeIcon
                      className="prefix primary-color"
                      icon={faDollar}
                    />
                  </span>
                  <input
                    className="primary-color"
                    value={laborRate || ""}
                    disabled
                  />
                  <span>
                    <FontAwesomeIcon className="suffix" icon={faPencil} />
                  </span>
                </div>
              ) : isNotShopBasicUser(currentUser) ? (
                <div
                  className={
                    parseFloat(laborRate) > parseFloat(retailLaborRate) ||
                    laborRate === null ||
                    laborRate === undefined ||
                    laborRate.length === 0
                      ? "input-rate-error"
                      : "input-rate"
                  }
                >
                  <span>
                    <FontAwesomeIcon
                      className="prefix primary-color"
                      icon={faDollar}
                    />
                  </span>
                  <input
                    className="primary-color"
                    value={laborRate || ""}
                    onChange={(e) =>
                      this.setState({ laborRate: e.target.value })
                    }
                    onKeyDown={handleKeypress}
                    error={!laborRate ? true : false}
                    min="1"
                    id="input"
                    type="number"
                  />
                  <span>
                    <FontAwesomeIcon className="suffix" icon={faPencil} />
                  </span>
                </div>
              ) : (
                <div className="input-rate">
                  <span>
                    <FontAwesomeIcon
                      className="prefix primary-color"
                      style={{ color: "#023CD1" }}
                      icon={faDollar}
                    />
                  </span>
                  <input
                    className="primary-color"
                    value={laborRate ? parseFloat(laborRate).toFixed(2) : ""}
                    disabled
                  />
                  <span>
                    <FontAwesomeIcon className="suffix" icon={faPencilSlash} />
                  </span>
                </div>
              )}
            </Segment>
          </Grid.Column>
        </Grid>
        <Card fluid>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={5}>
                  {t("service")}
                  <div style={{ display: "inline", marginLeft: "10px" }}>
                    <Dropdown
                      inline
                      defaultValue={DEFAULT_SERVICE_GROUPS[0].value}
                      options={
                        _.map(DEFAULT_SERVICE_GROUPS, (services) => ({
                          key: services.key,
                          text: t(services.text),
                          value: services.value,
                          image: services.image,
                        })) || null
                      }
                      onChange={this.setServiceGroup}
                      className="service-icon-color"
                    />
                  </div>
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={3}>
                  <p className="primary-color" style={{ margin: 0 }}>
                    {t("carAdvisePrice")}
                  </p>
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={3}>
                  {t("retailPrice")}
                  <Popup
                    content={RETAIL_PRICE_INFO}
                    pinned
                    position="bottom center"
                    size="tiny"
                    trigger={
                      <FontAwesomeIcon
                        className="info-Icon-retail-price"
                        icon={faInfoCircle}
                      />
                    }
                  />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={3}>
                  {t("retailSavings")}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <PricingCard
                pricings={pricingsGroups[0][selectedServiceGroup]}
                currentUser={currentUser}
                currentShop={currentShop}
                currentShopId={currentShopId}
                onEditing={this.onEditing}
                isEditable={isEditable}
                onSubmit={this.changedPricings}
              />
            </Table.Body>
          </Table>
        </Card>
      </div>
    );
  }
}
