import { SHOW_NAV, HIDE_NAV, UPDATE_CURRENT_SHOP_ID } from "../constants/actionTypes"

export function showNav(options = {}) {
  return {
    type: SHOW_NAV
  }
}

export function hideNav(options = {}) {
  return {
    type: HIDE_NAV
  }
}

export function updateCurrentShopId(options = {}) {
  return {
    type: UPDATE_CURRENT_SHOP_ID,
    payload: options.id
  }
}
