import React from "react";
import { connect } from "react-redux";
import { Button, Dropdown, Form, Message, Modal } from "semantic-ui-react";
import { OTHER, Reject_Reasons } from "../../constants/cancellationReasons";
import DatePicker from "react-datepicker";
import moment from "moment";
import { DATE_FORMAT } from "../../constants/dateTime";
import { updateAppointmentStatus } from "../../actions/sagas/appointmentSaga";
import store from "../../actions/configureStore";
import { withTranslation } from "react-i18next";
class RejectReasonModal extends React.Component {
  state = {
    selectedReason: "",
    isRescheduleDate: true,
    date: null,
    value: "",
    otherValue: "",
    isDropdownSelected: true,
    formData: {
      comment: "",
    },
    errors: {},
  };

  setRejectReason = (e, { value }) => {
    if (value !== OTHER)
      this.setState({ formData: { comment: "" }, errors: {} });
    this.setState({ value }, () => {
      this.setState({
        selectedReason: value,
        isDropdownSelected: true,
        isRescheduleDate: true,
      });
    });
  };

  onChange = (field, e) => {
    let updatedFormData = { ...this.state.formData };
    updatedFormData[field] = e.target.value;
    this.setState({
      formData: updatedFormData,
      otherValue: updatedFormData.comment,
      errors: {},
    });
  };

  formDataErrors = () => {
    const { formData } = this.state;
    const { comment } = formData;
    const errors = {};

    if (comment.length === 0 && this.state.value === OTHER)
      errors.comment = "Reject Reason is required";
    return errors;
  };

  onHandleClose = () => {
    const { close } = this.props;
    close();
    this.setState({
      isDropdownSelected: true,
      isRescheduleDate: true,
      formData: {
        comment: "",
      },
      errors: {},
    });
  };

  onReject = (appointment) => {
    const errors = this.formDataErrors();
    const { currentUser, currentShopId } = this.props;
    const { email } = currentUser;
    const {
      formData,
      value,
      otherValue,
      selectedReason,
      isDropdownSelected,
      date,
      isRescheduleDate,
    } = this.state;
    const { comment } = formData;
    this.setState({ selectedReason: value });

    if (isDropdownSelected && value.length !== 0) {
      if (Object.keys(errors).length === 0) {
        if (isRescheduleDate && date !== null) {
          if (otherValue && comment !== "") {
            const payload = {
              id: appointment.id,
              isAppointmentConfirmed: 0,
              rejectReason: "Other... " + otherValue,
              rejectedBy: email,
              shop_id: currentShopId,
              date: moment(date).parseZone().format(DATE_FORMAT.YYYY_MM_DD),
            };
            store.dispatch(updateAppointmentStatus({ payload: payload }));
          } else {
            const payload = {
              id: appointment.id,
              isAppointmentConfirmed: 0,
              rejectReason: selectedReason,
              rejectedBy: email,
              shop_id: currentShopId,
              date: moment(date).parseZone().format(DATE_FORMAT.YYYY_MM_DD),
            };
            store.dispatch(updateAppointmentStatus({ payload: payload }));
          }
        } else {
          this.setState({ isRescheduleDate: false });
        }
      } else {
        this.setState({ errors: errors });
      }
    } else {
      this.setState({ isDropdownSelected: false });
    }
  };
  onDateChange = (date) => {
    this.setState({ date: date, isRescheduleDate: true });
  };

  filterDays = (date) => {
    // Disable Weekends
    if (date.getDay() === 0 || date.getDay() === 6) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    const { open, close, appointment, t } = this.props;
    const { appointment_datetime } = appointment;
    const {
      errors,
      isDropdownSelected,
      formData,
      date,
      isRescheduleDate,
    } = this.state;
    const { comment } = formData;
    let minDate =
      appointment && appointment_datetime
        ? moment(appointment_datetime)
        : moment();
    if (minDate < moment()) minDate = moment();
    const twoWeeksFromNow = new Date(minDate);
    twoWeeksFromNow.setDate(twoWeeksFromNow.getDate() + 13);

    return (
      <Modal
        size="small"
        open={open}
        closeIcon
        onClose={() => close()}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{t("rejectTheAppointment")}</Modal.Header>
        <Modal.Content>
          <Form error>
            <Form.Field>{t("pleaseHelpUsText")}</Form.Field>
            <Form.Field>
              <Dropdown
                placeholder={t("chooseTheRejectReason")}
                selection
                options={Reject_Reasons.map((reason) => {
                  return {
                    key: reason.key,
                    text: t(reason.text),
                    value: reason.value,
                  };
                })}
                onChange={this.setRejectReason}
              />
              {!isDropdownSelected && (
                <Message error content={t("pleaseChooseRejectionReason")} />
              )}
            </Form.Field>
            <Form.Field>
              {this.state.value === OTHER && (
                <Form.TextArea
                  placeholder={t("enterYourRejectionReason")}
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) =>
                    e.keyCode === 32 ? e.stopPropagation() : null
                  }
                  onChange={(e) => this.onChange("comment", e)}
                  value={comment}
                  size="large"
                  rows={3}
                  error={errors.comment}
                />
              )}
            </Form.Field>
            <Form.Field>
              {this.state.value && (
                <div className="calendar">
                  <Form.Field label={t("selectDate")} required />
                  <DatePicker
                    inline
                    onChange={this.onDateChange}
                    selected={date}
                    minDate={minDate._d}
                    maxDate={twoWeeksFromNow}
                    error={errors.date}
                  />
                </div>
              )}
              {!isRescheduleDate && (
                <Message error content={t("pleaseChooseRescheduleDate")} />
              )}
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.onReject(appointment)}>
            {t("reschedule")}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentShopId } = state.shops;

  return {
    isLoading: state.application.isLoading,
    currentShopId: currentShopId,
  };
};

export default connect(mapStateToProps)(
  withTranslation("modals")(RejectReasonModal)
);
