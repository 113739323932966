import React, { Component } from "react";
import { Link } from "react-router-dom";
import { navigationPaths } from "../../constants/paths";
import { Form, Button } from "semantic-ui-react";
import { getSubdomain } from "../shared/StylesheetInjector";
import { setupSplitFlags } from "../config/SplitClient";

export default class SignInForm extends Component {
  state = {
    formData: {
      email: "",
      password: "",
    },
  };

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain() });
  }
  onFieldChange = (field, value) => {
    let updatedFormData = { ...this.state.formData };
    updatedFormData[field] = value;
    this.setState({ formData: updatedFormData });
  };

  onKeyDown = (event) => {
    const { onSubmit } = this.props;

    if (event.key === "Enter") {
      onSubmit(this.state.formData);
    }
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.onSubmit(this.state.formData);
  };

  render() {
    const { email, password } = this.state.formData;
    const { isSubmitting, t } = this.props;

    return (
      <Form className="no-session-form">
        <Form.Input
          value={email}
          onChange={(e) => this.onFieldChange("email", e.target.value)}
          fluid
          size="large"
          label={t("email")}
        />
        <br />
        <Form.Input
          value={password}
          onChange={(e) => this.onFieldChange("password", e.target.value)}
          fluid
          type="password"
          size="large"
          label={t("password")}
        />

        <div className="flex-container" style={{ marginTop: "30px" }}>
          <Link
            style={{ width: "50%" }}
            className="cursor-pointer"
            to={navigationPaths.forgotPassword()}
          >
            {t("forgotPassword")}
          </Link>
          <div style={{ width: "50%", textAlign: "right" }}>
            <Button
              className={"button-primary no-session-button"}
              primary
              disabled={isSubmitting}
              onClick={this.onSubmit}
            >
              {t("signIn")}
            </Button>
          </div>
        </div>
      </Form>
    );
  }
} // SignInForm
