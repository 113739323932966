import {
  formatInternalErrors,
  consoleLog,
  catchErrors,
} from "../../helpers/apiErrorHelpers";
import {
  SET_LOADING,
  UNSET_LOADING,
  USER_NOTE_CREATE_SUCCEEDED,
  USER_NOTE_UPDATE_SUCCEEDED,
  NOTES_PATH,
  USER_NOTE_DESTROY_SUCCEEDED,
} from "../../constants/actionTypes";
import { loadAppointments } from "./appointmentSaga";
import { loadOrders } from "./orderSaga";
import Notifications from "../../components/Notifications";
import store from "../configureStore";
import { deleteJSON, postJSON, putJSON } from "../../utils/fetch";
import i18n from "../../utils/i18n";

export function createNote(action) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_LOADING });
      let user = getState().users || {};
      const { authentication_token } = user;

      const payload = action || {};
      const { shop_order_id, shop_id, title, comment, created_by } = payload;

      const request = {
        url: `${NOTES_PATH}?shop_id=${shop_id}&shop_order_id=${shop_order_id}`,
        headers: { Authorization: authentication_token },
        body: {
          title,
          comment,
          created_by,
        },
      };

      const response = await postJSON(
        request.url,
        request.body,
        request.headers
      );
      catchErrors(response.result);

      await dispatch({
        type: USER_NOTE_CREATE_SUCCEEDED,
        payload: { note: response.result.note },
      });

      await dispatch({ type: UNSET_LOADING });

      await dispatch(loadAppointments({ payload: { shopId: shop_id } }));

      store.dispatch(
        Notifications.success({
          title: i18n.t("common:successTitle"),
          message: i18n.t("common:noteCreatedMsg"),
          position: "tr",
          autoDismiss: 5,
        })
      );
    } catch (errors) {
      await dispatch({ type: UNSET_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:errorCreatingNoteMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
      await dispatch(action.errorCallback);
    }
  };
}

export function updateNote(action) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_LOADING });
      let user = getState().users || {};
      const { authentication_token } = user;

      const payload = action || {};
      const {
        shop_order_id,
        shop_id,
        note_id,
        title,
        comment,
        created_by,
      } = payload;

      const request = {
        url: `${NOTES_PATH}/${note_id}?shop_id=${shop_id}&shop_order_id=${shop_order_id}`,
        headers: { Authorization: authentication_token },
        body: {
          title,
          comment,
          created_by,
        },
      };

      const response = await putJSON(
        request.url,
        request.body,
        request.headers
      );
      catchErrors(response.result);
      await dispatch(loadAppointments({ payload: { shopId: shop_id } }));

      await dispatch(loadOrders({ payload: { shopId: shop_id } }));

      await dispatch({
        type: USER_NOTE_UPDATE_SUCCEEDED,
        payload: { note: response.result.note },
      });
      await dispatch({ type: UNSET_LOADING });
      store.dispatch(
        Notifications.success({
          title: i18n.t("common:successTitle"),
          message: i18n.t("common:noteUpdatedMsg"),
          position: "tr",
          autoDismiss: 5,
        })
      );
    } catch (errors) {
      dispatch({ type: UNSET_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:errorUpdatingNoteMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
      await dispatch(action.errorCallback);
    }
  };
}

export function destroyNote(action) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_LOADING });
      let user = getState().users || {};
      const { authentication_token } = user;

      const payload = action || {};
      const { noteId, shopId } = payload;

      const request = {
        url: `${NOTES_PATH}/${noteId}`,
        headers: { Authorization: authentication_token },
        body: { id: noteId },
      };

      const response = await deleteJSON(
        request.url,
        request.body,
        request.headers
      );
      catchErrors(response.result);

      await dispatch({
        type: USER_NOTE_DESTROY_SUCCEEDED,
        payload: { noteId: response.result.id },
      });

      await dispatch(loadAppointments({ payload: { shopId: shopId } }));

      await dispatch({ type: UNSET_LOADING });
      store.dispatch(
        Notifications.success({
          title: i18n.t("common:successTitle"),
          message: i18n.t("common:noteDeletedMsg"),
          position: "tr",
          autoDismiss: 5,
        })
      );
    } catch (errors) {
      await dispatch({ type: UNSET_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:errorDeletingNoteMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
    }
  };
}
