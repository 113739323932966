import React, { Component } from "react";
import { Form, Button } from "semantic-ui-react";

export default class ForgotPasswordForm extends Component {
  state = {
    email: "",
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") this.props.onSubmit(this.state.email);
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.onSubmit(this.state.email);
  };

  render() {
    const { email } = this.state;
    const { isSubmitting, t } = this.props;

    return (
      <Form className="no-session-form">
        <Form.Input
          value={email}
          onChange={(e) => this.setState({ email: e.target.value })}
          fluid
          size="large"
          label="EMAIL"
        />

        <div className="flex-container" style={{ marginTop: "30px" }}>
          <div style={{ width: "100%", textAlign: "right" }}>
            <Button
              className={"button-primary no-session-button"}
              primary
              disabled={isSubmitting}
              onClick={this.onSubmit}
            >
              {t("resetPassword")}
            </Button>
          </div>
        </div>
      </Form>
    );
  }
} // ForgotPasswordForm
