import {
  SET_SHOP_PROMOTIONS_LOADING,
  SHOP_PROMOTION_CREATE_SUCCEEDED,
  SHOP_PROMOTION_LOAD_SUCCEEDED,
  UNSET_SHOP_PROMOTIONS_LOADING,
} from "../../constants/actionTypes";

export const initialState = {
  promotions: [],
  isLoading: false,
};

export default function (state = initialState, action) {
  // eslint-disable-next-line
  const { payload, type } = action;

  switch (type) {
    case SET_SHOP_PROMOTIONS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case UNSET_SHOP_PROMOTIONS_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case SHOP_PROMOTION_LOAD_SUCCEEDED:
      return {
        ...state,
        promotions: payload.promotions,
      };
    case SHOP_PROMOTION_CREATE_SUCCEEDED:
      return {
        ...state,
        promotions: [...payload.promotion, ...state.promotions],
      };
    default:
      return state;
  }
}
