import React, { Component } from "react";
import { Button, Modal, Form, Image, Message } from "semantic-ui-react";
import { DocumentUploader } from "../shared/DocumentUploader";
import _ from "lodash";
import store from "../../actions/configureStore";
import { createPromotion } from "../../actions/sagas/promotionSaga";

class AddPromotionModal extends Component {
  state = {
    formData: {
      name: "",
      description: "",
    },
    errors: {},
    selectedShop: null,
    isDropdownSelected: true,
    uploadedFiles: [],
    isFileSelected: true,
  };

  onFieldChange = (field, e) => {
    let updatedFormData = { ...this.state.formData };
    updatedFormData[field] = e.target.value;
    this.setState({ formData: updatedFormData });
  };

  onShopChange = (e, { value }) => {
    this.setState({ value }, () => {
      this.setState({
        selectedShop: value,
        isDropdownSelected: true,
      });
    });
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      this.validateAndSubmit();
    }
  };

  handleFilesUploaded = (file) => {
    this.setState((prevState) => ({
      uploadedFiles: [...prevState.uploadedFiles, file],
      isFileSelected: true,
    }));
  };

  handleFileDelete = (file) => {
    this.setState((prevState) => ({
      uploadedFiles: prevState.uploadedFiles.filter(
        (selectedFile) => selectedFile.fileName !== file
      ),
      isFileSelected: prevState.uploadedFiles.length === 0 ? false : true,
    }));
  };

  onHandleClose = () => {
    const { close } = this.props;
    close();
    this.setState({
      formData: {
        name: "",
        description: "",
      },
      errors: {},
      selectedShop: null,
      isDropdownSelected: true,
      uploadedFiles: [],
      isFileSelected: true,
    });
  };

  formDataErrors = () => {
    const { formData } = this.state;
    const { t } = this.props;
    const { name } = formData;
    const errors = {};

    if (name.length === 0) errors.name = t("promotionNameIsRequired");

    return errors;
  };

  createPromotion = async () => {
    const {
      formData,
      selectedShop,
      uploadedFiles,
      isDropdownSelected,
      isFileSelected,
    } = this.state;
    const errors = this.formDataErrors();
    const formDataBody = new FormData();
    formDataBody.append("promotion_name", formData.name);
    formDataBody.append("shop_id", selectedShop);

    if (uploadedFiles.length > 0) {
      for (var i = 0; i < uploadedFiles.length; i++) {
        formDataBody.append(
          `promotion_material_images[]`,
          uploadedFiles[i].file
        );
      }
    }
    if (Object.keys(errors).length === 0) {
      this.setState({ errors: errors });
      if (
        isDropdownSelected &&
        selectedShop !== null &&
        isFileSelected &&
        uploadedFiles.length > 0
      ) {
        await store.dispatch(createPromotion({ formDataBody }));
        this.props.close();
      } else {
        if (selectedShop === null) {
          this.setState({ isDropdownSelected: false });
        }
        if (uploadedFiles.length === 0) {
          this.setState({ isFileSelected: false });
        }
      }
    } else {
      this.setState({ errors: errors });
    }
  };

  render() {
    const {
      errors,
      file,
      fileType,
      selectedShop,
      isFileSelected,
      isDropdownSelected,
    } = this.state;
    const { name } = this.state.formData;
    const { open, shops, t } = this.props;
    return (
      <div className="promo-modal">
        <Modal
          open={open}
          onClose={this.onHandleClose}
          closeIcon
          size="tiny"
          closeOnDimmerClick={false}
          className="promo-modal-content"
        >
          <Modal.Header>{t("addNewPromotion")}</Modal.Header>
          <Modal.Content textAlign="center">
            <Form error>
              <Form.Field>
                <Form.Input
                  required
                  value={name}
                  onChange={(e) => this.onFieldChange("name", e)}
                  fluid
                  label={t("name")}
                  size="large"
                  error={errors.name}
                  placeholder={t("enterPromotionName")}
                />
              </Form.Field>
              <Form.Field>
                <Form.Dropdown
                  fluid
                  required
                  value={selectedShop}
                  options={
                    shops &&
                    shops.length > 0 &&
                    _.map(_.uniqBy(shops, "id"), (shop, index) => ({
                      key: shop.id,
                      text: `${shop.name} ${shop.store_number}`,
                      value: shop.id,
                    }))
                  }
                  onChange={this.onShopChange}
                  search
                  selection
                  placeholder={t("selectShop")}
                  floating
                  label={t("shop")}
                />
                {!isDropdownSelected && (
                  <Message error content={t("pleaseSelectShop")} />
                )}
              </Form.Field>

              {file && (
                <div>
                  {fileType === "application/pdf" ? (
                    <object
                      data={`${file}#toolbar=0`}
                      type="application/pdf"
                      width="100%"
                      height="300px"
                      className="document-preview"
                    />
                  ) : (
                    <Image src={file} className="document-preview" />
                  )}
                </div>
              )}
              <Form.Field required>
                <DocumentUploader
                  canUploadMultipleFiles={true}
                  onFilesUploaded={this.handleFilesUploaded}
                  onFilesDeleted={this.handleFileDelete}
                  t={t}
                />
                {!isFileSelected && (
                  <Message error content={t("pleaseSelectFileToUpload")} />
                )}
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              circular
              content={t("addPromotion")}
              onClick={() => this.createPromotion()}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default AddPromotionModal;
