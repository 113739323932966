export const CARADVISE_PRICE_INFO= "Retail Price $ - Retail Savings % + (Labor Hrs x Hourly Rate) + OEM Parts $ = CarAdvise Price"
export const RETAIL_PRICE_INFO= "Retail Price is the non-fleet everyday pricing that is given to the average customer"
export const EXTRA_QUARTS_WHATS_INCLUDED = "1 quart of oil"

export const CONVENTIONAL_EXTRA_QUARTS_PRICE = "conventional_extra_quarts_price"
export const SEMI_SYNTHETIC_EXTRA_QUARTS_PRICE = "semi_synthetic_extra_quarts_price"
export const EURO_SYNTHETIC_EXTRA_QUARTS_PRICE = "euro_synthetic_extra_quarts_price"
export const FULL_SYNTHETIC_EXTRA_QUARTS_PRICE = "full_synthetic_extra_quarts_price"


export const OIL_EXTRA_QUARTS = [
    CONVENTIONAL_EXTRA_QUARTS_PRICE,
    SEMI_SYNTHETIC_EXTRA_QUARTS_PRICE,
    EURO_SYNTHETIC_EXTRA_QUARTS_PRICE,
    FULL_SYNTHETIC_EXTRA_QUARTS_PRICE
]
