import React from "react";
import { Image } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/pro-light-svg-icons";
import {
  MAX_FILE_LENGTH,
  SUPPORTED_DOCUMENT_TYPES,
} from "../../constants/actionTypes";
import { fileSizes } from "../../helpers/documentHelpers";
import store from "../../actions/configureStore";
import Notifications from "../Notifications";
import i18n from "../../utils/i18n";

export class DocumentUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      selectedFiles: [],
    };
  }
  handleFileChange = (e) => {
    const { canUploadMultipleFiles, onFilesUploaded } = this.props;
    const { selectedFiles, errors } = this.state;
    const files = e.target.files;

    if (
      (canUploadMultipleFiles &&
        files.length > MAX_FILE_LENGTH &&
        selectedFiles.length + files.length > MAX_FILE_LENGTH) ||
      selectedFiles.length >= MAX_FILE_LENGTH ||
      selectedFiles.length + files.length > MAX_FILE_LENGTH
    ) {
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:onlyMsg")} ${MAX_FILE_LENGTH} ${i18n.t(
            "common:filesAreAllowedMsg"
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (SUPPORTED_DOCUMENT_TYPES.includes(file.type)) {
        if (file.size >= 5000000) {
          store.dispatch(
            Notifications.error({
              title: i18n.t("common:errorTitle"),
              message: i18n.t("common:fileSizeShouldBeLessThanOrEqualTo5MBMsg"),
              position: "tr",
              autoDismiss: 5,
            })
          );
          continue;
        }

        let reader = new FileReader();
        reader.onloadend = () => {
          const uploadedFile = {
            file: file,
            fileName: file.name,
            fileType: file.type,
            fileImage: reader.result,
            dateTime: file.lastModifiedDate.toLocaleString("en-US"),
            fileSize: fileSizes(file.size),
            url: URL.createObjectURL(file),
          };

          if (canUploadMultipleFiles) {
            if (
              selectedFiles.some(
                (selectedFile) => selectedFile.fileName === file.name
              )
            ) {
              store.dispatch(
                Notifications.error({
                  title: i18n.t("common:errorTitle"),
                  message: `${i18n.t("common:theFileMsg")} ${
                    file.name
                  } ${i18n.t("common:alreadyExistsInTheListMsg")}`,
                  position: "tr",
                  autoDismiss: 5,
                })
              );
              return;
            }

            this.setState((prevState) => ({
              selectedFiles: [...prevState.selectedFiles, uploadedFile],
            }));
          } else {
            if (
              selectedFiles.some(
                (selectedFile) => selectedFile.fileName === file.name
              )
            ) {
              store.dispatch(
                Notifications.error({
                  title: i18n.t("common:errorTitle"),
                  message: `${i18n.t("common:theFileMsg")} ${
                    file.name
                  } ${i18n.t("common:alreadyExists")}`,
                  position: "tr",
                  autoDismiss: 5,
                })
              );
              return;
            }
            this.setState({
              selectedFiles: [uploadedFile],
            });
          }
          // Callback function with the updated selected files
          onFilesUploaded(uploadedFile);
        };
        reader.readAsDataURL(file);
      } else {
        store.dispatch(
          Notifications.error({
            title: i18n.t("common:errorTitle"),
            message: i18n.t("common:onlyJpgPngFilesAllowedMsg"),
            position: "tr",
            autoDismiss: 5,
          })
        );
      }
    }
    // Clear the file input value, to allow selecting the same file again
    e.target.value = "";
  };

  deleteSelectedFile = (fileName) => {
    const { selectedFiles } = this.state;
    const { onFilesDeleted } = this.props;
    // Index of the file to be deleted
    const fileIndex = selectedFiles.findIndex(
      (file) => file.fileName === fileName
    );

    if (fileIndex !== -1) {
      const updatedFiles = [...selectedFiles];
      updatedFiles.splice(fileIndex, 1);

      this.setState({
        selectedFiles: updatedFiles,
      });

      // Callback function with the updated selected files
      onFilesDeleted(fileName);
    }
  };

  render() {
    const { canUploadMultipleFiles, t } = this.props;
    const { selectedFiles } = this.state;

    return (
      <div className="document-uploader-container fileupload-view">
        <div>
          <div>
            <div className="card">
              <div className="card-body">
                <div className="data-box">
                  <div className="file-upload">
                    <div className="file-upload-box">
                      <input
                        type="file"
                        id="fileupload"
                        className="file-upload-input"
                        onChange={(e) => this.handleFileChange(e)}
                        multiple={canUploadMultipleFiles}
                      />
                      <span>
                        {t("dragAndDrop")}
                        <span className="link">
                          {canUploadMultipleFiles
                            ? t("chooseFiles")
                            : t("chooseFile")}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="attach-box">
                    {selectedFiles.map((data, index) => {
                      const {
                        fileName,
                        fileType,
                        fileImage,
                        dateTime,
                        fileSize,
                      } = data;
                      return (
                        <div className="file-atc-box" key={index}>
                          {fileName.match(/.(jpg|jpeg|png|jfif)$/i) ? (
                            <div className="file-image">
                              <Image src={fileImage} alt="" />
                            </div>
                          ) : (
                            <div className="file-image">
                              <FontAwesomeIcon icon={faFileAlt} />
                            </div>
                          )}
                          <div className="file-detail">
                            <h6>{fileName}</h6>
                            <p></p>
                            <p>
                              <span>
                                {t("size")}
                                {fileSize}{" "}
                              </span>
                            </p>
                            <div className="file-actions">
                              <button
                                type="button"
                                className="file-action-btn"
                                onClick={() =>
                                  this.deleteSelectedFile(fileName)
                                }
                              >
                                {t("delete")}
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
