import i18n from "i18next";
import {
  EN_CANDA_LOCALE,
  EN_LOCALE,
  ES_USA_LOCALE,
  ES_LOCALE,
  FR_LOCALE,
  FR_CANDA_LOCALE,
} from "../constants/actionTypes";

export function setLanguage(language) {
  const locale =
    language === EN_LOCALE
      ? EN_CANDA_LOCALE
      : language === FR_LOCALE
      ? FR_CANDA_LOCALE
      : language === ES_LOCALE
      ? ES_USA_LOCALE
      : EN_LOCALE;
  i18n.changeLanguage(locale);
}
