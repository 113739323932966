import React from "react";
import { DISPLAY_EMPTY } from "../../constants/application";
import { Table } from "semantic-ui-react";
import { isNotShopBasicUser } from "../../helpers/userHelpers";
import { displayCurrency, handleKeypress } from "../../helpers/numberHelpers";
import { calculateRetailSavingsPercentage } from "../../helpers/pricingsHelpers";
import { OIL_EXTRA_QUARTS } from "../../constants/pricing";
import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollar } from "@fortawesome/free-solid-svg-icons";

export default class PricingRow extends React.Component {
  constructor(props) {
    super(props);

    const { pricing } = props;
    const {
      id,
      name,
      position,
      retailPrice,
      shopCaradvisePrice,
      displayName,
      serviceDefinitionId,
      companyId,
    } = pricing;
    this.state = {
      isEditable: false,
      modalOpen: false,
      openEditModal: false,
      formData: {
        retailPrice: retailPrice || "",
        id: id || null,
        shopCaradvisePrice: shopCaradvisePrice || "",
        name: name || "",
        position: position || "",
        displayName: displayName || "",
        shopId: props.currentShopId,
        company_id: companyId || props.currentShop.company_id,
        serviceDefinitionId: serviceDefinitionId || null,
      },
      errors: {},
      error: false,
    };
  }

  formDataErrors = () => {
    const { retailPrice, shopCaradvisePrice } = this.state.formData;

    if (retailPrice !== "" || retailPrice.length === 0)
      this.setState({ error: true });
    if (shopCaradvisePrice !== "" || shopCaradvisePrice.length === 0)
      this.setState({ error: true });
  };

  onFieldChange = (field, e) => {
    const { onSubmit } = this.props;
    const { formData } = this.state;
    if (/^0/.test(e.target.value)) {
      e.target.value = e.target.value.replace(/^0/, "");
    }
    let updatedFormData = { ...formData };
    updatedFormData[field] = e.target.value;
    this.setState({ formData: updatedFormData });
    this.formDataErrors();
    onSubmit(updatedFormData);
  };

  cancleChange = () => {
    const { pricing } = this.props;
    const { retailPrice, shopCaradvisePrice } = pricing;
    this.setState({
      openEditModal: false,
      errors: {},
      formData: {
        retailPrice: retailPrice,
        shopCaradvisePrice: shopCaradvisePrice,
      },
    });
  };
  render() {
    const { currentUser, isEditable, onEditing, pricing } = this.props;
    const { formData } = this.state;
    const { retailPrice, shopCaradvisePrice } = formData;
    return (
      <Table.Row key={pricing.displayName}>
        <Table.Cell width={5}>
          <p style={{ font: "normal normal medium 14px/18px" }}>
            {pricing.displayName}
          </p>
        </Table.Cell>
        <Table.Cell textAlign="center" width={1}>
          {!isEditable ? (
            <div style={{ textAlign: "center" }} onClick={() => onEditing()}>
              <div className="caradvise-price">
                <p className="primary-color">
                  {displayCurrency(shopCaradvisePrice)}
                </p>
              </div>
            </div>
          ) : isNotShopBasicUser(currentUser) &&
            !OIL_EXTRA_QUARTS.includes(pricing.name) ? (
            <div
              className={
                (pricing.id &&
                  shopCaradvisePrice === "" &&
                  shopCaradvisePrice.length === 0) ||
                (OIL_EXTRA_QUARTS.includes(pricing.name) &&
                  shopCaradvisePrice === "" &&
                  shopCaradvisePrice.length === 0) ||
                parseFloat(retailPrice) < parseFloat(shopCaradvisePrice)
                  ? "input-pricing-error"
                  : "input-pricing"
              }
            >
              <span>
                <FontAwesomeIcon
                  className="prefix primary-color"
                  icon={faDollar}
                />
              </span>
              <input
                required
                id="input"
                type="number"
                value={shopCaradvisePrice}
                className="primary-color"
                onChange={(e) => this.onFieldChange("shopCaradvisePrice", e)}
                size="mini"
                min="1"
                onKeyDown={handleKeypress}
              />
              <span>
                <FontAwesomeIcon
                  className="suffix"
                  icon={faPencil}
                  onClick={() => onEditing()}
                />
              </span>
            </div>
          ) : shopCaradvisePrice ? (
            <div style={{ textAlign: "center" }}>
              <div className="caradvise-price">
                <p className="primary-color">
                  {displayCurrency(shopCaradvisePrice)}
                </p>
              </div>
            </div>
          ) : (
            <div className="caradvise-price">
              <p className="primary-color">{DISPLAY_EMPTY}</p>
            </div>
          )}
        </Table.Cell>
        <Table.Cell width={3} onClick={() => onEditing()}>
          {!isEditable ? (
            <div style={{ textAlign: "center" }} onClick={() => onEditing()}>
              <div className="caradvise-price">
                <p className="black-color">
                  {displayCurrency(pricing.retailPrice)}
                </p>
              </div>
            </div>
          ) : isNotShopBasicUser(currentUser) ? (
            <div
              className={
                (pricing.id &&
                  retailPrice === "" &&
                  retailPrice.length === 0) ||
                (OIL_EXTRA_QUARTS.includes(pricing.name) &&
                  retailPrice === "" &&
                  retailPrice.length === 0) ||
                parseFloat(retailPrice) < parseFloat(shopCaradvisePrice)
                  ? "input-pricing-error"
                  : "input-pricing"
              }
            >
              <span>
                <FontAwesomeIcon className="prefix" icon={faDollar} />
              </span>
              <input
                required
                id="input"
                type="number"
                value={retailPrice}
                onChange={(e) => this.onFieldChange("retailPrice", e)}
                size="mini"
                min="1"
                onKeyDown={handleKeypress}
              />
              <span>
                <FontAwesomeIcon
                  className="suffix"
                  icon={faPencil}
                  onClick={() => onEditing()}
                />
              </span>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <div className="caradvise-price">
                <p>{displayCurrency(pricing.retailPrice)}</p>
              </div>
            </div>
          )}
        </Table.Cell>
        <Table.Cell width={3}>
          <div style={{ textAlign: "center", fontWeight: 600 }}>
            {retailPrice && shopCaradvisePrice ? (
              calculateRetailSavingsPercentage(retailPrice, shopCaradvisePrice)
            ) : (
              <div>{DISPLAY_EMPTY}</div>
            )}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }
}
