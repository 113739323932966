import {
  CURRENT_USER_LOAD_SUCCEEDED,
  CURRENT_USER_SETTINGS_UPDATE_SUCCEEDED,
  SIGN_OUT_SUCCEEDED,
  IDENTIFY_AND_SET_USER,
  REMOVE_USER,
  SET_USER_LANGUAGE,
} from "../../constants/actionTypes";

export const initialState = {
  currentUser: {},
};

export default function (state = initialState, action = {}) {
  const { type, payload, user } = action;
  let newSettings;

  switch (type) {
    case IDENTIFY_AND_SET_USER:
      return user;
    case REMOVE_USER:
      state = {};
      return state;
    case CURRENT_USER_LOAD_SUCCEEDED:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...payload.currentUser,
        },
      };

    case SIGN_OUT_SUCCEEDED:
      return {
        ...state,
        currentUser: {},
      };

    case CURRENT_USER_SETTINGS_UPDATE_SUCCEEDED:
      const { id } = state.currentUser;
      if (id === payload.id) {
        newSettings = { ...state.currentUser, ...payload };
      } else {
        newSettings = state.currentUser;
      }
      return {
        ...state,
        currentUser: newSettings,
      };

    case SET_USER_LANGUAGE:
      return {
        ...state,
        language: payload.language,
      };

    default:
      return state;
  }
}
