const rootPath = "";

export const navigationPaths = {
  root: () => "/",

  dashboard: () => "/",
  welcome: () => `${rootPath}/welcome`,
  forgotPassword: () => `${rootPath}/forgot_password`,
  resetPassword: () => `${rootPath}/reset_password`,
  signIn: () => `${rootPath}/sign_in`,
  signOut: () => `${rootPath}/sign_out`,
  signUp: () => `${rootPath}/sign_up`,
};

export const routerPaths = {
  root: "/",

  dashboard: "/",
  welcome: `${rootPath}/welcome`,
  forgotPassword: `${rootPath}/forgot_password`,
  resetPassword: `${rootPath}/reset_password`,
  signIn: `${rootPath}/sign_in`,
  signOut: `${rootPath}/sign_out`,
  signUp: `${rootPath}/sign_up`,

  pricing: `${rootPath}/pricing`,
  membership: `${rootPath}/membership`,
  users: `${rootPath}/users`,
  scheduling: `${rootPath}/scheduling`,
  analytics: `${rootPath}/analytics`,
  // appointments: `${rootPath}/appointments`,
  // orders:  `${rootPath}/orders`,
  location: `${rootPath}/location`,
  customerFeedback: `${rootPath}/customerFeedback`,
  shopOrders: `${rootPath}/shopOrders`,
  settings: `${rootPath}/settings`,
  promotions: `${rootPath}/promotions`,
};
