import {
  SERVICE_DEFINITIONS_LOAD_SUCCEEDED, SERVICE_DEFINITIONS_SEARCH_LOAD_SUCCEEDED
} from "../../constants/actionTypes"

export const initialState = {
  serviceDefinitions: [],
  searchServiceDefinitions: []
}

export default function(state=initialState, action = {}) {
  const { type, payload } = action

  switch(type) {
    case SERVICE_DEFINITIONS_LOAD_SUCCEEDED:
      return {
        ...state,
        serviceDefinitions: payload.serviceDefinitions
      }
    case SERVICE_DEFINITIONS_SEARCH_LOAD_SUCCEEDED:
      return {
        ...state,
        searchServiceDefinitions: payload.serviceDefinitions
      }
    default:
      return state
  }
}
