import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { navigationPaths } from "../../constants/paths";
import CustomLoader from "../../components/elements/CustomLoader";
import NoSessionLayout from "../../layouts/NoSessionLayout";
import SignUpForm from "../../components/users/SignUpForm";
import { Icon } from "semantic-ui-react";
import PrivacyPolicyAndTerms from "../privacy_terms/PrivacyPolicyAndTerms";
import { createUser } from "../../actions/sagas/userSaga";
import store from "../../actions/configureStore";
import { withTranslation } from "react-i18next";
import { getSubdomain } from "../../components/shared/StylesheetInjector";
import { OFF, setupSplitFlags } from "../../components/config/SplitClient";

class UserNew extends React.Component {
  onSubmit = (data) => {
    store.dispatch(createUser(data, { history: this.props.history }));
  };

  state = {};

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain() });
  }

  render() {
    const { isLoading, t } = this.props;

    return (
      <NoSessionLayout>
        {isLoading && <CustomLoader />}
        {!isLoading && (
          <div className="no-session-content">
            <div className="no-session-content-header">
              <Link
                className="sign-up-cursor-pointer"
                to={navigationPaths.signIn()}
              >
                <Icon
                  color="black"
                  name="arrow left"
                  size="large"
                  className="sign-up-cursor-pointer"
                />
              </Link>
              <h1>{t("signUp")}</h1>
            </div>
            <SignUpForm
              onSubmit={this.onSubmit}
              isSubmitting={isLoading}
              t={t}
            />
            <div style={{ marginTop: "15px" }}>
              {this.state.showFooterComponent &&
                this.state.showFooterComponent === OFF && (
                  <PrivacyPolicyAndTerms t={t} subdomain={getSubdomain()} />
                )}
            </div>
          </div>
        )}
      </NoSessionLayout>
    );
  }
} // UserNew

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
  currentUser: state.users.currentUser || {},
});

export default connect(mapStateToProps, { createUser })(
  withTranslation("users")(UserNew)
);
