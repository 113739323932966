import React from "react";
import { Table } from "semantic-ui-react";
import CustomerFeedbackRow from "./CustomerFeedbackRow";
import { isShopOwnerOrCompanyOwner } from "../../helpers/userHelpers";

export default class CustomerFeedbackTable extends React.Component {
  render() {
    const {
      dispatch,
      currentUser,
      currentShopId,
      currentShop,
      userCustomerRatings,
      t,
    } = this.props;
    return (
      <React.Fragment>
        <div className="dash-table">
          <Table selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t("customer")}</Table.HeaderCell>
                <Table.HeaderCell>{t("shopText")}</Table.HeaderCell>
                <Table.HeaderCell>{t("feedbackText")}</Table.HeaderCell>
                {isShopOwnerOrCompanyOwner(currentUser) && (
                  <Table.HeaderCell textAlign="center">
                    {t("actionText")}
                  </Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {userCustomerRatings.length > 0 ? (
                userCustomerRatings.map((rating) => {
                  return (
                    <CustomerFeedbackRow
                      key={rating.id}
                      customerRating={rating}
                      dispatch={dispatch}
                      currentShop={currentShop}
                      currentUser={currentUser}
                      currentShopId={currentShopId}
                      t={t}
                    />
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell>
                    <div className="div-center">{t("noRecordsFound")}</div>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>
      </React.Fragment>
    );
  }
}
