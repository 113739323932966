export const applicationIdentifier = "shop_portal";
export const DISPLAY_EMPTY = "-";
export const BACKSPACE = "Backspace";
export const TAB = "Tab";
export const UP_ARROW = "ArrowUp";
export const DOWN_ARROW = "ArrowDown";
export const LEFT_ARROW = "ArrowLeft";
export const RIGHT_ARROW = "ArrowRight";
export const APPOINTMENT = "APPOINTMENT";
export const ORDER = "ORDER";
export const CARADVISE_SUPPORT_EMAIL = "support@caradvise.com";
export const CARADVISE_SUPPORT_NUMBER = "8449238473";
export const NA = "N/A";

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const STRING_REGEX = /^\s*$/;

export const CASE_INSENSITIVE = "i";

export const FREE_WIFI = "free_wifi";
export const BASIC_WAITING_ROOM = "basic_waiting_room";
export const FLAT_SCREEN_TV = "flat_screen_tv";
export const FREE_COFFEE = "free_coffee";
export const HIGH_END_WAITING_ROOM = "high_end_waiting_room";
export const LOANER = "loaner";
export const DROP_OFF = "dropoff";
export const KID_FRIENDLY = "kid_friendly";
export const SHUTTLE = "shuttle";

export const FREE_WIFI_LABEL = "location:freeWifiLabel";
export const BASIC_WAITING_ROOM_LABEL = "location:basicWaitingRoomLabel";
export const FLAT_SCREEN_TV_LABEL = "location:flatScreenTVLabel";
export const FREE_COFFEE_LABEL = "location:freeCoffeeLabel";
export const HIGH_END_WAITING_ROOM_LABEL = "location:highEndWaitingRoomLabel";
export const LOANER_LABEL = "location:LoanerLabel";
export const DROP_OFF_LABEL = "location:dropOffLabel";
export const KID_FRIENDLY_LABEL = "location:kidFriendlyLabel";
export const SHUTTLE_LABEL = "location:shuttleLabel";

//Affiliations
export const CARADVISE = "caradvise";
export const NTWTBC = "ntw";

export const Amenities_Array = [
  {
    key: FREE_WIFI,
    text: FREE_WIFI_LABEL,
    value: FREE_WIFI,
  },
  {
    key: FREE_COFFEE,
    text: FREE_COFFEE_LABEL,
    value: FREE_COFFEE,
  },
  {
    key: BASIC_WAITING_ROOM,
    text: BASIC_WAITING_ROOM_LABEL,
    value: BASIC_WAITING_ROOM,
  },
  {
    key: FLAT_SCREEN_TV,
    text: FLAT_SCREEN_TV_LABEL,
    value: FLAT_SCREEN_TV,
  },
  {
    key: HIGH_END_WAITING_ROOM_LABEL,
    text: HIGH_END_WAITING_ROOM_LABEL,
    value: HIGH_END_WAITING_ROOM,
  },
  {
    key: LOANER,
    text: LOANER_LABEL,
    value: LOANER,
  },
  {
    key: DROP_OFF,
    text: DROP_OFF_LABEL,
    value: DROP_OFF,
  },
  {
    key: KID_FRIENDLY,
    text: KID_FRIENDLY_LABEL,
    value: KID_FRIENDLY,
  },
  {
    key: SHUTTLE,
    text: SHUTTLE_LABEL,
    value: SHUTTLE,
  },
];
