import React from "react";
import { connect } from "react-redux";
import { Divider, Modal, Table } from "semantic-ui-react";
import ReactStars from "react-rating-stars-component";
import { displayCurrency } from "../../helpers/numberHelpers";
import { withTranslation } from "react-i18next";

class CustomerFeedbackDetailsModal extends React.Component {
  render() {
    const { open, close, rating, t } = this.props;
    const { vehicle, order_service, shop_detail } = rating;
    return (
      <Modal size="small" open={open} closeIcon onClose={() => close()}>
        <Modal.Content>
          <h3 className="align-center"> {t("feedbackDetails")} </h3>
          <Divider />
          <div>
            <h3> {t("customerDetails")} </h3>
            <div className="display-margin">
              <span>
                <b>{t("customerName")} </b>
                {rating.customer_name}
              </span>
            </div>
          </div>
          <div className="div-align-flex">
            <b>{t("rating")}</b>
            <div className="margin-left">
              <ReactStars
                count={5}
                size={12}
                value={rating.score}
                edit={false}
              />
            </div>
          </div>
          <div className="margin-top">
            <b> {t("feedback")} </b>
            <div className="margin-left">{rating.customer_explanation}</div>
            {rating.shop_explanation && (
              <div className="margin-top">
                <h4> {t("shopResponses")}</h4>
                <div className="margin-left">
                  <span>
                    <b>{t("response")} </b> {rating.shop_explanation}
                  </span>
                  <br />
                  <span>
                    <b>{t("givenBy")} </b> {rating.shop_user_email}
                  </span>
                </div>
              </div>
            )}
          </div>
          <Divider />
          <div>
            <h3> {t("vehiclesDetails")} </h3>
            <div className="div-align-flex">
              <span>
                <b>{t("vin")} </b> {vehicle.vin}
              </span>
              <span className="margin-left">
                <b>{t("make")} </b> {vehicle.make}
              </span>
              <span className="margin-left">
                <b>{t("model")} </b> {vehicle.model}
              </span>
              <span className="margin-left">
                <b>{t("yr")} </b> {vehicle.year}
              </span>
              <span className="margin-left">
                <b>{t("mile")} </b> {vehicle.miles}
              </span>
            </div>
          </div>
          <Divider />
          <div>
            <h3> {t("shopDetails")} </h3>
            <div className="div-align-block">
              <b>{t("name")} </b> {shop_detail.name}
              <br />
              <span>
                <b>{t("address")} </b> {shop_detail.address}
              </span>
              <span className="margin-left">
                <b>{t("city")} </b> {shop_detail.city}
              </span>
              <span className="margin-left">
                <b>{t("state")} </b> {shop_detail.state}
              </span>
            </div>
          </div>
          <Divider />
          <div>
            <h3> {t("serviceDetails")} </h3>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t("nameText")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("position")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("overrideTotal")}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {order_service.map((service) => {
                  return (
                    <Table.Row key={service.id}>
                      <Table.Cell verticalAlign="top" width={5}>
                        {service.name}
                      </Table.Cell>
                      <Table.Cell>{service.position}</Table.Cell>
                      <Table.Cell>
                        {displayCurrency(service.override_total)}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentShopId } = state.shops;

  return {
    isLoading: state.application.isLoading,
    currentShopId: currentShopId,
  };
};

export default connect(mapStateToProps)(
  withTranslation("customerFeedback")(CustomerFeedbackDetailsModal)
);
