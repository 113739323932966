import React, { Component } from "react"
import { connect } from "react-redux"
import Notifications from "./Notifications"
import PropTypes from "prop-types"

class NotificationsContainer extends Component {
  render() {
    const { notifications } = this.props

    return (
      <Notifications notifications={notifications} />
    )
  }
}

NotificationsContainer.contextTypes = {
  store: PropTypes.object
}

NotificationsContainer.propTypes = {
  notifications: PropTypes.array
}

const mapStateToProps = (state) => ({
  notifications: state.notifications
})

export default connect(mapStateToProps)(NotificationsContainer)
