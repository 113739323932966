import React, { Component } from "react";
import { Button, Modal, Form, Divider, Grid, Image } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  Amenities_Array,
  BASIC_WAITING_ROOM,
  DROP_OFF,
  FLAT_SCREEN_TV,
  FREE_COFFEE,
  FREE_WIFI,
  HIGH_END_WAITING_ROOM,
  KID_FRIENDLY,
  LOANER,
  SHUTTLE,
} from "../../constants/application";
import store from "../../actions/configureStore";
import { updateShopDetails } from "../../actions/sagas/shopSaga";
import _ from "lodash";

class EditShopDetailsModal extends Component {
  constructor(props) {
    super(props);
    const { shopBusinessHours, amenitisArray } = props;
    const { amenities, shop_image } = shopBusinessHours;

    this.state = {
      errors: {},
      value: null,
      selectedState: shopBusinessHours.state,
      isStateDropdownSelected: true,
      selectedAmenities: amenitisArray || null,
      isFreeWifi: amenities ? amenities.free_wifi : null,
      isBasicWaitingRoom: amenities ? amenities.basic_waiting_room : null,
      isFreeCoffee: amenities ? amenities.free_coffee : null,
      isFlatScreenTV: amenities ? amenities.flat_screen_tv : null,
      isHighEndWaitingRoom: amenities ? amenities.high_end_waiting_room : null,
      isLoaner: amenities ? amenities.loaner : null,
      isDropOff: amenities ? amenities.dropoff : null,
      isKidFriendly: amenities ? amenities.kid_friendly : null,
      isShuttle: amenities ? amenities.shuttle : null,
      shopImage: shop_image || null,
      file: null,
    };
  }

  fileInputRef = React.createRef();

  setAmenities = (amenity) => {
    if (amenity.includes(FREE_WIFI)) {
      this.setState({ isFreeWifi: true });
    } else {
      this.setState({ isFreeWifi: false });
    }
    if (amenity.includes(FREE_COFFEE)) {
      this.setState({ isFreeCoffee: true });
    } else {
      this.setState({ isFreeCoffee: false });
    }
    if (amenity.includes(BASIC_WAITING_ROOM)) {
      this.setState({ isBasicWaitingRoom: true });
    } else {
      this.setState({ isBasicWaitingRoom: false });
    }
    if (amenity.includes(HIGH_END_WAITING_ROOM)) {
      this.setState({ isHighEndWaitingRoom: true });
    } else {
      this.setState({ isHighEndWaitingRoom: false });
    }
    if (amenity.includes(FLAT_SCREEN_TV)) {
      this.setState({ isFlatScreenTV: true });
    } else {
      this.setState({ isFlatScreenTV: false });
    }
    if (amenity.includes(LOANER)) {
      this.setState({ isLoaner: true });
    } else {
      this.setState({ isLoaner: false });
    }
    if (amenity.includes(KID_FRIENDLY)) {
      this.setState({ isKidFriendly: true });
    } else {
      this.setState({ isKidFriendly: false });
    }
    if (amenity.includes(DROP_OFF)) {
      this.setState({ isDropOff: true });
    } else {
      this.setState({ isDropOff: false });
    }
    if (amenity.includes(SHUTTLE)) {
      this.setState({ isShuttle: true });
    } else {
      this.setState({ isShuttle: false });
    }
  };

  onUpdateShopDetails = () => {
    const { currentShopId } = this.props;
    const {
      isFreeCoffee,
      isFreeWifi,
      isBasicWaitingRoom,
      isHighEndWaitingRoom,
      isLoaner,
      isDropOff,
      isKidFriendly,
      isShuttle,
      isFlatScreenTV,
      shopImage,
    } = this.state;

    const amenities = [
      {
        free_wifi: isFreeWifi,
        basic_waiting_room: isBasicWaitingRoom,
        free_coffee: isFreeCoffee,
        flat_screen_tv: isFlatScreenTV,
        high_end_waiting_room: isHighEndWaitingRoom,
        loaner: isLoaner,
        dropoff: isDropOff,
        kid_friendly: isKidFriendly,
        shuttle: isShuttle,
      },
    ];
    const formDataBody = new FormData();

    for (var i = 0; i < amenities.length; i++) {
      formDataBody.append(
        `amenities[][free_wifi]`,
        JSON.stringify(amenities[i].free_wifi)
      );
      formDataBody.append(
        `amenities[][basic_waiting_room]`,
        JSON.stringify(amenities[i].basic_waiting_room)
      );
      formDataBody.append(
        `amenities[][free_coffee]`,
        JSON.stringify(amenities[i].free_coffee)
      );
      formDataBody.append(
        `amenities[][flat_screen_tv]`,
        JSON.stringify(amenities[i].flat_screen_tv)
      );
      formDataBody.append(
        `amenities[][high_end_waiting_room]`,
        JSON.stringify(amenities[i].high_end_waiting_room)
      );
      formDataBody.append(
        `amenities[][loaner]`,
        JSON.stringify(amenities[i].loaner)
      );
      formDataBody.append(
        `amenities[][dropoff]`,
        JSON.stringify(amenities[i].dropoff)
      );
      formDataBody.append(
        `amenities[][kid_friendly]`,
        JSON.stringify(amenities[i].kid_friendly)
      );
      formDataBody.append(
        `amenities[][shuttle]`,
        JSON.stringify(amenities[i].shuttle)
      );
    }
    if (shopImage.url !== undefined)
      formDataBody.append("shop_image", shopImage.url);
    else formDataBody.append("shop_image", shopImage);
    store.dispatch(updateShopDetails({ id: currentShopId, formDataBody }));
  };

  handleOnChange = (e, data) => {
    this.setAmenities(data.value);
  };

  fileChange = (e) => {
    const image = e.target.files[0];
    this.setState({
      shopImage: image,
      file: URL.createObjectURL(image),
    });
  };

  render() {
    const { open, close, t } = this.props;
    const { selectedAmenities, file } = this.state;
    return (
      <>
        <Modal
          open={open}
          onClose={() => close()}
          closeIcon
          size="tiny"
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            <Grid centered columns={1}>
              <Grid.Column width={9}>
                <div style={{ textAlign: "center" }}>
                  <h3>{t("editAmenities")}</h3>
                </div>
                <Divider hidden />
                <Form error className="no-session-form">
                  <Form.Field>
                    <Form.Field>
                      <label>{t("addAmenities")}</label>
                      <Form.Select
                        placeholder={t("selectAmenities")}
                        defaultValue={selectedAmenities}
                        fluid
                        multiple
                        selection
                        options={
                          _.map(Amenities_Array, (amenity) => ({
                            key: amenity.key,
                            text: t(amenity.text),
                            value: amenity.value,
                          })) || null
                        }
                        onChange={this.handleOnChange}
                        className="amenities-edit"
                        open={true}
                      />
                    </Form.Field>
                  </Form.Field>
                  <Divider hidden />
                  {file && (
                    <React.Fragment>
                      <label>
                        <b>{t("shopImagePreview")}</b>
                      </label>
                      <Image src={file} className="image-preview" />
                    </React.Fragment>
                  )}
                  <Divider hidden />
                  <Form.Field>
                    <div style={{ textAlign: "center" }}>
                      <Button
                        circular
                        fluid
                        content={t("uploadImage")}
                        type="file"
                        onClick={() => this.fileInputRef.current.click()}
                      />
                      <input
                        ref={this.fileInputRef}
                        type="file"
                        hidden
                        onChange={this.fileChange}
                        accept="image/*"
                      />
                    </div>
                  </Form.Field>
                  <Divider hidden />
                </Form>
                <div style={{ textAlign: "center" }}>
                  <Button
                    fluid
                    circular
                    content={t("saveDetails")}
                    onClick={this.onUpdateShopDetails}
                  />
                </div>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentShopId } = state.shops;

  return {
    isLoading: state.application.isLoading,
    currentShopId: currentShopId,
  };
};

export default connect(mapStateToProps)(EditShopDetailsModal);
