import React from "react";
import { connect } from "react-redux";
import CustomLoader from "../elements/CustomLoader";
import * as arrayHelpers from "../../helpers/arrayHelpers";
import AppointmentSummary from "./AppointmentSummary";
import PricingSummary from "./PricingSummary";
import { constructUserPricings } from "../../helpers/pricingsHelpers";
import { Input } from "semantic-ui-react";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "../../constants/dateTime";
import moment from "moment";
import { isNationalOrRegionalOrOwner } from "../../helpers/userHelpers";
import store from "../../actions/configureStore";
import { loadDashboard } from "../../actions/sagas/dashboardSaga";
import { withTranslation } from "react-i18next";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentShopId: props.currentShopId,
      search: "",
      userAppointments: props.appointments.userAppointments,
      userPricings: props.pricings,
    };
  }

  async componentDidMount() {
    await this.fetchDashboardData();
  }

  fetchDashboardData() {
    store.dispatch(loadDashboard({ payload: {} }));
  }

  handleSearch(data, searchString) {
    let search = searchString.trim().toLowerCase();
    return (
      data &&
      data.filter(
        (e) =>
          (e.appt_accepted_by_shop &&
            e.appt_accepted_by_shop.toLowerCase().includes(search)) ||
          (e.customer_name && e.customer_name.toLowerCase().includes(search)) ||
          (e.vin && e.vin.toLowerCase().includes(search)) ||
          (e.appt_cancel_reason &&
            e.appt_cancel_reason.toLowerCase().includes(search)) ||
          (e.appt_cancel_by &&
            e.appt_cancel_by.toLowerCase().includes(search)) ||
          (e.appointment_datetime_pretty &&
            moment(e.appointment_datetime_pretty)
              .parseZone()
              .format(DATE_FORMAT.MM_DD_YYYY)
              .toLowerCase()
              .includes(search)) ||
          (e.appointment_datetime_pretty &&
            moment(e.appointment_datetime_pretty)
              .parseZone()
              .format(DATE_TIME_FORMAT.HHMMAA)
              .toLowerCase()
              .includes(search)) ||
          (e.name && e.name.toLowerCase().includes(search)) ||
          (e.position && e.position.toLowerCase().includes(search))
      )
    );
  }

  render() {
    const {
      currentShop,
      currentShopId,
      isLoading,
      appointments,
      pricings,
      currentUser,
      t,
    } = this.props;
    const { userAppointments } = appointments;
    const filteredAppointments =
      currentShopId !== null && currentShopId !== "null"
        ? userAppointments &&
          userAppointments.filter((app) => app.shop_id === currentShopId)
        : userAppointments;
    const filteredPricings =
      currentShopId !== null && currentShopId !== "null"
        ? pricings &&
          pricings.length > 0 &&
          pricings.filter((pricing) => pricing.shopId === currentShopId)
        : pricings;
    const { search } = this.state;

    return (
      <div className="full-dashboard-container">
        {isLoading && <CustomLoader />}
        {!isLoading && (
          <React.Fragment>
            {currentShop && (
              <React.Fragment>
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 14,
                    margin: "0 20px 30px 0",
                  }}
                >
                  <Input
                    icon="search"
                    placeholder="Search"
                    style={{ marginRight: "20px" }}
                    onChange={(e) => this.setState({ search: e.target.value })}
                  />
                </div>
                <AppointmentSummary
                  appointments={this.handleSearch(filteredAppointments, search)}
                  currentShopId={currentShopId}
                  t={t}
                />
                <PricingSummary
                  pricings={this.handleSearch(filteredPricings, search)}
                  currentUser={currentUser}
                  isNationalOrRegionalOrOwnerFlag={isNationalOrRegionalOrOwner(
                    currentUser
                  )}
                  currentShopId={currentShopId}
                  t={t}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const serviceDefs = state.serviceDefinitions.serviceDefinitions;
  const userPricings = state.pricings.userPricings;
  const shops = state.shops.userShops;
  return {
    currentUser: state.users.currentUser,
    isLoading:
      state.application.isDashboardLoading || state.application.isLoading
        ? true
        : false,
    appointments: state.appointments,
    currentShop:
      arrayHelpers.findObjectById(
        state.shops.userShops,
        state.shops.currentShopId
      ) || {},
    currentShopId: state.shops.currentShopId,
    services: serviceDefs,
    pricings: constructUserPricings(userPricings, shops),
  };
};

export default connect(mapStateToProps)(
  withTranslation("dashboard")(Dashboard)
);
