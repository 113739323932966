import React from "react";
import { Table, Button } from "semantic-ui-react";
import ReactStars from "react-rating-stars-component";
import ShopResponseModal from "./ShopResponseModal";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../constants/dateTime";
import CustomerFeedBackDetailsModal from "./CustomerFeedBackDetailsModal";
import { isShopOwnerOrCompanyOwner } from "../../helpers/userHelpers";

export default class CustomerFeedbackRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      rating: null,
      openDetailsModal: false,
    };
  }

  close = () => this.setState({ openModal: false, openDetailsModal: false });

  render() {
    const {
      customerRating,
      currentUser,
      currentShopId,
      currentShop,
      t,
    } = this.props;
    const { openModal, rating, openDetailsModal } = this.state;
    const {
      id,
      customer_name,
      created_at,
      shop_detail,
      score,
      customer_explanation,
    } = customerRating;
    const time = moment(created_at).format(DATE_TIME_FORMAT.YYDM);
    return (
      <React.Fragment>
        <Table.Row key={id}>
          <Table.Cell verticalAlign="top" width={3}>
            {customer_name}
            <br />
            <p>{time}</p>
            <div
              className="view-all-button"
              onClick={() =>
                this.setState({
                  openDetailsModal: !openDetailsModal,
                  rating: customerRating,
                })
              }
            >
              {t("viewMoreDetails")}
            </div>
          </Table.Cell>
          {shop_detail && (
            <Table.Cell verticalAlign="top" width={4}>
              {shop_detail.name}
              <p>
                {shop_detail.address +
                  ", " +
                  shop_detail.city +
                  ", " +
                  shop_detail.state}
              </p>
            </Table.Cell>
          )}
          <Table.Cell width={5}>
            <ReactStars
              count={5}
              size={12}
              value={score}
              edit={false}
              //activeColor="#ffd700"
            />
            {customer_explanation}
          </Table.Cell>
          {isShopOwnerOrCompanyOwner(currentUser) && (
            <Table.Cell verticalAlign="top" textAlign="center" collapsing>
              <Button
                circular
                onClick={() =>
                  this.setState({
                    openModal: !openModal,
                    rating: customerRating,
                  })
                }
              >
                {t("responseText")}
              </Button>
            </Table.Cell>
          )}
        </Table.Row>
        {openModal && (
          <ShopResponseModal
            open={openModal}
            close={this.close}
            currentUser={currentUser}
            currentShop={currentShop}
            currentShopId={currentShopId}
            rating={rating}
            t={t}
          />
        )}
        {openDetailsModal && (
          <CustomerFeedBackDetailsModal
            open={openDetailsModal}
            close={this.close}
            rating={rating}
          />
        )}
      </React.Fragment>
    );
  }
}
