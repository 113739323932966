import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers/rootReducer";
// import rootSaga from "./sagas/rootSaga";
// import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import * as Sentry from "@sentry/react";

const middlewares = [thunkMiddleware];

if (process.env.NODE_ENV === `development`) {
  const { createLogger } = require(`redux-logger`);

  middlewares.push(createLogger({ collapsed: true }));
}
const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const persistConfig = {
  key: "root",
  storage,
};

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(
      ...middlewares
      // sagaMiddleware
      /* add other middleware here */
    ),
    sentryReduxEnhancer
  )
);

const persistor = persistStore(store);

export function getPersistor() {
  return persistor;
}

/* inits Saga middleware */
// sagaMiddleware.run(rootSaga);

export default store;
