import React from "react";
import { Grid, Icon, Image, Item, Button, Popup } from "semantic-ui-react";
import { APPOINTMENT, ORDER } from "../../constants/application";
import {
  ACTIVE,
  ACTIVE_NEEDS_RESCHEDULE,
  APPROVAL,
  AWAITING_APPROVAL,
  CANCELLED,
  CHECKED_IN,
  COMPLETED,
  INITIATED_BY_USER,
  IN_BAY,
  IN_MANUAL_REVIEW,
  PAID,
  PAID_AT_SHOP,
  USER_INITIATED_NEEDS_RESCHEDULE,
  WAITING_FOR_PARTS,
  WALK_IN,
  WALK_IN_ACTIVE,
  WALK_IN_ACTIVE_NEEDS_RESCHEDULE,
  WALK_IN_NEEDS_RESCHEDULE,
} from "../../constants/actionTypes";
import { DATE_TIME_FORMAT } from "../../constants/dateTime";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileMedical, faFile } from "@fortawesome/free-solid-svg-icons";
import placeholderCarImage from "../../assets/images/placeholder_car.svg";
import ProgressStatusBar from "./ProgressStatusBar";
import { formatPhoneNumber } from "../../helpers/numberHelpers";
import RejectReasonModal from "../modals/RejectReasonModal";
import CancelReasonModal from "../modals/CancelReasonModal";
import ShowNotesModal from "../modals/ShowNotesModal";
import AddNoteModal from "../notes/AddNoteModal";
import ShowServicesModal from "../modals/ShowServicesModal";
import { getStyles } from "../shared/StylesheetInjector";
import { updateAppointmentStatus } from "../../actions/sagas/appointmentSaga";
import store from "../../actions/configureStore";

const styles = getStyles();

export default class OrderRow extends React.Component {
  state = {
    value: "",
    appointment: null,
    openNotesModal: false,
    openDetailsModal: false,
    openRescheduleModal: false,
    openCancelModal: false,
    openShowNotesModal: false,
    notes: null,
  };

  close = () =>
    this.setState({
      openDetailsModal: false,
      openNotesModal: false,
      openRescheduleModal: false,
      openShowNotesModal: false,
      openCancelModal: false,
      value: "",
    });

  getHours = (date) => {
    const appointmentDate = new Date(
      moment(date).parseZone().format(DATE_TIME_FORMAT.MMDYYHH)
    );
    const todaysDate = new Date(
      moment(new Date()).parseZone().format(DATE_TIME_FORMAT.MMDYYHH)
    );
    let diff = (appointmentDate.getTime() - todaysDate.getTime()) / 1000;

    return (diff /= 60 * 60);
  };

  onContinue = (appointment, currentShopId) => {
    let payload = null;
    if (
      appointment.status === INITIATED_BY_USER ||
      appointment.status === WALK_IN
    ) {
      payload = {
        id: appointment.id,
        isAppointmentConfirmed: 1,
        shop_id: currentShopId,
      };
    } else if (
      appointment.status === ACTIVE ||
      appointment.status === WALK_IN_ACTIVE
    ) {
      payload = {
        id: appointment.id,
        isAppointmentConfirmed: 2,
        shop_id: currentShopId,
      };
    } else if (appointment.status === CHECKED_IN) {
      payload = {
        id: appointment.id,
        isAppointmentConfirmed: 3,
        shop_id: currentShopId,
      };
    } else if (appointment.status === IN_BAY) {
      payload = {
        id: appointment.id,
        isAppointmentConfirmed: 5,
        shop_id: currentShopId,
      };
    } else if (appointment.status === APPROVAL) {
      payload = {
        id: appointment.id,
        isAppointmentConfirmed: 6,
        shop_id: currentShopId,
      };
    }

    if (payload !== null) {
      store.dispatch(updateAppointmentStatus({ payload: payload }));
    }
  };

  onBack = (appointment, currentShopId) => {
    let payload = null;
    if (
      appointment.status === ACTIVE ||
      appointment.status === WALK_IN_ACTIVE
    ) {
      payload = {
        id: appointment.id,
        isAppointmentConfirmed: 1,
        shop_id: currentShopId,
      };
    } else if (appointment.status === CHECKED_IN) {
      payload = {
        id: appointment.id,
        isAppointmentConfirmed: 1,
        shop_id: currentShopId,
      };
    } else if (appointment.status === IN_BAY) {
      payload = {
        id: appointment.id,
        isAppointmentConfirmed: 2,
        shop_id: currentShopId,
      };
    } else if (appointment.status === AWAITING_APPROVAL) {
      payload = {
        id: appointment.id,
        isAppointmentConfirmed: 3,
        shop_id: currentShopId,
      };
    } else if (appointment.status === WAITING_FOR_PARTS) {
      payload = {
        id: appointment.id,
        isAppointmentConfirmed: 3,
        shop_id: currentShopId,
      };
    }

    if (payload !== null) {
      store.dispatch(updateAppointmentStatus({ payload: payload }));
    }
  };

  render() {
    const {
      appointment,
      currentShopId,
      currentShop,
      currentUser,
      t,
    } = this.props;
    const {
      customer_name,
      order_services,
      appointment_datetime_pretty,
      year,
      make,
      model,
      vin,
      notes,
      status,
      customer_phone_number,
      customer_email,
      vehicle_image_url,
    } = appointment;
    const {
      openDetailsModal,
      openRescheduleModal,
      openCancelModal,
      openNotesModal,
      openShowNotesModal,
      services,
    } = this.state;
    const color =
      status === INITIATED_BY_USER || status === WALK_IN
        ? styles.overrideLinkColor
        : styles.red;
    const text =
      status === INITIATED_BY_USER || status === WALK_IN ? APPOINTMENT : ORDER;
    const apptTime = moment(appointment_datetime_pretty)
      .parseZone()
      .format(DATE_TIME_FORMAT.HHMMAA);
    const remainApptTime = this.getHours(appointment_datetime_pretty);
    const apptTimeColor =
      remainApptTime <= 2 && remainApptTime >= 0 ? styles.red : "";
    const noteCount = notes.length;
    return (
      <div>
        {noteCount === 0 ? (
          <div className="dot">
            <FontAwesomeIcon
              size="2x"
              icon={faFileMedical}
              onClick={() =>
                this.setState({
                  openNotesModal: !openNotesModal,
                })
              }
            />
            {openNotesModal && (
              <AddNoteModal
                open={openNotesModal}
                close={this.close}
                shop_order_id={appointment.id}
                currentUser={currentUser}
                currentShopId={currentShopId}
              />
            )}
          </div>
        ) : (
          <div className="dot-note">
            <span
              className="fa-layers fa-fw"
              onClick={() =>
                this.setState({
                  openShowNotesModal: !openShowNotesModal,
                  notes: notes,
                })
              }
            >
              <FontAwesomeIcon
                icon={faFile}
                size="2x"
                style={{ color: styles.whiteTextColor }}
              />
              <span
                className="fa-layers-text"
                data-fa-transform="shrink-10 down-3"
              >
                {noteCount}
              </span>
            </span>
            {openShowNotesModal && (
              <div className="note-container">
                <ShowNotesModal
                  open={openShowNotesModal}
                  close={this.close}
                  notes={notes}
                  currentUser={currentUser}
                  shop_order_id={appointment.id}
                  currentShopId={currentShopId}
                />
              </div>
            )}
          </div>
        )}
        <div className="div-card">
          <Grid celled="internally" columns={4}>
            <Grid.Row>
              <Grid.Column width={4}>
                <div className="div-left">
                  <h6 style={{ color: color }}>
                    <Icon name="clock" />- {text}
                  </h6>
                  <div>
                    {appointment && vehicle_image_url ? (
                      <div style={{ width: "80px" }}>
                        <Image src={vehicle_image_url} size="tiny" />
                      </div>
                    ) : (
                      <div style={{ width: "80px" }}>
                        <Image src={placeholderCarImage} size="small" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="personal-details">
                  <h6 style={{ color: color }}>{t("personalDetails")}</h6>
                  <Item.Group>
                    <Item>
                      <Item.Content>
                        <div className="customer-wrap">
                          <Popup
                            size="mini"
                            trigger={<p>{customer_name}</p>}
                            content={customer_name}
                            position="top center"
                          />
                        </div>
                        <Item.Description>
                          <span>
                            <b>{t("phone")} </b>
                            {formatPhoneNumber(customer_phone_number)}
                          </span>
                          <br />
                          <div className="email-wrap">
                            <span>
                              <b>{t("email")}&nbsp;</b>
                            </span>
                            <Popup
                              size="mini"
                              trigger={<p>{customer_email}</p>}
                              content={customer_email}
                              position="top center"
                            />
                          </div>
                        </Item.Description>
                      </Item.Content>
                    </Item>
                  </Item.Group>
                </div>
              </Grid.Column>
              <Grid.Column width={5}>
                <div className="div-left">
                  <h6 style={{ color: color }}>{t("shopDetails")}</h6>
                  <Item.Group>
                    <Item>
                      <div style={{ display: "block" }}>
                        <p style={{ width: "180px", height: "50px" }}>
                          {order_services
                            .filter((val, i) => i < 2)
                            .map((service) => {
                              return service.name;
                            })
                            .join(", ")}
                        </p>
                        {order_services.length > 2 && (
                          <div
                            className="view-all-button"
                            onClick={() =>
                              this.setState({
                                openDetailsModal: !openDetailsModal,
                                services: order_services,
                              })
                            }
                          >
                            {t("viewMoreServices")}
                          </div>
                        )}
                      </div>
                    </Item>
                  </Item.Group>
                </div>
                <div style={{ marginLeft: "140px", marginTop: "-18px" }}>
                  <Item.Group>
                    <Item>
                      <Item.Content>
                        <Item.Description>
                          <span style={{ color: apptTimeColor }}>
                            <b>{t("appTime")}</b> {apptTime}{" "}
                          </span>
                          <br />
                          <span>
                            <b>{t("bay")} </b>
                          </span>
                          <br />
                          <span>
                            <b>{t("mechanic")} </b>
                          </span>
                          <br />
                          <span>
                            <b>{t("repairTime")} </b>
                          </span>
                        </Item.Description>
                      </Item.Content>
                    </Item>
                  </Item.Group>
                </div>
              </Grid.Column>
              <Grid.Column width={3}>
                <div className="div-column">
                  <h6 style={{ color: color }}>{t("vehicleDetails")}</h6>
                  <Item.Group>
                    <Item>
                      <div style={{ display: "block" }}>
                        <span className="span-text">
                          <b>{t("year")}</b> {year}
                        </span>
                        <br />
                        <span className="span-text">
                          <b>{t("make")}</b> {make}
                        </span>
                        <br />
                        <span className="span-text">
                          <b>{t("model")}</b> {model}
                        </span>
                        <br />
                        <span className="span-text">
                          <b>{t("vin")}</b> {vin}
                        </span>
                      </div>
                    </Item>
                  </Item.Group>
                </div>
              </Grid.Column>
              <Grid.Column width={4}>
                <div>
                  <ProgressStatusBar status={status} t={t} />
                </div>
                <div className="action-buttons">
                  <div>
                    {status === IN_BAY || status === WAITING_FOR_PARTS ? (
                      <button
                        className="btn btn-secondary btn-x-sm"
                        onClick={() => this.onBack(appointment, currentShopId)}
                      >
                        {"<<"} {t("back")}
                      </button>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    {status === COMPLETED ||
                    status === PAID ||
                    status === PAID_AT_SHOP ||
                    status === IN_MANUAL_REVIEW ||
                    status === AWAITING_APPROVAL ||
                    status === CANCELLED ||
                    status === INITIATED_BY_USER ||
                    status === WALK_IN ? (
                      <React.Fragment></React.Fragment>
                    ) : (
                      <button
                        className="btn btn-secondary btn-x-sm"
                        style={{
                          backgroundColor: "#4E4E4E",
                          marginTop: "10px",
                        }}
                        onClick={() =>
                          this.setState({
                            openCancelModal: !openCancelModal,
                            appointment: appointment,
                          })
                        }
                      >
                        {t("discard")}
                      </button>
                    )}
                  </div>
                  {status === COMPLETED ||
                  status === PAID ||
                  status === PAID_AT_SHOP ||
                  status === IN_MANUAL_REVIEW ? (
                    <Button size="huge">{t("completed")}</Button>
                  ) : remainApptTime <= 0 &&
                    (status === INITIATED_BY_USER || status === WALK_IN) ? (
                    <Button
                      size="huge"
                      style={{ backgroundColor: "#FFC373" }}
                      onClick={() =>
                        this.setState({
                          openRescheduleModal: !openRescheduleModal,
                          appointment: appointment,
                        })
                      }
                    >
                      {t("reschedule")}
                    </Button>
                  ) : status === CANCELLED ? (
                    <Button
                      color="red"
                      style={{ backgroundColor: "#F60A0A" }}
                      size="huge"
                    >
                      {t("cancelled")}
                    </Button>
                  ) : status === USER_INITIATED_NEEDS_RESCHEDULE ||
                    status === WALK_IN_NEEDS_RESCHEDULE ||
                    status === WALK_IN_ACTIVE_NEEDS_RESCHEDULE ||
                    status === ACTIVE_NEEDS_RESCHEDULE ? (
                    <Button size="huge" style={{ backgroundColor: "#FFC373" }}>
                      {t("rescheduled")}
                    </Button>
                  ) : (
                    <Button
                      size="huge"
                      onClick={() =>
                        this.onContinue(appointment, currentShopId)
                      }
                    >
                      {t("continue")} {">>"}
                    </Button>
                  )}
                  {openRescheduleModal && (
                    <RejectReasonModal
                      open={openRescheduleModal}
                      close={this.close}
                      currentUser={currentUser}
                      currentShop={currentShop}
                      currentShopId={currentShopId}
                      appointment={appointment}
                    />
                  )}
                  {openCancelModal && (
                    <CancelReasonModal
                      open={openCancelModal}
                      close={this.close}
                      currentUser={currentUser}
                      currentShop={currentShop}
                      currentShopId={currentShopId}
                      appointment={appointment}
                    />
                  )}
                  {openDetailsModal && (
                    <ShowServicesModal
                      open={openDetailsModal}
                      close={this.close}
                      services={services}
                      t={t}
                    />
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}
