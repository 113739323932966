import React from "react";
import OrderRow from "./OrderRow";

export default class ShopOrderTable extends React.Component {
  state = {
    appointments: this.props.appointments,
  };

  render() {
    const { currentUser, dispatch, t } = this.props;
    return (
      <React.Fragment>
        <div className="div-table">
          {this.props.appointments && this.props.appointments.length > 0 ? (
            this.props.appointments.map((appts) => {
              return (
                <OrderRow
                  key={appts.id}
                  appointment={appts}
                  currentUser={currentUser}
                  currentShopId={appts.shop_id}
                  dispatch={dispatch}
                  t={t}
                />
              );
            })
          ) : (
            <div className="no-content">{t("noShopOrdersFound")}</div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
