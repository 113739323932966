import React from "react";
import { connect } from "react-redux";
import { Button, Dropdown, Form, Message, Modal } from "semantic-ui-react";
import {
  Cancellation_Reasons,
  OTHER,
  TOOK_RETAIL,
  Took_Retail_Reasons,
} from "../../constants/cancellationReasons";
import { isManager } from "../../helpers/userHelpers";
import store from "../../actions/configureStore";
import { createNote } from "../../actions/sagas/noteSaga";
import { updateOrderStatus } from "../../actions/sagas/orderSaga";
import { withTranslation } from "react-i18next";

class CancelReasonModal extends React.Component {
  state = {
    selectedReason: "",
    selectedCancellationReason: "",
    value: "",
    cancelReasonValue: "",
    isDropdownSelected: true,
    formData: {
      cancelComment: "",
      noteComment: "",
    },
    errors: {},
    selectedRetailReason: "",
    isRetailValueSelected: true,
    noteTitle: "",
  };

  onChange = (field, e) => {
    let updatedFormData = { ...this.state.formData };
    updatedFormData[field] = e.target.value;
    this.setState({
      formData: updatedFormData,
      cancelReasonValue: updatedFormData.cancelComment,
    });
  };

  setCancellationReason = (e, { value }) => {
    if (value !== TOOK_RETAIL)
      this.setState({ selectedRetailReason: "", isRetailValueSelected: true });
    else if (value !== OTHER)
      this.setState({ formData: { cancelComment: "" }, errors: {} });
    this.setState({ value }, () => {
      this.setState({
        selectedCancellationReason: value,
        isDropdownSelected: true,
        noteTitle: value,
      });
    });
  };

  formCancelDataErrors = () => {
    const { cancelComment, noteComment } = this.state.formData;
    const { appointment, currentUser } = this.props;
    const { notes } = appointment;
    const { value } = this.state;
    const errors = {};

    if (cancelComment.length === 0 && value === OTHER)
      errors.cancelComment = "Cancellation Reason is required";
    if (
      (noteComment === undefined || noteComment.length === 0) &&
      value &&
      notes.length === 0 &&
      isManager(currentUser) &&
      value !== OTHER
    )
      errors.noteComment = "Comment is required";
    return errors;
  };

  selectedRetailReason = (value) => {
    this.setState({ selectedRetailReason: value, isRetailValueSelected: true });
  };

  retailReasons = (reason) => {
    const { selectedRetailReason } = this.state;
    return (
      <div
        className="div-retail-reasons"
        style={{
          backgroundColor:
            selectedRetailReason === reason.value ? "#216ba5" : "",
          color: selectedRetailReason === reason.value ? "#fff" : "",
        }}
        onClick={() => this.selectedRetailReason(reason.value)}
      >
        {reason.value}
      </div>
    );
  };

  createNote = (id, noteTitle, comment) => {
    const { currentUser, currentShopId } = this.props;
    const { email } = currentUser;
    store.dispatch(
      createNote({
        shop_order_id: id,
        shop_id: currentShopId,
        title: noteTitle,
        comment: comment,
        created_by: email,
      })
    );
  };

  onCancel = (order) => {
    const errors = this.formCancelDataErrors();
    const { currentUser, currentShopId, appointment } = this.props;
    const { email } = currentUser;
    const { notes } = appointment;
    const notesCount = notes.length;
    const {
      value,
      selectedCancellationReason,
      isDropdownSelected,
      cancelReasonValue,
      selectedRetailReason,
      formData,
      noteTitle,
    } = this.state;
    const { cancelComment, noteComment } = formData;
    this.setState({ setCancellationReason: value, noteTitle: value });

    if (isDropdownSelected && value.length !== 0) {
      if (Object.keys(errors).length === 0) {
        if (cancelReasonValue && cancelComment !== "") {
          const payload = {
            id: order.id,
            cancellationReason: "Other... " + cancelReasonValue,
            cancelledBy: email,
            shop_id: currentShopId,
          };
          store.dispatch(updateOrderStatus({ payload: payload }));

          if (notesCount === 0 && isManager(currentUser)) {
            this.createNote(order.id, noteTitle, cancelReasonValue);
          }
        } else if (value === TOOK_RETAIL && selectedRetailReason.length !== 0) {
          const payload = {
            id: order.id,
            cancellationReason:
              selectedCancellationReason + "- " + selectedRetailReason,
            cancelledBy: email,
            shop_id: currentShopId,
          };
          store.dispatch(updateOrderStatus({ payload: payload }));

          if (notesCount === 0 && isManager(currentUser)) {
            this.createNote(order.id, noteTitle, noteComment);
          }
        } else {
          if (value === TOOK_RETAIL && selectedRetailReason.length === 0) {
            this.setState({ isRetailValueSelected: false });
          } else {
            const payload = {
              id: order.id,
              cancellationReason: selectedCancellationReason,
              cancelledBy: email,
              shop_id: currentShopId,
            };
            store.dispatch(updateOrderStatus({ payload: payload }));

            if (notesCount === 0 && isManager(currentUser)) {
              this.createNote(order.id, noteTitle, noteComment);
            }
          }
        }
      } else {
        this.setState({ errors: errors });
      }
    } else {
      this.setState({ isDropdownSelected: false });
    }
  };

  render() {
    const { open, close, appointment, currentUser, t } = this.props;
    const { notes } = appointment;
    const {
      errors,
      isDropdownSelected,
      isRetailValueSelected,
      formData,
      value,
    } = this.state;
    const { cancelComment, noteComment } = formData;
    const notesCount = notes.length;
    return (
      <Modal
        size="small"
        closeIcon
        open={open}
        onClose={() => close()}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{t("cancellingTheOrder")}</Modal.Header>
        <Modal.Content>
          <Form error>
            <Form.Field>{t("pleaseHelpUsText")}</Form.Field>
            <Form.Field>
              <Dropdown
                placeholder={t("chooseCancellationReason")}
                selection
                options={Cancellation_Reasons.map((reason) => {
                  return {
                    key: reason.key,
                    text: t(reason.text),
                    value: reason.value,
                  };
                })}
                onChange={this.setCancellationReason}
              />
              {!isDropdownSelected && (
                <Message error content={t("pleaseChooseCancellationReason")} />
              )}
            </Form.Field>
            <Form.Field>
              {value === OTHER && (
                <Form.TextArea
                  placeholder={t("enterYourCancellationReason")}
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) =>
                    e.keyCode === 32 ? e.stopPropagation() : null
                  }
                  onChange={(e) => this.onChange("cancelComment", e)}
                  value={cancelComment}
                  size="large"
                  rows={3}
                  error={errors.cancelComment}
                />
              )}
            </Form.Field>
            <Form.Field>
              {value === TOOK_RETAIL &&
                Took_Retail_Reasons.map((reason) => {
                  return (
                    <React.Fragment key={reason.key}>
                      {this.retailReasons(reason)}
                    </React.Fragment>
                  );
                })}
              {!isRetailValueSelected && (
                <Message error content={t("pleaseChooseRetailReason")} />
              )}
            </Form.Field>
            <Form.Field>
              {notesCount === 0 &&
                isManager(currentUser) &&
                value &&
                value !== OTHER && (
                  <Form.TextArea
                    placeholder={t("enterTheComment")}
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) =>
                      e.keyCode === 32 ? e.stopPropagation() : null
                    }
                    onChange={(e) => this.onChange("noteComment", e)}
                    value={noteComment}
                    size="large"
                    rows={3}
                    error={errors.noteComment}
                  />
                )}
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.onCancel(appointment)}>
            {t("cancelOrder")}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentShopId } = state.shops;
  const currentShop = state.shops.userShops.find(
    (s) => s.id === state.shops.currentShopId
  );

  return {
    isLoading: state.application.isLoading,
    currentShopId: currentShopId,
    currentUser: state.users.currentUser,
    currentShop: currentShop,
  };
};

export default connect(mapStateToProps)(
  withTranslation("modals")(CancelReasonModal)
);
