import { SplitFactory } from "@splitsoftware/splitio";
import store from "../../actions/configureStore";
import { getSubdomain } from "../shared/StylesheetInjector";

export async function setupSplitFlags(opts = {}) {
  if (
    window.cachedCartFlags &&
    Object.keys(window.cachedCartFlags).length > 0
  ) {
    this.setState({ ...this.state, ...window.cachedCartFlags });
    if (opts.callback != null) opts.callback(window.cachedCartFlags);
  } else {
    let { currentUser } = this.props;
    let result = await SplitClient.getTreatmentsWithConfig(
      [FOOTER_COMPONENT],
      currentUser,
      opts
    );

    let cachedFlags = {
      showFooterComponent: result && result[FOOTER_COMPONENT].treatment,
      footerComponentConfig:
        result && JSON.parse(result[FOOTER_COMPONENT].config),
    };

    this.setState(cachedFlags);
    if (opts.callback != null) opts.callback(cachedFlags);
    window.cachedCartFlags = cachedFlags;
  }
}

export async function getSplitClient({ customer }) {
  let env = "development";
  if (window.location.hostname.split(".")[1] === "development") env = "staging";
  else if (!window.location.hostname.match(/localhost/)) env = "production";
  let factory = SplitFactory({
    core: {
      authorizationKey: process.env.REACT_APP_BROWSER_API_KEY,
      key:
        customer && customer.id
          ? customer.id
          : process.env.REACT_APP_BROWSER_API_KEY,
    },
  });
  return new Promise((resolve, reject) => {
    if (!window.splitIOClient) window.splitIOClient = factory.client();

    if (!window.splitSDKReady)
      window.splitIOClient.on(window.splitIOClient.Event.SDK_READY, () => {
        resolve(window.splitIOClient);
        window.splitSDKReady = true;
      });
    else resolve(window.splitIOClient);
  });
}

export default class SplitClient {
  static async funcCaller(treatments, currentUser, opts, func) {
    // if (!currentUser) return
    if (!window.splitClientFlagCache) window.splitClientFlagCache = {};

    let splitClient = await getSplitClient({ customer: currentUser });

    let result = splitClient[func](treatments, {
      id: currentUser && currentUser.id,
      email: currentUser && currentUser.email,
      affiliation_id: currentUser && currentUser.affiliation_id,
      ...opts,
    });

    return result;
  }

  static async getTreatmentsWithConfig(treatments, currentUser, opts) {
    return this.funcCaller(
      treatments,
      currentUser,
      opts,
      "getTreatmentsWithConfig"
    );
  }

  static async getTreatments(treatments, currentUser, opts) {
    return this.funcCaller(treatments, currentUser, opts, "getTreatments");
  }

  static async getTreatment(treatment, currentUser, opts) {
    return await this.getTreatments([treatment], currentUser, opts);
  }
}

export const sdkConfig = {
  core: {
    authorizationKey: process.env.REACT_APP_BROWSER_API_KEY,
    key:
      store.getState().currentUser && store.getState().currentUser.id
        ? store.getState().currentUser.id
        : process.env.REACT_APP_BROWSER_API_KEY,
  },
};

export const factoryAttributes = {
  sub_domain: getSubdomain(),
};

export const ON = "on";
export const OFF = "off";
export const FOOTER_COMPONENT = "Show_Footer_Component";
