import React from "react";

const ProgressBar = (props) => {
  const {
    // isLoading = props.percent === "loading",
    percent,
    size = "small",
    showInfo = false,
    color
  } = props;
  return (
        <div className="progress-outer">
        <div
            className={`progress ${size ? "progress--" + size : ""}`}
        >
            <div className={`progress-bar`}  style={{ width: percent + "%", background: color }}></div>
        </div>

        { showInfo ? (
            <span className="progress-info">{percent}%</span>
        ) : (
            ""
        )}
        </div>
  );
};

export default ProgressBar;
