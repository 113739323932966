import { SHOW_NAV, HIDE_NAV } from "../../constants/actionTypes"

export const initialState = {
  navOpen: true
};

export default function ui(state = initialState, action) {
  switch (action.type) {
    case SHOW_NAV:
      return {
        ...state,
        navOpen: true
      }
    case HIDE_NAV:
      return {
        ...state,
        navOpen: false
      }

    default:
      return state;
  }
}
