import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import StylesheetInjector from "./components/shared/StylesheetInjector";
import { sdkConfig } from "./components/config/SplitClient";
import { SplitFactory } from "@splitsoftware/splitio-react";

StylesheetInjector();

ReactDOM.render(<App />, document.getElementById("root"));

ReactDOM.render(
  <SplitFactory config={sdkConfig}>
    <App />
  </SplitFactory>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
