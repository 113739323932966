export const RNS_SHOW_NOTIFICATION = "RNS_SHOW_NOTIFICATION"
export const RNS_HIDE_NOTIFICATION = "RNS_HIDE_NOTIFICATION"
export const RNS_REMOVE_ALL_NOTIFICATIONS = "RNS_REMOVE_ALL_NOTIFICATIONS"

export function show(options = {}, level = "success") {
  return {
    type: RNS_SHOW_NOTIFICATION,
    ...options,
    uid: options.uid || Date.now(),
    level
  }
}

const defaultOptions = {
  position: "tc",
  autoDismiss: 5
}

export function success(options) {
  return show({ ...defaultOptions, autoDismiss: 4, title: "Success!", ...options}, "success")
}

export function info(options) {
  return show({ ...defaultOptions, ...options}, "info")
}

export function warning(options) {
  return show({ ...defaultOptions, ...options}, "warning")
}

export function error(options) {
  return show({ ...defaultOptions, title: "Error", ...options}, "error")
}

export function hide(uid) {
  return {
    type: RNS_HIDE_NOTIFICATION,
    uid
  }
}

export function removeAll() {
  return { type: RNS_REMOVE_ALL_NOTIFICATIONS }
}
