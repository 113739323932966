import React from "react";
import { Button, Grid, Icon } from "semantic-ui-react";
import Switch from "react-switch";
import store from "../../actions/configureStore";
import { updateSettings } from "../../actions/sagas/userSaga";
class FlagSettings extends React.Component {
  constructor(props) {
    super(props);
    const { currentUser } = props;
    this.state = {
      isEmailChecked: currentUser.is_email || false,
      isSmsChecked: currentUser.is_sms || false,
    };
  }

  handleEmail = (checked) => {
    this.setState({ isEmailChecked: checked });
  };
  handleSms = (checked) => {
    this.setState({ isSmsChecked: checked });
  };

  updateSettings = (id) => {
    const { isEmailChecked, isSmsChecked } = this.state;
    store.dispatch(
      updateSettings({
        id: id,
        isEmail: isEmailChecked,
        isSMS: isSmsChecked,
      })
    );
  };

  render() {
    const { isEmailChecked, isSmsChecked } = this.state;
    const { currentUser, t } = this.props;
    const lang = localStorage.getItem("selectedLang");
    const { id } = currentUser;
    return (
      <div className="settings">
        <h3>{t("howYouReceiveNotification")}</h3>
        <Grid divided="vertically">
          <Grid.Row>
            <Grid.Column width={10}>
              <Icon
                name="mail outline"
                size="big"
                disabled
                className="icon-style"
              />
              <span className="lable-span">{t("email")}</span>
            </Grid.Column>
            <Grid.Column width={2}>
              <Switch
                onChange={this.handleEmail}
                checked={isEmailChecked}
                height={20}
                width={36}
                handleDiameter={12}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={10}>
              <Icon
                name="comments outline"
                size="big"
                disabled
                className="icon-style"
              />
              <span className="lable-span">{t("sms")}</span>
            </Grid.Column>
            <Grid.Column width={2}>
              <Switch
                onChange={this.handleSms}
                checked={isSmsChecked}
                height={20}
                width={36}
                handleDiameter={12}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Button
          floated="right"
          style={{
            margin: "0 55px 30px 0 ",
            width: lang === "fr" ? "140px" : "100px",
          }}
          circular
          content={t("save")}
          color="green"
          onClick={() => this.updateSettings(id)}
        />
      </div>
    );
  }
}
export default FlagSettings;
