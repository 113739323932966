import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { navigationPaths } from "../../constants/paths";
import CustomLoader from "../../components/elements/CustomLoader";
import NoSessionLayout from "../../layouts/NoSessionLayout";
import ForgotPasswordForm from "../../components/sessions/ForgotPasswordForm";
import { Icon } from "semantic-ui-react";
import store from "../../actions/configureStore";
import { forgotPassword } from "../../actions/sagas/userSaga";
import { withTranslation } from "react-i18next";

class ForgotPassword extends React.Component {
  onSubmit = (email) => {
    store.dispatch(
      forgotPassword({
        email: email,
        callback: () => {
          this.props.history.push({ pathname: navigationPaths.dashboard() });
        },
      })
    );
  };

  render() {
    const { isLoading, t } = this.props;
    return (
      <NoSessionLayout>
        {isLoading && <CustomLoader />}
        {!isLoading && (
          <div className="no-session-content">
            <div className="no-session-content-header">
              <Link
                className="forget-password-cursor-pointer"
                to={navigationPaths.signIn()}
              >
                <Icon
                  color="black"
                  name="arrow left"
                  size="large"
                  className="cursor-pointer"
                />
              </Link>
              <h1>{t("forgotPassword")}</h1>
            </div>
            <ForgotPasswordForm
              onSubmit={this.onSubmit}
              isSubmitting={isLoading}
              t={t}
            />
          </div>
        )}
      </NoSessionLayout>
    );
  }
} // ForgotPassword

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
});

export default connect(mapStateToProps, { forgotPassword })(
  withTranslation("session")(ForgotPassword)
);
