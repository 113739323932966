//Reject Reasons
export const TIME_NOT_AVAILABLE = "modals:timeNotAvailable";
export const SHOP_CLOSED_AT_THIS_TIME = "modals:shopClosedAtThisTime";
export const PARTS_NOT_AVAILABLE = "modals:partsNotAvailable";
export const SHORT_STAFFED = "modals:shortStaffed";
export const SHOP_DOES_NOT_PERFORM_THIS_SERVICE =
  "modals:shopDoesNotPerformThisService";
export const OTHER = "modals:other";

//Cancellations Reason
export const NO_SHOW = "modals:noShow";
export const A_CUSTOMER_CALLED_TO_RESCHEDULE =
  "modals:aCustomerCalledToReschedule";
export const TOOK_RETAIL = "modals:tookRetail";
export const STAFFING_ISSUE = "modals:staffingIssue";
export const COULD_NOT_PERFORM_SERVICE_DESIRED =
  "modals:couldNotPerformServiceDesired";
export const ADMIN_CANCELLED = "modals:adminCancellled";
export const PLAN_ON_RESCHEDULING = "modals:planOnRescheduling";
export const INCORRECT_SHOP_CHOSEN = "modals:incorrectShopChosen";
export const INCORRECT_SERVICE_CHOSEN = "modals:incorrectServiceChosen";
export const NA_ETC = "modals:notAvailETCMsg";

//Took Retail
export const TOOK_RETAIL_SHOP_CONFUSION_DID_BY_ACCIDENT =
  "Shop confusion, did by accident";
export const TOOK_RETAIL_THE_CUSTOMER_WANTED_TO_PAY_IN_THE_SHOP =
  "the customer wanted to pay in the shop";
export const TOOK_RETAIL_CUSTOMER_CONFUSION = "customer confusion";
export const TOOK_RETAIL_TECHNICAL_ISSUE = "technical issue";
export const TOOK_RETAIL_THE_CUSTOMER_WANTED_TO_USE_A_COUPON =
  "the customer wanted to use a coupon";

export const Reject_Reasons = [
  {
    key: TIME_NOT_AVAILABLE,
    text: TIME_NOT_AVAILABLE,
    value: TIME_NOT_AVAILABLE,
  },
  {
    key: SHOP_CLOSED_AT_THIS_TIME,
    text: SHOP_CLOSED_AT_THIS_TIME,
    value: SHOP_CLOSED_AT_THIS_TIME,
  },
  {
    key: PARTS_NOT_AVAILABLE,
    text: PARTS_NOT_AVAILABLE,
    value: PARTS_NOT_AVAILABLE,
  },
  {
    key: SHORT_STAFFED,
    text: SHORT_STAFFED,
    value: SHORT_STAFFED,
  },
  {
    key: SHOP_DOES_NOT_PERFORM_THIS_SERVICE,
    text: SHOP_DOES_NOT_PERFORM_THIS_SERVICE,
    value: SHOP_DOES_NOT_PERFORM_THIS_SERVICE,
  },
  {
    key: OTHER,
    text: OTHER,
    value: OTHER,
  },
];

export const Cancellation_Reasons = [
  {
    key: NO_SHOW,
    text: NO_SHOW,
    value: NO_SHOW,
  },
  {
    key: A_CUSTOMER_CALLED_TO_RESCHEDULE,
    text: A_CUSTOMER_CALLED_TO_RESCHEDULE,
    value: A_CUSTOMER_CALLED_TO_RESCHEDULE,
  },
  {
    key: TOOK_RETAIL,
    text: TOOK_RETAIL,
    value: TOOK_RETAIL,
  },
  // {
  //     key: TOOK_RETAIL_SHOP_CONFUSION_DID_BY_ACCIDENT,
  //     text: TOOK_RETAIL_SHOP_CONFUSION_DID_BY_ACCIDENT,
  //     value: TOOK_RETAIL_SHOP_CONFUSION_DID_BY_ACCIDENT,
  // },
  // {
  //     key: TOOK_RETAIL_THE_CUSTOMER_WANTED_TO_PAY_IN_THE_SHOP,
  //     text: TOOK_RETAIL_THE_CUSTOMER_WANTED_TO_PAY_IN_THE_SHOP,
  //     value: TOOK_RETAIL_THE_CUSTOMER_WANTED_TO_PAY_IN_THE_SHOP,
  // },
  // {
  //     key: TOOK_RETAIL_CUSTOMER_CONFUSION,
  //     text: TOOK_RETAIL_CUSTOMER_CONFUSION,
  //     value: TOOK_RETAIL_CUSTOMER_CONFUSION,
  // },
  // {
  //     key: TOOK_RETAIL_TECHNICAL_ISSUE,
  //     text: TOOK_RETAIL_TECHNICAL_ISSUE,
  //     value: TOOK_RETAIL_TECHNICAL_ISSUE,
  // },
  // {
  //     key: TOOK_RETAIL_THE_CUSTOMER_WANTED_TO_USE_A_COUPON,
  //     text: TOOK_RETAIL_THE_CUSTOMER_WANTED_TO_USE_A_COUPON,
  //     value: TOOK_RETAIL_THE_CUSTOMER_WANTED_TO_USE_A_COUPON,
  // },
  {
    key: COULD_NOT_PERFORM_SERVICE_DESIRED,
    text: COULD_NOT_PERFORM_SERVICE_DESIRED,
    value: COULD_NOT_PERFORM_SERVICE_DESIRED,
  },
  {
    key: TIME_NOT_AVAILABLE,
    text: TIME_NOT_AVAILABLE,
    value: TIME_NOT_AVAILABLE,
  },
  {
    key: SHOP_CLOSED_AT_THIS_TIME,
    text: SHOP_CLOSED_AT_THIS_TIME,
    value: SHOP_CLOSED_AT_THIS_TIME,
  },
  {
    key: STAFFING_ISSUE,
    text: STAFFING_ISSUE,
    value: STAFFING_ISSUE,
  },
  {
    key: OTHER,
    text: OTHER,
    value: OTHER,
  },
];

export const Took_Retail_Reasons = [
  {
    key: TOOK_RETAIL_SHOP_CONFUSION_DID_BY_ACCIDENT,
    text: TOOK_RETAIL_SHOP_CONFUSION_DID_BY_ACCIDENT,
    value: TOOK_RETAIL_SHOP_CONFUSION_DID_BY_ACCIDENT,
  },
  {
    key: TOOK_RETAIL_THE_CUSTOMER_WANTED_TO_PAY_IN_THE_SHOP,
    text: TOOK_RETAIL_THE_CUSTOMER_WANTED_TO_PAY_IN_THE_SHOP,
    value: TOOK_RETAIL_THE_CUSTOMER_WANTED_TO_PAY_IN_THE_SHOP,
  },
  {
    key: TOOK_RETAIL_CUSTOMER_CONFUSION,
    text: TOOK_RETAIL_CUSTOMER_CONFUSION,
    value: TOOK_RETAIL_CUSTOMER_CONFUSION,
  },
  {
    key: TOOK_RETAIL_TECHNICAL_ISSUE,
    text: TOOK_RETAIL_TECHNICAL_ISSUE,
    value: TOOK_RETAIL_TECHNICAL_ISSUE,
  },
  {
    key: TOOK_RETAIL_THE_CUSTOMER_WANTED_TO_USE_A_COUPON,
    text: TOOK_RETAIL_THE_CUSTOMER_WANTED_TO_USE_A_COUPON,
    value: TOOK_RETAIL_THE_CUSTOMER_WANTED_TO_USE_A_COUPON,
  },
];
