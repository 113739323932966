import {
  CUSTOMER_FEEDBACK_LOAD_SUCCEEDED,
  // CUSTOMER_FEEDBACK_UPDATE_SAGA,
} from "../../constants/actionTypes";

export const initialState = {
  userCustomerRatings: [],
};

export default function (state = initialState, action = {}) {
  const { payload, type } = action;
  switch (type) {
    case CUSTOMER_FEEDBACK_LOAD_SUCCEEDED:
      return {
        ...state,
        userCustomerRatings: payload.customerRatings,
      };
    // case CUSTOMER_FEEDBACK_UPDATE_SAGA:
    //   const newResponse = state.userCustomerRatings.map((rating) => {
    //     return rating.id === payload.id ? payload : rating;
    //   });
    //   return {
    //     ...state,
    //     userCustomerRatings: newResponse,
    //   };
    default:
      return state;
  }
}
