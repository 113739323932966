import combinedReducers from "./combinedReducers"

const rootReducer = (state, action) => {
  if (action.type === "SIGN_OUT_SUCCEEDED") {
    state = undefined
  }

  return combinedReducers(state, action)
}

export default rootReducer
