import React from "react";
import { connect } from "react-redux";
import * as arrayHelpers from "../../helpers/arrayHelpers";
// import NoShopImage from "../../assets/images/no-shop-selected.png";

class NoShopSelected extends React.Component {
  render() {
    const { message } = this.props;
    return (
      <div className={"no-shop-image"}>
        <p className="centered">{message}</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
  currentShop:
    arrayHelpers.findObjectById(
      state.shops.userShops,
      state.shops.currentShopId
    ) || {},
});

export default connect(mapStateToProps)(NoShopSelected);
