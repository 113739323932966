import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { navigationPaths } from "../../constants/paths";
import CustomLoader from "../../components/elements/CustomLoader";
import NoSessionLayout from "../../layouts/NoSessionLayout";
import SignInForm from "../../components/sessions/SignInForm";
import PrivacyPolicyAndTerms from "../privacy_terms/PrivacyPolicyAndTerms";
import { getSubdomain } from "../../components/shared/StylesheetInjector";
import { CARADVISE, NTWTBC } from "../../constants/application";
import { signIn } from "../../actions/sagas/sessionSaga";
import { withTranslation } from "react-i18next";
import { OFF, setupSplitFlags } from "../../components/config/SplitClient";

class SessionNew extends React.Component {
  constructor(props) {
    super(props);

    const locationStateData = this.props.location.state || {};

    const params = window.location.href.match(/key=([\w\d-]+)?&?/);
    const key = (params && params[1]) || locationStateData.key;

    if (key) {
      this.onSubmit({ key });
    }
    this.state = {};
  }

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain() });
  }

  onSubmit = (data) => {
    this.props.signIn(data, { history: this.props.history });
  };

  render() {
    const { isLoading, t } = this.props;
    const subdomain = getSubdomain();
    let lable;
    if (subdomain === CARADVISE) {
      lable = " CarAdvise ";
    } else if (subdomain === NTWTBC) {
      lable = " NTW ";
    }

    return (
      <NoSessionLayout>
        {isLoading && <CustomLoader />}
        {!isLoading && (
          <div className="no-session-content">
            <div className="no-session-content-header">
              <h1>{t("signIn")}</h1>
            </div>
            <SignInForm
              onSubmit={this.onSubmit}
              isSubmitting={isLoading}
              t={t}
            />
            <div className="no-session-content-footer">
              <div className="no-session-content-footer-highlight">
                <div className="ca-black" style={{ fontSize: "18px" }}>
                  {t("newToTheText")}
                  {`${lable}`}
                  {t("portal")}
                  <strong>
                    <Link
                      className="cursor-pointer"
                      to={navigationPaths.signUp()}
                    >
                      {t("signUp")}
                    </Link>
                  </strong>
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                {this.state.showFooterComponent &&
                  this.state.showFooterComponent === OFF && (
                    <PrivacyPolicyAndTerms t={t} subdomain={subdomain} />
                  )}
              </div>
            </div>
          </div>
        )}
      </NoSessionLayout>
    );
  }
} // SessionNew

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
  currentUser: state.users.currentUser || {},
});

export default connect(mapStateToProps, { signIn })(
  withTranslation("session")(SessionNew)
);
