import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Item,
  Modal,
  Table,
} from "semantic-ui-react";
import {
  ACTIVE,
  ACTIVE_NEEDS_RESCHEDULE,
  APPROVAL,
  AWAITING_APPROVAL,
  CANCELLED,
  CHECKED_IN,
  COMPLETED,
  DUE,
  INITIATED_BY_USER,
  IN_BAY,
  IN_MANUAL_REVIEW,
  PAID,
  PAST_DUE,
  UPCOMING,
  USER_INITIATED_NEEDS_RESCHEDULE,
  WAITING_FOR_PARTS,
  WALK_IN,
  WALK_IN_ACTIVE,
  WALK_IN_ACTIVE_NEEDS_RESCHEDULE,
  WALK_IN_NEEDS_RESCHEDULE,
} from "../../constants/actionTypes";
import {
  acceptButton,
  checkInButton,
  inBayButton,
  waitingPartsButton,
  waitingApprovalButton,
  completeButton,
} from "../../helpers/ordersHelper";
import * as arrayHelpers from "../../helpers/arrayHelpers";
import { displayDateTime } from "../../helpers/numberHelpers";
import CancelReasonModal from "../modals/CancelReasonModal";
import RejectReasonModal from "../modals/RejectReasonModal";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../constants/dateTime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartXmark, faGears } from "@fortawesome/pro-solid-svg-icons";
import _, { round } from "lodash";
import { isNationalOrRegionalOrOwner } from "../../helpers/userHelpers";

class AppointmentSummary extends React.Component {
  state = {
    openModal: false,
    openRejectModal: false,
    openCancelModal: false,
    openCancellationModal: false,
    appointment: null,
    openDetailsModal: false,
  };

  close = () =>
    this.setState({
      openModal: false,
      openRejectModal: false,
      openDetailsModal: false,
      appointment: null,
    });
  closeCancelModal = () =>
    this.setState({ openCancelModal: false, openCancellationModal: false });

  getHours = (date) => {
    const appointmentDate = new Date(
      moment(date).parseZone().format(DATE_TIME_FORMAT.MMDYYHH)
    );
    const todaysDate = new Date(
      moment(new Date()).parseZone().format(DATE_TIME_FORMAT.MMDYYHH)
    );
    let diff = (appointmentDate.getTime() - todaysDate.getTime()) / 1000;

    return (diff /= 60 * 60);
  };

  showServicesModal = (appointments) => {
    let serviceCount = appointments.service_definitions.length;
    return (
      <Modal
        size="tiny"
        dimmer="blurring"
        closeIcon
        closeOnDimmerClick={false}
        trigger={
          <Button
            size="mini"
            circular
            style={{ display: "inline-block", borderRadius: "50px" }}
          >
            <FontAwesomeIcon
              icon={faGears}
              style={{ width: "25px", height: "auto", margin: "inherit" }}
            />
            <span style={{ fontSize: "20px" }}>{serviceCount}</span>
          </Button>
        }
      >
        <Modal.Header>Services</Modal.Header>
        <Modal.Content className="scrolling content">
          <div style={{ maxHeight: "40vh" }}>
            {appointments &&
              appointments.order_services.map((service, index) => {
                return (
                  <Modal.Description key={service.id}>
                    <Item.Group divided>
                      <Item>
                        <Item.Content verticalAlign="middle">
                          <Item.Header>
                            {index + 1}. {service.name && service.name}{" "}
                            {service.position ? "- " + service.position : ""}
                          </Item.Header>
                          <Item.Description>
                            Service Price:{" "}
                            {service &&
                            service.price_estimate &&
                            service.price_estimate.shop_price_estimate
                              ? "$" +
                                round(
                                  service.price_estimate.shop_price_estimate,
                                  2
                                )
                              : service && service.override_total
                              ? "$" + round(service.override_total, 2)
                              : "--"}
                          </Item.Description>
                        </Item.Content>
                      </Item>
                    </Item.Group>
                    <br />
                  </Modal.Description>
                );
              })}
          </div>
        </Modal.Content>
      </Modal>
    );
  };

  render() {
    const { appointments, currentShop, currentUser, dispatch, t } = this.props;
    const {
      openModal,
      openRejectModal,
      openCancelModal,
      openCancellationModal,
      appointment,
    } = this.state;
    const upcomingAppointments = _.uniqBy(appointments, "id").filter(
      (appts) => appts.status === INITIATED_BY_USER || appts.status === WALK_IN
    );
    const activeAppointments = _.uniqBy(appointments, "id").filter(
      (order) =>
        order.status === ACTIVE ||
        order.status === WALK_IN_ACTIVE ||
        order.status === CHECKED_IN ||
        order.status === IN_BAY ||
        order.status === PAID ||
        order.status === WAITING_FOR_PARTS ||
        order.status === AWAITING_APPROVAL ||
        order.status === APPROVAL ||
        order.status === COMPLETED ||
        order.status === IN_MANUAL_REVIEW ||
        order.status === CANCELLED
    );
    const rescheduleAppointments = _.uniqBy(appointments, "id").filter(
      (appts) =>
        appts.status === USER_INITIATED_NEEDS_RESCHEDULE ||
        appts.status === WALK_IN_ACTIVE_NEEDS_RESCHEDULE ||
        appts.status === ACTIVE_NEEDS_RESCHEDULE ||
        appts.status === WALK_IN_NEEDS_RESCHEDULE
    );

    return (
      <Card fluid>
        <Card.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <div className="d-flex">
                  <h3 className="table-header">{t("upcomingAppointments")}</h3>
                  <div className="badge">{upcomingAppointments.length}</div>
                </div>
                {/* <p>Some appointments need to be confirmed</p>
                                <b>{upcomingAppointments.length} scheduled appointments might not transact.</b> */}
              </Grid.Column>
              <Grid.Column width={13}>
                <div className="dash-table">
                  <Table unstackable striped compact selectable>
                    <Table.Header>
                      <Table.Row>
                        {currentUser &&
                          currentUser.shop_user_shops &&
                          currentUser.shop_user_shops.length > 0 &&
                          isNationalOrRegionalOrOwner(currentUser) && (
                            <Table.HeaderCell width={3}>
                              {t("shopName")}
                            </Table.HeaderCell>
                          )}
                        <Table.HeaderCell width={2}>
                          {t("customerText")}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          {t("services")}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          {t("date&Time")}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          {t("status")}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          {t("actionText")}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {upcomingAppointments.length > 0 ? (
                        upcomingAppointments.map((appts) => {
                          const serviceCount = appts.service_definitions.length;
                          return (
                            <Table.Row key={appts.id}>
                              {currentUser &&
                                currentUser.shop_user_shops &&
                                currentUser.shop_user_shops.length > 0 &&
                                isNationalOrRegionalOrOwner(currentUser) && (
                                  <Table.Cell>
                                    <b>{appts.appt_accepted_by_shop}</b>
                                  </Table.Cell>
                                )}
                              <Table.Cell>
                                <p>
                                  {" "}
                                  <b>{appts.customer_name}</b>
                                </p>
                                <p>
                                  <b>{t("vin")} </b>
                                  {appts.vin}
                                </p>
                              </Table.Cell>
                              <Table.Cell>
                                {serviceCount === 0 ? (
                                  <Button
                                    circular
                                    onClick={() =>
                                      this.setState({
                                        openDetailsModal: !this.state
                                          .openDetailsModal,
                                      })
                                    }
                                  >
                                    <Icon.Group size="large">
                                      <Icon name="cog" />
                                      <Icon corner="top right" name="plus" />
                                    </Icon.Group>
                                  </Button>
                                ) : (
                                  this.showServicesModal(appts)
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {displayDateTime(
                                  appts.appointment_datetime_pretty
                                )}
                              </Table.Cell>
                              {this.getHours(appts.appointment_datetime) >=
                              2 ? (
                                <Table.Cell>{t("upcoming")}</Table.Cell>
                              ) : this.getHours(appts.appointment_datetime) <
                                0 ? (
                                <Table.Cell>{t("pastDue")}</Table.Cell>
                              ) : this.getHours(appts.appointment_datetime) <=
                                  2 &&
                                this.getHours(appts.appointment_datetime) >=
                                  0 ? (
                                <Table.Cell>{t("due")}</Table.Cell>
                              ) : (
                                <Table.Cell></Table.Cell>
                              )}
                              <Table.Cell textAlign="center">
                                <Dropdown
                                  className="icon"
                                  button
                                  icon="angle down"
                                  style={{
                                    color: "white",
                                    backgroundColor: "grey",
                                    borderRadius: "50px",
                                  }}
                                  text={t("acceptOrReject")}
                                  floating
                                >
                                  <Dropdown.Menu>
                                    <React.Fragment>
                                      {acceptButton(
                                        appts,
                                        appts.shop_id,
                                        dispatch
                                      )}
                                      <Button
                                        style={{ margin: "5px", width: "90%" }}
                                        size="small"
                                        compact
                                        negative
                                        onClick={() =>
                                          this.setState({
                                            openModal: !openModal,
                                            appointment: appts,
                                          })
                                        }
                                      >
                                        <Icon name="delete" />
                                        {t("rejectText")}
                                      </Button>
                                      <Button
                                        style={{
                                          margin: "5px",
                                          width: "90%",
                                          height: "28px",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        size="small"
                                        compact
                                        negative
                                        onClick={() =>
                                          this.setState({
                                            openCancelModal: !openCancelModal,
                                            appointment: appts,
                                          })
                                        }
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "6px",
                                            margin: "0 5px",
                                          }}
                                          icon={faCartXmark}
                                        />
                                        {t("cancelOrder")}
                                      </Button>
                                    </React.Fragment>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })
                      ) : (
                        <Table.Row>
                          <Table.Cell>
                            <div className="no-content">
                              {t("noRecordsFound")}
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>
                  {openModal && (
                    <RejectReasonModal
                      open={openModal}
                      close={this.close}
                      currentUser={currentUser}
                      currentShop={currentShop}
                      currentShopId={appointment.shop_id}
                      appointment={appointment}
                    />
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden style={{ height: "20px" }} />
            <Grid.Row>
              <Grid.Column width={8}>
                <div className="d-flex">
                  <h3 className="table-header">{t("activeOrders")}</h3>
                  <div className="badge">{activeAppointments.length}</div>
                </div>

                {/* <p>Some orders needs resolution</p>
                                <b>{activeAppointments.length} orders might not transact.</b> */}
              </Grid.Column>
              <Grid.Column width={13}>
                <div className="dash-table">
                  <Table unstackable striped compact selectable>
                    <Table.Header>
                      <Table.Row>
                        {currentUser &&
                          currentUser.shop_user_shops &&
                          currentUser.shop_user_shops.length > 0 &&
                          isNationalOrRegionalOrOwner(currentUser) && (
                            <Table.HeaderCell width={3}>
                              {t("shopName")}
                            </Table.HeaderCell>
                          )}
                        <Table.HeaderCell width={2}>
                          {t("customerText")}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          {t("services")}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          {t("date&Time")}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          {t("status")}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          {t("actionText")}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {activeAppointments.length > 0 ? (
                        activeAppointments.map((order) => {
                          let color =
                            order.status === ACTIVE ||
                            order.status === WALK_IN_ACTIVE ||
                            order.status === CHECKED_IN ||
                            order.status === IN_BAY ||
                            order.status === WAITING_FOR_PARTS ||
                            order.status === AWAITING_APPROVAL ||
                            order.status === APPROVAL ||
                            order.status === COMPLETED ||
                            order.status === PAID ||
                            order.status === IN_MANUAL_REVIEW ||
                            order.status === APPROVAL
                              ? "green"
                              : order.status === CANCELLED
                              ? "red"
                              : "grey";
                          let text =
                            order.status === ACTIVE ||
                            order.status === WALK_IN_ACTIVE
                              ? t("accepted")
                              : order.status === CHECKED_IN
                              ? t("checkedIn")
                              : order.status === IN_BAY
                              ? t("inBay")
                              : order.status === WAITING_FOR_PARTS
                              ? t("waitingForParts")
                              : order.status === AWAITING_APPROVAL
                              ? t("waitingForApproval")
                              : order.status === APPROVAL
                              ? t("approval")
                              : order.status === IN_MANUAL_REVIEW
                              ? t("completed")
                              : order.status === PAID
                              ? t("completed")
                              : order.status === CANCELLED
                              ? t("cancelled")
                              : t("acceptOrReject");
                          const serviceCount = order.service_definitions.length;
                          return (
                            <Table.Row key={order.id}>
                              {currentUser &&
                                currentUser.shop_user_shops &&
                                currentUser.shop_user_shops.length > 0 &&
                                isNationalOrRegionalOrOwner(currentUser) && (
                                  <Table.Cell>
                                    <b>{order.appt_accepted_by_shop}</b>
                                  </Table.Cell>
                                )}
                              <Table.Cell>
                                <p>
                                  {" "}
                                  <b>{order.customer_name}</b>
                                </p>
                                <p>
                                  <b>{t("vin")} </b>
                                  {order.vin}
                                </p>
                              </Table.Cell>
                              <Table.Cell>
                                {serviceCount === 0 ? (
                                  <Button
                                    circular
                                    onClick={() =>
                                      this.setState({
                                        openDetailsModal: !this.state
                                          .openDetailsModal,
                                      })
                                    }
                                  >
                                    <Icon.Group size="large">
                                      <Icon name="cog" />
                                      <Icon corner="top right" name="plus" />
                                    </Icon.Group>
                                  </Button>
                                ) : (
                                  this.showServicesModal(order)
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {displayDateTime(
                                  order.appointment_datetime_pretty
                                )}
                              </Table.Cell>
                              {this.getHours(order.appointment_datetime) > 2 ? (
                                <Table.Cell>{t("upcoming")}</Table.Cell>
                              ) : this.getHours(order.appointment_datetime) <
                                0 ? (
                                <Table.Cell>{t("pastDue")}</Table.Cell>
                              ) : this.getHours(order.appointment_datetime) <
                                  2 &&
                                this.getHours(order.appointment_datetime) >=
                                  0 ? (
                                <Table.Cell>{t("due")}</Table.Cell>
                              ) : (
                                ""
                              )}
                              <Table.Cell textAlign="center">
                                {order.status === COMPLETED ||
                                order.status === IN_MANUAL_REVIEW ||
                                order.status === PAID ? (
                                  <Button
                                    style={{
                                      margin: "5px",
                                      color: "white",
                                      backgroundColor: color,
                                      borderRadius: "20px",
                                      width: "200px",
                                      textAlign: "center",
                                    }}
                                    size="small"
                                  >
                                    {t("completed")}
                                  </Button>
                                ) : order.status === CANCELLED ? (
                                  <Button
                                    style={{
                                      margin: "5px",
                                      color: "white",
                                      backgroundColor: color,
                                      borderRadius: "20px",
                                      width: "200px",
                                      textAlign: "center",
                                    }}
                                    size="small"
                                  >
                                    {t("cancelled")}
                                  </Button>
                                ) : (
                                  <Dropdown
                                    className="icon"
                                    button
                                    icon="angle down"
                                    style={{
                                      color: "white",
                                      backgroundColor: color,
                                      borderRadius: "20px",
                                      width: "200px",
                                      textAlign: "center",
                                    }}
                                    text={text}
                                    floating
                                  >
                                    <Dropdown.Menu>
                                      {order.status === ACTIVE ||
                                      order.status === WALK_IN_ACTIVE ? (
                                        <React.Fragment>
                                          {checkInButton(
                                            order,
                                            order.shop_id,
                                            dispatch
                                          )}
                                          {inBayButton(
                                            order,
                                            order.shop_id,
                                            dispatch
                                          )}
                                          {waitingPartsButton(
                                            order,
                                            order.shop_id,
                                            dispatch
                                          )}
                                          {waitingApprovalButton(
                                            order,
                                            order.shop_id,
                                            dispatch
                                          )}
                                          <Button
                                            style={{
                                              margin: "5px",
                                              width: "90%",
                                            }}
                                            size="small"
                                            compact
                                            negative
                                            onClick={() =>
                                              this.setState({
                                                openModal: !openModal,
                                                appointment: order,
                                              })
                                            }
                                          >
                                            <Icon name="delete" />
                                            {t("rejectText")}
                                          </Button>
                                          <Button
                                            style={{
                                              margin: "5px",
                                              width: "90%",
                                            }}
                                            size="small"
                                            compact
                                            negative
                                            onClick={() =>
                                              this.setState({
                                                openCancelModal: !openCancelModal,
                                                appointment: order,
                                              })
                                            }
                                          >
                                            <Icon name="delete" />
                                            {t("cancelOrder")}
                                          </Button>
                                        </React.Fragment>
                                      ) : order.status === CHECKED_IN ? (
                                        <React.Fragment>
                                          {inBayButton(
                                            order,
                                            order.shop_id,
                                            dispatch
                                          )}
                                          {waitingPartsButton(
                                            order,
                                            order.shop_id,
                                            dispatch
                                          )}
                                          {waitingApprovalButton(
                                            order,
                                            order.shop_id,
                                            dispatch
                                          )}
                                          {completeButton(
                                            order,
                                            order.shop_id,
                                            dispatch
                                          )}
                                          <Button
                                            style={{
                                              margin: "5px",
                                              width: "90%",
                                            }}
                                            size="small"
                                            compact
                                            negative
                                            onClick={() =>
                                              this.setState({
                                                openCancelModal: !openCancelModal,
                                                appointment: order,
                                              })
                                            }
                                          >
                                            <Icon name="delete" />
                                            {t("cancelOrder")}
                                          </Button>
                                        </React.Fragment>
                                      ) : order.status === IN_BAY ? (
                                        <React.Fragment>
                                          {checkInButton(
                                            order,
                                            order.shop_id,
                                            dispatch
                                          )}
                                          {waitingPartsButton(
                                            order,
                                            order.shop_id,
                                            dispatch
                                          )}
                                          {waitingApprovalButton(
                                            order,
                                            order.shop_id,
                                            dispatch
                                          )}
                                          {completeButton(
                                            order,
                                            order.shop_id,
                                            dispatch
                                          )}
                                          <Button
                                            style={{
                                              margin: "5px",
                                              width: "90%",
                                            }}
                                            size="small"
                                            compact
                                            negative
                                            onClick={() =>
                                              this.setState({
                                                openCancelModal: !openCancelModal,
                                                appointment: order,
                                              })
                                            }
                                          >
                                            <Icon name="delete" />
                                            {t("cancelOrder")}
                                          </Button>
                                        </React.Fragment>
                                      ) : order.status === WAITING_FOR_PARTS ? (
                                        <React.Fragment>
                                          {checkInButton(
                                            order,
                                            order.shop_id,
                                            dispatch
                                          )}
                                          {inBayButton(
                                            order,
                                            order.shop_id,
                                            dispatch
                                          )}
                                          {waitingApprovalButton(
                                            order,
                                            order.shop_id,
                                            dispatch
                                          )}
                                          {completeButton(
                                            order,
                                            order.shop_id,
                                            dispatch
                                          )}
                                          <Button
                                            style={{
                                              margin: "5px",
                                              width: "90%",
                                            }}
                                            size="small"
                                            compact
                                            negative
                                            onClick={() =>
                                              this.setState({
                                                openCancelModal: !openCancelModal,
                                                appointment: order,
                                              })
                                            }
                                          >
                                            <Icon name="delete" />
                                            {t("cancelOrder")}
                                          </Button>
                                        </React.Fragment>
                                      ) : order.status === AWAITING_APPROVAL ? (
                                        <React.Fragment>
                                          {inBayButton(
                                            order,
                                            order.shop_id,
                                            dispatch
                                          )}
                                          {waitingPartsButton(
                                            order,
                                            order.shop_id,
                                            dispatch
                                          )}
                                          <Button
                                            style={{
                                              margin: "5px",
                                              width: "90%",
                                            }}
                                            size="small"
                                            compact
                                            negative
                                            onClick={() =>
                                              this.setState({
                                                openCancelModal: !openCancelModal,
                                                appointment: order,
                                              })
                                            }
                                          >
                                            <Icon name="delete" />
                                            {t("cancelOrder")}
                                          </Button>
                                        </React.Fragment>
                                      ) : order.status === APPROVAL ? (
                                        <React.Fragment>
                                          {completeButton(
                                            order,
                                            order.shop_id,
                                            dispatch
                                          )}
                                          <Button
                                            style={{
                                              margin: "5px",
                                              width: "90%",
                                            }}
                                            size="small"
                                            compact
                                            negative
                                            onClick={() =>
                                              this.setState({
                                                openCancelModal: !openCancelModal,
                                                appointment: order,
                                              })
                                            }
                                          >
                                            <Icon name="delete" />
                                            {t("cancelOrder")}
                                          </Button>
                                        </React.Fragment>
                                      ) : (
                                        ""
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                )}
                              </Table.Cell>
                            </Table.Row>
                          );
                        })
                      ) : (
                        <Table.Row>
                          <Table.Cell>
                            <div className="no-content">
                              {t("noRecordsFound")}
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      )}
                      {openRejectModal && (
                        <RejectReasonModal
                          open={openRejectModal}
                          close={this.close}
                          currentUser={currentUser}
                          currentShop={currentShop}
                          currentShopId={appointment.shop_id}
                          appointment={appointment}
                        />
                      )}
                      {openCancelModal && (
                        <CancelReasonModal
                          open={openCancelModal}
                          close={this.closeCancelModal}
                          currentUser={currentUser}
                          currentShop={currentShop}
                          currentShopId={appointment.shop_id}
                          appointment={appointment}
                        />
                      )}
                    </Table.Body>
                  </Table>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden style={{ height: "20px" }} />
            <Grid.Row>
              <Grid.Column width={8}>
                <div className="d-flex">
                  <h3 className="table-header">
                    {t("expiredRejectedAppointments")}
                  </h3>
                  <div className="badge">{rescheduleAppointments.length}</div>
                </div>
                {/* <p>Some appointments needs resolution</p>
                                <b>{rescheduleAppointments.length} appointment(s) might not transact.</b> */}
              </Grid.Column>
              <Grid.Column width={13}>
                <div className="dash-table">
                  <Table unstackable striped compact selectable>
                    <Table.Header>
                      <Table.Row>
                        {currentUser &&
                          currentUser.shop_user_shops &&
                          currentUser.shop_user_shops.length > 0 &&
                          isNationalOrRegionalOrOwner(currentUser) && (
                            <Table.HeaderCell width={3}>
                              {t("shopName")}
                            </Table.HeaderCell>
                          )}
                        <Table.HeaderCell width={2}>
                          {t("customerText")}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          {t("services")}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          {t("date&Time")}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          {t("reasonText")}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          {t("actionText")}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {rescheduleAppointments.length > 0 ? (
                        rescheduleAppointments.map((appts) => {
                          const serviceCount = appts.service_definitions.length;
                          return (
                            <Table.Row key={appts.id}>
                              {currentUser &&
                                currentUser.shop_user_shops &&
                                currentUser.shop_user_shops.length > 0 &&
                                isNationalOrRegionalOrOwner(currentUser) && (
                                  <Table.Cell>
                                    <b>{appts.appt_accepted_by_shop}</b>
                                  </Table.Cell>
                                )}
                              <Table.Cell>
                                <p>
                                  {" "}
                                  <b>{appts.customer_name}</b>
                                </p>
                                <p>
                                  <b>{t("vin")} </b>
                                  {appts.vin}
                                </p>
                              </Table.Cell>
                              <Table.Cell>
                                {serviceCount === 0 ? (
                                  <Button
                                    circular
                                    onClick={() =>
                                      this.setState({
                                        openDetailsModal: !this.state
                                          .openDetailsModal,
                                      })
                                    }
                                  >
                                    <Icon.Group size="large">
                                      <Icon name="cog" />
                                      <Icon corner="top right" name="plus" />
                                    </Icon.Group>
                                  </Button>
                                ) : (
                                  this.showServicesModal(appts)
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {displayDateTime(
                                  appts.appointment_datetime_pretty
                                )}
                              </Table.Cell>
                              <Table.Cell singleLine={false}>
                                {appts.appt_cancel_reason}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                <Dropdown
                                  className="icon"
                                  button
                                  icon="angle down"
                                  style={{
                                    color: "white",
                                    backgroundColor: "red",
                                    borderRadius: "50px",
                                  }}
                                  text={t("rejectedStatus")}
                                  floating
                                >
                                  <Dropdown.Menu>
                                    <React.Fragment>
                                      {acceptButton(
                                        appts,
                                        appts.shop_id,
                                        dispatch
                                      )}
                                      <Button
                                        style={{ margin: "5px", width: "90%" }}
                                        size="small"
                                        compact
                                        negative
                                        onClick={() =>
                                          this.setState({
                                            openCancellationModal: !openCancellationModal,
                                            appointment: appts,
                                          })
                                        }
                                      >
                                        <Icon name="delete" />
                                        {t("cancelOrder")}
                                      </Button>
                                    </React.Fragment>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })
                      ) : (
                        <Table.Row>
                          <Table.Cell>
                            <div className="no-content">
                              {t("noRecordsFound")}
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>
                  {openCancellationModal && (
                    <CancelReasonModal
                      open={openCancellationModal}
                      close={this.closeCancelModal}
                      currentUser={currentUser}
                      currentShop={currentShop}
                      currentShopId={appointment.shop_id}
                      appointment={appointment}
                    />
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden style={{ height: "20px" }} />
          </Grid>
        </Card.Content>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
  currentShop:
    arrayHelpers.findObjectById(
      state.shops.userShops,
      state.shops.currentShopId
    ) || {},
});

export default connect(mapStateToProps)(AppointmentSummary);
