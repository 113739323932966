import React from "react";
import { Item } from "semantic-ui-react";
import { CARADVISE_SUPPORT_NUMBER } from "../../constants/application";
import { formatPhoneNumber } from "../../helpers/numberHelpers";

export default class Clock extends React.Component {
  constructor(props) {
    super(props);
    var time = this.getTimeString();
    var date = this.getDateString();
    this.state = {
      time: time,
      date: date,
    };
  }
  getTimeString() {
    const time = new Intl.DateTimeFormat("en-US", {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    }).format(new Date());

    return time;
  }

  getDateString() {
    const date = new Intl.DateTimeFormat("en-US", {
      month: "numeric",
      day: "2-digit",
      year: "2-digit",
    }).format(new Date());
    return date;
  }

  componentDidMount() {
    const _this = this;
    this.timer = setInterval(function () {
      var time = _this.getTimeString();
      var date = _this.getDateString();
      _this.setState({
        time: time,
        date: date,
      });
    }, 1000);
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  render() {
    const {
      currentShop,
      shops,
      isNationalOrRegionalOrOwnerFlag,
      t,
    } = this.props;
    const { date, time } = this.state;
    return (
      <div className="div-clock">
        <span style={{ fontSize: "20px" }}>{time}</span>
        <span
          style={{ fontSize: "30px", marginRight: "10px", marginLeft: "10px" }}
        >
          |
        </span>
        <span style={{ fontSize: "20px" }}>{date}</span>

        <div>
          <h6>{t("quickContact")}</h6>
          <Item.Group>
            <Item>
              <div style={{ display: "block" }}>
                {currentShop && isNationalOrRegionalOrOwnerFlag ? (
                  <>
                    <h4>{t("shopOwner")}</h4>
                    <p style={{ margin: "0", width: "100%" }}>
                      {currentShop.owner_name} -{" "}
                      {formatPhoneNumber(currentShop.main_phone_number)}
                    </p>
                  </>
                ) : shops && shops[0] ? (
                  <>
                    <h4>{t("shopOwner")}</h4>
                    <p style={{ margin: "0", width: "100%" }}>
                      {shops[0].owner_name} -{" "}
                      {formatPhoneNumber(shops[0].main_phone_number)}
                    </p>
                  </>
                ) : (
                  <div />
                )}
                <h4>{t("carAdviseSupport")}</h4>
                <p>{formatPhoneNumber(CARADVISE_SUPPORT_NUMBER)}</p>
              </div>
            </Item>
          </Item.Group>
        </div>
      </div>
    );
  }
}
