import {
  OWNER_ROLE,
  MANAGER_ROLE,
  COMPANY_OWNER,
  NATIONAL_MANAGER,
  REGIONAL_MANAGER,
} from "../constants/userRoles";
import _ from "lodash";

export function isOwnerOrManagerOrCompanyOwner(user) {
  if (_.isEmpty(user) && !user.role_name) return false;

  return [
    OWNER_ROLE,
    MANAGER_ROLE,
    COMPANY_OWNER,
    NATIONAL_MANAGER,
    REGIONAL_MANAGER,
  ].includes(user.role_name);
}

export function isManager(user) {
  if (_.isEmpty(user) && !user.role_name) return false;

  return [MANAGER_ROLE].includes(user.role_name);
}

export function isShopOwnerOrCompanyOwner(user) {
  if (_.isEmpty(user) && !user.role_name) return false;

  return (
    user.role_name &&
    [OWNER_ROLE, NATIONAL_MANAGER, REGIONAL_MANAGER, COMPANY_OWNER].includes(
      user.role_name
    )
  );
}

export function isNotShopBasicUser(user) {
  if (_.isEmpty(user) && !user.role_name) return false;

  return (
    user.role_name &&
    [
      OWNER_ROLE,
      NATIONAL_MANAGER,
      REGIONAL_MANAGER,
      COMPANY_OWNER,
      MANAGER_ROLE,
    ].includes(user.role_name)
  );
}

export function isNationalOrRegionalOrOwner(user) {
  if (_.isEmpty(user) && !user.role_name) return false;
  return (
    user.role_name &&
    [OWNER_ROLE, NATIONAL_MANAGER, REGIONAL_MANAGER, COMPANY_OWNER].includes(
      user.role_name
    )
  );
}
