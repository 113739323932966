import {
    COMMON_SERVICES_LOAD_SUCCEEDED
  } from "../../constants/actionTypes"
  
  export const initialState = {
    commonServices: [],
  }
  
  export default function(state=initialState, action = {}) {
    const { type, payload } = action
  
    switch(type) {
      case COMMON_SERVICES_LOAD_SUCCEEDED:
        return {
          ...state,
          commonServices: payload.commonServices
        }
      default:
        return state
    }
  }
  