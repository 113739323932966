import React from "react";
import { connect } from "react-redux";

import CustomLoader from "../elements/CustomLoader";
import {
  Button,
  Card,
  Grid,
  Icon,
  Image,
  Input,
  Modal,
  Popup,
} from "semantic-ui-react";
import AddPromotionModal from "./AddPromotionModal";
import {
  isNationalOrRegionalOrOwner,
  isNotShopBasicUser,
} from "../../helpers/userHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../constants/dateTime";
import {
  handleDownload,
  handleDownloadAll,
} from "../../helpers/documentHelpers";
import _, { sortBy } from "lodash";
import { CASE_INSENSITIVE } from "../../constants/application";
import { loadShopsPromotions } from "../../actions/sagas/promotionSaga";
import { withTranslation } from "react-i18next";

class Promotions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      openAddPromotionModal: false,
      promotions: [],
    };
  }

  componentDidMount() {
    this.props.loadShopsPromotions();
  }

  handleModalClose = () => {
    this.setState({ openAddPromotionModal: false });
  };

  addPromotionGrid = () => {
    const { promotions, t } = this.props;
    return (
      <div className="promotion-card">
        <div className={"add-promo-card"}>
          <Icon
            style={{ cursor: "pointer" }}
            circular
            name="plus"
            size="big"
            onClick={() =>
              this.setState({
                openAddPromotionModal: !this.state.openAddPromotionModal,
              })
            }
          />
          <h4> {t("newPromotion")} </h4>
        </div>
      </div>
    );
  };

  showSavedPromotions = (documents) => {
    const { t } = this.props;
    let files = [];
    if (documents && documents.length > 0) {
      documents.forEach((doc) => {
        files.push(doc);
      });
    }
    return (
      <Modal
        size="small"
        dimmer="blurring"
        closeIcon
        closeOnDimmerClick={false}
        trigger={
          <div className="file-actions">
            <a
              // href={document.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("previewAll")}
            </a>
          </div>
        }
      >
        <Modal.Header>
          {t("promotionsDocuments")}
          <button
            type="button"
            className="download-all-btn"
            onClick={() => handleDownloadAll(files)}
          >
            {t("downloadAll")}
          </button>
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description key={document.id}>
            <Grid columns={3} doubling>
              {documents &&
                documents.map((document, index) => {
                  const fileName =
                    document.url &&
                    new URL(document.url).pathname.split("/").pop();
                  let file = [];
                  file.push(document);
                  return (
                    <Grid.Column key={document.index}>
                      <div className="invoice-documents">
                        <div className="invoice-document-name">
                          <span style={{ marginRight: "10px" }}>
                            {index + 1}.
                          </span>
                          <span>{fileName}</span>
                        </div>
                        <div className="file-atc-box" key={index}>
                          {fileName.match(/.(jpg|jpeg|png|jfif)$/i) ? (
                            <div className="file-image">
                              <Image
                                src={document.url}
                                alt=""
                                crossOrigin="anonymous"
                              />
                            </div>
                          ) : (
                            <div className="file-image">
                              <FontAwesomeIcon icon={faFileAlt} />
                            </div>
                          )}
                          <div className="file-detail">
                            <div className="file-actions">
                              <button
                                type="button"
                                className="file-action-btn"
                                onClick={() => handleDownload(file)}
                              >
                                {t("download")}
                              </button>
                            </div>
                            <div className="preview-link">
                              <a
                                href={document.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {t("preview")}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid.Column>
                  );
                })}
            </Grid>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  };

  showDocuments = (promo) => {
    const { t } = this.props;
    let fileName;
    let file = [];
    if (
      promo.promotion_material_images &&
      promo.promotion_material_images.length === 1
    ) {
      fileName = new URL(promo.promotion_material_images[0].url).pathname
        .split("/")
        .pop();
      return (
        <>
          {fileName && fileName.match(/.(jpg|jpeg|png|jfif)$/i) ? (
            <div className="file-image">
              <Image
                src={promo.promotion_material_images[0].url}
                alt=""
                crossOrigin="anonymous"
              />
            </div>
          ) : (
            <div className="file-image">
              <FontAwesomeIcon icon={faFileAlt} />
            </div>
          )}
          <div className="file-detail">
            <div className="file-name">
              <Popup content={fileName} trigger={<h5>{fileName}</h5>} />
            </div>
            <div className="file-actions">
              <button
                type="button"
                className="file-action-btn"
                onClick={() => handleDownload(promo.promotion_material_images)}
              >
                {t("download")}
              </button>
            </div>
            <div className="preview-link">
              <a
                href={promo.promotion_material_images[0].url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("preview")}
              </a>
            </div>
          </div>
        </>
      );
    } else {
      let files = [];
      if (
        promo.promotion_material_images &&
        promo.promotion_material_images.length > 0
      ) {
        promo.promotion_material_images.forEach((file) => {
          files.push(file);
        });
      }
      return (
        <React.Fragment>
          <div className="file-image">
            <FontAwesomeIcon icon={faFileAlt} />
          </div>
          <div className="files-detail">
            <div className="file-name">
              <h5>{files.length} Promotion Files</h5>
              <div className="file-actions"></div>
              <button
                type="button"
                className="file-action-btn"
                onClick={() => handleDownloadAll(files)}
              >
                {t("downloadAll")}
              </button>
              {this.showSavedPromotions(files)}
            </div>
          </div>
        </React.Fragment>
      );
    }
  };

  handleSearch(data, searchString) {
    let searchValue = searchString.trim().toLowerCase();
    return data.filter(
      (e) =>
        (e.promotion_name &&
          e.promotion_name
            .toLowerCase()
            .match(new RegExp(searchValue, CASE_INSENSITIVE))) ||
        (e.created_at &&
          moment(e.created_at)
            .format(DATE_TIME_FORMAT.LLL)
            .toLowerCase()
            .match(new RegExp(searchValue, CASE_INSENSITIVE))) ||
        (e.shop &&
          e.shop.name
            .toLowerCase()
            .match(new RegExp(searchValue, CASE_INSENSITIVE))) ||
        (e.shop &&
          e.shop.store_number
            .toLowerCase()
            .match(new RegExp(searchValue, CASE_INSENSITIVE)))
    );
  }

  render() {
    const {
      isLoading,
      currentUser,
      shops,
      dispatch,
      promotions,
      currentShopId,
      t,
    } = this.props;
    const { openAddPromotionModal } = this.state;
    const { search } = this.state;
    const filteredPromotions =
      currentShopId !== null && currentShopId !== "null"
        ? promotions &&
          _.uniqBy(promotions, "id").filter(
            (app) => app.shop_id === currentShopId
          )
        : _.uniqBy(promotions, "id");
    const role = isNationalOrRegionalOrOwner(currentUser);
    return (
      <div className="full-promo-container">
        {isLoading && <CustomLoader />}
        {!isLoading && (
          <React.Fragment>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                margin: "14px 20px 30px 0",
              }}
            >
              <Input
                icon="search"
                placeholder={t("search")}
                style={{ marginRight: "20px" }}
                onChange={(e) => this.setState({ search: e.target.value })}
              />
              {isNotShopBasicUser(currentUser) && (
                <Button
                  circular
                  onClick={() =>
                    this.setState({
                      openAddPromotionModal: !this.state.openAddPromotionModal,
                    })
                  }
                >
                  {t("addNewPromotion")}
                </Button>
              )}
              {openAddPromotionModal && (
                <AddPromotionModal
                  open={openAddPromotionModal}
                  close={this.handleModalClose}
                  currentUser={currentUser}
                  shops={shops}
                  dispatch={dispatch}
                  t={t}
                />
              )}
            </div>
            {promotions &&
            promotions.length === 0 &&
            isNotShopBasicUser(currentUser) ? (
              <div style={{ textAlign: "center" }}>
                <div className="add-promo">{this.addPromotionGrid()}</div>
              </div>
            ) : (
              !isNotShopBasicUser(currentUser) &&
              promotions.length === 0 && (
                <div style={{ textAlign: "center" }}>
                  {t("noShopPromotionsAvailable")}
                </div>
              )
            )}
            <Grid columns={3} stackable className="promotions-list">
              {filteredPromotions &&
                filteredPromotions.length > 0 &&
                sortBy(
                  this.handleSearch(filteredPromotions, search),
                  "created_at"
                )
                  .reverse()
                  .map((promo, index) => {
                    const shop = promo.shop && promo.shop;
                    const updatedTime = moment(promo.updated_at).format(
                      DATE_TIME_FORMAT.YYYYMD_HHMMSS
                    );
                    return (
                      <Grid.Column key={promo.id}>
                        <div className="promotion-card">
                          <div className="promotion-items">
                            <div className="promotion-documents">
                              <div className="promotion-document-name">
                                <h3>{t("promotion")}</h3>
                                <span className="promo-name">
                                  {t("promoName")} {promo.promotion_name}
                                </span>
                              </div>
                              <div className="promotion-shop-details">
                                <div className="promotion-shop-name">
                                  <p>
                                    {t("shopName")}{" "}
                                    <span className="promo-details">
                                      {shop.name}{" "}
                                      {shop.store_number && shop.store_number}
                                    </span>{" "}
                                  </p>
                                </div>
                              </div>
                              <div className="file-atc-box" key={index}>
                                {this.showDocuments(promo)}
                              </div>
                            </div>
                          </div>
                          <div className="last-updated-container">
                            <p>
                              {t("createdAt")}{" "}
                              <span className="promo-details">
                                {moment(promo.created_at).format(
                                  DATE_TIME_FORMAT.LLL
                                )}
                              </span>{" "}
                            </p>
                            <p>
                              {t("lastUpdated")}{" "}
                              <span className="last-updated">
                                {moment(updatedTime).fromNow()}
                              </span>
                            </p>
                          </div>
                        </div>
                      </Grid.Column>
                    );
                  })}
              {promotions &&
                promotions.length !== 0 &&
                promotions.length <= 2 &&
                isNotShopBasicUser(currentUser) && (
                  <Grid.Column textAlign="center">
                    {this.addPromotionGrid()}
                  </Grid.Column>
                )}
            </Grid>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentShopId } = state.shops;
  const currentShop = state.shops.userShops.find(
    (s) => s.id === state.shops.currentShopId
  );
  return {
    shops: state.shops.userShops,
    isLoading: state.promotions.isLoading,
    currentUser: state.users.currentUser,
    currentShop: currentShop,
    currentShopId: currentShopId,
    promotions: state.promotions.promotions,
  };
};

export default connect(mapStateToProps, { loadShopsPromotions })(
  withTranslation("promotion")(Promotions)
);
