// NOTE: "Application" functions will add an application prefix to all cookie names. If this is undesired, the standard functions are available.
// taken from caradvise fleetmd repository

const COOKIE_PREFIX = "shop-portal"
export const JWT_COOKIE_KEY = "current_user_token"
export const ID_COOKIE_KEY = "current_user_id"
export const EMAIL_COOKIE_KEY = "current_user_email"
export const CONFIRMED_COOKIE_KEY = "current_user_confirmed"
export const ALL_COOKIE_KEYS = [ID_COOKIE_KEY, EMAIL_COOKIE_KEY, JWT_COOKIE_KEY]

export function getStoredJwt() {
  return getApplicationCookie(JWT_COOKIE_KEY)
}

export function currentUserId() {
  return getApplicationCookie(ID_COOKIE_KEY)
}

export function deleteApplicationCookie(name) {
  const prefixedName = prefixCookieName(name)
  const result = deleteCookie(prefixedName)
  return result
}

export function deleteCookie(name) {
  let d = window.location.hostname.split(".")
  while (d.length > 0) {
    let cookieBase = encodeURIComponent(name) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path="
    let p = window.location.pathname.split("/")
    document.cookie = cookieBase + "/"
    while (p.length > 0) {
      document.cookie = cookieBase + p.join("/")
      p.pop()
    }
    d.shift()
  }
  document.cookie = `${name}=;` // In case the above did not work, and doesn't always on some browsers.
  return true
}

export function deleteApplicationCookies(names = ALL_COOKIE_KEYS) {
  const prefixedNames = names.map((name) => prefixCookieName(name))
  const result = deleteCookies(prefixedNames)
  return result
}

export function deleteCookies(names) {
  names.forEach((name) => deleteCookie(name))
  return true
}

export function getApplicationCookie(name) {
  const prefixedName = prefixCookieName(name)
  const result = getCookie(prefixedName)
  return result
}

export function userSignedIn() {
  const jwt = getApplicationCookie(JWT_COOKIE_KEY)
  return ![null, undefined, ""].includes(jwt)
}

export function userConfirmed() {
  const confirmed = getApplicationCookie(CONFIRMED_COOKIE_KEY)
  return [true, "true"].includes(confirmed)
}

export function userSignedInAndConfirmed() {
  return userSignedIn() && userConfirmed()
}

export function getCookie(name) {
  let key = `${name}=`
  let decodedCookie = decodeURIComponent(document.cookie)
  let cookieArray = decodedCookie.split(";")
  let cookieVal, currentCookie

  for (let i = 0; i < cookieArray.length; i++) {
    currentCookie = cookieArray[i]

    while (currentCookie.charAt(0) === " ") {
      currentCookie = currentCookie.substring(1)
    }

    if (currentCookie.indexOf(key) === 0) {
      cookieVal = currentCookie.substring(key.length, currentCookie.length)
      if (cookieVal !== "") return cookieVal
    }
  }

  return ""
}

export function prefixCookieName(name) {
  return `${COOKIE_PREFIX}_${name}`
}

export function setApplicationCookie(name, value) {
  const prefixedName = prefixCookieName(name)
  const result = setCookie(prefixedName, value)
  return result
}

export function setCookie(name, value) {
  // NOTE: An explicit path needs to be set to properly set cookies for Internet Explorer.
  document.cookie = `${name}=${value}; path=/;`
  return true
}
