import React, { Component } from "react";
import { Provider } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./utils/i18n";
import Router from "./Router";
import Store, { getPersistor } from "./actions/configureStore";
import { PersistGate } from "redux-persist/integration/react";

class App extends Component {
  render() {
    return (
      <Provider store={Store}>
        <PersistGate persistor={getPersistor()}>
          <I18nextProvider i18n={i18n}>
            <Router />
          </I18nextProvider>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
