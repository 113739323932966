import {
  formatInternalErrors,
  consoleLog,
  catchErrors,
} from "../../helpers/apiErrorHelpers";
import {
  COMMON_SERVICES_LOAD_SUCCEEDED,
  UNSET_LOADING,
  SET_LOADING,
  COMMON_SERVICES_PATH,
} from "../../constants/actionTypes";
import Notifications from "../../components/Notifications";
import store from "../configureStore";
import { getJSON } from "../../utils/fetch";
import i18n from "../../utils/i18n";

export function loadCommonServices(action) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_LOADING });
      let user = getState().users || {};
      const { authentication_token } = user;
      const request = {
        url: COMMON_SERVICES_PATH,
        headers: { Authorization: authentication_token },
        body: {},
      };

      const response = await getJSON(
        request.url,
        request.body,
        request.headers
      );
      catchErrors(response.result);
      await dispatch({
        type: COMMON_SERVICES_LOAD_SUCCEEDED,
        payload: { commonServices: response.result },
      });
      await dispatch({ type: UNSET_LOADING });
    } catch (errors) {
      dispatch({ type: UNSET_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:errorWhileLoadingDataMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
    }
  };
}
