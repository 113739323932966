import {
  formatInternalErrors,
  toString,
  consoleLog,
  catchErrors,
} from "../../helpers/apiErrorHelpers";
import {
  currentUserId,
  setApplicationCookie,
  CONFIRMED_COOKIE_KEY,
} from "../../helpers/sessionHelpers";
import {
  SET_LOADING,
  UNSET_LOADING,
  CURRENT_USER_LOAD_SUCCEEDED,
  SHOPS_LOAD_SUCCEEDED,
  CURRENT_USER_SETTINGS_UPDATE_SUCCEEDED,
  IDENTIFY_AND_SET_USER,
  REMOVE_USER,
} from "../../constants/actionTypes";
import Notifications from "../../components/Notifications";
import store from "../configureStore";
import { getJSON, postJSON, putJSON } from "../../utils/fetch";
import { USERS_PATH } from "../../constants/actionTypes";
import { navigationPaths } from "../../constants/paths";
import { storeUserFromToken } from "./sessionSaga";
import i18n from "../../utils/i18n";

function setAndIdentifyUser(user) {
  return { type: IDENTIFY_AND_SET_USER, user };
}
export function setUserData(user) {
  return async function (dispatch) {
    dispatch(setAndIdentifyUser(user));
  };
}
export function removeUser() {
  return { type: REMOVE_USER, loading: false };
}

export function createUser(formData, opts = {}) {
  return async function (dispatch) {
    try {
      dispatch({ type: SET_LOADING });

      const {
        firstName,
        lastName,
        email,
        phone,
        password,
        shopName,
        numShops,
        accessCode,
      } = formData;

      const request = {
        url: USERS_PATH,
        headers: {},
        body: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
          password: password,
          password_confirmation: password,
          brand_name: shopName,
          total_shops: numShops,
          accessCode: accessCode,
        },
      };

      const response = await postJSON(
        request.url,
        request.body,
        request.headers
      );
      catchErrors(response.result);
      await dispatch(setUserData(response.result));
      await dispatch(storeUserFromToken(response.result));
      await dispatch({ type: UNSET_LOADING });

      if (response.result) {
        opts.history.push({ pathname: navigationPaths.dashboard() });
      }
    } catch (errors) {
      dispatch({ type: UNSET_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:signUpFailedMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
    }
  };
}

export function loadUser() {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_LOADING });
      let user = getState().users || {};
      const { authentication_token } = user;

      if (authentication_token) {
        const request = {
          url: `${USERS_PATH}/${currentUserId()}`,
          headers: { Authorization: authentication_token },
          body: {},
        };

        const response = await getJSON(
          request.url,
          request.body,
          request.headers
        );
        const user = response.result;
        catchErrors(user);

        dispatch({
          type: CURRENT_USER_LOAD_SUCCEEDED,
          payload: { currentUser: user },
        });
        await dispatch(
          setApplicationCookie,
          CONFIRMED_COOKIE_KEY,
          user.confirmed
        );
        dispatch({
          type: SHOPS_LOAD_SUCCEEDED,
          payload: { shops: user.shops },
        });
        dispatch({ type: UNSET_LOADING });
      }
    } catch (errors) {
      dispatch({ type: UNSET_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:loadUserFailedMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
    }
  };
}

export function forgotPassword(action) {
  return async function (dispatch) {
    try {
      dispatch({ type: SET_LOADING });
      const { email } = action;

      const request = {
        url: `${USERS_PATH}/forgot_password`,
        body: { email: email },
      };

      const response = await postJSON(request.url, request.body);
      catchErrors(response.result);

      store.dispatch(
        Notifications.success({
          title: i18n.t("common:successTitle"),
          message: i18n.t("common:passwordResetEmailSentMsg"),
          position: "tr",
          autoDismiss: 5,
        })
      );
      dispatch({ type: UNSET_LOADING });
      await dispatch(action.callback);
    } catch (errors) {
      dispatch({ type: UNSET_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:errorMsg")} ${toString(formattedErrors)}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
    }
  };
}

export function resetPassword(action) {
  return async function (dispatch) {
    try {
      dispatch({ type: SET_LOADING });

      const { password, passwordConfirmation, resetToken } = action;

      const request = {
        url: `${USERS_PATH}/reset_password`,
        body: {
          password: password,
          password_confirmation: passwordConfirmation,
          reset_token: resetToken,
        },
      };

      const response = await putJSON(request.url, request.body);
      catchErrors(response.result);
      store.dispatch(
        Notifications.success({
          title: i18n.t("common:successTitle"),
          message: i18n.t("common:passwordUpdatedMsg"),
          position: "tr",
          autoDismiss: 5,
        })
      );
      dispatch({ type: UNSET_LOADING });
      await dispatch(action.callback);
    } catch (errors) {
      dispatch({ type: UNSET_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:errorMsg")} ${toString(formattedErrors)}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
    }
  };
}

export function updateSettings(action) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_LOADING });
      let user = getState().users || {};
      const { authentication_token } = user;
      const { isEmail, isSMS, id } = action;

      const request = {
        url: `${USERS_PATH}/${id}`,
        headers: { Authorization: authentication_token },
        body: {
          is_email: isEmail,
          is_sms: isSMS,
        },
      };

      const response = await putJSON(
        request.url,
        request.body,
        request.headers
      );
      catchErrors(response.result);

      await dispatch({
        type: CURRENT_USER_SETTINGS_UPDATE_SUCCEEDED,
        payload: { currentUser: response.result },
      });
      await dispatch(loadUser());
      dispatch({ type: UNSET_LOADING });
      store.dispatch(
        Notifications.success({
          title: i18n.t("common:successTitle"),
          message: i18n.t("common:settingsUpdatedMsg"),
          position: "tr",
          autoDismiss: 5,
        })
      );
    } catch (errors) {
      dispatch({ type: UNSET_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:errorUpdatingSettingsMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
      await dispatch(action.errorCallback);
    }
  };
}
