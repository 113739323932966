import { getJSON, putJSON } from "../../utils/fetch";
import {
  formatInternalErrors,
  consoleLog,
  catchErrors,
} from "../../helpers/apiErrorHelpers";
import {
  SET_SHOP_FEEDBACK_LOADING,
  UNSET_SHOP_FEEDBACK_LOADING,
  CUSTOMER_FEEDBACK_LOAD_SUCCEEDED,
  CUSTOMER_RATING_PATH,
  CUSTOMER_FEEDBACK_UPDATE_SUCCEEDED,
} from "../../constants/actionTypes";
import Notifications from "../../components/Notifications";
import store from "../configureStore";
import i18n from "../../utils/i18n";

export function fetchCustomerFeedback(action) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_SHOP_FEEDBACK_LOADING });

      let user = getState().users || {};
      const { authentication_token } = user;
      const { shopId } = action;

      if (authentication_token && shopId) {
        const request = {
          url: `${CUSTOMER_RATING_PATH}?shop_id=${shopId}`,
          headers: { Authorization: authentication_token },
          body: {},
        };

        const response = await getJSON(
          request.url,
          request.body,
          request.headers
        );
        catchErrors(response.result);

        dispatch({
          type: CUSTOMER_FEEDBACK_LOAD_SUCCEEDED,
          payload: { customerRatings: response.result },
        });
        dispatch({ type: UNSET_SHOP_FEEDBACK_LOADING });
      }
    } catch (errors) {
      dispatch({ type: UNSET_SHOP_FEEDBACK_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t(
            "common:errorWhileLoadingCustomersRatingsMsg"
          )} ${toString(formattedErrors)}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
    }
  };
}

export function updateCustomerFeedback(action) {
  return async function (dispatch) {
    try {
      dispatch({ type: SET_SHOP_FEEDBACK_LOADING });
      const { id, shop_id, shop_user_email, shop_explanation } = action;
      const request = {
        url: `/${CUSTOMER_RATING_PATH}/${id}`,
        body: {
          shop_explanation: shop_explanation,
          shop_user_email: shop_user_email,
        },
      };

      const response = await putJSON(request.url, request.body);
      catchErrors(response.result);

      await dispatch({
        type: CUSTOMER_FEEDBACK_UPDATE_SUCCEEDED,
        payload: { customerRatings: response.result },
      });

      await dispatch(fetchCustomerFeedback({ shopId: shop_id }));

      await dispatch({ type: UNSET_SHOP_FEEDBACK_LOADING });

      store.dispatch(
        Notifications.success({
          title: i18n.t("common:successTitle"),
          message: i18n.t("common:responseAddedMsg"),
          position: "tr",
          autoDismiss: 5,
        })
      );
    } catch (errors) {
      dispatch({ type: UNSET_SHOP_FEEDBACK_LOADING });
      const formattedErrors = formatInternalErrors(errors);

      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:errorWhileAddingResponseMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
      dispatch(action.errorCallback);
    }
  };
}
