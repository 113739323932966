import React, { Component } from "react";
import {
  Button,
  Modal,
  Form,
  Divider,
  Grid,
  Checkbox,
} from "semantic-ui-react";
import { connect } from "react-redux";
import {
  DATE_TIME_FORMAT,
  DAY_ORDER_IN_WEEK,
  ERRORS_MESSAGES,
  INVALID_DATE,
} from "../../constants/dateTime";
import moment from "moment";
import store from "../../actions/configureStore";
import { updateShopDetails } from "../../actions/sagas/shopSaga";

class ShopBusinessHoursEditModal extends Component {
  constructor(props) {
    super(props);
    const { shopBusinessHours } = props;
    const { operational_business_days } = shopBusinessHours;
    const isSundayWorking =
      operational_business_days &&
      operational_business_days.filter(
        (day) => day.order_in_week === DAY_ORDER_IN_WEEK.SUNDAY
      );
    const mondayHours =
      operational_business_days &&
      operational_business_days.filter(
        (day) => day.order_in_week === DAY_ORDER_IN_WEEK.MONDAY
      );
    const tuesdayHours =
      operational_business_days &&
      operational_business_days.filter(
        (day) => day.order_in_week === DAY_ORDER_IN_WEEK.TUESDAY
      );
    const wednesdayHours =
      operational_business_days &&
      operational_business_days.filter(
        (day) => day.order_in_week === DAY_ORDER_IN_WEEK.WEDNESDAY
      );
    const thursdayHours =
      operational_business_days &&
      operational_business_days.filter(
        (day) => day.order_in_week === DAY_ORDER_IN_WEEK.THURSDAY
      );
    const fridayHours =
      operational_business_days &&
      operational_business_days.filter(
        (day) => day.order_in_week === DAY_ORDER_IN_WEEK.FRIDAY
      );
    const saturdayHours =
      operational_business_days &&
      operational_business_days.filter(
        (day) => day.order_in_week === DAY_ORDER_IN_WEEK.SATURDAY
      );
    const sundayHours =
      operational_business_days &&
      operational_business_days.filter(
        (day) => day.order_in_week === DAY_ORDER_IN_WEEK.SUNDAY
      );
    this.state = {
      sundayHours: sundayHours,
      mondayHours: mondayHours,
      tuesdayHours: tuesdayHours,
      wednesdayHours: wednesdayHours,
      thursdayHours: thursdayHours,
      fridayHours: fridayHours,
      saturdayHours: saturdayHours,
      isSundayClose:
        isSundayWorking &&
        isSundayWorking.length > 0 &&
        (isSundayWorking[0].open_time === null ||
          isSundayWorking[0].open_time === INVALID_DATE ||
          isSundayWorking[0].open_time === isSundayWorking[0].close_time)
          ? true
          : isSundayWorking && isSundayWorking.length === 0
          ? true
          : false,
      isMondayClose:
        mondayHours &&
        mondayHours.length > 0 &&
        (mondayHours[0].open_time === null ||
          mondayHours[0].open_time === INVALID_DATE ||
          mondayHours[0].open_time === mondayHours[0].close_time)
          ? true
          : mondayHours && mondayHours.length === 0
          ? true
          : false,
      isTuesdayClose:
        tuesdayHours &&
        tuesdayHours.length > 0 &&
        (tuesdayHours[0].open_time === null ||
          tuesdayHours[0].open_time === INVALID_DATE ||
          tuesdayHours[0].open_time === tuesdayHours[0].close_time)
          ? true
          : tuesdayHours && tuesdayHours.length === 0
          ? true
          : false,
      isWednesdayClose:
        wednesdayHours &&
        wednesdayHours.length > 0 &&
        (wednesdayHours[0].open_time === null ||
          wednesdayHours[0].open_time === INVALID_DATE ||
          wednesdayHours[0].open_time === wednesdayHours[0].close_time)
          ? true
          : wednesdayHours && wednesdayHours.length === 0
          ? true
          : false,
      isThursdaysClose:
        thursdayHours &&
        thursdayHours.length > 0 &&
        (thursdayHours[0].open_time === null ||
          thursdayHours[0].open_time === INVALID_DATE ||
          thursdayHours[0].open_time === thursdayHours[0].close_time)
          ? true
          : thursdayHours && thursdayHours.length === 0
          ? true
          : false,
      isFridayClose:
        fridayHours &&
        fridayHours.length > 0 &&
        (fridayHours[0].open_time === null ||
          fridayHours[0].open_time === INVALID_DATE ||
          fridayHours[0].open_time === fridayHours[0].close_time)
          ? true
          : thursdayHours && fridayHours.length === 0
          ? true
          : false,
      isSaturdayClose:
        saturdayHours &&
        saturdayHours.length > 0 &&
        (saturdayHours[0].open_time === null ||
          saturdayHours[0].open_time === INVALID_DATE ||
          saturdayHours[0].open_time === saturdayHours[0].close_time)
          ? true
          : saturdayHours && saturdayHours.length === 0
          ? true
          : false,
      formData: {
        monOpenTime:
          mondayHours.length > 0
            ? moment(mondayHours[0].open_time)
                .parseZone()
                .format(DATE_TIME_FORMAT.LT)
            : null,
        monCloseTime:
          mondayHours.length > 0
            ? moment(mondayHours[0].close_time)
                .parseZone()
                .format(DATE_TIME_FORMAT.LT)
            : null,
        tueOpenTime:
          tuesdayHours.length > 0
            ? moment(tuesdayHours[0].open_time)
                .parseZone()
                .format(DATE_TIME_FORMAT.LT)
            : null,
        tueCloseTime:
          tuesdayHours.length > 0
            ? moment(tuesdayHours[0].close_time)
                .parseZone()
                .format(DATE_TIME_FORMAT.LT)
            : null,
        wedOpenTime:
          wednesdayHours.length > 0
            ? moment(wednesdayHours[0].open_time)
                .parseZone()
                .format(DATE_TIME_FORMAT.LT)
            : null,
        wedCloseTime:
          wednesdayHours.length > 0
            ? moment(wednesdayHours[0].close_time)
                .parseZone()
                .format(DATE_TIME_FORMAT.LT)
            : null,
        thuOpenTime:
          thursdayHours.length > 0
            ? moment(thursdayHours[0].open_time)
                .parseZone()
                .format(DATE_TIME_FORMAT.LT)
            : null,
        thuCloseTime:
          thursdayHours.length > 0
            ? moment(thursdayHours[0].close_time)
                .parseZone()
                .format(DATE_TIME_FORMAT.LT)
            : null,
        friOpenTime:
          fridayHours.length > 0
            ? moment(fridayHours[0].open_time)
                .parseZone()
                .format(DATE_TIME_FORMAT.LT)
            : null,
        friCloseTime:
          fridayHours.length > 0
            ? moment(fridayHours[0].close_time)
                .parseZone()
                .format(DATE_TIME_FORMAT.LT)
            : null,
        satOpenTime:
          saturdayHours.length > 0
            ? moment(saturdayHours[0].open_time)
                .parseZone()
                .format(DATE_TIME_FORMAT.LT)
            : null,
        satCloseTime:
          saturdayHours.length > 0
            ? moment(saturdayHours[0].close_time)
                .parseZone()
                .format(DATE_TIME_FORMAT.LT)
            : null,
        sunOpenTime:
          isSundayWorking.length > 0
            ? moment(sundayHours[0].open_time)
                .parseZone()
                .format(DATE_TIME_FORMAT.LT)
            : null,
        sunCloseTime:
          isSundayWorking.length > 0
            ? moment(sundayHours[0].close_time)
                .parseZone()
                .format(DATE_TIME_FORMAT.LT)
            : null,
      },
      errors: {},
      value: null,
      monday: {
        id: mondayHours.length > 0 && mondayHours[0].id,
        order_in_week: mondayHours.length > 0 && mondayHours[0].order_in_week,
        open_time:
          mondayHours.length > 0
            ? moment(mondayHours[0].open_time)
                .parseZone()
                .format(DATE_TIME_FORMAT.LT)
            : "09:00",
        close_time:
          mondayHours.length > 0
            ? moment(mondayHours[0].close_time)
                .parseZone()
                .format(DATE_TIME_FORMAT.LT)
            : "17:00",
      },
      tuesday: {
        id: tuesdayHours.length > 0 && tuesdayHours[0].id,
        order_in_week: tuesdayHours.length > 0 && tuesdayHours[0].order_in_week,
        open_time:
          tuesdayHours.length > 0 &&
          moment(tuesdayHours[0].open_time)
            .parseZone()
            .format(DATE_TIME_FORMAT.LT),
        close_time:
          tuesdayHours.length > 0 &&
          moment(tuesdayHours[0].close_time)
            .parseZone()
            .format(DATE_TIME_FORMAT.LT),
      },
      wednesday: {
        id: wednesdayHours.length > 0 && wednesdayHours[0].id,
        order_in_week:
          wednesdayHours.length > 0 && wednesdayHours[0].order_in_week,
        open_time:
          wednesdayHours.length > 0 &&
          moment(wednesdayHours[0].open_time)
            .parseZone()
            .format(DATE_TIME_FORMAT.LT),
        close_time:
          wednesdayHours.length > 0 &&
          moment(wednesdayHours[0].close_time)
            .parseZone()
            .format(DATE_TIME_FORMAT.LT),
      },
      thursday: {
        id: thursdayHours.length > 0 && thursdayHours[0].id,
        order_in_week:
          thursdayHours.length > 0 && thursdayHours[0].order_in_week,
        open_time:
          thursdayHours.length > 0 &&
          moment(thursdayHours[0].open_time)
            .parseZone()
            .format(DATE_TIME_FORMAT.LT),
        close_time:
          thursdayHours.length > 0 &&
          moment(thursdayHours[0].close_time)
            .parseZone()
            .format(DATE_TIME_FORMAT.LT),
      },
      friday: {
        id: fridayHours.length > 0 && fridayHours[0].id,
        order_in_week: fridayHours.length > 0 && fridayHours[0].order_in_week,
        open_time:
          fridayHours.length > 0 &&
          moment(fridayHours[0].open_time)
            .parseZone()
            .format(DATE_TIME_FORMAT.LT),
        close_time:
          fridayHours.length > 0 &&
          moment(fridayHours[0].close_time)
            .parseZone()
            .format(DATE_TIME_FORMAT.LT),
      },
      saturday: {
        id: saturdayHours.length > 0 && saturdayHours[0].id,
        order_in_week:
          saturdayHours.length > 0 && saturdayHours[0].order_in_week,
        open_time:
          saturdayHours.length > 0 &&
          moment(saturdayHours[0].open_time)
            .parseZone()
            .format(DATE_TIME_FORMAT.LT),
        close_time:
          saturdayHours.length > 0 &&
          moment(saturdayHours[0].close_time)
            .parseZone()
            .format(DATE_TIME_FORMAT.LT),
      },
      sunday: {
        id:
          sundayHours.length > 0 && sundayHours[0].id !== null
            ? sundayHours[0].id
            : null,
        order_in_week:
          sundayHours.length > 0
            ? sundayHours[0].order_in_week
            : DAY_ORDER_IN_WEEK.SUNDAY,
        open_time:
          sundayHours.length > 0
            ? moment(sundayHours[0].open_time)
                .parseZone()
                .format(DATE_TIME_FORMAT.LT)
            : null,
        close_time:
          sundayHours.length > 0
            ? moment(sundayHours[0].close_time)
                .parseZone()
                .format(DATE_TIME_FORMAT.LT)
            : null,
      },
    };
  }

  fileInputRef = React.createRef();

  onFieldChange = (field, field2, e, status) => {
    const {
      mondayHours,
      tuesdayHours,
      wednesdayHours,
      thursdayHours,
      fridayHours,
      saturdayHours,
      sundayHours,
    } = this.state;
    let updatedFormData = { ...this.state.formData };

    if (e !== undefined) {
      updatedFormData[field] = e.target.value;
    } else {
      updatedFormData[field] = "";
      updatedFormData[field2] = "";
    }

    this.setState({
      formData: updatedFormData,
    });
    this.setState({
      monday: {
        id: mondayHours.length ? mondayHours[0].id : null,
        order_in_week: mondayHours.length
          ? mondayHours[0].order_in_week
          : DAY_ORDER_IN_WEEK.MONDAY,
        open_time: updatedFormData.monOpenTime,
        close_time: updatedFormData.monCloseTime,
      },
    });
    this.setState({
      tuesday: {
        id: tuesdayHours.length > 0 && tuesdayHours[0].id,
        order_in_week:
          tuesdayHours.length > 0
            ? tuesdayHours[0].order_in_week
            : DAY_ORDER_IN_WEEK.TUESDAY,
        open_time: updatedFormData.tueOpenTime,
        close_time: updatedFormData.tueCloseTime,
      },
    });
    this.setState({
      wednesday: {
        id: wednesdayHours.length > 0 && wednesdayHours[0].id,
        order_in_week:
          wednesdayHours.length > 0
            ? wednesdayHours[0].order_in_week
            : DAY_ORDER_IN_WEEK.WEDNESDAY,
        open_time: updatedFormData.wedOpenTime,
        close_time: updatedFormData.wedCloseTime,
      },
    });
    this.setState({
      thursday: {
        id: thursdayHours.length > 0 && thursdayHours[0].id,
        order_in_week:
          thursdayHours.length > 0
            ? thursdayHours[0].order_in_week
            : DAY_ORDER_IN_WEEK.THURSDAY,
        open_time: updatedFormData.thuOpenTime,
        close_time: updatedFormData.thuCloseTime,
      },
    });
    this.setState({
      friday: {
        id: fridayHours.length > 0 && fridayHours[0].id,
        order_in_week:
          fridayHours.length > 0
            ? fridayHours[0].order_in_week
            : DAY_ORDER_IN_WEEK.FRIDAY,
        open_time: updatedFormData.friOpenTime,
        close_time: updatedFormData.friCloseTime,
      },
    });
    this.setState({
      saturday: {
        id: saturdayHours.length > 0 && saturdayHours[0].id,
        order_in_week:
          saturdayHours.length > 0
            ? saturdayHours[0].order_in_week
            : DAY_ORDER_IN_WEEK.SATURDAY,
        open_time: updatedFormData.satOpenTime,
        close_time: updatedFormData.satCloseTime,
      },
    });

    this.setState({
      sunday: {
        id: sundayHours.length > 0 && sundayHours[0].id,
        order_in_week:
          sundayHours.length > 0
            ? sundayHours[0].order_in_week
            : DAY_ORDER_IN_WEEK.SUNDAY,
        open_time: updatedFormData.sunOpenTime,
        close_time: updatedFormData.sunCloseTime,
      },
    });
  };

  formDataErrors = () => {
    const { t } = this.props;
    const {
      formData,
      isSundayClose,
      isMondayClose,
      isTuesdayClose,
      isWednesdayClose,
      isThursdaysClose,
      isFridayClose,
      isSaturdayClose,
    } = this.state;
    const {
      monOpenTime,
      monCloseTime,
      tueOpenTime,
      tueCloseTime,
      wedOpenTime,
      wedCloseTime,
      thuOpenTime,
      thuCloseTime,
      friOpenTime,
      friCloseTime,
      satOpenTime,
      satCloseTime,
      sunOpenTime,
      sunCloseTime,
    } = formData;
    const errors = {};
    if (!isMondayClose) {
      if (
        monOpenTime.length === 0 ||
        monOpenTime === null ||
        monOpenTime === INVALID_DATE
      )
        errors.monOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_REQUIRED);

      if (
        monCloseTime.length === 0 ||
        monCloseTime === null ||
        monCloseTime === INVALID_DATE
      )
        errors.monCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_REQUIRED);

      if (
        monOpenTime === monCloseTime &&
        monOpenTime !== INVALID_DATE &&
        monOpenTime.length !== 0 &&
        monCloseTime.length !== 0 &&
        monCloseTime !== INVALID_DATE
      ) {
        errors.monOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_SHOULD_NOT_SAME);
        errors.monCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_SHOULD_NOT_SAME);
      }
      if (
        monOpenTime >= monCloseTime &&
        monCloseTime !== INVALID_DATE &&
        monCloseTime.length !== 0 &&
        monOpenTime !== monCloseTime
      ) {
        errors.monOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_MUST_BE_LESS);
        errors.monCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_MUST_BE_GREATER);
      }
    }
    if (!isTuesdayClose) {
      if (
        tueOpenTime.length === 0 ||
        tueOpenTime === null ||
        tueOpenTime === INVALID_DATE
      )
        errors.tueOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_REQUIRED);

      if (
        tueCloseTime.length === 0 ||
        tueCloseTime === null ||
        tueCloseTime === INVALID_DATE
      )
        errors.tueCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_REQUIRED);

      if (
        tueOpenTime === tueCloseTime &&
        tueOpenTime !== INVALID_DATE &&
        tueOpenTime.length !== 0 &&
        tueCloseTime.length !== 0 &&
        tueCloseTime !== INVALID_DATE
      ) {
        errors.tueOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_SHOULD_NOT_SAME);
        errors.tueCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_SHOULD_NOT_SAME);
      }
      if (
        tueOpenTime >= tueCloseTime &&
        tueCloseTime !== INVALID_DATE &&
        tueCloseTime.length !== 0 &&
        tueOpenTime !== tueCloseTime
      ) {
        errors.tueOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_MUST_BE_LESS);
        errors.tueCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_MUST_BE_GREATER);
      }
    }
    if (!isWednesdayClose) {
      if (
        wedOpenTime.length === 0 ||
        wedOpenTime === null ||
        wedOpenTime === INVALID_DATE
      )
        errors.wedOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_REQUIRED);

      if (
        wedCloseTime.length === 0 ||
        wedCloseTime === null ||
        wedCloseTime === INVALID_DATE
      )
        errors.wedCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_REQUIRED);

      if (
        wedOpenTime === wedCloseTime &&
        wedOpenTime !== INVALID_DATE &&
        wedOpenTime.length !== 0 &&
        wedCloseTime.length !== 0 &&
        wedCloseTime !== INVALID_DATE
      ) {
        errors.wedOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_SHOULD_NOT_SAME);
        errors.wedCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_SHOULD_NOT_SAME);
      }
      if (
        wedOpenTime >= wedCloseTime &&
        wedCloseTime !== INVALID_DATE &&
        wedCloseTime.length !== 0 &&
        wedOpenTime !== wedCloseTime
      ) {
        errors.wedOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_MUST_BE_LESS);
        errors.wedCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_MUST_BE_GREATER);
      }
    }
    if (!isThursdaysClose) {
      if (
        thuOpenTime.length === 0 ||
        thuOpenTime === null ||
        thuOpenTime === INVALID_DATE
      )
        errors.thuOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_REQUIRED);

      if (
        thuCloseTime.length === 0 ||
        thuCloseTime === null ||
        thuCloseTime === INVALID_DATE
      )
        errors.thuCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_REQUIRED);

      if (
        thuOpenTime === thuCloseTime &&
        thuOpenTime !== INVALID_DATE &&
        thuOpenTime.length !== 0 &&
        thuCloseTime.length !== 0 &&
        thuCloseTime !== INVALID_DATE
      ) {
        errors.thuOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_SHOULD_NOT_SAME);
        errors.thuCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_SHOULD_NOT_SAME);
      }
      if (
        thuOpenTime >= thuCloseTime &&
        thuCloseTime !== INVALID_DATE &&
        thuCloseTime.length !== 0 &&
        thuOpenTime !== thuCloseTime
      ) {
        errors.thuOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_MUST_BE_LESS);
        errors.thuCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_MUST_BE_GREATER);
      }
    }
    if (!isFridayClose) {
      if (
        friOpenTime.length === 0 ||
        friOpenTime === null ||
        friOpenTime === INVALID_DATE
      )
        errors.friOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_REQUIRED);

      if (
        friCloseTime.length === 0 ||
        friCloseTime === null ||
        friCloseTime === INVALID_DATE
      )
        errors.friCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_REQUIRED);

      if (
        friOpenTime === friCloseTime &&
        friOpenTime !== INVALID_DATE &&
        friOpenTime.length !== 0 &&
        friCloseTime.length !== 0 &&
        friCloseTime !== INVALID_DATE
      ) {
        errors.friOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_SHOULD_NOT_SAME);
        errors.friCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_SHOULD_NOT_SAME);
      }
      if (
        friOpenTime >= friCloseTime &&
        friCloseTime !== INVALID_DATE &&
        friCloseTime.length !== 0 &&
        friOpenTime !== friCloseTime
      ) {
        errors.friOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_MUST_BE_LESS);
        errors.friCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_MUST_BE_GREATER);
      }
    }
    if (!isSaturdayClose) {
      if (
        satOpenTime.length === 0 ||
        satOpenTime === null ||
        satOpenTime === INVALID_DATE
      )
        errors.satOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_REQUIRED);

      if (
        satCloseTime.length === 0 ||
        satCloseTime === null ||
        satCloseTime === INVALID_DATE
      )
        errors.satCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_REQUIRED);

      if (
        satOpenTime === satCloseTime &&
        satOpenTime !== INVALID_DATE &&
        satOpenTime.length !== 0 &&
        satCloseTime.length !== 0 &&
        satCloseTime !== INVALID_DATE
      ) {
        errors.satOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_SHOULD_NOT_SAME);
        errors.satCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_SHOULD_NOT_SAME);
      }
      if (
        satOpenTime >= satCloseTime &&
        satCloseTime !== INVALID_DATE &&
        satCloseTime.length !== 0 &&
        satOpenTime !== satCloseTime
      ) {
        errors.satOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_MUST_BE_LESS);
        errors.satCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_MUST_BE_GREATER);
      }
    }
    if (!isSundayClose) {
      if (
        sunOpenTime.length === 0 ||
        sunOpenTime === null ||
        sunOpenTime === INVALID_DATE
      )
        errors.sunOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_REQUIRED);

      if (
        sunCloseTime.length === 0 ||
        sunCloseTime === null ||
        sunCloseTime === INVALID_DATE
      )
        errors.sunCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_REQUIRED);

      if (
        sunOpenTime === sunCloseTime &&
        sunOpenTime !== INVALID_DATE &&
        sunOpenTime.length !== 0 &&
        sunCloseTime !== INVALID_DATE &&
        sunCloseTime.length !== 0
      ) {
        errors.sunOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_SHOULD_NOT_SAME);
        errors.sunCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_SHOULD_NOT_SAME);
      }
      if (
        sunOpenTime >= sunCloseTime &&
        sunCloseTime !== INVALID_DATE &&
        sunCloseTime.length !== 0 &&
        sunOpenTime !== sunCloseTime
      ) {
        errors.sunOpenTime = t(ERRORS_MESSAGES.OPEN_TIME_MUST_BE_LESS);
        errors.sunCloseTime = t(ERRORS_MESSAGES.CLOSE_TIME_MUST_BE_GREATER);
      }
    }
    return errors;
  };

  onUpdateShopDetails = () => {
    const errors = this.formDataErrors();
    const { currentShopId } = this.props;
    const {
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
    } = this.state;
    const operational_business_days = [
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
    ];

    const formDataBody = new FormData();
    for (var i = 0; i < operational_business_days.length; i++) {
      if (operational_business_days[i].id) {
        formDataBody.append(
          `operational_business_days[][id]`,
          JSON.stringify(operational_business_days[i].id)
        );
        formDataBody.append(
          `operational_business_days[][order_in_week]`,
          JSON.stringify(operational_business_days[i].order_in_week)
        );
        formDataBody.append(
          `operational_business_days[][open_time]`,
          JSON.stringify(operational_business_days[i].open_time)
        );
        formDataBody.append(
          `operational_business_days[][close_time]`,
          JSON.stringify(operational_business_days[i].close_time)
        );
      } else {
        // formDataBody.append(`operational_business_days[][id]`, JSON.stringify(operational_business_days[i].id));
        formDataBody.append(
          `operational_business_days[][order_in_week]`,
          JSON.stringify(operational_business_days[i].order_in_week)
        );
        formDataBody.append(
          `operational_business_days[][open_time]`,
          JSON.stringify(operational_business_days[i].open_time)
        );
        formDataBody.append(
          `operational_business_days[][close_time]`,
          JSON.stringify(operational_business_days[i].close_time)
        );
      }
    }

    if (Object.keys(errors).length === 0) {
      store.dispatch(updateShopDetails({ id: currentShopId, formDataBody }));
    } else {
      this.setState({ errors: errors });
    }
  };

  onShopStatusHandle = (value, e) => {
    const {
      isSundayClose,
      isMondayClose,
      isTuesdayClose,
      isWednesdayClose,
      isThursdaysClose,
      isFridayClose,
      isSaturdayClose,
    } = this.state;
    if (value.id === "sunday") {
      this.setState({ isSundayClose: value.checked });
      this.onFieldChange(
        "sunOpenTime",
        "sunCloseTime",
        undefined,
        isSundayClose
      );
    } else if (value.id === "monday") {
      this.setState({ isMondayClose: value.checked });
      this.onFieldChange(
        "monOpenTime",
        "monCloseTime",
        undefined,
        isMondayClose
      );
    } else if (value.id === "tuesday") {
      this.setState({ isTuesdayClose: value.checked });
      this.onFieldChange(
        "tueOpenTime",
        "tueCloseTime",
        undefined,
        isTuesdayClose
      );
    } else if (value.id === "wednesday") {
      this.setState({ isWednesdayClose: value.checked });
      this.onFieldChange(
        "wedOpenTime",
        "wedCloseTime",
        undefined,
        isWednesdayClose
      );
    } else if (value.id === "thursday") {
      this.setState({ isThursdaysClose: value.checked });
      this.onFieldChange(
        "thuOpenTime",
        "thuCloseTime",
        undefined,
        isThursdaysClose
      );
    } else if (value.id === "friday") {
      this.setState({ isFridayClose: value.checked });
      this.onFieldChange(
        "friOpenTime",
        "friCloseTime",
        undefined,
        isFridayClose
      );
    } else if (value.id === "saturday") {
      this.setState({ isSaturdayClose: value.checked });
      this.onFieldChange(
        "satOpenTime",
        "satCloseTime",
        undefined,
        isSaturdayClose
      );
    }
    this.setState({ errors: {} });
  };

  render() {
    const { open, close, t } = this.props;
    const {
      formData,
      errors,
      isMondayClose,
      isTuesdayClose,
      isSundayClose,
      isWednesdayClose,
      isThursdaysClose,
      isFridayClose,
      isSaturdayClose,
    } = this.state;
    const {
      monOpenTime,
      monCloseTime,
      tueOpenTime,
      tueCloseTime,
      wedOpenTime,
      wedCloseTime,
      thuOpenTime,
      thuCloseTime,
      friOpenTime,
      friCloseTime,
      satOpenTime,
      satCloseTime,
      sunOpenTime,
      sunCloseTime,
    } = formData;
    return (
      <>
        <Modal
          open={open}
          onClose={() => close()}
          closeIcon
          size="tiny"
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            <div className="alignHours">
              <Grid columns={1}>
                <Grid.Column>
                  <div style={{ textAlign: "center" }}>
                    <h3>{t("editOpeningHours")}</h3>
                  </div>
                  <Divider hidden />
                  <Form error className="no-session-form">
                    <Form.Group widths="equal">
                      <Form.Field required>
                        <label>{t("monOpen")}</label>
                        <Form.Input
                          value={monOpenTime}
                          onChange={(e) =>
                            this.onFieldChange("monOpenTime", "", e, "")
                          }
                          fluid
                          size="small"
                          type="time"
                          error={errors.monOpenTime}
                          disabled={isMondayClose}
                        />
                      </Form.Field>
                      <Form.Field required>
                        <label>{t("monClose")}</label>
                        <Form.Input
                          value={monCloseTime}
                          onChange={(e) =>
                            this.onFieldChange("monCloseTime", "", e, "")
                          }
                          fluid
                          size="small"
                          error={errors.monCloseTime}
                          type="time"
                          disabled={isMondayClose}
                        />
                      </Form.Field>
                      <div className="statusLabel">
                        <Checkbox
                          id="monday"
                          label={t("close")}
                          onChange={(e, value) =>
                            this.onShopStatusHandle(value, e)
                          }
                          checked={isMondayClose}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field required>
                        <label>{t("tueOpen")}</label>
                        <Form.Input
                          value={tueOpenTime}
                          onChange={(e) =>
                            this.onFieldChange("tueOpenTime", "", e, "")
                          }
                          fluid
                          size="small"
                          type="time"
                          error={errors.tueOpenTime}
                          disabled={isTuesdayClose}
                        />
                      </Form.Field>
                      <Form.Field required>
                        <label>{t("tueClose")}</label>
                        <Form.Input
                          value={tueCloseTime}
                          onChange={(e) =>
                            this.onFieldChange("tueCloseTime", "", e, "")
                          }
                          fluid
                          size="small"
                          error={errors.tueCloseTime}
                          type="time"
                          disabled={isTuesdayClose}
                        />
                      </Form.Field>
                      <Checkbox
                        id="tuesday"
                        onChange={(e, value) => this.onShopStatusHandle(value)}
                        checked={isTuesdayClose}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field required>
                        <label>{t("wedOpen")}</label>
                        <Form.Input
                          value={wedOpenTime}
                          onChange={(e) =>
                            this.onFieldChange("wedOpenTime", "", e, "")
                          }
                          fluid
                          size="small"
                          type="time"
                          error={errors.wedOpenTime}
                          disabled={isWednesdayClose}
                        />
                      </Form.Field>
                      <Form.Field required>
                        <label>{t("wedClose")}</label>
                        <Form.Input
                          value={wedCloseTime}
                          onChange={(e) =>
                            this.onFieldChange("wedCloseTime", "", e, "")
                          }
                          fluid
                          size="small"
                          error={errors.wedCloseTime}
                          type="time"
                          disabled={isWednesdayClose}
                        />
                      </Form.Field>
                      <Checkbox
                        id="wednesday"
                        onChange={(e, value) => this.onShopStatusHandle(value)}
                        checked={isWednesdayClose}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field required>
                        <label>{t("thuOpen")}</label>
                        <Form.Input
                          value={thuOpenTime}
                          onChange={(e) =>
                            this.onFieldChange("thuOpenTime", "", e, "")
                          }
                          fluid
                          size="small"
                          type="time"
                          error={errors.thuOpenTime}
                          disabled={isThursdaysClose}
                        />
                      </Form.Field>
                      <Form.Field required>
                        <label>{t("thuClose")}</label>
                        <Form.Input
                          value={thuCloseTime}
                          onChange={(e) =>
                            this.onFieldChange("thuCloseTime", "", e, "")
                          }
                          fluid
                          size="small"
                          error={errors.thuCloseTime}
                          type="time"
                          disabled={isThursdaysClose}
                        />
                      </Form.Field>
                      <Checkbox
                        id="thursday"
                        onChange={(e, value) => this.onShopStatusHandle(value)}
                        checked={isThursdaysClose}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field required>
                        <label>{t("friOpen")}</label>
                        <Form.Input
                          value={friOpenTime}
                          onChange={(e) =>
                            this.onFieldChange("friOpenTime", "", e, "")
                          }
                          fluid
                          size="small"
                          type="time"
                          error={errors.friOpenTime}
                          disabled={isFridayClose}
                        />
                      </Form.Field>
                      <Form.Field required>
                        <label>{t("friClose")}</label>
                        <Form.Input
                          value={friCloseTime}
                          onChange={(e) =>
                            this.onFieldChange("friCloseTime", "", e, "")
                          }
                          fluid
                          size="small"
                          error={errors.friCloseTime}
                          type="time"
                          disabled={isFridayClose}
                        />
                      </Form.Field>
                      <Checkbox
                        id="friday"
                        onChange={(e, value) => this.onShopStatusHandle(value)}
                        checked={isFridayClose}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field required>
                        <label>{t("satOpen")}</label>
                        <Form.Input
                          value={satOpenTime}
                          onChange={(e) =>
                            this.onFieldChange("satOpenTime", "", e, "")
                          }
                          fluid
                          size="small"
                          type="time"
                          error={errors.satOpenTime}
                          disabled={isSaturdayClose}
                        />
                      </Form.Field>
                      <Form.Field required>
                        <label>{t("satClose")}</label>
                        <Form.Input
                          value={satCloseTime}
                          onChange={(e) =>
                            this.onFieldChange("satCloseTime", "", e, "")
                          }
                          fluid
                          size="small"
                          error={errors.satCloseTime}
                          type="time"
                          disabled={isSaturdayClose}
                        />
                      </Form.Field>
                      <Checkbox
                        id="saturday"
                        onChange={(e, value) => this.onShopStatusHandle(value)}
                        checked={isSaturdayClose}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field required>
                        <label>{t("sunOpen")}</label>
                        <Form.Input
                          value={sunOpenTime}
                          onChange={(e) =>
                            this.onFieldChange("sunOpenTime", "", e, "")
                          }
                          fluid
                          size="small"
                          type="time"
                          error={errors.sunOpenTime}
                          disabled={isSundayClose}
                        />
                      </Form.Field>
                      <Form.Field required>
                        <label>{t("sunClose")}</label>
                        <Form.Input
                          value={sunCloseTime}
                          onChange={(e) =>
                            this.onFieldChange("sunCloseTime", "", e, "")
                          }
                          fluid
                          size="small"
                          error={errors.sunCloseTime}
                          type="time"
                          disabled={isSundayClose}
                        />
                      </Form.Field>
                      <Checkbox
                        id="sunday"
                        onChange={(e, value) => this.onShopStatusHandle(value)}
                        checked={isSundayClose}
                      />
                    </Form.Group>
                  </Form>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      fluid
                      circular
                      content={t("saveDetails")}
                      onClick={this.onUpdateShopDetails}
                    />
                  </div>
                </Grid.Column>
              </Grid>
            </div>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentShopId } = state.shops;

  return {
    isLoading: state.application.isLoading,
    currentShopId: currentShopId,
  };
};

export default connect(mapStateToProps)(ShopBusinessHoursEditModal);
