import React from "react";
import { connect } from "react-redux";
import { Card, Grid, Image } from "semantic-ui-react";
import CustomLoader from "../elements/CustomLoader";
import ShopBasicDetails from "./ShopBasicDetails";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { getSubdomain } from "../shared/StylesheetInjector";
import { isEmpty } from "lodash";
import NoShopSelected from "../shared/NoShopSelected";
import store from "../../actions/configureStore";
import { loadBusinessHours } from "../../actions/sagas/shopSaga";
import { withTranslation } from "react-i18next";

const MapComponent = withGoogleMap(({ lat, lng }) => (
  <GoogleMap
    defaultZoom={18}
    defaultCenter={{ lat: lat, lng: lng }}
    defaultOptions={{ disableDefaultUI: true }}
  >
    <Marker position={{ lat: lat, lng: lng }} />
  </GoogleMap>
));

class Location extends React.Component {
  componentDidMount() {
    const { currentShopId, shopBusinessHours } = this.props;
    if (
      currentShopId &&
      currentShopId !== "null" &&
      isEmpty(shopBusinessHours)
    ) {
      store.dispatch(loadBusinessHours({ shopId: currentShopId }));
    }
  }

  async componentDidUpdate(prevProps) {
    const { currentShopId } = this.props;
    if (currentShopId !== "null" && currentShopId !== prevProps.currentShopId) {
      store.dispatch(loadBusinessHours({ shopId: currentShopId }));
    }
  }

  render() {
    const {
      shopBusinessHours,
      isLoading,
      currentUser,
      currentShop,
      t,
    } = this.props;
    const { lat, lng, shop_image } = shopBusinessHours;
    const sub = getSubdomain();
    let logo = `${sub}_white_logo.svg`;
    return (
      <div className="container">
        {isLoading && <CustomLoader />}
        {!isLoading && (
          <React.Fragment>
            {currentShop ? (
              <Card fluid>
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <ShopBasicDetails
                        shopBusinessHours={shopBusinessHours}
                        currentUser={currentUser}
                        style={{ float: "left" }}
                        t={t}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <div>
                        <div className="div-align">
                          <div className="image-center">
                            {shopBusinessHours &&
                            shop_image &&
                            shop_image.url !== null ? (
                              <Image
                                src={shop_image.url}
                                className="image-dimensions"
                              />
                            ) : (
                              <Image
                                src={`images/${logo}`}
                                className="image-dimensions"
                              />
                            )}
                          </div>
                          <div className="map-center">
                            {shopBusinessHours &&
                              lat !== undefined &&
                              lng !== undefined && (
                                <MapComponent
                                  lat={lat}
                                  lng={lng}
                                  loadingElement={
                                    <div style={{ height: "100%" }} />
                                  }
                                  containerElement={
                                    <div className="map-dimensions" />
                                  }
                                  mapElement={
                                    <div
                                      style={{
                                        height: `100%`,
                                        borderRadius: "20px",
                                      }}
                                    />
                                  }
                                />
                              )}
                          </div>
                        </div>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card>
            ) : (
              <NoShopSelected message={t("pleaseSelectShopPlaceholder")} />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentShopId } = state.shops;
  const currentShop = state.shops.userShops.find(
    (s) => s.id === state.shops.currentShopId
  );
  const shopBusinessHours = state.shops.shopHours;
  const userAppointments = state.appointments;
  const userCustomerRatings = state.customerRatings.userCustomerRatings;

  return {
    currentUser: state.users.currentUser,
    isLoading:
      state.application.isShopHoursLoading || state.application.isLoading
        ? true
        : false,
    currentShop: currentShop,
    currentShopId: currentShopId,
    shopBusinessHours: shopBusinessHours,
    appointments: userAppointments,
    customerRatings: userCustomerRatings,
  };
};

export default connect(mapStateToProps)(withTranslation("location")(Location));
