import {
    USER_NOTE_CREATE_SUCCEEDED, USER_NOTE_DESTROY_SUCCEEDED, USER_NOTE_UPDATE_SUCCEEDED,
  } from "../../constants/actionTypes"

  export const initialState = {
    userNotes: []
  }

  export default function(state=initialState, action = {}) {
    const { payload, type } = action
    switch(type) {
      case USER_NOTE_CREATE_SUCCEEDED:
        return {
            ...state,
            userNotes: [...state.userNotes, payload.note]
          }
      case USER_NOTE_UPDATE_SUCCEEDED:
        const newNote = state.userNotes.map(note => {
          return note.id === payload.note.id ? payload.note : note;
        })
        return {
          ...state,
          userNotes: newNote
        }
      case USER_NOTE_DESTROY_SUCCEEDED:
        const userNotes = state.userNotes.filter(note => {
          return note.id !== payload.noteId
        })
        return {
          ...state,
          userNotes: userNotes
        }
      default:
        return state
    }
  }
  