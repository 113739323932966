import carAdviseStyles from "../../assets/stylesheets/caradvise.scss";
import ntwStyles from "../../assets/stylesheets/ntw.scss";
import { CARADVISE, NTWTBC } from "../../constants/application";

const styles = {
  caradvise: carAdviseStyles,
  ntw: ntwStyles,
};

const loadStylesheet = (url, callback) => {
  let sheet = document.createElement("link");
  sheet.rel = "stylesheet";
  sheet.href = url;
  sheet.type = "text/css";
  if (callback) sheet.onload = () => callback();
  document.head.appendChild(sheet);
};

export function getSubdomain(host) {
  if (!host) {
    host = window.location.hostname;
  }
  if (host.match(/ntwfleetdealer/)) {
    return NTWTBC;
  }

  let firstPeriod = host.indexOf(".");
  let subdomain;

  if (firstPeriod !== -1)
    subdomain = host.substring(0, firstPeriod).toLowerCase();
  else subdomain = host;

  if (
    subdomain === "www" ||
    subdomain === "localhost" ||
    subdomain === "shops"
  ) {
    subdomain = CARADVISE;
  }
  return subdomain;
}

export default function StylesheetInjector() {
  let subdomain = getSubdomain();

  let styleSheet = `${process.env.PUBLIC_URL}/semantic.min.css`;
  const styles = getStyles();

  loadStylesheet(styleSheet, () =>
    loadStylesheet(`${process.env.PUBLIC_URL}/${subdomain}.css`, () => {
      document.querySelector("#splash").style.display = "none";
      let root = document.querySelector("#root");
      root.style.display = "block";
      root.style.fontFamily = styles.fontFamily;
    })
  );
}

export function getStyles() {
  let subdomain = getSubdomain();
  return styles[subdomain];
}
