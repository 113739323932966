import OIL_CHANGE_LOGO from "../assets/images/service_icons/OilChangeGroup.svg";
import TIRE_LOGO from "../assets/images/service_icons/TireServiceGroup.svg";
import FILTERS_LOGO from "../assets/images/service_icons/FiltersGroup.svg";
import BATTERY_LOGO from "../assets/images/service_icons/BatteryGroup.svg";
import BRAKES_LOGO from "../assets/images/service_icons/BreaksGroup.svg";
import MISC_LOGO from "../assets/images/service_icons/MiscGroup.svg";
import INSPECTION_LOGO from "../assets/images/service_icons/InspectionsGroup.svg";

export const OIL_CHANGE_GROUP = "oilChangeGroup";
export const BRAKES_GROUP = "brakesGroup";
export const TIRES_GROUP = "tiresGroup";
export const MISC_GROUP = "miscGroup";
export const CUSTOM_GROUP = "customGroup";
export const FILTERS_GROUP = "filtersGroup";
export const BATTERY_GROUP = "batteryGroup";
export const INSPECTION_GROUP = "inspectionGroup";

export const OIL_CHANGE_HEADING = "pricings:oilChangeLabel";
export const BRAKE_HEADING = "pricings:brakesLabel";
export const TIRE_SERVICES_HEADING = "pricings:tireServicesLabel";
export const MISC_HEADING = "pricings:miscLabel";
export const CUSTOM_HEADING = "pricings:addedBysUserLabel";
export const FILTERS_HEADING = "pricings:filtersLabel";
export const BATTERY_HEADING = "pricings:batteryLabel";
export const INSPECTION_HEADING = "pricings:inpectionsLabel";

const OIL_CHANGE = { id: 2391, group: OIL_CHANGE_GROUP };
const TIRE_ROTATION = { id: 375, group: TIRES_GROUP };
const TIRE_AND_WHEEL_BALANCE = { id: 4630, group: TIRES_GROUP };
const WHEEL_ALIGNMENT = { id: 169, group: TIRES_GROUP };
const BRAKE_PADS_REPLACEMENT = { id: 3240, group: BRAKES_GROUP };
const BRAKE_INSPECTION = { id: 3267, group: BRAKES_GROUP };
const FLAT_TIRE_REPAIR = { id: 559, group: TIRES_GROUP };
const ENGINE_AIR_FILTER = { id: 3933, group: FILTERS_GROUP };
const CABIN_AIR_FILTER = { id: 4443, group: FILTERS_GROUP };
const BATTERY_REPLACEMENT = { id: 3567, group: BATTERY_GROUP };
const MULTI_POINT_INSPECTION = { id: 467, group: INSPECTION_GROUP };
const VISUAL_SAFETY_INSPECTION_RIDESHARE = {
  id: 7604,
  group: INSPECTION_GROUP,
};
const TIRE_INSTALL = { id: 557, group: TIRES_GROUP };
const WIPER_BLADES = { id: 3570, group: MISC_GROUP };

export const DEFAULT_SERVICE_DEFINITIONS = [
  OIL_CHANGE,
  TIRE_ROTATION,
  TIRE_AND_WHEEL_BALANCE,
  WHEEL_ALIGNMENT,
  BRAKE_PADS_REPLACEMENT,
  BRAKE_INSPECTION,
  FLAT_TIRE_REPAIR,
  ENGINE_AIR_FILTER,
  CABIN_AIR_FILTER,
  BATTERY_REPLACEMENT,
  MULTI_POINT_INSPECTION,
  VISUAL_SAFETY_INSPECTION_RIDESHARE,
  TIRE_INSTALL,
  WIPER_BLADES,
];

export const DEFAULT_SERVICE_GROUPS = [
  {
    key: OIL_CHANGE_HEADING,
    text: OIL_CHANGE_HEADING,
    value: OIL_CHANGE_GROUP,
    image: { avatar: false, src: OIL_CHANGE_LOGO },
  },
  {
    key: TIRE_SERVICES_HEADING,
    text: TIRE_SERVICES_HEADING,
    value: TIRES_GROUP,
    image: { avatar: false, src: TIRE_LOGO },
  },
  {
    key: FILTERS_HEADING,
    text: FILTERS_HEADING,
    value: FILTERS_GROUP,
    image: { avatar: false, src: FILTERS_LOGO },
  },
  {
    key: BATTERY_HEADING,
    text: BATTERY_HEADING,
    value: BATTERY_GROUP,
    image: { avatar: false, src: BATTERY_LOGO },
  },
  {
    key: BRAKE_HEADING,
    text: BRAKE_HEADING,
    value: BRAKES_GROUP,
    image: { avatar: false, src: BRAKES_LOGO },
  },
  {
    key: MISC_HEADING,
    text: MISC_HEADING,
    value: MISC_GROUP,
    image: { avatar: false, src: MISC_LOGO },
  },
  {
    key: INSPECTION_HEADING,
    text: INSPECTION_HEADING,
    value: INSPECTION_GROUP,
    image: { avatar: false, src: INSPECTION_LOGO },
  },
];
