import React from "react";
import { connect } from "react-redux";
import { Table, Input } from "semantic-ui-react";
import { BACKSPACE, TAB } from "../../constants/application";
import store from "../../actions/configureStore";
import { updatePricing } from "../../actions/sagas/pricingSaga";

class PricingRowSummary extends React.Component {
  constructor(props) {
    super(props);

    const { pricing } = props;
    const { costOfLabor, costPerParts, retailPrice } = pricing;
    this.state = {
      openEditModal: false,
      formData: {
        costOfLabor: costOfLabor,
        costOfParts: costPerParts,
        retailPrice: retailPrice,
      },
      errors: {},
    };
  }

  formDataErrors = () => {
    const { costOfLabor, costOfParts, retailPrice } = this.state.formData;
    const errors = {};

    if (costOfLabor.length === 0)
      errors.costOfLabor = "Cost of Labor is required";

    if (costOfParts.length === 0)
      errors.costOfParts = "Cost of Parts is required";

    if (retailPrice.length === 0)
      errors.retailPrice = "Retail Price is required";

    return errors;
  };

  handleKeypress = (e) => {
    const characterCode = e.key;
    if (characterCode === BACKSPACE || characterCode === TAB) return;
    if (e.key === "-") {
      e.preventDefault();
      return false;
    }

    if (characterCode === ".") {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    }

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  };

  onUpdatePricing = () => {
    const errors = this.formDataErrors();
    const { pricing, currentShopId } = this.props;
    const { id } = pricing;
    const { formData } = this.state;
    const { costOfLabor, costOfParts, retailPrice } = formData;
    if (Object.keys(errors).length === 0) {
      store.dispatch(
        updatePricing({
          payload: {
            id: id,
            shopId: currentShopId,
            costOfLabor: costOfLabor,
            costOfParts: costOfParts,
            retailPrice: retailPrice,
          },
        })
      );
    } else {
      this.setState({ errors: errors });
    }
  };

  onFieldChange = (field, e) => {
    let updatedFormData = { ...this.state.formData };
    updatedFormData[field] = e.target.value;
    this.setState({ formData: updatedFormData });
  };
  cancleChange = () => {
    const { pricing } = this.props;
    const { costOfLabor, costPerParts, retailPrice } = pricing;
    this.setState({
      openEditModal: false,
      errors: {},
      formData: {
        costOfLabor: costOfLabor,
        costOfParts: costPerParts,
        retailPrice: retailPrice,
      },
    });
  };
  render() {
    const { pricing, isNationalOrRegionalOrOwnerFlag } = this.props;
    const { openEditModal, errors } = this.state;
    const { retailPrice } = this.state.formData;
    return (
      <Table.Row key={pricing.displayName}>
        {isNationalOrRegionalOrOwnerFlag && (
          <Table.Cell>
            <b>{pricing.shopName}</b>
          </Table.Cell>
        )}
        <Table.Cell>{pricing.name}</Table.Cell>
        {pricing.position ? (
          <Table.Cell>{pricing.position}</Table.Cell>
        ) : (
          <Table.Cell>--</Table.Cell>
        )}
        {<Table.Cell>${pricing.shopCaradvisePrice}</Table.Cell>}
        {!openEditModal ? (
          <Table.Cell>${pricing.retailPrice}</Table.Cell>
        ) : (
          <Table.Cell>
            <Input
              required
              id="input"
              type="number"
              value={retailPrice}
              onChange={(e) => this.onFieldChange("retailPrice", e)}
              fluid
              size="large"
              min="0"
              onKeyDown={this.handleKeypress}
            />
            <span className="pricing-error-msg">
              {errors.retailPrice ? errors.retailPrice : ""}
            </span>
          </Table.Cell>
        )}
        {/* { 
              <Table.Cell>
                {pricing.retailSavings}%
              </Table.Cell> 
          }  */}
      </Table.Row>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
  currentUser: state.users.currentUser,
  currentShopId: state.shops.currentShopId,
});

export default connect(mapStateToProps)(PricingRowSummary);
