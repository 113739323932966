import React from "react";
import { withTranslation } from "react-i18next";
import { Dimmer, Loader } from "semantic-ui-react";

class CustomLoader extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <Dimmer active inverted style={{ padding: 0 }}>
        <Loader indeterminate size="massive">
          {t("loading")}
        </Loader>
      </Dimmer>
    );
  }
}

export default withTranslation("common")(CustomLoader);
