import {
  USER_PRICINGS_LOAD_SUCCEEDED,
  USER_PRICING_CREATE_SUCCEEDED,
  USER_PRICING_UPDATE_SUCCEEDED,
  USER_PRICING_DESTROY_SUCCEEDED
} from "../../constants/actionTypes"

export const initialState = {
  userPricings: [],
  extraQuarts: []
}

export default function(state=initialState, action = {}) {
  const { type, payload } = action
  let newPricings

  switch(type) {
    case USER_PRICINGS_LOAD_SUCCEEDED:
      return {
        ...state,
        userPricings: payload.pricings.pricings,
        extraQuarts: payload.pricings.additional_quarts
      }
    case USER_PRICING_CREATE_SUCCEEDED:
      return {
        ...state,
       payload
      }
    case USER_PRICING_UPDATE_SUCCEEDED:
      return {
        ...state,
        payload 
      }
    case USER_PRICING_DESTROY_SUCCEEDED:
      newPricings = state.userPricings.filter(pricing => {
        return pricing.id !== payload.pricingId
      })
      return {
        ...state,
        userPricings: newPricings
      }
    default:
      return state
  }
}
