import moment from "moment";
import {
  BACKSPACE,
  DISPLAY_EMPTY,
  DOWN_ARROW,
  LEFT_ARROW,
  RIGHT_ARROW,
  TAB,
  UP_ARROW,
} from "../constants/application";
import { DATE_TIME_FORMAT } from "../constants/dateTime";

export function toCurrencyString(value) {
  if (value == null || value === "") {
    // check null or undefined or empty string
    return "";
  } else {
    const num = typeof value === "number" ? value : Number(value);
    return `$${num
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }
}

// Function to give a default string if number value is missing or blank
export const displayCurrency = (val) => {
  return [null, undefined, ""].includes(val)
    ? DISPLAY_EMPTY
    : toCurrencyString(val);
};

export const cleanedMoneyInput = (val) => {
  // allow only digits and `.` character
  let result = val.replace(/[^\d\.]/gi, "");
  // remove duplicate `.`
  result = result.replace(/\.{2}/gi, ".");
  // find xx.xx format
  let match = result.match(/\d+?(\.{1}\d{2})/g);

  return match ? match[0] : result;
};

// TODO: move this to arrayHelpers when Michael's PR is merged with that file
export function sortByAttr(collection, attr, direction = "asc") {
  const lessThanVal = direction === "asc" ? -1 : 1;
  const greaterThanVal = lessThanVal === -1 ? 1 : -1;

  return collection.sort((a, b) => {
    let aVal = a[attr];
    let bVal = b[attr];

    if (typeof a[attr] === "string") {
      aVal = aVal.toLowerCase();
      bVal = bVal.toLowerCase();
    }

    return aVal < bVal ? lessThanVal : aVal > bVal ? greaterThanVal : 0;
  });
}

export function handleKeypress(e) {
  const characterCode = e.key;
  if (
    characterCode === BACKSPACE ||
    characterCode === TAB ||
    characterCode === LEFT_ARROW ||
    characterCode === UP_ARROW ||
    characterCode === DOWN_ARROW ||
    characterCode === RIGHT_ARROW
  )
    return;
  if (e.key == "-") {
    e.preventDefault();
    return false;
  }

  if (characterCode === ".") {
    if (e.currentTarget.value && e.currentTarget.value.length) {
      return;
    }
  }

  const characterNumber = Number(characterCode);
  if (characterNumber >= 0 && characterNumber <= 9) {
    if (e.currentTarget.value && e.currentTarget.value.length) {
      return;
    }
  } else {
    e.preventDefault();
  }
}
export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}

export function displayDateTime(val) {
  var time = moment(val).parseZone().format(DATE_TIME_FORMAT.HHMMAA);
  var date = moment(val).parseZone().format(DATE_TIME_FORMAT.DDMMM);
  var dateTime = time + " - " + date;
  return dateTime;
}

export function displayPercentage(number) {
  const option = {
    style: "percent",
  };
  const formatter = new Intl.NumberFormat("en-US", option);
  const percentage = formatter.format(number / 100);
  return percentage;
}
