import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import CustomLoader from "../../components/elements/CustomLoader";
import NoSessionLayout from "../../layouts/NoSessionLayout";
import { navigationPaths } from "../../constants/paths";
import { userSignedInAndConfirmed } from "../../helpers/sessionHelpers";
import { Button, Image } from "semantic-ui-react";
import LogoCheck from "../../assets/images/logo-checkmark.png";
import { Link } from "react-router-dom";
import { getSubdomain } from "../../components/shared/StylesheetInjector";
import { CARADVISE } from "../../constants/application";
import { loadUser } from "../../actions/sagas/userSaga";
import store from "../../actions/configureStore";
import { withTranslation } from "react-i18next";

class UnconfirmedUserMessage extends React.Component {
  componentWillMount = () => {
    store.dispatch(loadUser());
  };

  render() {
    const { isLoading, t } = this.props;
    const sub = getSubdomain();
    let logo;
    if (userSignedInAndConfirmed())
      return <Redirect to={navigationPaths.dashboard()} />;
    if (sub !== CARADVISE) {
      logo = "https://app.caradvise.com/images/" + sub + "-favicon.png";
    }
    return (
      <NoSessionLayout>
        {isLoading && <CustomLoader />}
        {!isLoading && (
          <div className="no-session-content">
            <div className="no-session-content-header">
              <div className="thankyoucontent">
                <div className="wrapper-1">
                  <div className="wrapper-2">
                    <h1 className="thank-you-title">{t("thankYou")}</h1>
                    <Image centered src={logo ? logo : LogoCheck} />
                    <p>
                      {t("weWillConfirmYourShopAndFollowUpViaEmailMessage")}
                    </p>
                    <Link to={navigationPaths.signOut()}>
                      <Button
                        negative
                        content="Sign Out"
                        className="welcome-sign-out"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </NoSessionLayout>
    );
  }
} // UnconfirmedUserMessage

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
});

export default connect(mapStateToProps, { loadUser })(
  withTranslation("users")(UnconfirmedUserMessage)
);
