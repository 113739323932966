import React, { Component } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { routerPaths } from "./constants/paths";
import {
  userSignedIn,
  userSignedInAndConfirmed,
} from "./helpers/sessionHelpers";
// import Pricing from "./components/pricings/Pricing"
// import ShopInformation from "./components/shop_information/ShopInformation"
import Membership from "./components/Membership";
import Users from "./components/Users";
import Scheduling from "./components/Scheduling";
import Analytics from "./components/Analytics";
import SessionNew from "./views/sessions/SessionNew";
import UserNew from "./views/users/UserNew";
import UnconfirmedUserMessage from "./views/users/UnconfirmedUserMessage";
import ForgotPassword from "./views/sessions/ForgotPassword";
import ResetPassword from "./views/sessions/ResetPassword";
import SessionDestroy from "./views/sessions/SessionDestroy";
import Sidebar from "./components/Sidebar";
import NotificationsContainer from "./components/NotificationsContainer";
// import Appointments from "./components/appointments/Appointments"
// import Orders from "./components/orders/Orders"
import Dashboard from "./components/dashboard/Dashboard";
import Location from "./components/location/Location";
import CustomerFeedback from "./components/customer_feedback/CustomerFeedback";
import AppointmentsOrders from "./components/appointments&orders/Main";
import Settings from "./components/settings/Settings";
import PricingNew from "./components/pricings_new/Pricing";
import Promotions from "./components/promotions/Promotions";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

const WrappedScrollToTop = withRouter(ScrollToTop);

class Router extends Component {
  preventDoubleSignIn(ComponentToRender, routeProps = {}) {
    if (userSignedIn()) {
      return <Redirect to={routerPaths.dashboard} />;
    } else {
      return <ComponentToRender {...routeProps} />;
    }
  }

  renderComponent(ComponentToRender, routeProps = {}) {
    return <ComponentToRender {...routeProps} />;
  }

  requireSignIn(ComponentToRender, routeProps = {}) {
    if (userSignedInAndConfirmed()) {
      return <ComponentToRender {...routeProps} />;
    } else if (userSignedIn()) {
      return <Redirect to={routerPaths.welcome} />;
    } else {
      const params = window.location.href.match(/key=([\w\d-]+)?&?/);
      const key = params && params[1];
      const path = window.location.pathname;
      return (
        <Redirect
          to={{
            pathname: routerPaths.signIn,
            state: { key: key, path: path },
          }}
        />
      );
    }
  }

  renderUnconfirmed(ComponentToRender, routeProps = {}) {
    if (userSignedInAndConfirmed()) {
      return <Redirect to={routerPaths.dashboard} />;
    } else if (userSignedIn()) {
      return <ComponentToRender {...routeProps} />;
    } else {
      return <Redirect to={routerPaths.signIn} />;
    }
  }

  render() {
    return (
      <BrowserRouter>
        <WrappedScrollToTop>
          <NotificationsContainer />
          <Switch>
            <Route
              exact
              path={routerPaths.signIn}
              render={(props) => this.preventDoubleSignIn(SessionNew, props)}
            />

            <Route
              exact
              path={routerPaths.signUp}
              render={(props) => this.preventDoubleSignIn(UserNew, props)}
            />

            <Route
              exact
              path={routerPaths.forgotPassword}
              render={(props) => this.renderComponent(ForgotPassword, props)}
            />

            <Route
              exact
              path={routerPaths.resetPassword}
              render={(props) => this.renderComponent(ResetPassword, props)}
            />

            <Route
              exact
              path={routerPaths.signOut}
              render={(props) => this.renderComponent(SessionDestroy, props)}
            />

            <Route
              exact
              path={routerPaths.welcome}
              render={(props) =>
                this.renderUnconfirmed(UnconfirmedUserMessage, props)
              }
            />

            <Sidebar>
              <Route
                exact
                path={routerPaths.dashboard}
                render={(props) => this.requireSignIn(Dashboard, props)}
              />

              <Route
                exact
                path={routerPaths.location}
                render={(props) => this.requireSignIn(Location, props)}
              />

              <Route
                exact
                path={routerPaths.promotions}
                render={(props) => this.requireSignIn(Promotions, props)}
              />
              {/* <Route exact path={routerPaths.pricing} render={(props) => this.requireSignIn(Pricing, props)} /> */}

              <Route
                exact
                path={routerPaths.membership}
                render={(props) => this.requireSignIn(Membership, props)}
              />

              <Route
                exact
                path={routerPaths.users}
                render={(props) => this.requireSignIn(Users, props)}
              />

              <Route
                exact
                path={routerPaths.scheduling}
                render={(props) => this.requireSignIn(Scheduling, props)}
              />

              <Route
                exact
                path={routerPaths.analytics}
                render={(props) => this.requireSignIn(Analytics, props)}
              />

              {/* <Route exact path={routerPaths.appointments} render={(props) => this.requireSignIn(Appointments, props)} />

              <Route exact path={routerPaths.orders} render={(props) => this.requireSignIn(Orders, props)} /> */}

              <Route
                exact
                path={routerPaths.customerFeedback}
                render={(props) => this.requireSignIn(CustomerFeedback, props)}
              />

              <Route
                exact
                path={routerPaths.shopOrders}
                render={(props) =>
                  this.requireSignIn(AppointmentsOrders, props)
                }
              />

              <Route
                exact
                path={routerPaths.settings}
                render={(props) => this.requireSignIn(Settings, props)}
              />
              <Route
                exact
                path={routerPaths.pricing}
                render={(props) => this.requireSignIn(PricingNew, props)}
              />
            </Sidebar>
          </Switch>
        </WrappedScrollToTop>
      </BrowserRouter>
    );
  }
}

export default Router;
