import {
  formatInternalErrors,
  consoleLog,
  catchErrors,
} from "../../helpers/apiErrorHelpers";
import {
  APPOINTMENTS_PATH,
  ORDERS_PATH,
  SET_LOADING,
  UNSET_LOADING,
  ORDERS_LOAD_SUCCEEDED,
  ORDER_UPDATE_SUCCEEDED,
  // CANCELLED,
  // WALK_IN_ACTIVE,
  // ACTIVE,
  // STATUS,
  // CHECKED_IN,
  // IN_BAY,
  // WAITING_FOR_PARTS,
  // AWAITING_APPROVAL,
  // COMPLETED,
  // IN_MANUAL_REVIEW,
  // APPROVAL,
  // PAID,
} from "../../constants/actionTypes";
import { loadAppointments } from "./appointmentSaga";
import { loadDashboard } from "./dashboardSaga";
import Notifications from "../../components/Notifications";
import store from "../configureStore";
import { getJSON, putJSON } from "../../utils/fetch";
import i18n from "../../utils/i18n";

// const SCHEDULED_STATUSES_FROM_RETAIL = [
//   ACTIVE,
//   CANCELLED,
//   WALK_IN_ACTIVE,
//   CHECKED_IN,
//   IN_BAY,
//   WAITING_FOR_PARTS,
//   AWAITING_APPROVAL,
//   APPROVAL,
//   COMPLETED,
//   IN_MANUAL_REVIEW,
//   PAID,
// ].map((string) => STATUS[string]);

export function loadOrders() {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_LOADING });

      let user = getState().users || {};
      const { authentication_token } = user;

      const request = {
        url: `/${APPOINTMENTS_PATH}`,
        headers: { Authorization: authentication_token },
        body: {},
      };

      const response = await getJSON(
        request.url,
        request.body,
        request.headers
      );
      catchErrors(response.result);

      await dispatch({
        type: ORDERS_LOAD_SUCCEEDED,
        payload: { orders: response.result },
      });

      await dispatch({ type: UNSET_LOADING });
    } catch (errors) {
      await dispatch({ type: UNSET_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:errorWhileLoadingOrdersMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
    }
  };
}

export function updateOrderStatus(action) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_LOADING });
      let user = getState().users || {};
      const { authentication_token } = user;

      const payload = action || {};
      const { id, cancellationReason, cancelledBy, shop_id } = payload;
      const request = {
        url: `/${ORDERS_PATH}/${id}?cancellation_reason=${cancellationReason}&order_cancelled_by=${cancelledBy}`,
        headers: { Authorization: authentication_token },
        body: {},
      };

      const response = await putJSON(
        request.url,
        request.body,
        request.headers
      );
      catchErrors(response.result);

      await dispatch({
        type: ORDER_UPDATE_SUCCEEDED,
        payload: { order: response.result },
      });

      await dispatch(loadAppointments({ payload: { shopId: shop_id } }));

      await dispatch(loadDashboard({ payload: { shopId: shop_id } }));

      await dispatch({ type: UNSET_LOADING });
      store.dispatch(
        Notifications.success({
          title: i18n.t("common:successTitle"),
          message: i18n.t("common:orderStatusUpdatedMsg"),
          position: "tr",
          autoDismiss: 5,
        })
      );
    } catch (errors) {
      dispatch({ type: UNSET_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:errorUpdatingStatusMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
      await dispatch(action.errorCallback);
    }
  };
}
