import React, { Component } from "react";
import { Link } from "react-router-dom";
import { navigationPaths, routerPaths } from "../constants/paths";
import { Icon } from "semantic-ui-react";
import Corousel from "../components/shared/Carousel";
import { getSubdomain } from "../components/shared/StylesheetInjector";
import ReactSVG from "react-svg";
import { connect } from "react-redux";
import PrivacyPolicyFooter from "../components/sessions/PrivacyPolicyFooter";
import { OFF, setupSplitFlags } from "../components/config/SplitClient";

class NoSessionLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  logoSize(subdomain) {
    return (
      {
        caradvise: 500,
        ntw: 400,
      }[subdomain] || 208
    );
  }
  logoMobileSize(subdomain) {
    return (
      {
        caradvise: 300,
        ntw: 300,
      }[subdomain] || 208
    );
  }
  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain() });
  }
  render() {
    const sub = getSubdomain();
    let svgStyle = this.logoSize(sub) ? { width: this.logoSize(sub) } : {};
    let svgMobileStyle = this.logoMobileSize(sub)
      ? { width: this.logoMobileSize(sub) }
      : {};
    let headerLogo = `${sub}_logo.svg`;
    return (
      <div className={"app"}>
        <div className={"no-session-container"}>
          <div className="no-session-container-header">
            {window.location.pathname !== routerPaths.signIn && (
              <Link
                className="no-session-cursor-pointer"
                to={navigationPaths.signIn()}
              >
                <Icon
                  inverted
                  color="grey"
                  name="arrow left"
                  size="large"
                  className="no-session-cursor-pointer"
                />
              </Link>
            )}
            <div className={"no-session-logo-region-small"}>
              <ReactSVG
                src={`images/${headerLogo}`}
                className="no-session-logo-small"
                svgStyle={svgMobileStyle}
              />
            </div>
          </div>
          <div className="no-session-carousel">
            <Corousel headerLogo={headerLogo} svgStyle={svgStyle} />
          </div>

          <div className={"no-session-content-container"}>
            {this.props.children}
          </div>
        </div>
        {this.state.showFooterComponent &&
          this.state.showFooterComponent !== OFF && (
            <PrivacyPolicyFooter
              footerComponentConfig={this.state.footerComponentConfig}
            />
          )}
      </div>
    );
  }
} // NoSessionLayout

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser || {},
});

export default connect(mapStateToProps)(NoSessionLayout);
