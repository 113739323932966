import {
  formatInternalErrors,
  toString,
  consoleLog,
  catchErrors,
} from "../../helpers/apiErrorHelpers";
import {
  setApplicationCookie,
  deleteApplicationCookies,
  JWT_COOKIE_KEY,
  ID_COOKIE_KEY,
  EMAIL_COOKIE_KEY,
  CONFIRMED_COOKIE_KEY,
} from "../../helpers/sessionHelpers";
import {
  SET_LOADING,
  UNSET_LOADING,
  CURRENT_USER_LOAD_SUCCEEDED,
  SIGN_OUT_SUCCEEDED,
  SESSIONS_PATH,
} from "../../constants/actionTypes";
import Notifications from "../../components/Notifications";
import store from "../configureStore";
import { postJSON } from "../../utils/fetch";
import { navigationPaths } from "../../constants/paths";
import { removeUser, setUserData } from "./userSaga";
import i18n from "../../utils/i18n";

export function signIn(formData, opts = {}) {
  return async function (dispatch) {
    dispatch({ type: SET_LOADING });
    const request = {
      url: SESSIONS_PATH,
      headers: {},
      body: {
        email: formData.email,
        password: formData.password,
        key: formData.key,
      },
    };
    const response = await postJSON(request.url, request.body, request.headers);
    if (response.result) {
      await dispatch(setUserData(response.result));
      await dispatch(storeUserFromToken(response.result));
      await dispatch({ type: UNSET_LOADING });
      if (response.result.authentication_token) {
        opts.history.push({ pathname: navigationPaths.dashboard() });
      }
    } else {
      dispatch({ type: UNSET_LOADING });
      const formattedErrors = formatInternalErrors(
        response.error || response.errors
      );
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:signInFailedMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
    }
  };
}

export function storeUserFromToken(user) {
  return async function (dispatch) {
    try {
      const { authentication_token, id, email, confirmed, role } = user;
      setApplicationCookie(JWT_COOKIE_KEY, authentication_token);
      setApplicationCookie(ID_COOKIE_KEY, id);
      setApplicationCookie(EMAIL_COOKIE_KEY, email);
      setApplicationCookie(CONFIRMED_COOKIE_KEY, confirmed);

      dispatch({
        type: CURRENT_USER_LOAD_SUCCEEDED,
        payload: {
          currentUser: {
            id: id,
            email: email,
            confirmed: confirmed,
            role: role,
          },
        },
      });
    } catch (errors) {
      const formattedErrors = formatInternalErrors(errors);
      consoleLog(formattedErrors);
    }
  };
}

export function signOut(opts = {}) {
  return async function (dispatch) {
    await dispatch({ type: SET_LOADING });
    const success = deleteApplicationCookies();
    if (success !== true)
      throw new Error(i18n.t("common:somethingWentWrongMsg"));
    await dispatch(removeUser());
    await dispatch({ type: SIGN_OUT_SUCCEEDED });
    await dispatch({ type: UNSET_LOADING });
    if (opts.callback) opts.callback();
  };
}
