import { EXTRA_QUARTS_WHATS_INCLUDED } from "../constants/pricing";
import { displayCurrency } from "./numberHelpers";

export const constructPricings = (
  currentShopId,
  commonServices,
  userPricings
) => {
  let pricings = [];
  userPricings.forEach((p) => {
    const {
      id,
      position,
      service_definition,
      company,
      shop_id,
      retail_price,
      updated_at,
      company_id,
      caradvise_avg_price,
      pricing,
      brand_price,
      cost_of_labor,
      cost_per_parts,
    } = p;
    pricings.push({
      id: id,
      companyId: company_id || (company && company.id),
      updatedAt: updated_at,
      name: service_definition.name,
      position: position,
      serviceDefinitionId: service_definition.id,
      shopId: shop_id,
      shopCaradvisePrice: parseFloat(pricing).toFixed(2),
      retailPrice: parseFloat(retail_price).toFixed(2),
      caradviseAvgPrice: parseFloat(caradvise_avg_price).toFixed(2),
      brandPrice: Math.round(brand_price * Math.pow(10, 2)) / Math.pow(10, 2),
      retailSavings: company && company.retail_savings,
      costOfLabor:
        Math.round(cost_of_labor * Math.pow(10, 2)) / Math.pow(10, 2),
      costPerParts:
        Math.round(cost_per_parts * Math.pow(10, 2)) / Math.pow(10, 2),
    });
  });
  commonServices.forEach((sd) => {
    if (sd.positions.length > 0) {
      sd.positions.forEach((p) => {
        if (pricingAlreadyIncluded(pricings, sd, p)) return;
        pricings.push({
          name: sd.name,
          position: p,
          serviceDefinitionId: sd.service_id,
          shopId: currentShopId,
        });
      });
    } else {
      if (pricingAlreadyIncluded(pricings, sd)) return;
      pricings.push({
        name: sd.name,
        serviceDefinitionId: sd.service_id,
        shopId: currentShopId,
      });
    }
  });

  return pricings.map((p) => ({ ...p, displayName: displayName(p) }));
};

export function constructSearchPricings(currentShopId, serviceDefs) {
  let pricings = [];

  serviceDefs.forEach((sd) => {
    if (sd.positions.length > 0) {
      sd.positions.forEach((p) => {
        pricings.push({
          name: sd.name,
          position: p,
          serviceDefinitionId: sd.id,
          shopId: currentShopId,
        });
      });
    } else {
      pricings.push({
        name: sd.name,
        serviceDefinitionId: sd.id,
        shopId: currentShopId,
      });
    }
  });

  return pricings.map((p) => ({ ...p, displayName: displayName(p) }));
}

function pricingAlreadyIncluded(pricings, serviceDef, position) {
  // use `==` for position to catch undefined or null
  return pricings.find(
    (p) =>
      p.serviceDefinitionId === serviceDef.service_id && p.position === position
  );
}

function displayName(pricing) {
  let { name, position } = pricing;
  return position == null ? name : `${name} - ${position}`;
}

export const constructUserPricings = (userPricings, shops) => {
  let pricings = [];
  userPricings.forEach((p) => {
    const {
      id,
      position,
      service_definition,
      company,
      shop_id,
      retail_price,
      updated_at,
      caradvise_avg_price,
      pricing,
      brand_price,
      cost_of_labor,
      cost_per_parts,
      company_id,
    } = p;
    const shop =
      shops &&
      shops.length > 0 &&
      shops.filter((shop) => shop.id === shop_id)[0];
    pricings.push({
      id: id,
      companyId: company_id || (company && company.id),
      updatedAt: updated_at,
      name: service_definition.name,
      position: position,
      serviceDefinitionId: service_definition.id,
      shopId: shop_id,
      shopCaradvisePrice: parseFloat(pricing).toFixed(2),
      retailPrice: parseFloat(retail_price).toFixed(2),
      caradviseAvgPrice: parseFloat(caradvise_avg_price).toFixed(2),
      brandPrice: Math.round(brand_price * Math.pow(10, 2)) / Math.pow(10, 2),
      retailSavings: company && company.retail_savings,
      costOfLabor:
        Math.round(cost_of_labor * Math.pow(10, 2)) / Math.pow(10, 2),
      costPerParts:
        Math.round(cost_per_parts * Math.pow(10, 2)) / Math.pow(10, 2),
      shopName: `${shop.name} ${shop.store_number}`,
    });
  });

  return pricings.map((p) => ({ ...p, displayName: displayName(p) }));
};

export const getRetailsSavingsAmount = (retailPrice, retailSavings) => {
  return (retailPrice * retailSavings) / 100;
};

function getKeyValue(object) {
  return Object.keys(object).reduce(function (result, key) {
    return result.concat(
      object[key] && typeof object[key] === "object"
        ? getKeyValue(object[key])
        : [[key, object[key]]]
    );
  }, []);
}

function titleCase(str) {
  var splitStr = str.toLowerCase().split("_");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

export const constructExtraQuarts = (extraQuarts) => {
  let quarts = [];
  const userQuarts = getKeyValue(extraQuarts);
  userQuarts.forEach((p) => {
    quarts.push({
      displayName: titleCase(p[0]),
      name: p[0],
      retailPrice: p[1],
      whatsIncluded: EXTRA_QUARTS_WHATS_INCLUDED,
    });
  });
  return quarts;
};

export function calculateRetailSavingsPercentage(retailPrice, carAdvisePrice) {
  const option = {
    style: "percent",
  };
  const formatter = new Intl.NumberFormat("en-US", option);
  const retailSavingsPercentage = formatter.format(
    (retailPrice - carAdvisePrice) / retailPrice
  );
  const retailSavings = retailPrice - carAdvisePrice;
  const displayRetailsSavings =
    retailSavingsPercentage + " - " + displayCurrency(retailSavings);
  return displayRetailsSavings;
}
