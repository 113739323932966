import React from "react";
import PrivacyModal from "./PrivacyPolicy";
import Terms from "./Terms";
import { NTWTBC } from "../../constants/application";

export default function PrivacyPolicyAndTerms({ t, subdomain }) {
  return (
    <div>
      {t("bySigningInYouAreAgreeText")}{" "}
      {subdomain === NTWTBC ? (
        <>
          <a
            style={{ textDecoration: "underline" }}
            href="https://www.ntw.com/privacy-policy"
            rel="noopener noreferrer"
            target="_blank"
          >
            {" "}
            {t("privacyPolicy")}{" "}
          </a>{" "}
          {t("and")}{" "}
          <a
            style={{ textDecoration: "underline" }}
            href="https://www.ntw.com/terms-of-use"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t("termsOfUse")}
          </a>
        </>
      ) : (
        <>
          <PrivacyModal t={t} /> {t("and")} <Terms t={t} />
        </>
      )}
    </div>
  );
}
