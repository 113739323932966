import _ from "lodash";
import store from "../actions/configureStore";
import * as uiActions from "../actions/ui";

export function selectShopOptions(shops, options = {}) {
  let shopOptions = [];
  if (shops && shops.length > 0) {
    shopOptions = options.includeBlank
      ? [{ text: options.t("allShops"), value: "null" }]
      : [];
  }
  shops &&
    shops.length > 0 &&
    _.uniqBy(shops, "id").forEach((shop) => {
      shopOptions.push({
        text: `${shop.name} ${shop.store_number}`,
        value: shop.id,
      });
      if (!options.includeBlank) {
        store.dispatch(uiActions.updateCurrentShopId({ id: shop.id }));
      }
    });
  return shopOptions;
}
