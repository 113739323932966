import {
  SET_APPOINTMENT_LOADING,
  SET_DASHBOARD_LOADING,
  SET_LOADING,
  SET_SHOP_FEEDBACK_LOADING,
  SET_SHOP_HOURS_LOADING,
  SET_SHOP_PRICINGS_LOADING,
  UNSET_APPOINTMENT_LOADING,
  UNSET_DASHBOARD_LOADING,
  UNSET_LOADING,
  UNSET_SHOP_FEEDBACK_LOADING,
  UNSET_SHOP_HOURS_LOADING,
  UNSET_SHOP_PRICINGS_LOADING,
} from "../../constants/actionTypes";

const initialState = {
  isLoading: false,
};

export default function (state = initialState, action = {}) {
  const { type } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case UNSET_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case SET_APPOINTMENT_LOADING:
      return {
        ...state,
        isAppointmentsLoading: true,
      };
    case UNSET_APPOINTMENT_LOADING:
      return {
        ...state,
        isAppointmentsLoading: false,
      };
    case SET_DASHBOARD_LOADING:
      return {
        ...state,
        isDashboardLoading: true,
      };
    case UNSET_DASHBOARD_LOADING:
      return {
        ...state,
        isDashboardLoading: false,
      };
    case SET_SHOP_HOURS_LOADING:
      return {
        ...state,
        isShopHoursLoading: true,
      };
    case UNSET_SHOP_HOURS_LOADING:
      return {
        ...state,
        isShopHoursLoading: false,
      };
    case SET_SHOP_PRICINGS_LOADING:
      return {
        ...state,
        isShopPricingsLoading: true,
      };
    case UNSET_SHOP_PRICINGS_LOADING:
      return {
        ...state,
        isShopPricingsLoading: false,
      };
    case SET_SHOP_FEEDBACK_LOADING:
      return {
        ...state,
        isShopFeedbackLoading: true,
      };
    case UNSET_SHOP_FEEDBACK_LOADING:
      return {
        ...state,
        isShopFeedbackLoading: false,
      };
    default:
      return state;
  }
}
