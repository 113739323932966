import welcome1 from "../assets/images/welcome1.png";
import welcome2 from "../assets/images/welcome2.png";
import welcome3 from "../assets/images/welcome3.png";

export const CARADVISE_NO_SESSION_BULLETS = [
  {
    image: welcome1,
    title: "carousel:searchAndCompareTitle",
    text: "carousel:save10To40PerOnVehicleText",
  },
  {
    image: welcome2,
    title: "carousel:bookAndScheduleTitle",
    text: "carousel:easilyBookAndScheduleText",
  },
  {
    image: welcome3,
    title: "carousel:approveAndPayTitle",
    text: "carousel:onlyPayForWhatNeedText",
  },
];

export const NTW_FLEET_DEALER_NO_SESSION_BULLETS = [
  {
    image: welcome1,
    title: "carousel:attractAndEarnAdditionalFleetTitle",
    text: "carousel:haveSeamlesDigitalConnectionText",
  },
  {
    image: welcome2,
    title: "carousel:digitalFleetManagementTitle",
    text: "carousel:easilyManageAppointmentText",
  },
  {
    image: welcome3,
    title: "carousel:earnAndRetainMoreFleetTitle",
    text: "carousel:fleetsEnjoyEnhancedText",
  },
];
