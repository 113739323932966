import React from "react";
import { connect } from "react-redux";
import CustomLoader from "../elements/CustomLoader";
import {
  constructExtraQuarts,
  constructPricings,
} from "../../helpers/pricingsHelpers";
import PricingsTableEnhanced from "./PricingTableEnhanced";
import { isEmpty } from "lodash";
import NoShopSelected from "../shared/NoShopSelected";
import store from "../../actions/configureStore";
import { loadCommonServices } from "../../actions/sagas/commonServicesSaga";
import { loadPricings } from "../../actions/sagas/pricingSaga";
import { withTranslation } from "react-i18next";

class Pricing extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.currentShopId !== "null" &&
      this.props.currentShopId !== prevProps.currentShopId
    ) {
      store.dispatch(loadPricings());
      store.dispatch(loadCommonServices());
    }
  }

  componentDidMount() {
    const { currentShopId, pricings } = this.props;
    if (currentShopId && currentShopId !== "null" && isEmpty(pricings)) {
      store.dispatch(loadPricings());
      store.dispatch(loadCommonServices());
    }
  }

  render() {
    const {
      currentShop,
      isLoading,
      pricings,
      currentShopId,
      currentUser,
      extraQuarts,
      dispatch,
      t,
    } = this.props;
    return (
      <div className="full-pricing-container">
        {isLoading && <CustomLoader />}
        {!isLoading && (
          <React.Fragment>
            {currentShop ? (
              <PricingsTableEnhanced
                currentShopId={currentShopId}
                pricings={pricings}
                extraQuarts={extraQuarts}
                currentUser={currentUser}
                currentShop={currentShop}
                dispatch={dispatch}
                t={t}
              />
            ) : (
              <NoShopSelected message={t("selectShopForPricingMessage")} />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentShopId } = state.shops;
  const currentShop = state.shops.userShops.find(
    (s) => s.id === state.shops.currentShopId
  );
  const userPricings = state.pricings.userPricings.filter(
    (p) => p.shop_id === currentShopId
  );
  const extraQuarts = state.pricings.extraQuarts;
  const commonServices = state.commonServices.commonServices;

  return {
    isLoading:
      state.application.isShopPricingsLoading || state.application.isLoading
        ? true
        : false,
    currentUser: state.users.currentUser,
    currentShop: currentShop,
    currentShopId: currentShopId,
    commonServices: commonServices,
    pricings: constructPricings(currentShopId, commonServices, userPricings),
    extraQuarts: constructExtraQuarts(extraQuarts),
  };
};

export default connect(mapStateToProps)(withTranslation("pricings")(Pricing));
