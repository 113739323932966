import React from "react";
import { Item, Modal } from "semantic-ui-react";

class ShowServicesModal extends React.Component {
  render() {
    const { open, close, services, t } = this.props;
    return (
      <Modal
        size="small"
        closeIcon
        open={open}
        onClose={() => close()}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{t("services")}</Modal.Header>
        <Modal.Content className="scrolling content">
          <div style={{ maxHeight: "40vh" }}>
            {services.map((service, index) => {
              return (
                <Modal.Description key={service.id}>
                  <Item.Group divided>
                    <Item>
                      <Item.Content verticalAlign="middle">
                        <Item.Header>
                          {index + 1}. {service.name}
                        </Item.Header>
                        <Item.Description>
                          {t("totalPrice")} $
                          {service.price_estimate.shop_price_estimate
                            ? service.price_estimate.shop_price_estimate
                            : service.override_total}
                        </Item.Description>
                      </Item.Content>
                    </Item>
                  </Item.Group>
                  <br />
                </Modal.Description>
              );
            })}
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default ShowServicesModal;
