import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import TranslationDropdown from "./Translation";
const COPYRIGHT = "copyRight";
const DO_NOT_SHARE_INFORMATION = "doNotShareInfo";
class PrivacyPolicyFooter extends Component {
  render() {
    const { footerComponentConfig, t } = this.props;
    let isFirstKey = true;
    return (
      <React.Fragment>
        {!isEmpty(footerComponentConfig) && (
          <div className="privacy-policy-footer">
            <footer>
              <div className="info">
                {footerComponentConfig.hasOwnProperty(COPYRIGHT) ? (
                  <div className="copyright">
                    {t(footerComponentConfig.copyRight.label)}
                  </div>
                ) : (
                  ""
                )}
                {Object.keys(footerComponentConfig).map((key, index) => {
                  const { url, label } = footerComponentConfig[key];
                  const prefix = !isFirstKey ? `|` : "";
                  if (key === COPYRIGHT) {
                    return;
                  }
                  isFirstKey = false;
                  return (
                    <div className="legal">
                      <>
                        {prefix}
                        <a href={url} target="_blank">
                          {" "}
                          {t(label)}{" "}
                        </a>
                      </>
                    </div>
                  );
                })}
              </div>
              {
                <TranslationDropdown
                  footerComponentConfig={footerComponentConfig}
                />
              }
            </footer>
          </div>
        )}
      </React.Fragment>
    );
  }
} // class PrivacyPolicyFooter

export default withTranslation("common")(PrivacyPolicyFooter);
