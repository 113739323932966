import {
  formatInternalErrors,
  consoleLog,
  catchErrors,
} from "../../helpers/apiErrorHelpers";
import {
  APPOINTMENTS_PATH,
  SET_APPOINTMENT_LOADING,
  UNSET_APPOINTMENT_LOADING,
  APPOINTMENTS_LOAD_SUCCEEDED,
  APPOINTMENT_UPDATE_SUCCEEDED,
  // WALK_IN,
  // INITIATED_BY_USER,
  // STATUS,
  // ACTIVE,
  // CANCELLED,
  // WALK_IN_ACTIVE,
  // CHECKED_IN,
  // IN_BAY,
  // WAITING_FOR_PARTS,
  // AWAITING_APPROVAL,
  // COMPLETED,
  // IN_MANUAL_REVIEW,
  // ACTIVE_NEEDS_RESCHEDULE,
  // USER_INITIATED_NEEDS_RESCHEDULE,
  // WALK_IN_NEEDS_RESCHEDULE,
  // WALK_IN_ACTIVE_NEEDS_RESCHEDULE,
  // APPROVAL,
  // PAID,
  // PAID_AT_SHOP,
} from "../../constants/actionTypes";
import { loadOrders } from "./orderSaga";
import Notifications from "../../components/Notifications";
import store from "../configureStore";
import { getJSON, postJSON } from "../../utils/fetch";
import i18n from "../../utils/i18n";

// const SCHEDULED_STATUSES_FROM_RETAIL = [
//   ACTIVE,
//   CANCELLED,
//   WALK_IN_ACTIVE,
//   CHECKED_IN,
//   IN_BAY,
//   WAITING_FOR_PARTS,
//   AWAITING_APPROVAL,
//   COMPLETED,
//   IN_MANUAL_REVIEW,
//   WALK_IN,
//   USER_INITIATED_NEEDS_RESCHEDULE,
//   WALK_IN_ACTIVE_NEEDS_RESCHEDULE,
//   APPROVAL,
//   ACTIVE_NEEDS_RESCHEDULE,
//   WALK_IN_NEEDS_RESCHEDULE,
//   INITIATED_BY_USER,
//   PAID_AT_SHOP,
//   PAID,
// ].map((string) => STATUS[string]);

export function loadAppointments() {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_APPOINTMENT_LOADING });

      let user = getState().users || {};
      const { authentication_token } = user;

      const request = {
        url: `/${APPOINTMENTS_PATH}`,
        headers: { Authorization: authentication_token },
        body: {},
      };

      const response = await getJSON(
        request.url,
        request.body,
        request.headers
      );
      catchErrors(response.result);

      await dispatch({
        type: APPOINTMENTS_LOAD_SUCCEEDED,
        payload: { appointments: response.result },
      });

      await dispatch({ type: UNSET_APPOINTMENT_LOADING });
    } catch (errors) {
      await dispatch({ type: UNSET_APPOINTMENT_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:errorWhileLoadingAptsMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
    }
  };
}

export function updateAppointmentStatus(action) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SET_APPOINTMENT_LOADING });
      let user = getState().users || {};
      const { authentication_token } = user;

      const payload = action.payload || {};
      const {
        id,
        isAppointmentConfirmed,
        rejectReason,
        rejectedBy,
        shop_id,
        date,
      } = payload;

      const request = {
        url: `/${APPOINTMENTS_PATH}?order_id=${id}&appt_flag=${isAppointmentConfirmed}&appt_cancel_reason=${rejectReason}&appt_cancel_by=${rejectedBy}`,
        headers: { Authorization: authentication_token },
        body: {
          date: date,
        },
      };

      const response = await postJSON(
        request.url,
        request.body,
        request.headers
      );
      catchErrors(response.result);

      dispatch({
        type: APPOINTMENT_UPDATE_SUCCEEDED,
        payload: { appointment: response.result.shop_order },
      });

      if (shop_id) loadOrders({ payload: { shopId: shop_id } });
      dispatch({ type: UNSET_APPOINTMENT_LOADING });
      store.dispatch(
        Notifications.success({
          title: i18n.t("common:successTitle"),
          message: i18n.t("common:appointmentStatusUpdatedMsg"),
          position: "tr",
          autoDismiss: 5,
        })
      );
    } catch (errors) {
      dispatch({ type: UNSET_APPOINTMENT_LOADING });
      const formattedErrors = formatInternalErrors(errors);
      store.dispatch(
        Notifications.error({
          title: i18n.t("common:errorTitle"),
          message: `${i18n.t("common:errorUpdatingStatusMsg")} ${toString(
            formattedErrors
          )}`,
          position: "tr",
          autoDismiss: 5,
        })
      );
      consoleLog(formattedErrors);
    }
  };
}
