import {
    APPOINTMENTS_LOAD_SUCCEEDED, APPOINTMENT_UPDATE_SUCCEEDED,
  } from "../../constants/actionTypes"

  export const initialState = {
    userAppointments: []
  }

  export default function(state=initialState, action = {}) {
    const { payload, type } = action
    switch(type) {
      case APPOINTMENTS_LOAD_SUCCEEDED:
        return {
          ...state,
          userAppointments: payload.appointments
        }
      case APPOINTMENT_UPDATE_SUCCEEDED:
          const newAppointments = state.userAppointments.map(appt => {
            return appt.id === payload.appointment.id ? payload.appointment : appt;
          })
        return {
          ...state,
          userAppointments: newAppointments
        }   
      default:
        return state
    }
  }
  