import React, { Component } from "react";
import { setLanguage } from "../../helpers/translationHelpers";
import { EN_LOCALE, FR_LOCALE, ES_LOCALE } from "../../constants/actionTypes";
import store from "../../actions/configureStore";

export default class TranslationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectLang: localStorage.getItem("selectedLang") || "en",
    };
  }

  changeLangauge = async (e) => {
    const langCode = e.target.value;
    await this.setState({ selectLang: langCode });
    await localStorage.setItem("selectedLang", this.state.selectLang);
    const lang = localStorage.getItem("selectedLang");

    const preferences =
      lang === FR_LOCALE
        ? { language: FR_LOCALE }
        : lang === ES_LOCALE
        ? { language: ES_LOCALE }
        : { language: EN_LOCALE };
    setLanguage(preferences["language"]);
    store.dispatch({
      type: "SET_USER_LANGUAGE",
      payload: { language: preferences.language },
    });
  };
  componentDidMount = () => {
    const userSelectedLanguage = localStorage.getItem("selectedLang");
    if (userSelectedLanguage === ES_LOCALE) {
      store.dispatch({
        type: "SET_USER_LANGUAGE",
        payload: { language: ES_LOCALE },
      });
      setLanguage(ES_LOCALE);
    } else if (userSelectedLanguage === FR_LOCALE) {
      store.dispatch({
        type: "SET_USER_LANGUAGE",
        payload: { language: FR_LOCALE },
      });
      setLanguage(FR_LOCALE);
    } else {
      store.dispatch({
        type: "SET_USER_LANGUAGE",
        payload: { language: EN_LOCALE },
      });
      setLanguage(EN_LOCALE);
    }
  };

  render() {
    const { footerComponentConfig } = this.props;
    return (
      <React.Fragment>
        {footerComponentConfig &&
          footerComponentConfig.showTranslationComponent && (
            <div>
              <select
                value={this.state.selectLang}
                onChange={this.changeLangauge}
                id="lang"
              >
                <option value="en">English (US)</option>
                <option value="fr">Français (CA)</option>
                <option value="es">Español (US)</option>
              </select>
            </div>
          )}
      </React.Fragment>
    );
  }
}
