import JSZip from "jszip";
export const fileSizes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export function handleDownload(files) {
  files.forEach((file) => {
    const { url } = file;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network Error");
        }
        return response.blob();
      })
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobURL;
        link.setAttribute("download", getFileNameFromUrl(url));
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  });
}

export function handleDownloadAll(files) {
  const zip = new JSZip();

  const filePromises = files.map((file) => {
    const fileName = new URL(file.url).pathname.split("/").pop();
    return fetch(file.url)
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          throw new Error(
            `Error with status ${response.status}, url: ${response.url}`
          );
        }
      })
      .then((blob) => {
        return zip.file(fileName, blob);
      });
  });

  Promise.all(filePromises)
    .then(() => {
      return zip.generateAsync({ type: "blob" });
    })
    .then((content) => {
      const url = window.URL.createObjectURL(content);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "promotions_documents.zip");
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

export const getFileNameFromUrl = (url) => {
  return new URL(url).pathname.split("/").pop();
};
