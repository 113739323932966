import moment from "moment";

export const INVALID_DATE = "Invalid date";
export const WALK_IN_TIME = "03:00 AM";
export const MONTH = "month";

export const DATE_FORMAT = Object.freeze({
  YYYY_MM_DD: "YYYY-MM-DD",
  MMMM_YYYY: "MMMM YYYY",
  MM_DD_YYYY: "MM/DD/YYYY",
  M_D_YY: "M/D/YY",
});

export const DATE_TIME_FORMAT = Object.freeze({
  LT: "HH:mm",
  LTS: "HH:mm:ss",
  L: "DD/MM/YYYY",
  LL: "D MMMM YYYY",
  LLL: "D MMMM YYYY HH:mm A",
  LLLL: "dddd D MMMM YYYY HH:mm",
  HHMM: "HH:mm",
  DDMMM: "DD MMM",
  HHMMAA: "hh:mm A",
  MMDYYHH: "MMMM D, YYYY HH:mm:ss",
  YYDM: "MM-DD-YY HH:mm",
  lll: "ddd Do MMMM YY hh:mm A",
  YYYYMD_HHMMSS: "YYYY-MM-DD hh:mm:ss A",
});

export const DAY_ORDER_IN_WEEK = Object.freeze({
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
});

export const DAYS = Object.freeze({
  SUNDAY: "location:sunday",
  MONDAY: "location:monday",
  TUESDAY: "location:tuesday",
  WEDNESDAY: "location:wednesday",
  THURSDAY: "location:thursday",
  FRIDAY: "location:friday",
  SATURDAY: "location:saturday",
});

export const WEEK_DAYS = Object.freeze({
  SUNDAY: "Sun",
  MONDAY: "Mon",
  TUESDAY: "Tue",
  WEDNESDAY: "Wed",
  THURSDAY: "Thu",
  FRIDAY: "Fri",
  SATURDAY: "Sat",
});

export const TODAY = "customerFeedback:today";
export const THIS_WEEK = "customerFeedback:thisWeek";
export const THIS_MONTH = "customerFeedback:thisMonth";
export const THIS_YEAR = "customerFeedback:thisYear";
export const ALL_TIME = "customerFeedback:allTime";

export const summary = [
  {
    key: TODAY,
    text: TODAY,
    value: TODAY,
  },
  {
    key: THIS_WEEK,
    text: THIS_WEEK,
    value: THIS_WEEK,
  },
  {
    key: THIS_MONTH,
    text: THIS_MONTH,
    value: THIS_MONTH,
  },
];

export const filters = [
  {
    key: TODAY,
    text: TODAY,
    value: moment().format(DATE_FORMAT.YYYY_MM_DD),
  },
  {
    key: THIS_WEEK,
    text: THIS_WEEK,
    value: moment().clone().weekday(0),
  },
  {
    key: THIS_MONTH,
    text: THIS_MONTH,
    value:
      moment().format(DATE_FORMAT.YYYY_MM_DD).split("-")[0] +
      "-" +
      moment().format(DATE_FORMAT.YYYY_MM_DD).split("-")[1],
  },
  {
    key: THIS_YEAR,
    text: THIS_YEAR,
    value: moment().format(DATE_FORMAT.YYYY_MM_DD).split("-")[0],
  },
  {
    key: ALL_TIME,
    text: ALL_TIME,
    value: null,
  },
];

export const ERRORS_MESSAGES = Object.freeze({
  OPEN_TIME_REQUIRED: "location:openTimeIsRequired",
  CLOSE_TIME_REQUIRED: "location:closeTimeIsRequired",
  OPEN_TIME_MUST_BE_LESS: "location:openTimeMustBeLessThanCloseTime",
  CLOSE_TIME_MUST_BE_GREATER: "location:closeTimeMustBeGreaterThanOpenTime",
  OPEN_TIME_SHOULD_NOT_SAME: "location:openTimeShouldNotBeSameAsCloseTime",
  CLOSE_TIME_SHOULD_NOT_SAME: "location:closeTimeShouldNotBeSameAsOpenTime",
});
