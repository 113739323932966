import React from "react";
import { Button, Grid, Icon } from "semantic-ui-react";
import {
  BASIC_WAITING_ROOM,
  BASIC_WAITING_ROOM_LABEL,
  DROP_OFF,
  DROP_OFF_LABEL,
  FLAT_SCREEN_TV,
  FLAT_SCREEN_TV_LABEL,
  FREE_COFFEE,
  FREE_COFFEE_LABEL,
  FREE_WIFI,
  FREE_WIFI_LABEL,
  HIGH_END_WAITING_ROOM,
  HIGH_END_WAITING_ROOM_LABEL,
  KID_FRIENDLY,
  KID_FRIENDLY_LABEL,
  LOANER,
  LOANER_LABEL,
  NA,
  SHUTTLE,
  SHUTTLE_LABEL,
} from "../../constants/application";
import { formatPhoneNumber } from "../../helpers/numberHelpers";
import EditShopDetailsModal from "./EditShopDetailsModal";
import ShopBusinessHoursEditModal from "./ShopBusinessHoursEditModal";
import ShopHours from "./ShopHours";
import AmenitiesEditModal from "./AmenitiesEditModal";
import { isShopOwnerOrCompanyOwner } from "../../helpers/userHelpers";

class ShopBasicDetails extends React.Component {
  state = {
    mobileNumber: "",
    openEditShopDetailsModal: false,
    openEditShopBusinessHoursModal: false,
    openAmenitiesModal: false,
    amenitisArray: [],
    iconClassName: "not-display-icon",
    editButtonClass: "not-display-icon",
    className: "not-display-icon",
  };

  editShopDetailsModalClose = () => {
    this.setState({
      openEditShopDetailsModal: false,
      openEditShopBusinessHoursModal: false,
      openAmenitiesModal: false,
    });
  };

  getAmenities = (object) => {
    const { amenitisArray } = this.state;
    Object.entries(object).forEach(([key, value]) => {
      if (value === true && !amenitisArray.includes(key))
        amenitisArray.push(key);
    });
  };

  showAmenities = (amenities) => {
    const { t } = this.props;
    return amenities.map((aminity) => (
      <li key={aminity}>
        {aminity === FREE_WIFI
          ? t(FREE_WIFI_LABEL)
          : aminity === BASIC_WAITING_ROOM
          ? t(BASIC_WAITING_ROOM_LABEL)
          : aminity === FLAT_SCREEN_TV
          ? t(FLAT_SCREEN_TV_LABEL)
          : aminity === FREE_COFFEE
          ? t(FREE_COFFEE_LABEL)
          : aminity === HIGH_END_WAITING_ROOM
          ? t(HIGH_END_WAITING_ROOM_LABEL)
          : aminity === LOANER
          ? t(LOANER_LABEL)
          : aminity === DROP_OFF
          ? t(DROP_OFF_LABEL)
          : aminity === KID_FRIENDLY
          ? t(KID_FRIENDLY_LABEL)
          : aminity === SHUTTLE
          ? t(SHUTTLE_LABEL)
          : ""}
      </li>
    ));
  };

  render() {
    const { shopBusinessHours, currentUser, t } = this.props;
    const lang = localStorage.getItem("selectedLang");
    const {
      openEditShopDetailsModal,
      openEditShopBusinessHoursModal,
      openAmenitiesModal,
      amenitisArray,
      iconClassName,
      editButtonClass,
      className,
    } = this.state;
    const {
      name,
      address_line1,
      city,
      state,
      zip,
      store_number,
      main_phone_number,
      main_email,
      owner_name,
      number_of_bays,
      operational_business_days,
      amenities,
    } = shopBusinessHours;
    const currentShopInitials =
      name &&
      `${name
        .slice(0, 1)
        .toLowerCase()
        .replace(/(^| )(\w)/g, (s) => s.toUpperCase())}`;
    const currentShopAddress =
      address_line1 && address_line1 + ", " + city + ", " + state + ", " + zip;
    if (amenities) {
      this.getAmenities(amenities);
    }
    const aminitiesArray1 = amenitisArray.slice(amenitisArray.length / 2);
    const aminitiesArray2 = amenitisArray.slice(0, amenitisArray.length / 2);
    return (
      <React.Fragment>
        <div
          className="shop-info"
          onMouseEnter={(e) =>
            this.setState({ editButtonClass: "display-edit-button" })
          }
          onMouseLeave={(e) =>
            this.setState({ editButtonClass: "not-display-icon" })
          }
        >
          <div className="my-shop-logo">{currentShopInitials}</div>
          <div>
            <span className="shop-name">{name && name}</span>
            <span className="shop-address">{currentShopAddress}</span>
          </div>
          <div className={editButtonClass}>
            {isShopOwnerOrCompanyOwner(currentUser) && (
              <Button
                circular
                size="large"
                onClick={() =>
                  this.setState({
                    openEditShopDetailsModal: !openEditShopDetailsModal,
                  })
                }
                style={{ width: lang === "fr" ? "100px" : "80px" }}
              >
                {t("edit")}
              </Button>
            )}
          </div>
          <div className={editButtonClass}>
            {openEditShopDetailsModal ? (
              <EditShopDetailsModal
                open={openEditShopDetailsModal}
                close={this.editShopDetailsModalClose}
                shopBusinessHours={shopBusinessHours}
                amenitisArray={amenitisArray}
                t={t}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <Grid columns={2}>
          <Grid.Row
            onMouseEnter={(e) =>
              this.setState({ editButtonClass: "display-edit-button" })
            }
            onMouseLeave={(e) =>
              this.setState({ editButtonClass: "not-display-icon" })
            }
          >
            <Grid.Column width={7}>
              <span className="headings">
                {t("storeNumber")} {store_number ? store_number : ""}
              </span>
            </Grid.Column>
            <Grid.Column width={7}>
              <span className="headings">
                {t("numberOfBays")} {number_of_bays ? number_of_bays : ""}
              </span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            onMouseEnter={(e) =>
              this.setState({ editButtonClass: "display-edit-button" })
            }
            onMouseLeave={(e) =>
              this.setState({ editButtonClass: "not-display-icon" })
            }
          >
            <Grid.Column width={7} className="details">
              <span style={{ display: "flex" }}>
                <b> {t("email")} </b> &nbsp;
                <span id="email">{main_email ? main_email : ""}</span>
              </span>
              <br />
              <span>
                <b> {t("phoneNumber")} </b>
                {main_phone_number ? formatPhoneNumber(main_phone_number) : ""}
              </span>
            </Grid.Column>
            <Grid.Column width={7}>
              {/* <span style={{display: "flex"}}><b> Time Zone: </b>&nbsp;{shop_time_zone}</span>
                            <br /> */}
              <span className="mobView">
                <b> {t("manager")} </b>
                {owner_name && owner_name !== "null" ? owner_name : ""}
              </span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7}>
              <div
                style={{ display: "inline-flex" }}
                onMouseEnter={(e) =>
                  this.setState({ iconClassName: "display-icon" })
                }
                onMouseLeave={(e) =>
                  this.setState({ iconClassName: "not-display-icon" })
                }
              >
                <ShopHours
                  operationalBusinessDays={operational_business_days}
                  shopBusinessHours={shopBusinessHours}
                  amenitisArray={amenitisArray}
                  t={t}
                />
                {isShopOwnerOrCompanyOwner(currentUser) && (
                  <Icon
                    className={iconClassName}
                    onClick={() =>
                      this.setState({
                        openEditShopBusinessHoursModal: !openEditShopBusinessHoursModal,
                      })
                    }
                    name="edit"
                  />
                )}
              </div>

              {openEditShopBusinessHoursModal ? (
                <ShopBusinessHoursEditModal
                  open={openEditShopBusinessHoursModal}
                  close={this.editShopDetailsModalClose}
                  shopBusinessHours={shopBusinessHours}
                  amenitisArray={amenitisArray}
                  t={t}
                />
              ) : (
                ""
              )}
            </Grid.Column>
            <Grid.Column>
              <div
                onMouseEnter={(e) =>
                  this.setState({ className: "display-edit-amenities-icon" })
                }
                onMouseLeave={(e) =>
                  this.setState({ className: "not-display-icon" })
                }
              >
                <div style={{ display: "inline-flex" }}>
                  <span className="headings">{t("amenities")} </span>
                  {isShopOwnerOrCompanyOwner(currentUser) && (
                    <Icon
                      className={className}
                      onClick={() =>
                        this.setState({
                          openAmenitiesModal: !openAmenitiesModal,
                        })
                      }
                      name="edit"
                    />
                  )}
                </div>
                <ul
                  style={{
                    listStyle: "none",
                    marginLeft: "30px",
                    padding: "0",
                  }}
                >
                  {amenitisArray && amenitisArray.length > 0 ? (
                    <div style={{ display: "inline-flex" }}>
                      <span style={{ width: "max-content" }}>
                        {aminitiesArray1 && this.showAmenities(aminitiesArray1)}
                      </span>
                      <span
                        style={{ width: "max-content", marginLeft: "20px" }}
                      >
                        {aminitiesArray2 && this.showAmenities(aminitiesArray2)}
                      </span>
                    </div>
                  ) : (
                    NA
                  )}
                </ul>
              </div>
              {openAmenitiesModal ? (
                <AmenitiesEditModal
                  open={openAmenitiesModal}
                  close={this.editShopDetailsModalClose}
                  shopBusinessHours={shopBusinessHours}
                  amenitisArray={amenitisArray}
                  t={t}
                />
              ) : (
                ""
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

export default ShopBasicDetails;
